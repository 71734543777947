import styled from "styled-components";

export const FormProfileEditPage = styled.div`
    width: 100%;
    max-width: 1100px;
    padding: 10px 10%;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputContainer p {
        text-align: center;
        color: red;
        font-size: 13px;
        margin: 2px 0;
        height: 14px;
    }

    .inputs {
        width: 320px;
    }

    .checkboxListContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .permissionsBlock {
        width: 100%;
    }

    .checkboxesList {
        width: 100%;
        max-height: 260px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 1050px) {
        .checkboxesList {
            max-height: 540px;
        }
        @media (max-width: 400px) {
            .checkboxesList {
                max-height: 100%;
            }
            .inputs {
                width: 260px;
            }
        }
    }
`;
