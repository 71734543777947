import { FactCheck, Add, Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonGeneric from "../../components/ButtonGeneric";
import { If } from "../../components/If";
import ModalAddEditCheckList from "../../components/ModalAddEditCheckList";
import ModalConfirm from "../../components/ModalConfirm";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import { useAuth } from "../../contexts/contexts";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { Container, ContentBtnNewCheckList, ContentTable } from "./styles";
import { ItemsChecklistListProps } from "./types";

export default function ItemsCheckListPage() {
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [visibleModalDelete, setVisibleModalDelete] =
        useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [itemsChecklists, setItemsChecklists] = useState<
        ItemsChecklistListProps[]
    >([]);
    const [itemChecklistSelected, setItemChecklistSelected] =
        useState<ItemsChecklistListProps | null>(null);

    const [idItemSelected, setIdItemSelected] = useState<number | null>(null);

    const navigate = useNavigate();

    const { hasPermissions, permissions } = useAuth();

    useEffect(() => {
        if (
            !hasPermissions("validators-checklist.listar") &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const getAllItemsCheckLists = async () => {
        try {
            setLoading(true);
            const responseItemsCheckLists = await api.get(
                "/customerValidationForm/"
            );
            if (responseItemsCheckLists.status) {
                setItemsChecklists(responseItemsCheckLists.data.response);
            }
        } catch (error) {
            defaultToast("error", "erro ao carregar os itens de checlists");
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModalEdit = (datasItem: ItemsChecklistListProps | null) => {
        setItemChecklistSelected(datasItem);
        setVisibleModal(true);
    };

    const handleOpenModalDelete = (idItem: number) => {
        setVisibleModalDelete(true);
        setIdItemSelected(idItem);
    };

    const handleDeleteItem = async () => {
        try {
            setLoading(true);
            const responseDeleteItem = await api.delete(
                `customerValidationForm/${idItemSelected}`
            );

            if (responseDeleteItem.status) {
                defaultToast("success", "Item deletado com sucesso");
                getAllItemsCheckLists();
                setVisibleModalDelete(false);
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllItemsCheckLists();
    }, []);

    const columns: GridColDef[] = [
        {
            field: "description",
            headerName: "Descrição",
            align: "left",
            flex: 35,
        },
        {
            field: "order",
            headerName: "Ordem",
            align: "left",
            flex: 5,
        },
        {
            field: "ações",
            headerName: "Ações",
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            flex: 5,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <If
                            condition={hasPermissions(
                                "validators-checklist.editar"
                            )}
                        >
                            <IconButton
                                color="primary"
                                onClick={() => handleOpenModalEdit(params.row)}
                            >
                                <Edit />
                            </IconButton>
                        </If>
                        <If
                            condition={hasPermissions(
                                "validators-checklist.deletar"
                            )}
                        >
                            <IconButton
                                onClick={() =>
                                    handleOpenModalDelete(params.row.id)
                                }
                                style={{
                                    color: "var(--delete)",
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </If>
                    </div>
                );
            },
        },
    ];

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Itens de checklists",
                    subtitle:
                        "Configurações de checklists para validações do aplicativo",
                    icon: <FactCheck sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <ContentBtnNewCheckList>
                        <ButtonGeneric
                            onclick={() => setVisibleModal(true)}
                            buttonColor="var(--primary)"
                            text="Novo checklist"
                            icon={<Add />}
                        />
                    </ContentBtnNewCheckList>

                    <ContentTable>
                        <TableDataGrid
                            hideExports
                            columns={columns}
                            rows={itemsChecklists}
                            loading={loading}
                        />
                    </ContentTable>

                    <ModalAddEditCheckList
                        itemChecklistSelected={itemChecklistSelected}
                        setItemChecklistSelected={setItemChecklistSelected}
                        getAllItemsCheckLists={getAllItemsCheckLists}
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                    />

                    <ModalConfirm
                        visible={visibleModalDelete}
                        setVisible={setVisibleModalDelete}
                        titleButton="Confirmar"
                        titleModal="Deseja realmente dele este item?"
                        isLoadingButton={loading}
                        onClick={handleDeleteItem}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
