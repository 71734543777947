/* eslint-disable */
import styled from "styled-components";

export const TabsContent = styled.div`
    width: 100%;
    padding: 10px 30px;
    box-shadow: 0px 0px 7px #88888830;
    background-color: white;
    position: relative;
    padding-bottom: 30px;

    .MuiTabPanel-root {
        padding: 15px;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
    }

    .container-cities-customer {
        max-height: 200px;
        overflow-y: auto;

        .content-cities-customer {
            margin: 1rem 0;

            > span {
                font-weight: bold;
            }
        }
    }

    .printButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .iconPrint {
        cursor: pointer;
        font-size: 2rem;
    }

    .titlePageContainer {
        text-align: center;
    }

    .content-header-status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .span-item {
            display: flex;
            gap: 5px;
            justify-content: right;
            justify-items: right
        }
    }

    .content-header-informations {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .boldText {
        font-size: 0.9rem;

        font-weight: 500;
    }

    h2 {
        margin-bottom: 3px;
    }

    h3 {
        margin-bottom: 3px;
    }

    .contentFields {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    .contentFieldsToSignatories {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        flex-wrap: wrap;
        margin-bottom: 0px;
        border-bottom: 1px dotted #b3b3b38c;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    .contentRows {
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .justifyCenter {
        display: flex;
        justify-content: center;
    }

    @keyframes colorChange {
        0% {
            color: white;
        }
        100% {
            color: black;
        }
    }

    .circleColor {
        display: flex;
        width: 90px;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #b3b3b38c;
        animation: colorChange 2s infinite alternate;
    }

    .informationsLinkContractContainer {
        margin-bottom: 10px;
        border: 1px solid #b3b3b38c;
        padding: 10px 5%;
        border-radius: 8px;
    }

    .buttonLinkContainer {
        width: 48%;
        display: flex;
        align-items: center;
    }

    .verySmallText {
        font-size: 0.7rem;
    }

    .smallText {
        font-size: 0.8rem;
    }

    .implementationsList {
        border-radius: 8px;
        padding: 0 4px;
        margin-bottom: 10px;
        background: #b3b3b33c;
    }

    .buttonInstall {
        width: 180px;
        font-size: 12px;
        padding: 4px 6px;
        border-radius: 4px;
        cursor: pointer;
    }

    @media (max-width: 800px) {
        padding: 1px;
        width: 100%;

        .contentFields {
            flex-direction: column;
        }
        .contentRows {
            width: 100%;
        }
        @media (max-width: 400px) {
            .checkboxesList {
                max-height: 100%;
            }
            .inputs {
                width: 260px;
            }
        }
    }
`;

export const ValidateCustomerModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 550px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;

    .topModalText {
        text-align: center;
        margin-bottom: 5%;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 550px) {
        .inputsContainer {
            flex-direction: column;
        }
    }
`;

export const InstallImplementationModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 550px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;

    .topModalText {
        text-align: center;
        margin-bottom: 5%;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 550px) {
        .inputsContainer {
            flex-direction: column;
        }
    }
`;

export const FinancialStyled = styled.div`
    height: 700px;
    .headerFinancialDetails {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: start;
    }

    .refresh-button {
        cursor: pointer;
    }

    .refresh-button-animation {
        cursor: pointer;
        transition: transform 0.2s;
        animation: rotate 0.7s forwards; /* Usando uma animação CSS para obter o efeito de rotação */
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg); /* A rotação final de 360 graus */
        }
    }

    form {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    .inputDueDate {
        width: 200px;
        position: relative;
        margin-bottom: 0;
    }

    h2 {
        border: none;
    }
    @media (max-width: 550px) {
        .headerFinancialDetails {
            flex-direction: column;
            gap: 25px;
            width: 100%;
        }

        form {
            flex-direction: column;
            align-items: flex-end;
            white-space: nowrap;
        }

        .inputsContainer {
            flex-direction: column;
        }

        .ezepgG {
            width: 100%;
        }
    }
`;

export const BillingDetailsModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    overflow: auto;

    h4 {
        text-align: center;
    }

    .topModalContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;
    }

    .topModalContainer button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: 0.3s;
        font-family: sans-serif;
        padding: 2px 3px;
        width: auto;
        height: auto;
        position: absolute;
        right: 0;
        &:hover {
            background-color: #8888889e;
        }
    }

    .mainContainerBilling {
        width: 100%;
    }

    .titleModal {
        display: flex;
        justify-content: center;
    }

    .titleModal h3 {
        font-size: 22px;
    }

    .rowsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 25px;
    }

    .content-date-picker-modal {
    }

    .keyAndValueContainer {
        width: 48%;
    }

    .keyContent {
        font-weight: 700;
        font-size: 14px;
        margin: 0;
    }

    .valueContent {
        font-weight: 400;
        font-size: 14px;
        margin: 0;
    }

    .detailsPayment {
        background-color: #b3b3b33c;
        border-radius: 8px;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
    }

    .payment-link {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    @media (max-width: 400px) {
        .keyAndValueContainer {
            width: 100%;
        }
    }
`;

export const EditNoteModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    overflow: auto;

    h4 {
        text-align: center;
    }

    p {
        font-weight: 400;
    }

    .topModalContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;
    }

    .topModalContainer button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: 0.3s;
        font-family: sans-serif;
        padding: 2px 3px;
        width: auto;
        height: auto;
        position: absolute;
        right: 0;
        &:hover {
            background-color: #8888889e;
        }
    }

    form {
        width: 100%;
    }

    .buttonsContainer {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }
`;

export const DeleteNoteModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    overflow: auto;

    h4 {
        text-align: center;
    }

    .topModalContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;
    }

    .topModalContainer button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: 0.3s;
        font-family: sans-serif;
        padding: 2px 3px;
        width: auto;
        height: auto;
        position: absolute;
        right: 0;
        &:hover {
            background-color: #8888889e;
        }
    }

    form {
        width: 100%;
    }

    .buttonsContainer {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

export const NotesCustomerStyled = styled.div`
    h2 {
        text-align: center;
    }

    .chatMainContainer {
        display: flex;
        flex-direction: column-reverse;
        background-color: #ebebeb;
        padding: 20px;
        margin: 20px 0;
        border-radius: 8px;
    }

    .inputFile {
        margin-top: 10px;
        cursor: pointer;
    }

    .inputFile[type="file"]::file-selector-button {
        padding: 0.2em 0.4em;
        border-radius: 4px;
        transition: 1s;
        color: white;
        background-color: var(--primary);
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .buttonContainer {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .chatsContainer {
        margin-bottom: 35px;
        padding: 10px;
        border-radius: 4px;
        background-color: #bfd7ff;
        min-width: 40%;
    }

    .topChatContainer {
        position: relative;
        max-height: 25px;
        border-bottom: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 35px;
    }

    .editedNoteText {
        position: absolute;
        top: 1;
        right: 50%;
        transform: translateX(50%);
        font-size: 12px;
        color: #cccccc;
    }

    .hamburgerMenuContainer {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hamburgerMenuContainer button svg {
        position: absolute;
        padding-right: 10px;
    }

    .nameUserNote {
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
    }

    .topChatContainer span {
        font-size: 13px;
        font-weight: 400;
        margin-left: 10px;
    }

    .chatsContents .quill {
        padding: 0px 23px !important;
        border: none !important;
    }

    .chatsContents .ql-container {
        border: none !important;
    }

    .deletedNoteMessage {
        padding: 0 35px;
        color: #ff0000ba;
        font-weight: 700;
    }

    .fileContainer {
        padding: 10px 35px !important;
        border-top: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
    }

    .chatsContents p {
        font-size: 14px;
    }

    .fileContainer p {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
    }

    .fileContainer a {
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        text-decoration: none;
        color: black;
        &:hover {
            text-decoration: underline;
        }
    }

    .newNoteTitle {
        font-weight: 700;
        padding: 0;
        margin-top: 50px;
        text-align: center;
    }

    @media (max-width: 550px) {
        .topChatContainer {
            max-height: none;
            display: flex;
            align-items: start;
            flex-direction: column;
        }

        .topChatContainer {
            padding: 0px 15px;
        }

        .editedNoteText {
            right: 10%;
        }

        .chatsContents {
            padding: 0px 15px;
        }
    }
`;
