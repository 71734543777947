/* eslint-disable */
import styled from "styled-components";

export const Container = styled.div`
    
`;

export const ContentButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 1rem 0;
`;

export const ContentInputs = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ContentBtnSearch = styled.div``;

export const ContentTable = styled.div`
    margin: 1rem 0;
    height: 600px;

    .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
        display: none;
    }
`;

export const ContentPagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;
