import {
    Modal,
    TextField,
    Select,
    FormHelperText,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

import { Container, Form } from "./styles";
import { Constants } from "../../configs/constants";
import { api } from "../../services/api";
import { defaultToast } from "../../helpers/toast/defaultToast";

import { VersionsApplicationsPageProps } from "../../pages/VersionsApplicationsPage/types";

const formVersionApplication = yup.object().shape({
    typeApp: yup.string().required("Este campo é obrigatório"),
    versionApp: yup.string().required("Este campo é obrigatório"),
    descriptionApp: yup.string().required("Este campo é obrigatório"),
});

const typesApps: any = Constants.appTypes;

interface FormVersionApplicationProps {
    typeApp: string;
    versionApp: string;
    descriptionApp: string;
}

interface ModalEditVersionsProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
    versionSelected: VersionsApplicationsPageProps | null;
    setVersionSelected: (data: VersionsApplicationsPageProps | null) => void;
    getLastVersions: () => void;
}

export function ModalEditVersions({
    visible,
    setVisible,
    setVersionSelected,
    versionSelected,
    getLastVersions,
}: ModalEditVersionsProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, setValue } =
        useForm<FormVersionApplicationProps>({
            resolver: yupResolver(formVersionApplication),
        });

    const handleSaveVersions = async (datas: FormVersionApplicationProps) => {
        try {
            setLoading(true);
            const responseSaveVersions = await api.put(
                `appVersion/${versionSelected?.id}`,
                {
                    title: JSON.parse(datas.typeApp).name,
                    version: datas.versionApp,
                    app_type_id: JSON.parse(datas.typeApp).id,
                    description: datas.descriptionApp,
                }
            );

            if (responseSaveVersions.status) {
                setVersionSelected(null);
                setVisible(false);
                getLastVersions();
                defaultToast("success", "Versão editada com sucesso!");
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoading(false);
        }
    };

    const getObjectById = (id: number) => {
        // eslint-disable-next-line
        for (const key in typesApps) {
            if (typesApps[key].id === id) {
                return typesApps[key];
            }
        }
        return null;
    };

    useEffect(() => {
        if (versionSelected) {
            // eslint-disable-next-line
            setValue(
                "typeApp",
                JSON.stringify(getObjectById(versionSelected.app_type_id))
            );
            setValue("versionApp", versionSelected?.version);
            setValue("descriptionApp", versionSelected?.description);
        }
    }, [versionSelected]);

    return (
        <Modal
            open={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="modal-edit-version-title"
            aria-describedby="modal-edit-version-description"
        >
            <Container>
                <Form onSubmit={handleSubmit(handleSaveVersions)}>
                    <h2>{`Editar Versão ${versionSelected?.version}`}</h2>
                    <Controller
                        control={control}
                        name="typeApp"
                        render={({
                            field: {
                                onChange,
                                value = JSON.stringify(
                                    Constants.appTypes.mobilityDriver
                                ),
                            },
                            fieldState: { error },
                        }) => (
                            <FormControl fullWidth>
                                <InputLabel id="type-application-select-label">
                                    Tipo do aplicativo
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="type-application-select-label"
                                    id="type-application-select"
                                    label="Tipo do aplicativo"
                                    onChange={onChange}
                                    value={value}
                                    error={Boolean(error)}
                                >
                                    <MenuItem
                                        value={JSON.stringify(
                                            Constants.appTypes.mobilityDriver
                                        )}
                                    >
                                        {Constants.appTypes.mobilityDriver.name}
                                    </MenuItem>
                                    <MenuItem
                                        value={JSON.stringify(
                                            Constants.appTypes.mobilityCustomer
                                        )}
                                    >
                                        {
                                            Constants.appTypes.mobilityCustomer
                                                .name
                                        }
                                    </MenuItem>
                                    <MenuItem
                                        value={JSON.stringify(
                                            Constants.appTypes.deliveryAdmin
                                        )}
                                    >
                                        {Constants.appTypes.deliveryAdmin.name}
                                    </MenuItem>
                                    <MenuItem
                                        value={JSON.stringify(
                                            Constants.appTypes.deliveryCustomer
                                        )}
                                    >
                                        {
                                            Constants.appTypes.deliveryCustomer
                                                .name
                                        }
                                    </MenuItem>
                                </Select>
                                {error && (
                                    <FormHelperText error>
                                        {error.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />

                    <Controller
                        control={control}
                        name="versionApp"
                        render={({
                            field: { onChange, value = "" },
                            fieldState: { error },
                        }) => (
                            <TextField
                                size="small"
                                label="Versão"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={error && error.message}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="descriptionApp"
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <TextField
                                size="small"
                                multiline
                                rows={10}
                                placeholder="Melhorias"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={error && error.message}
                            />
                        )}
                    />

                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                    >
                        Salvar
                    </LoadingButton>
                </Form>
            </Container>
        </Modal>
    );
}
