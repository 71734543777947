import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
} from "@mui/material";

interface Item {
    value: string | number;
    label: string;
}

interface Props {
    label: string;
    onChange: (e: any) => void;
    items: Item[];
    value: string | number;
    name: string;
    size?: number;
    enter?: boolean;
    onBlur?: (e: any) => void;
    onSelect?: (e: any) => void;
    onFocus?: (e: any) => void;
    fullWidth?: boolean;
    defaultValue?: string | number;
    error?: boolean;
    helperText?: string;
}

export function SelectComponent({
    label,
    onChange,
    items,
    value,
    name,
    size = 12,
    enter = false,
    onBlur,
    onFocus,
    onSelect,
    fullWidth,
    defaultValue,
    error,
    helperText,
}: Props) {
    return (
        <FormControl
            fullWidth
            className="select"
            sx={{
                width: `${fullWidth ? "100%" : `${size}rem`}`,
            }}
            size="small"
        >
            <InputLabel className="select-label">{label}</InputLabel>
            <Select
                fullWidth
                labelId="select-status-label"
                id="select-status"
                value={value}
                label={label}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e)}
                name={name}
                onDragEnter={
                    enter
                        ? (e) => {
                              onChange(e);
                          }
                        : undefined
                }
                onBlur={onBlur}
                onFocus={onFocus}
                error={error}
            >
                {items.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
