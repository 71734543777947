/* eslint-disable @typescript-eslint/no-explicit-any */
import { Close, Warning, Edit } from "@mui/icons-material";
import { Chip, Modal, IconButton, Popover } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "@mui/x-date-pickers/locales";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

import Toast from "../../components/Toast";
import { formatCurrencyToShow } from "../../helpers/currency";
import { getStatusChip } from "../../helpers/statusChip/statusChip";
import { Invoice } from "./MonthlyBilling/Invoice";
import { BillingDetailsModalStyled } from "./styles";
import { api } from "../../services/api";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { TransformDateEUA } from "../../helpers/tranformDateEUA";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCloseBillingDetailsModal: () => void;
    openBillingDetailsModal: boolean;
    currentBillingDetails: BillingsDetailsProps | null;
    handleClick: () => void;
    holder?: any;
    getFinancial?: () => void;
    setOpenBillingDetailsModal: (data: boolean) => void;
}

function BillingDetailsModal({
    handleCloseBillingDetailsModal,
    openBillingDetailsModal,
    currentBillingDetails,
    handleClick,
    holder,
    getFinancial,
    setOpenBillingDetailsModal,
}: Props) {
    const [loadingDueDate, setLoadingDueDate] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [dateSelected, setDateSelected] = useState<Dayjs | null>(null);

    const handleOpenEditDate = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseEditDate = () => {
        setAnchorEl(null);
        setDateSelected(null);
    };

    function handleCloseToast() {
        setOpenToast(false);
    }

    const updated = () => {
        handleClick();
    };

    const handleUpdateDueDay = async () => {
        if (!dateSelected) {
            defaultToast("warning", "Escolha uma data diferente!");
            return;
        }

        try {
            setLoadingDueDate(true);

            const dateFormated = TransformDateEUA(
                new Date(dateSelected as any).toLocaleDateString()
            )?.split(" ")[0];

            const responseUpdateDueDay = await api.put(
                `monthly-billing/${currentBillingDetails?.id}`,
                {
                    due_date: dateFormated,
                }
            );

            if (responseUpdateDueDay.status) {
                defaultToast(
                    "success",
                    "Data de vencimento alterada com sucesso!"
                );
                handleCloseBillingDetailsModal();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (error) {
            defaultToast("error", "Erro ao alterar data de vencimento");
        } finally {
            setLoadingDueDate(false);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Modal
            open={openBillingDetailsModal}
            onClose={handleCloseBillingDetailsModal}
        >
            <BillingDetailsModalStyled>
                <div className="topModalContainer">
                    <h3>Detalhes da fatura</h3>
                    {/* eslint-disable-next-line */}
                    <button
                        onClick={handleCloseBillingDetailsModal}
                        type="button"
                    >
                        <Close />
                    </button>
                </div>

                <div className="mainContainerBilling">
                    <div className="titleModal" />
                    <div className="rowsContainer">
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Situação:</p>
                            <p className="valueContent">
                                {currentBillingDetails
                                    ? getStatusChip(
                                          currentBillingDetails.status_id
                                      )
                                    : getStatusChip("" as any)}
                            </p>
                        </div>
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Valor total:</p>
                            <p className="valueContent">
                                {" "}
                                R$:{" "}
                                {currentBillingDetails &&
                                    formatCurrencyToShow(
                                        (currentBillingDetails &&
                                            currentBillingDetails?.total_amount) ||
                                            ""
                                    )}
                            </p>
                        </div>
                    </div>
                    <div className="rowsContainer">
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Data de referência:</p>
                            <p className="valueContent">
                                {(currentBillingDetails &&
                                    currentBillingDetails?.date_reference
                                        .split("-")
                                        .reverse()
                                        .join("/")) ||
                                    ""}
                            </p>
                        </div>
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Vencimento em:</p>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <p className="valueContent">
                                    {(currentBillingDetails &&
                                        currentBillingDetails?.due_date
                                            .slice(0, -9)
                                            .split("-")
                                            .reverse()
                                            .join("/")) ||
                                        ""}
                                </p>
                                <IconButton
                                    title="Editar vencimento"
                                    onClick={handleOpenEditDate}
                                >
                                    <Edit color="primary" />
                                </IconButton>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseEditDate}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    <div className="content-date-picker-modal">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            localeText={
                                                ptBR.components
                                                    .MuiLocalizationProvider
                                                    .defaultProps.localeText
                                            }
                                            adapterLocale="pt-br"
                                        >
                                            <StaticDatePicker
                                                defaultValue={dayjs(
                                                    currentBillingDetails?.due_date?.split(
                                                        " "
                                                    )[0]
                                                )}
                                                onChange={(dateSelected) => {
                                                    setDateSelected(
                                                        dateSelected
                                                    );
                                                }}
                                                onAccept={handleUpdateDueDay}
                                                onClose={handleCloseEditDate}
                                                loading={loadingDueDate}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="rowsContainer">
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Data de fechamento:</p>
                            <p className="valueContent">
                                {(currentBillingDetails &&
                                    currentBillingDetails?.closing_date
                                        .slice(0, -9)
                                        .split("-")
                                        .reverse()
                                        .join("/")) ||
                                    ""}
                            </p>
                        </div>
                        <div className="keyAndValueContainer">
                            <p className="keyContent">Id da mensalidade:</p>
                            <p className="valueContent">
                                {(currentBillingDetails &&
                                    currentBillingDetails?.uuid) ||
                                    ""}
                            </p>
                        </div>

                        {currentBillingDetails?.status_id === 1 ||
                        currentBillingDetails?.status_id === 4 ? null : (
                            <div className="rowsContainer">
                                <div className="payment-link">
                                    {currentBillingDetails?.total_amount ===
                                    0.0 ? (
                                        <span>
                                            <Chip
                                                label="Faturas fazias não podem ser pagas"
                                                icon={<Warning />}
                                                color="warning"
                                                size="medium"
                                            />
                                        </span>
                                    ) : (
                                        <h4>
                                            Link de pagamento:{" "}
                                            <a
                                                href={`${window.env.REACT_APP_LINK_PAYMENT}checkout?id=${currentBillingDetails?.payment?.payment_reference}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                abrir link.
                                            </a>
                                        </h4>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {currentBillingDetails &&
                        currentBillingDetails?.payment?.payment_date && (
                            <div className="rowsContainer">
                                <div className="keyAndValueContainer">
                                    <p className="keyContent">
                                        Data do pagamento:
                                    </p>
                                    <p className="valueContent">
                                        {new Date(
                                            currentBillingDetails?.payment.payment_date
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        )}

                    <div>
                        <Invoice
                            setOpenBillingDetailsModal={
                                setOpenBillingDetailsModal
                            }
                            getFinancial={getFinancial as any}
                            billing={
                                currentBillingDetails?.billing_products ?? []
                            }
                            monthlyBilling={currentBillingDetails}
                            updated={updated}
                            holder={holder}
                        />
                    </div>
                </div>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    // severity={toastRequisitionResult}
                    // text={textToast}
                />
            </BillingDetailsModalStyled>
        </Modal>
    );
}
export default BillingDetailsModal;
