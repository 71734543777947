/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, MoreVert } from "@mui/icons-material";
import {
    AlertColor,
    Chip,
    Button,
    Popover,
    Switch,
    Divider,
} from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";

import InputGeneric from "../../../components/InputGeneric";
import PreloadButton from "../../../components/PreloadButton";
import TableDataGrid from "../../../components/TableDataGrid";
import Toast from "../../../components/Toast";
import { formatCurrencyToShow } from "../../../helpers/currency";
import { getStatusChip } from "../../../helpers/statusChip/statusChip";
import { defaultToast } from "../../../helpers/toast/defaultToast";
import { api } from "../../../services/api";
import BillingDetailsModal from "../BillingDetailsModal";
import StartGeneratingInvoicesModal from "../StartGeneratingInvoicesModal";
import { FinancialStyled } from "../styles";
import { CustomersProps } from "../types";
import { CustomerCards } from "./CustomerCards";
import {
    Chips,
    FinacialDetails,
    OptionStyled,
    ContentProposalCustomer,
    ContainerProposalCustomer,
} from "./styles";
import { Account, ProposalByAccountProps } from "./types";

interface Props {
    currentAccountId: number | undefined;
    myParam: string;
    setCurrentCustomer: Dispatch<SetStateAction<CustomersProps | undefined>>;
    currentCustomer: CustomersProps | undefined;
}

type dueDateFormTypeValues = {
    dueDate: number;
};

const dueDateFormSchema = yup.object().shape({
    dueDate: yup
        .number()
        .required("Campo obrigatório")
        .min(2, "Apenas valores entre 2 e 28")
        .max(28, "Apenas valores entre 2 e 28"),
});

function FinancialContent({
    currentAccountId,
    myParam,
    setCurrentCustomer,
    currentCustomer,
}: Props) {
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<dueDateFormTypeValues>({
        resolver: yupResolver(dueDateFormSchema),
    });
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [loadingTable, setLoadingTable] = useState(true);

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    // LISTA DE TODAS AS FATURAS
    const [monthlyBillings, setMonthlyBillings] = useState<any>([]);
    const [currentAccount, setCurrentAccount] = useState<Account>({
        id: 0,
        enabled: false,
    });
    const [accountEnabled, setAccountEnabled] = useState<boolean>(
        currentAccount?.enabled
    );

    // SOMENTE A FATURA SELECIONADA
    const [currentBillingDetails, setCurrentBillingDetails] =
        useState<BillingsDetailsProps | null>(null);

    const [openBillingDetailsModal, setOpenBillingDetailsModal] =
        useState(false);

    const [proposalsByAccount, setProposalsByAccount] =
        useState<ProposalByAccountProps | null>(null);

    const handleCloseBillingDetailsModal = () => {
        setOpenBillingDetailsModal(false);
    };

    const getFinancials = () => {
        if (currentAccountId !== null)
            api.get(`/account/${currentAccountId}`).then((response) => {
                setLoadingTable(false);

                setCurrentAccount({
                    id: response.data.response.id,
                    enabled: Boolean(response.data.response.enabled),
                });
                setAccountEnabled(Boolean(response.data.response.enabled));
                setMonthlyBillings(response.data.response.monthly_billings);
                setValue("dueDate", response.data.response.due_day);
            });
        else {
            setLoadingTable(false);
        }
    };

    const getProposalByAccount = async () => {
        try {
            const responseProposalAccount = await api.get(
                `proposal/find-by-account/${currentAccountId}`
            );
            setProposalsByAccount(responseProposalAccount.data.response);
        } catch (error) {
            defaultToast("success", "Erro ao buscar proposta de cliente");
            console.error(error);
        }
    };

    useEffect(() => {
        getFinancials();
        getProposalByAccount();
    }, []);

    function updateDueDay() {
        setIsLoadingButton(true);

        api.put(`/account/${currentAccountId}`, {
            due_day: getValues("dueDate"),
        }).then(() => {
            setLoadingTable(false);
            api.get(`/account/${currentAccountId}`)
                .then((response) => {
                    setLoadingTable(false);
                    setMonthlyBillings(response.data.response.monthly_billings);
                    setValue("dueDate", response.data.response.due_day);
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                })
                .catch((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(response.response.data.response.name);
                    setIsLoadingButton(false);
                });
            setIsLoadingButton(false);
        });
    }
    const [
        openStartGeneratingInvoicesModal,
        setOpenStartGeneratingInvoicesModal,
    ] = useState(false);

    const handleCloseStartGeneratingInvoicesModal = () => {
        setOpenStartGeneratingInvoicesModal(false);
    };

    const columns: GridColDef[] = [
        {
            field: "month_reference",
            headerName: "Mês de referência",
            flex: 10,
        },
        {
            field: "date_reference",
            headerName: "Data de referência",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.month_and_year_reference}`,
        },
        {
            field: "data_de_fechamento",
            headerName: "Data de fechamento",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.closing_date.split(" ")[0]}`,
        },
        {
            field: "data_de_vencimento",
            headerName: "Data de Vencimento",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.due_date.split(" ")[0]}`,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 10,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div>{getStatusChip(params.row.status.id)}</div>
            ),
        },
        {
            field: "total_amount",
            headerName: "Valor",
            flex: 10,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {params.row.status.id === 7 ? (
                        <s>
                            R$ {formatCurrencyToShow(params.row.total_amount)}
                        </s>
                    ) : (
                        <span>
                            R$ {formatCurrencyToShow(params.row.total_amount)}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "total_paid",
            headerName: "Valor Pago",
            flex: 10,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {params.row.status.id === 7 ? (
                        <s>R$ {formatCurrencyToShow(params.row.total_paid)}</s>
                    ) : (
                        <span>
                            R$ {formatCurrencyToShow(params.row.total_paid)}
                        </span>
                    )}
                </span>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 15,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <Visibility
                        className="cursorPointerGlobal"
                        // PEGO SOMENTE OS DETALHES DA FATURA SELECIONADA
                        onClick={() => {
                            setOpenBillingDetailsModal(true);
                            setCurrentBillingDetails(params.row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const [refreshAnimation, setRefreshAnimation] = useState(false);

    const handleClick = () => {
        setRefreshAnimation(true);
        setTimeout(() => {
            setRefreshAnimation(false);
        }, 710);

        api.get(`/account/${currentAccountId}`).then((response) => {
            setLoadingTable(false);
            setCurrentAccount({
                id: response.data.response.id,
                enabled: Boolean(response.data.response.enabled),
            });
            setAccountEnabled(Boolean(response.data.response.enabled));
            setMonthlyBillings(response.data.response.monthly_billings);
            setValue("dueDate", response.data.response.due_day);
        });
    };

    const handleSwitchMonthlyBilling = (accountEnabledStatus: boolean) => {
        setLoadingTable(true);
        api.patch(
            `/account/on-off/${currentAccountId}/${Number(
                accountEnabledStatus
            )}`
        )
            .then(() => {
                defaultToast(
                    "success",
                    `${
                        accountEnabledStatus ? "habilitado" : "desabilitado"
                    } com sucesso!`
                );
            })
            .catch(() => {
                defaultToast(
                    "error",
                    "Não foi possível alterar o status da fatura"
                );
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        handleClick();
    }, [accountEnabled]);

    return (
        <>
            <FinancialStyled>
                <div className="containerListTablePagesGlobal">
                    <div className="headerFinancialDetails">
                        <div className="contentRows">
                            <OptionStyled>
                                <Button
                                    aria-describedby={id}
                                    type="button"
                                    color="inherit"
                                    variant="contained"
                                    onClick={(e) => {
                                        // setOpenStartGeneratingInvoicesModal(
                                        //     true
                                        // );
                                        handleClickPopover(e);
                                    }}
                                    size="small"
                                >
                                    <MoreVert />
                                    Opções
                                </Button>
                                <Chips>
                                    {currentCustomer?.activate_invoice === 1 ? (
                                        <Chip
                                            label="Assinatura ativa"
                                            color="success"
                                            variant="outlined"
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {currentAccount.enabled === true ? (
                                        <Chip
                                            label="Geração de faturas ativa"
                                            color="success"
                                            variant="outlined"
                                        />
                                    ) : (
                                        <Chip
                                            label={
                                                currentCustomer?.account_id !==
                                                null
                                                    ? "Geração de faturas inativa"
                                                    : "Cliente não possui um conta de faturas"
                                            }
                                            color="default"
                                            variant="outlined"
                                        />
                                    )}
                                </Chips>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        padding: "12px",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                        }}
                                    >
                                        <div>
                                            <Switch
                                                checked={accountEnabled}
                                                onChange={() => {
                                                    setAccountEnabled(
                                                        !accountEnabled
                                                    );
                                                    handleSwitchMonthlyBilling(
                                                        !accountEnabled
                                                    );
                                                }}
                                            />{" "}
                                            {accountEnabled
                                                ? "Pausar geração e envio de faturas"
                                                : "Retornar geração e envio de faturas"}
                                        </div>
                                        <Divider />
                                        <Button
                                            aria-describedby={id}
                                            type="button"
                                            className="buttonInstall"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setOpenStartGeneratingInvoicesModal(
                                                    true
                                                );
                                            }}
                                            size="small"
                                            disabled={
                                                currentCustomer?.activate_invoice ===
                                                1
                                            }
                                        >
                                            Ativar assinatura do plano na fatura
                                        </Button>
                                    </div>
                                </Popover>
                            </OptionStyled>

                            {proposalsByAccount &&
                                proposalsByAccount?.payment.length > 0 && (
                                    <ContainerProposalCustomer>
                                        <ContentProposalCustomer>
                                            <span>Entrada de pagamento -</span>
                                            <span>
                                                {`R$ ${formatCurrencyToShow(
                                                    proposalsByAccount
                                                        .payment[0].amount
                                                )}`}
                                            </span>
                                        </ContentProposalCustomer>

                                        <div>
                                            <Link
                                                title="Ver detalhes da proposta"
                                                rel="noreferrer"
                                                target="_blank"
                                                to={`/comercial/proposal/proposalDetails?id=${proposalsByAccount?.id}`}
                                            >
                                                Ver detalhes da proposta
                                            </Link>
                                        </div>
                                    </ContainerProposalCustomer>
                                )}
                        </div>
                        {currentAccountId !== null ? (
                            <form onSubmit={handleSubmit(updateDueDay)}>
                                <Controller
                                    control={control}
                                    name="dueDate"
                                    render={({
                                        field: { onBlur, value = "", ref },
                                    }) => (
                                        <InputGeneric
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const { value } = event.target;

                                                setValue(
                                                    "dueDate",
                                                    Number(
                                                        value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        )
                                                    ) as number
                                                );
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            size="small"
                                            className="inputDueDate"
                                            label="Dia do vencimento"
                                            error={!!errors.dueDate}
                                            ref={ref}
                                            helperText={
                                                errors?.dueDate?.message
                                            }
                                        />
                                    )}
                                />
                                <PreloadButton
                                    colorText="white"
                                    background="var(--confirm)"
                                    loading={isLoadingButton}
                                    text="Atualizar"
                                    type="submit"
                                    sx={{ minWidth: "140px" }}
                                />
                            </form>
                        ) : (
                            ""
                        )}
                    </div>
                    <TableDataGrid
                        rows={monthlyBillings}
                        columns={columns}
                        loading={loadingTable}
                    />
                </div>
            </FinancialStyled>
            <BillingDetailsModal
                getFinancial={getFinancials}
                openBillingDetailsModal={openBillingDetailsModal}
                handleCloseBillingDetailsModal={handleCloseBillingDetailsModal}
                currentBillingDetails={currentBillingDetails}
                handleClick={handleClick}
                holder={{
                    phone: currentCustomer?.phone,
                }}
                setOpenBillingDetailsModal={setOpenBillingDetailsModal}
            />
            <StartGeneratingInvoicesModal
                setCurrentCustomer={setCurrentCustomer}
                openStartGeneratingInvoicesModal={
                    openStartGeneratingInvoicesModal
                }
                handleCloseStartGeneratingInvoicesModal={
                    handleCloseStartGeneratingInvoicesModal
                }
                myParam={myParam ?? ""}
            />
            <FinacialDetails>
                <CustomerCards cards={currentCustomer?.cards ?? []} />
            </FinacialDetails>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default FinancialContent;
