/* eslint-disable */

import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    FormGroup,
    AlertColor,
    FormLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/contexts";

import {
    Container,
    ContentTitlePage,
    Body,
    ContentInput,
    ContentButtonSave,
} from "./styles";

import { api } from "../../services/api";

import PageWrapper from "../../components/PageWrapper";
import PreloadButton from "../../components/PreloadButton";
import PreloadFb from "../../components/Preload";

import { TypesNotificationsProps, DateReferencesProps } from "./types";
import Toast from "../../components/Toast";

const schemaForms = yup.object({
    enabled: yup.string().required("O campo status é obrigatório"),
    titleMessage: yup.string().required("O campo título é obrigatório"),
    bodyMessage: yup.string().required("O campo mensagem é obrigatório"),
    when: yup.string().required("O campo quantidade de dias é obrigatório"),
    dateReference: yup
        .number()
        .required("O campo de data de referência é obrigatório"),
    type: yup.number().required("O campo tipo de notificação é obrigatório"),
    short_message: yup
        .string()
        .required("O campo mensagem curta é obrigatório"),
    sendTo: yup
        .object()
        .shape({
            email: yup.boolean(),
            sms: yup.boolean(),
            whatsapp: yup.boolean(),
        })
        .required("O campo de modos de envio é obrigatório"),
});

let objectFormSend = {
    email: false,
    sms: false,
    whatsapp: false,
};

type SchemaFormData = yup.InferType<typeof schemaForms>;

function ConfigAddNotificationsPage() {
    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const [typesNotifications, setTypesNotifications] = useState<
        TypesNotificationsProps[]
    >([]);

    const [datesReferences, setDatesReferences] = useState<
        DateReferencesProps[]
    >([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [openToast, setOpenToast] = useState<boolean>(false);
    const [textToast, setTextToast] = useState<string>("");
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();

    const navigate = useNavigate();

    const { hasPermissions, permissions } = useAuth();

    const handleAddNewNotifications = async (datas: SchemaFormData) => {
        try {
            setIsLoading(true);

            const responseAddNotifications = await api.post("notifications", {
                title: datas.titleMessage,
                body: datas.bodyMessage,
                when: Number(datas.when),
                date_reference_id: datas.dateReference,
                type_id: datas.type,
                enabled: datas.enabled === "true" ? true : false,
                vias: datas.sendTo,
                short_body: datas.short_message,
            });

            if (responseAddNotifications.status) {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast("Nova configuração adicionada com sucesso");

                setTimeout(() => {
                    navigate("/settings/notifications");
                    setOpenToast(false);
                }, 2000);
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast("Erro ao salvar noa configuração de notificação");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeFormsSend = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target as HTMLInputElement;

        if (value === "email") {
            objectFormSend.email
                ? (objectFormSend.email = false)
                : (objectFormSend.email = true);
        }

        if (value === "sms") {
            objectFormSend.sms
                ? (objectFormSend.sms = false)
                : (objectFormSend.sms = true);
        }

        if (value === "whatsapp") {
            objectFormSend.whatsapp
                ? (objectFormSend.whatsapp = false)
                : (objectFormSend.whatsapp = true);
        }

        setValue("sendTo", objectFormSend as any);
    };

    const getTypesNotificationsAndDateReferences = () => {
        setLoading(true);
        const responseTypesNotifications = api.get("notifications/types");
        const responseDateReferences = api.get("notifications/date-references");

        Promise.all([responseTypesNotifications, responseDateReferences])
            .then((response) => {
                if (response[0].status) {
                    setTypesNotifications(response[0].data.response);
                }

                if (response[1].status) {
                    setDatesReferences(response[1].data.response);
                }
            })
            .catch((error) => {
                console.error(error);
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(
                    "Erro ao pegar os tipos de notificações e datas de referentes"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const translateDateReference = (value: string) => {
        switch (value) {
            case "due_date":
                return "Data de Vencimento";
            case "closing_date":
                return "Data de Fechamento";
            default:
                return "not found";
        }
    };

    useEffect(() => {
        if (
            hasPermissions("notificacao.criar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    useEffect(() => {
        getTypesNotificationsAndDateReferences();
        setValue("sendTo", objectFormSend);
    }, []);

    return (
        <PageWrapper>
            {loading ? (
                <PreloadFb />
            ) : (
                <Container>
                    <ContentTitlePage>
                        <h1>Nova Configuração de Notificação</h1>
                    </ContentTitlePage>
                    <Body onSubmit={handleSubmit(handleAddNewNotifications)}>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="titleMessage"
                                render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        ref={ref}
                                        name={name}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        className="smallInput"
                                        label="Título da mensagem"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="when"
                                render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        type="number"
                                        fullWidth
                                        ref={ref}
                                        name={name}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        className="smallInput"
                                        label="Quantos dias antes ou depois notificar, ex: 5, -9, 1"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="enabled"
                                render={({
                                    field: { name, onChange, ref, value },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="status-select-label"
                                        >
                                            Status
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            ref={ref}
                                            value={value}
                                            size="small"
                                            labelId="status-select-label"
                                            id="status-select"
                                            label="Status"
                                        >
                                            <MenuItem value="true">
                                                Ativo
                                            </MenuItem>
                                            <MenuItem value="false">
                                                Inativo
                                            </MenuItem>
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="type"
                                render={({
                                    field: { name, onChange, ref, value },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="type-notifications-select-label"
                                        >
                                            Tipo de Notificação
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            ref={ref}
                                            value={value}
                                            size="small"
                                            labelId="type-notifications-select-label"
                                            id="type-notifications-select"
                                            label="Tipo de Notificação"
                                        >
                                            {typesNotifications.map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value.id}
                                                        value={value.id}
                                                    >
                                                        {value.name.toLocaleUpperCase()}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="dateReference"
                                render={({
                                    field: { name, onChange, ref, value },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="date-reference-select-label"
                                        >
                                            Data Referente
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            ref={ref}
                                            value={value}
                                            size="small"
                                            labelId="date-reference-select-label"
                                            id="date-reference-select"
                                            label="Data Referente"
                                        >
                                            {datesReferences.map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value.id}
                                                        value={value.id}
                                                    >
                                                        {translateDateReference(
                                                            value.column_name
                                                        ).toLocaleUpperCase()}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </ContentInput>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="sendTo"
                                render={({
                                    field: { ref },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormLabel>Formas de envio</FormLabel>
                                        <FormGroup
                                            row
                                            ref={ref}
                                            onChange={handleChangeFormsSend}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox value="email" />
                                                }
                                                label="Email"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox value="sms" />
                                                }
                                                label="SMS"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox value="whatsapp" />
                                                }
                                                label="Whatsapp"
                                            />

                                            {error && (
                                                <FormHelperText>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </FormGroup>
                                    </div>
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="bodyMessage"
                                render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        ref={ref}
                                        name={name}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        className="smallInput"
                                        label="Mensagem"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        multiline
                                        rows={5}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="short_message"
                                render={({
                                    field: { onChange, value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        ref={ref}
                                        name={name}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        className="smallInput"
                                        label="Mensagem curta"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentButtonSave>
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoading}
                                text="CRIAR NOTIFICAÇÃO"
                                type="submit"
                                sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "13px !important",
                                }}
                            />
                        </ContentButtonSave>
                    </Body>
                    <Toast
                        open={openToast}
                        onClose={() => setOpenToast(false)}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Container>
            )}
        </PageWrapper>
    );
}

export default ConfigAddNotificationsPage;
