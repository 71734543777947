/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    TaskAlt,
    Visibility,
    AppShortcut,
    Person,
    NoCell,
    Delete,
    MonetizationOn,
} from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HeaderPage } from "../../components/Header/HeaderPage";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGridPagination from "../../components/TableDataGridPagination";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { maskCpfCnpj } from "../../helpers/cpfOrCnpjValidator";
import { api } from "../../services/api";
import { FilterHeader } from "./FilterHeader";
import { Content, ContentFilterTable } from "./styles";
import { statusItems } from "./types";

function CustomersPagePage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataCustomersPagesList, setDataCustomersPagesList] = useState<
        CustomerProps[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("clientes.visualizar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [totalPages, setTotalPages] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [queryFilter, setQueryFilter] = useState<string>("");

    useEffect(() => {
        setLoadingTable(true);
        // api.get(`/customers/filters`).then((response) => {
        //     console.log(response.data.response);
        // });

        api.get(`/customers/pagination/?page=${currentPage}${queryFilter}`)
            .then((response) => {
                setLoadingTable(false);
                setDataCustomersPagesList(response.data.response.data);
                setTotalPages(response.data.response.last_page);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, [currentPage, queryFilter]);

    function search(newQueryFilter: string) {
        setQueryFilter(newQueryFilter);
    }

    function getCustomerStatusName(statusId: number) {
        return statusItems.find((item) => item.value === statusId)?.label;
    }

    const columns: GridColDef[] = [
        // { field: "id", headerName: "ID", flex: 5 },
        {
            field: "name",
            headerName: "Cliente",
            flex: 15,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <span>
                        <strong>{params.row.name}</strong>
                    </span>{" "}
                    <br />
                    <strong style={{ color: "var(--secondary)" }}>
                        {maskCpfCnpj(params.row.document ?? "00000000000")}
                    </strong>
                </div>
            ),
        },
        {
            field: "holder_name",
            headerName: "Titular",
            flex: 20,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <span>
                        <strong>
                            {params.row.holder?.full_name ??
                                params.row.holder_name}
                        </strong>
                    </span>
                    <br />
                    <span style={{ color: "var(--secondary)" }}>
                        <strong style={{ fontSize: "9pt" }}>
                            {params.row.email}
                        </strong>
                    </span>
                </div>
            ),
        },
        {
            field: "project_name",
            headerName: "Nome do Projeto",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <strong>{params.row.project_name}</strong>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${getCustomerStatusName(params.row.customer_status_id)}`,
        },
        {
            field: "created_at",
            headerName: "Data de criação",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.created_at
                    .slice(0, -17)
                    .split("-")
                    .reverse()
                    .join("/")}`,
        },

        {
            field: "ações",
            headerName: "Ações",
            flex: 15,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div
                    className="containerActionButtonsListTablePagesGlobal"
                    style={{
                        marginRight: "10px",
                    }}
                >
                    <If
                        condition={
                            params.row.customer_status.name === "excluido"
                        }
                    >
                        <Tooltip title="excluido">
                            <Delete color="error" />
                        </Tooltip>
                    </If>
                    <If
                        condition={
                            params.row.customer_status.name !== "excluido"
                        }
                    >
                        <If condition={params.row.validation_id}>
                            <Tooltip title="validado">
                                <TaskAlt color="success" />
                            </Tooltip>
                        </If>
                        <If condition={params.row.activate_invoice}>
                            <Tooltip title="Assinatura ativa">
                                <MonetizationOn color="success" />
                            </Tooltip>
                        </If>
                        <If condition={!params.row?.migrated_client}>
                            <Tooltip
                                title={
                                    params.row.generated_id
                                        ? "apk gerado"
                                        : "apk pendente"
                                }
                            >
                                {params.row.generated_id ? (
                                    <AppShortcut color="success" />
                                ) : (
                                    <NoCell color="warning" />
                                )}
                            </Tooltip>
                        </If>
                    </If>
                    <If
                        condition={hasPermissions(
                            "clientes.detalhes.visualizar"
                        )}
                    >
                        <Visibility
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/comercial/customers/customerDetail?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    console.log(permissions);

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <Content>
                        <div
                            style={{ marginLeft: "30px", marginBottom: "15px" }}
                        >
                            <HeaderPage
                                title="Clientes"
                                subtitle="Detalhes gerais e lista de clientes"
                                icon={
                                    <Person
                                        sx={{ fontSize: "30px", color: "#fff" }}
                                    />
                                }
                            />
                        </div>
                        {/* <CardsStyled>
                            <Card
                                title="Total de Clientes"
                                color="#eeeef7"
                                colorTitle={blue[900]}
                                value="1234"
                                description=""
                                icon={
                                    <Person
                                        sx={{
                                            fontSize: "40px",
                                            color: blue[900],
                                        }}
                                    />
                                }
                            />
                            <Card
                                title="Ativos"
                                color="#eeeef7"
                                colorTitle={green[700]}
                                value="354"
                                description=""
                                icon={
                                    <ToggleOn
                                        sx={{
                                            fontSize: "40px",
                                            color: green[700],
                                        }}
                                    />
                                }
                            />
                            <Card
                                title="Com aviso"
                                color="#eeeef7"
                                colorTitle={yellow[900]}
                                value="19"
                                description=""
                                icon={
                                    <Warning
                                        sx={{
                                            fontSize: "40px",
                                            color: yellow[900],
                                        }}
                                    />
                                }
                            />
                            <Card
                                title="Aguardando Instalação"
                                color="#eeeef7"
                                colorTitle={red[600]}
                                value="5"
                                description=""
                                icon={
                                    <HourglassBottom
                                        sx={{
                                            fontSize: "40px",
                                            color: red[600],
                                        }}
                                    />
                                }
                            />
                        </CardsStyled> */}
                        <ContentFilterTable>
                            <FilterHeader
                                handleSearch={(query: string) => {
                                    search(query);
                                }}
                            />
                        </ContentFilterTable>
                        <TableDataGridPagination
                            rows={dataCustomersPagesList}
                            columns={columns}
                            // onRowClick={(rowParams: GridRowParams) => {
                            //     setSelectedRowCustomersPage(rowParams.row);
                            // }}
                            loading={loadingTable}
                            setLoading={setLoadingTable}
                            page={Number(currentPage)}
                            totalPages={totalPages}
                            onClickPagination={(event: any) => {
                                setCurrentPage(event.target.innerText);
                            }}
                            hideExport
                        />
                    </Content>
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default CustomersPagePage;
