/* eslint-disable react/jsx-no-duplicate-props */
import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarMonth, LocationCity, RememberMe } from "@mui/icons-material";
import { AlertColor } from "@mui/lab";
import { InputAdornment } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import Toggle from "../../components/Toggle";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { api } from "../../services/api";
import { FormPlanRegisterPage } from "./styles";

interface ImplementationsProps {
    description: string;
    id: number;
    name: string;
}

interface DataProfileImplementationsListProps {
    description: string;
    id: number;
    name: string;
    implementations: ImplementationsProps[];
}

type planFormTypeValues = {
    description?: string;
    periodInMonths: number;
    standardPayment: string;
    installmentStandard: number;
    subscriptionStandardPayment: string;
    recurringPayment: string;
    subscriptionRecurringPayment: string;
    installmentRecurring: number;
    subscriptionAnnualPayment: string;
    driversQuantity: number;
    valuePerSurplusDriver: string;
    cities: number;
    surplusValueCeiling: string;
    deadLine: number;
};

const planFormSchema = yup.object().shape({
    description: yup.string().required(),
    periodInMonths: yup.number().required(),
    standardPayment: yup.string().required(),
    installmentStandard: yup.number().required(),
    subscriptionStandardPayment: yup.string().required(),
    recurringPayment: yup.string().required(),
    subscriptionRecurringPayment: yup.string().required(),
    installmentRecurring: yup.number().required(),
    subscriptionAnnualPayment: yup.string().required(),
    driversQuantity: yup.number().required(),
    valuePerSurplusDriver: yup.string(),
    cities: yup.number().required(),
    surplusValueCeiling: yup.string(),
    deadLine: yup.number().required(),
});

function PlanRegisterPage() {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("produtos.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [planIsActiveValue, setPlanIsActiveValue] = useState(1);

    function handleChangePlanActiveStatus() {
        // eslint-disable-next-line no-unused-expressions
        planIsActiveValue === 1
            ? setPlanIsActiveValue(0)
            : setPlanIsActiveValue(1);
    }

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<planFormTypeValues>({
        resolver: yupResolver(planFormSchema),
    });

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [dataProfilePermissionsList, setDataProfilePermissionsList] =
        useState<DataProfileImplementationsListProps[]>([]);

    useEffect(() => {
        setIsLoading(true);
        api.get("/implementations/modules")
            .then((response) => {
                setDataProfilePermissionsList(response.data.response);
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const [selectedImplementationsList, setSelectedImplementationList] =
        useState<number[]>([]);

    // MÁSCARA DE DINHEIRO BRL EM TODOS OS CAMPOS

    function handleChangeStandardPaymentMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "standardPayment",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeRecurringPaymentMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "recurringPayment",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeSubscriptionStandardPaymentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "subscriptionStandardPayment",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeSubscriptionRecurringPaymentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "subscriptionRecurringPayment",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeSubscriptionAnnualPaymentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "subscriptionAnnualPayment",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeValuePerSurplusDriverMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "valuePerSurplusDriver",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeSurplusValueCeilingMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "surplusValueCeiling",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }
    // FIM DA MÁSCARA

    const handleChecked = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedImplementationList(
                selectedImplementationsList.filter(
                    (item: number) => item !== id
                )
            );
        } else {
            setSelectedImplementationList([...selectedImplementationsList, id]);
        }
    };

    const [isPRO, setIsPRO] = useState(false);
    function handleChangeIsPro() {
        setIsPRO(!isPRO);
    }

    const createNewPlan: SubmitHandler<planFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.post(`/plans`, {
            product_id: 1,
            description: formValues.description,
            dead_line: formValues.deadLine,
            standard_payment_price: parseFloat(
                formValues.standardPayment
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            subscription_standard_payment_price: parseFloat(
                formValues.subscriptionStandardPayment
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            installment_standard: formValues.installmentStandard,
            recurring_payment_price: parseFloat(
                formValues.recurringPayment
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            subscription_recurring_payment_price: parseFloat(
                formValues.subscriptionRecurringPayment
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            installment_recurring: formValues.installmentRecurring,
            subscription_annual_payment_price: parseFloat(
                formValues.subscriptionAnnualPayment
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            period_in_months: formValues.periodInMonths,
            drivers_quantity: formValues.driversQuantity,
            value_per_surplus_driver: parseFloat(
                formValues.valuePerSurplusDriver
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            cities: formValues.cities,
            surplus_value_ceiling: formValues.surplusValueCeiling
            ? parseFloat(
                formValues.surplusValueCeiling.replaceAll(".", "").replaceAll(",", ".")
              ) : 0,
            is_active: planIsActiveValue,
            implementation_id: selectedImplementationsList,
            is_pro: isPRO,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/settings/products");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            <FormPlanRegisterPage>
                <div className="topFormPage">
                    <h2>Cadastrar Novo Plano</h2>
                </div>

                <form onSubmit={handleSubmit(createNewPlan)}>
                    <Toggle
                        value={isPRO}
                        label="Plano PRO"
                        checked={isPRO}
                        onChange={() => {
                            handleChangeIsPro();
                        }}
                    />
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="description"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Nome do Plano"
                                    error={!!errors.description}
                                    ref={ref}
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="periodInMonths"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    inputProps={{ min: 1, max: 24 }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    type="number"
                                    label="Validade do Plano em Meses"
                                    placeholder="Valor máximo 24"
                                    error={!!errors.periodInMonths}
                                    ref={ref}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarMonth />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                    <h3>Valor de à vista</h3>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="standardPayment"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(event) =>
                                        handleChangeStandardPaymentMask(event)
                                    }
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor da Ativação"
                                    error={!!errors.standardPayment}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="subscriptionStandardPayment"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeSubscriptionStandardPaymentMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor da Assinatura"
                                    error={!!errors.subscriptionStandardPayment}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="installmentStandard"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Digite a quantidade de parcelas"
                                    type="number"
                                    error={!!errors.installmentStandard}
                                    ref={ref}
                                    inputProps={{ min: 1, max: 12 }}
                                />
                            )}
                        />
                    </div>
                    <h3>Valor de parcelado</h3>

                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="recurringPayment"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(event) =>
                                        handleChangeRecurringPaymentMask(event)
                                    }
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor da Ativação"
                                    error={!!errors.recurringPayment}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="subscriptionRecurringPayment"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeSubscriptionRecurringPaymentMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor da Assinatura"
                                    error={
                                        !!errors.subscriptionRecurringPayment
                                    }
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="installmentRecurring"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Digite a quantidade de parcelas"
                                    type="number"
                                    error={!!errors.installmentRecurring}
                                    ref={ref}
                                    inputProps={{ min: 1, max: 12 }}
                                />
                            )}
                        />
                    </div>

                    <h3>Especificações do Plano</h3>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="subscriptionAnnualPayment"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeSubscriptionAnnualPaymentMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor da Assinatura Anual"
                                    error={!!errors.subscriptionAnnualPayment}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="driversQuantity"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Quantidade de motoristas"
                                    error={!!errors.driversQuantity}
                                    ref={ref}
                                    inputProps={{
                                        min: 1,
                                    }}
                                    type="number"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RememberMe />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="valuePerSurplusDriver"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeValuePerSurplusDriverMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor por motorista excedente"
                                    error={!!errors.valuePerSurplusDriver}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="cities"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    type="number"
                                    label="Quantidade de Cidades"
                                    error={!!errors.cities}
                                    ref={ref}
                                    inputProps={{
                                        min: 1,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationCity />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="surplusValueCeiling"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeSurplusValueCeilingMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Valor máximo por excedentes"
                                    error={!!errors.surplusValueCeiling}
                                    ref={ref}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="deadLine"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label="Dias para a entrega"
                                    type="number"
                                    error={!!errors.deadLine}
                                    ref={ref}
                                    inputProps={{ min: 1 }}
                                />
                            )}
                        />
                    </div>
                    <div className="checkboxListContainer">
                        <h3>Implementações</h3>
                        {!isLoading ? (
                            dataProfilePermissionsList
                                .filter(
                                    (item) => item.implementations.length > 0
                                )
                                .map(({ id, description, implementations }) => (
                                    <div
                                        className="implementationsBlock"
                                        key={id}
                                    >
                                        <h2>{description}</h2>
                                        <div className="checkboxesList">
                                            {implementations.map(
                                                ({ id, description }) => (
                                                    <FormControlLabel
                                                        key={id}
                                                        checked={selectedImplementationsList.includes(
                                                            id
                                                        )}
                                                        control={
                                                            <Checkbox
                                                                key={
                                                                    description
                                                                }
                                                                onClick={() => {
                                                                    handleChecked(
                                                                        id,
                                                                        selectedImplementationsList.includes(
                                                                            id
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={description}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <Loading />
                        )}
                    </div>
                    <div className="planActiveContainer">
                        <h4>Plano ativo?</h4>
                        <Toggle
                            value={planIsActiveValue}
                            label={
                                planIsActiveValue === 1 ? "Ativo" : "Inativo"
                            }
                            checked={planIsActiveValue === 1}
                            onChange={() => {
                                handleChangePlanActiveStatus();
                            }}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/products")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormPlanRegisterPage>
        </PageWrapper>
    );
}
export default PlanRegisterPage;
