export interface BillingDataProps {
    closing_date: string;
    created_at: string;
    customer_id: string | number | null;
    customer_name: string | null;
    date_reference: string;
    due_date: string;
    holder_name: string;
    id: number;
    payment_at: any;
    proposal_id: number;
    status_id: number;
    total_amount: number;
    month_reference: string;
    payment: {
        payment_reference: string;
    };
}

export const mbStatus = [
    {
        value: 1,
        label: "Abertas",
    },
    {
        value: 2,
        label: "Fechadas",
    },
    {
        value: 3,
        label: "Atrasadas",
    },
    {
        value: 4,
        label: "Pagas",
    },
    {
        value: 5,
        label: "Sem Pendências",
    },
];

export interface MonthlyBilling {
    payment_at: number;
}

export const mbPeriod = [
    {
        value: 3,
        label: "3 meses",
    },
    {
        value: 6,
        label: "6 meses",
    },
    {
        value: 12,
        label: "1 ano",
    },
    {
        value: 36,
        label: "3 anos",
    },
    {
        value: 60,
        label: "5 anos",
    },
    {
        value: 120,
        label: "10 anos",
    },
];

export interface FinancialProps {
    account: {
        app_plan_id: number;
        customer_id: number;
        due_day: number;
        enabled: number;
        id: number;
    };
    account_id: number;
    billing_products: any;
    closing_date: string;
    date_reference: string;
    due_date: string;
    id: number;
    initial_date: string;
    month_and_year_reference: string;
    month_reference: string;
    payment: {
        amount: string;
        deleted_at: string | null;
        due_date: string;
        form_payment_id: number;
        holder_id: number;
        id: number;
        payment_date: string | null | number;
        payment_reference: string;
        payment_status_id: number;
        product_info: any;
        product_payment_id: number;
        proposal_id: string | number | null;
        renegotiated: number;
        total_amount: number;
    };
    status: {
        id: number;
        name: string;
    };
    payment_at: string;
    payment_id: number;
    status_id: number;
    total_amount: number;
    total_paid: number;
    transaction_id: string | number | null;
    uuid: string;
    write_off: any;
}
