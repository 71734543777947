import styled from "styled-components";

export const Content = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
`;

export const ContentIcon = styled.div`
    width: 100%;
    text-align: center;
`;

export const Title = styled.h3`
    text-align: center;
`;

export const Text = styled.p`
    text-align: center;
`;

export const ContentButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
