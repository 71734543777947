import { AlertColor, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import ButtonGeneric from "../../components/ButtonGeneric";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { ValidateCustomerModalStyled } from "./styles";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentCustomer: any;
    myParam: string;
    handleCloseStartGeneratingInvoicesModal: () => void;
    openStartGeneratingInvoicesModal: boolean;
}

function StartGeneratingInvoicesModal({
    setCurrentCustomer,
    handleCloseStartGeneratingInvoicesModal,
    openStartGeneratingInvoicesModal,
    myParam,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function installImplementation() {
        setIsLoadingButton(true);
        api.post(`/customers/activate-invoice/${myParam}`, {
            activate_invoice: 1,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseStartGeneratingInvoicesModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                api.get(`customers/${myParam}`)
                    .then((response) => {
                        setCurrentCustomer(response.data.response);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openStartGeneratingInvoicesModal}
            onClose={handleCloseStartGeneratingInvoicesModal}
        >
            <ValidateCustomerModalStyled>
                <Box>
                    <p className="topModalText">
                        Ativar geração de faturas do cliente?
                    </p>
                    <form name="editFormModal">
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => {
                                    handleCloseStartGeneratingInvoicesModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoadingButton}
                                text="Confirmar"
                                type="submit"
                                onClick={() => {
                                    installImplementation();
                                }}
                                sx={{ minWidth: "140px" }}
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </ValidateCustomerModalStyled>
        </Modal>
    );
}
export default StartGeneratingInvoicesModal;
