import {
    Logout,
    SwapHorizontalCircle,
    AccountCircle,
} from "@mui/icons-material";
import {
    Box,
    Button,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import { MouseEvent, useState } from "react";

import { useAuth } from "../../contexts/contexts";
import { If } from "../If";
import { NavBar } from "./AdminStyles";

export function AdminNavBar() {
    const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(
        null
    );

    const { signOut } = useAuth();

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorUserMenu(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorUserMenu(null);
    };

    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");

    return (
        <NavBar className="no-print">
            <div>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 0,
                        marginRight: "10px",
                    }}
                >
                    <If condition={window.innerWidth > 950}>
                        <Button
                            onClick={() => {
                                const event = new Event("changeNavBar");
                                const main =
                                    document.getElementById("main-divider");

                                main?.dispatchEvent(event);
                            }}
                        >
                            <SwapHorizontalCircle sx={{ color: "#767676" }} />
                        </Button>
                    </If>

                    <Button
                        onClick={handleOpenUserMenu}
                        sx={{
                            my: 2,
                            display: "flex",
                            color: "grey",
                            fontWeight: 600,
                        }}
                        endIcon={<AccountCircle />}
                    >
                        {name}
                    </Button>
                    <Menu
                        sx={{ mt: "40px" }}
                        anchorEl={anchorUserMenu}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorUserMenu)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem onClick={handleCloseUserMenu}>
                            <ListItemButton onClick={signOut} sx={{ pl: 1 }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary="Sair da conta" />
                            </ListItemButton>
                        </MenuItem>
                    </Menu>
                </Box>
            </div>
        </NavBar>
    );
}
