import { AlertColor, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { DataUserProps } from "../../pages/UsersPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalBlockUserStyle } from "./styles";

interface Props {
    openBlockUserModal: boolean;
    handleCloseBlockUserModal: () => void;
    selectedRowUser: GridRowParams["row"];
    setDataUsersList: (value: DataUserProps[]) => void;
}

function ModalBlockUser({
    openBlockUserModal,
    handleCloseBlockUserModal,
    selectedRowUser,
    setDataUsersList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function editUserBlockState() {
        setIsLoadingButton(true);
        api.put(`/users/block/${selectedRowUser.id}`, {
            is_blocked: selectedRowUser.is_blocked === 0 ? 1 : 0,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseBlockUserModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                api.get("/users")
                    .then((response) => {
                        setDataUsersList(response.data.response.data);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal open={openBlockUserModal} onClose={handleCloseBlockUserModal}>
            <ModalBlockUserStyle>
                <Box>
                    <p className="topModalText">
                        {selectedRowUser.is_blocked === 0
                            ? `Deseja bloquear "${selectedRowUser.name}"?`
                            : `Deseja desbloquear "${selectedRowUser.name}"?`}
                    </p>
                    <form name="editFormModal">
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => {
                                    handleCloseBlockUserModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background={
                                    selectedRowUser.is_blocked === 0
                                        ? `var(--delete)`
                                        : `var(--confirm)`
                                }
                                loading={isLoadingButton}
                                text={
                                    selectedRowUser.is_blocked === 0
                                        ? `Bloquear`
                                        : `Desbloquear `
                                }
                                type="submit"
                                onClick={() => {
                                    editUserBlockState();
                                }}
                                sx={{ minWidth: "140px" }}
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </ModalBlockUserStyle>
        </Modal>
    );
}
export default ModalBlockUser;
