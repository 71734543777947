/* eslint-disable */

export function setMaskMoney(value: string|number): string {
    if (Number.isNaN(value)) return "0,00";
    value = value.toString();
    let v = value.replace(/\D/g, "");
    v = String((parseInt(v) / 100).toFixed(2));
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return v;
}
