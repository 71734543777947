import { NoteAdd, ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MultipleSelect from "../../components/MultipleSelect";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import { useAuth } from "../../contexts/contexts";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { useDateInitialDefault } from "../../helpers/useDateInitialDefault";
import { api } from "../../services/api";
import {
    Container,
    ContentButtonGeneratedInvoice,
    ContentInputs,
    ContentRegisterInvoices,
} from "./styles";
import { mbStatus } from "./types";
import MonthlyDelayedReport from "./Reports/MonthlyDelayedReport";

export const handleFormartDate = (date: string): string => {
    const dateDismembered = date.split("-");
    const year = dateDismembered[0];
    const month = dateDismembered[1];
    const day = dateDismembered[2];

    return `${day}-${month}-${year}`;
};

function NewInvoicesFull() {
    const { hasPermissions, permissions } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (!hasPermissions("relatorio.criar") && permissions.length > 0) {
            navigate("/Error");
        }
    }, [permissions]);

    const [loading, setLoading] = useState<boolean>(false);
    const [statusSelected, setStatusSelected] = useState<number[]>([]);

    const [startDate, setStartDate] = useState<string | null>(
        `${useDateInitialDefault().day}-${useDateInitialDefault().month - 1}-${
            useDateInitialDefault().year
        }`
    );
    const [endDate, setEndDate] = useState<string | null>(
        `${useDateInitialDefault().day}-${useDateInitialDefault().month}-${
            useDateInitialDefault().year
        }`
    );

    // eslint-disable-next-line

    const handleChangeDate = (value: any, type: "startDate" | "endDate") => {
        const date = new Date(value).toLocaleDateString().split("/");

        const dateFormated = `${date[2]}-${date[1]}-01`;

        if (dateFormated) {
            if (type === "endDate") {
                setEndDate(handleFormartDate(dateFormated) as string);
            } else {
                setStartDate(handleFormartDate(dateFormated) as string);
            }
        }
    };

    const handleGenerateInvoices = async () => {
        try {
            setLoading(true);
            const responseGenerateInvoices = await api.get(
                `v2/monthlybilling/report?date_reference=${startDate},${endDate}${
                    statusSelected.length === 0
                        ? ""
                        : `&status=${statusSelected}`
                }`
            );

            if (responseGenerateInvoices.status) {
                defaultToast("success", responseGenerateInvoices.data.response);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao gerar relatório");
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Geração de Relatórios",
                    subtitle: "",
                    icon: <NoteAdd sx={{ color: "#fff" }} />,
                }}
                style={{
                    height: "100%",
                }}
            >
                <Container>
                    <ContentRegisterInvoices>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    Faturas Mensais (completo)
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ContentInputs>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        localeText={
                                            ptBR.components
                                                .MuiLocalizationProvider
                                                .defaultProps.localeText
                                        }
                                        adapterLocale="pt-br"
                                    >
                                        <DatePicker
                                            views={["month", "year"]}
                                            label="Data Inicial"
                                            format="MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    fullWidth: true,
                                                },
                                            }}
                                            onChange={(value) =>
                                                handleChangeDate(
                                                    value,
                                                    "startDate"
                                                )
                                            }
                                            defaultValue={dayjs(
                                                `${new Date().getFullYear()}-${new Date().getMonth()}-01`
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        localeText={
                                            ptBR.components
                                                .MuiLocalizationProvider
                                                .defaultProps.localeText
                                        }
                                        adapterLocale="pt-br"
                                    >
                                        <DatePicker
                                            views={["month", "year"]}
                                            label="Data Final"
                                            format="MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    fullWidth: true,
                                                },
                                            }}
                                            onChange={(value) =>
                                                handleChangeDate(
                                                    value,
                                                    "endDate"
                                                )
                                            }
                                            defaultValue={dayjs(
                                                `${new Date().getFullYear()}-${
                                                    new Date().getMonth() + 1
                                                }-01`
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <MultipleSelect
                                        size="small"
                                        label="Status"
                                        items={mbStatus}
                                        setValuesSelected={setStatusSelected}
                                        valuesSelected={statusSelected}
                                    />

                                    <ContentButtonGeneratedInvoice>
                                        <LoadingButton
                                            variant="contained"
                                            loading={loading}
                                            onClick={handleGenerateInvoices}
                                            fullWidth
                                        >
                                            Gerar
                                        </LoadingButton>
                                    </ContentButtonGeneratedInvoice>
                                </ContentInputs>
                            </AccordionDetails>
                        </Accordion>
                    </ContentRegisterInvoices>
                    <MonthlyDelayedReport />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}

export default NewInvoicesFull;
