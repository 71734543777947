import styled from "styled-components";

export const Container = styled.form`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1200px;
    min-width: 280px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    font-weight: 700;
`;

export const TitleModal = styled.h3`
    font-size: 24px;
    text-align: center;
`;

export const ContentInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0;
`;

export const ContentButtonSave = styled.div`
    display: flex;
    justify-content: center;
`;
