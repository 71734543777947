import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

interface Props {
    variant: string;
    color:
        | "success"
        | "inherit"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "warning";
    title?: string;
    size: "small" | "medium" | "large";
    onClick: (event: MouseEvent) => void;
    loading: boolean;
    disabledButton?: boolean;
}

export default function ConfirmModal({
    variant,
    title = "Confirmar pagamento",
    size,
    color,
    onClick,
    loading,
    disabledButton,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [colorButton, setColorButton] = React.useState("success");

    const confirm = (e: MouseEvent | any) => {
        handleClose();
        onClick(e);
    };
    return (
        <div>
            <LoadingButton
                onClick={handleOpen}
                variant="contained"
                color={color}
                loading={loading}
                size="small"
            >
                {title}
            </LoadingButton>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        fontWeight="bold"
                        align="center"
                        marginBottom="30px"
                    >
                        Deseja confirmar essa ação?
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            color="inherit"
                            variant="contained"
                        >
                            cancelar
                        </Button>
                        <Button
                            onClick={confirm}
                            color="success"
                            variant="contained"
                            disabled={disabledButton || false}
                        >
                            confirmar
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
