export function integerNumberMask(number: number): string {
    // Verifica se o número é um número inteiro válido
    if (Number.isInteger(number)) {
        // Converte o número em uma string
        const numberString: string = number.toString();

        // Separa os dígitos em um array
        const digits: string[] = numberString.split("").reverse();

        // Aplica a máscara desejada (usando ponto como separador de milhares)
        const maskedNumber: string = digits.reduce(
            (acc: string, digit: string, index: number) => {
                if (index > 0 && index % 3 === 0) {
                    // Adicione um ponto a cada 3 dígitos
                    return `${acc}.${digit}`;
                }
                return acc + digit;
            },
            ""
        );

        return maskedNumber.split("").reverse().join("");
    }
    // Se o número não for um inteiro válido, retorne uma string vazia ou o próprio valor
    return "";
}
