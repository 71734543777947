/*eslint-disable*/
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface MultipleSelectWithSearchProps {
    placeholder: string;
    label: string;
    options: any;
    value: any;
    setValue: (data: any) => void;
    error?: any;
    textError?: any;
    width: string;
    className?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    onChangeTextField?: (text: string) => void;
    loading?: boolean;
}

const SelectSearch = ({
    label,
    placeholder,
    options,
    value,
    setValue,
    error,
    textError,
    width,
    className,
    fullWidth,
    disabled,
    onChangeTextField,
    loading,
}: MultipleSelectWithSearchProps) => {
    const handleSelectChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <Autocomplete
            disablePortal
            id={label}
            options={options}
            onChange={handleSelectChange}
            value={value}
            size="small"
            sx={{
                width: fullWidth ? "100%" : width,
            }}
            disabled={disabled}
            loading={loading}
            noOptionsText="Sem opções"
            loadingText="Carregando"
            renderInput={(params) => (
                <TextField
                    className={className ? className : "default-input"}
                    {...params}
                    label={label}
                    onChange={(event) => {
                        if (
                            onChangeTextField &&
                            event.target.value.length > 0
                        ) {
                            onChangeTextField(event.target.value);
                        }
                    }}
                    placeholder={placeholder}
                    error={error}
                    helperText={textError}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default SelectSearch;
