import { Button, Pagination, Tooltip } from "@mui/material";
import {
    AppShortcut,
    Delete,
    MonetizationOn,
    NoCell,
    Settings,
    TaskAlt,
    Visibility,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";

import {
    Container,
    ContentTable,
    ContentPagination,
    HeaderTable,
} from "./styles";

import PageWrapper from "../../components/PageWrapper";
import { PageDefault } from "../../components/PageDefault";
import { api } from "../../services/api";
import { If } from "../../components/If";
import { getCustomerStatusName } from "../CustomerCentral/GridColumns";
import { maskCpfCnpj } from "../../helpers/cpfOrCnpjValidator";
import { useAuth } from "../../contexts/contexts";
import TableDataGrid from "../../components/TableDataGrid";
import { FilterHeader } from "../CustomersPage/FilterHeader";

let last_page: number | null = null;

export function AnaPage() {
    const [queryFilter, setQueryFilter] = useState<string>("");
    const [dataCustomersPagesList, setDataCustomersPagesList] = useState<
        CustomerProps[]
    >([]);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const getCustomers = async (page = 1) => {
        try {
            setLoadingTable(true);
            const responseCustomers = await api.get(
                `/customers/pagination/?page=${page}${queryFilter}`
            );

            if (responseCustomers.status) {
                setDataCustomersPagesList(responseCustomers.data.response.data);
                last_page = responseCustomers.data.response.last_page;
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingTable(false);
        }
    };

    useEffect(() => {
        getCustomers();
    }, [queryFilter]);

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Cliente",
            flex: 15,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <span>
                        <strong>{params.row.name}</strong>
                    </span>{" "}
                    <br />
                    <strong style={{ color: "var(--secondary)" }}>
                        {maskCpfCnpj(params.row.document ?? "00000000000")}
                    </strong>
                </div>
            ),
        },
        {
            field: "holder_name",
            headerName: "Titular",
            flex: 20,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <span>
                        <strong>
                            {params.row.holder?.full_name ??
                                params.row.holder_name}
                        </strong>
                    </span>
                    <br />
                    <span style={{ color: "var(--secondary)" }}>
                        <strong style={{ fontSize: "9pt" }}>
                            {params.row.email}
                        </strong>
                    </span>
                </div>
            ),
        },
        {
            field: "project_name",
            headerName: "Nome do Projeto",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <strong>{params.row.project_name}</strong>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${getCustomerStatusName(params.row.customer_status_id)}`,
        },
        {
            field: "created_at",
            headerName: "Data de criação",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.created_at
                    .slice(0, -17)
                    .split("-")
                    .reverse()
                    .join("/")}`,
        },

        {
            field: "ações",
            headerName: "Ações",
            flex: 15,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div
                    className="containerActionButtonsListTablePagesGlobal"
                    style={{
                        marginRight: "10px",
                    }}
                >
                    <If
                        condition={
                            params.row.customer_status.name === "excluido"
                        }
                    >
                        <Tooltip title="excluido">
                            <Delete color="error" />
                        </Tooltip>
                    </If>
                    <If
                        condition={
                            params.row.customer_status.name !== "excluido"
                        }
                    >
                        <If condition={params.row.validation_id}>
                            <Tooltip title="validado">
                                <TaskAlt color="success" />
                            </Tooltip>
                        </If>
                        <If condition={params.row.activate_invoice}>
                            <Tooltip title="Assinatura ativa">
                                <MonetizationOn color="success" />
                            </Tooltip>
                        </If>
                        <If condition={!params.row?.migrated_client}>
                            <Tooltip
                                title={
                                    params.row.generated_id
                                        ? "apk gerado"
                                        : "apk pendente"
                                }
                            >
                                {params.row.generated_id ? (
                                    <AppShortcut color="success" />
                                ) : (
                                    <NoCell color="warning" />
                                )}
                            </Tooltip>
                        </If>
                    </If>
                    <If
                        condition={hasPermissions(
                            "clientes.detalhes.visualizar"
                        )}
                    >
                        <Visibility
                            titleAccess="Ver Detalhes"
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/ana/listagem-clientes/detalhes-clientes?idCustomer=${params.id}`
                                );
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Ana",
                    subtitle:
                        "Tela focado na configurações dos usuários e seu(s) aplicativo(s)",
                    icon: <Settings sx={{ color: "#fff" }} />,
                }}
                heightAuto
            >
                <Container>
                    <HeaderTable>
                        <FilterHeader
                            handleSearch={(query: string) => {
                                setQueryFilter(query);
                            }}
                            hideAddClient
                        />
                    </HeaderTable>
                    <ContentTable>
                        <TableDataGrid
                            hideExports
                            columns={columns}
                            rows={dataCustomersPagesList}
                            loading={loadingTable}
                            hideFilter
                            hideFooter
                            hideFooterPagination
                        />
                    </ContentTable>

                    {last_page && (
                        <ContentPagination>
                            <Pagination
                                count={last_page}
                                color="primary"
                                onChange={(event: any) =>
                                    getCustomers(
                                        Number(event.target.textContent)
                                    )
                                }
                                hideNextButton
                                hidePrevButton
                            />
                        </ContentPagination>
                    )}
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
