import { FilterAlt, FilterAltOff, Search } from "@mui/icons-material";
import { TextField, Tooltip, Button, Popover } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { If } from "../../components/If";
import InputGeneric from "../../components/InputGeneric";
import { SelectComponent } from "../../components/Select/Select";
import { useAuth } from "../../contexts/contexts";
import {
    FilterStyled,
    SearchDiv,
    FilterContent,
    DateFilter,
    SearchContent,
    ButtonSearch,
    ActionLeft,
} from "./filterStyles";
import { ContentBtnAddCustomer } from "./styles";
import { Filters, fildsItems, periodsItems, statusItems } from "./types";

interface Props {
    handleSearch: (query: string) => void;
    hideAddClient?: boolean;
}

function getDateToFormat(subDays: number) {
    const d = new Date();
    d.setDate(d.getDate() - subDays);
    return d.toISOString().substr(0, 10);
}

const defaultFilter = {
    filterType: "name",
    filterValue: "",
    status: null,
    startDate: new Date("1970-01-01 00:00:00").toISOString().substr(0, 10),
    endDate: getDateToFormat(0),
    monthlybilling: null,
    period: 20000,
};

export function FilterHeader({ handleSearch, hideAddClient }: Props) {
    const [filter, setFilter] = useState<Filters>(defaultFilter);
    const { hasPermissions, permissions } = useAuth();
    const navigate = useNavigate();

    function search() {
        let queryConstruct = "";
        if (filter.status !== undefined && filter) {
            if (filter?.filterValue) {
                queryConstruct += `&${filter.filterType}=${filter.filterValue}`;
            }
        }

        if (filter.status !== undefined && filter.status !== null) {
            queryConstruct += `&status=${filter.status}`;
        }

        if (filter.startDate !== undefined && filter.endDate !== undefined) {
            queryConstruct += `&start_date=${filter.startDate}&end_date=${filter.endDate}`;
        }

        handleSearch(queryConstruct);
    }

    function handleFilterChange(event: any, afterSearch = false) {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value,
        });
    }

    function clearFilters() {
        handleSearch("");
        setFilter(defaultFilter);
    }

    // Filter Popover
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <FilterContent>
            <SearchDiv>
                <Button
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                >
                    Filtrar <FilterAlt />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{
                        padding: "12px",
                    }}
                >
                    <SearchContent>
                        <FilterStyled>
                            <SelectComponent
                                label="Status"
                                fullWidth
                                items={statusItems}
                                value={Number(filter.status)}
                                name="status"
                                onChange={(e) => {
                                    handleFilterChange(e, true);
                                }}
                            />
                        </FilterStyled>
                        <div>
                            <SelectComponent
                                label="Campo"
                                items={fildsItems}
                                value={String(filter.filterType)}
                                onChange={(e) => {
                                    handleFilterChange(e);
                                }}
                                name="filterType"
                                defaultValue={fildsItems[0]?.value}
                            />
                            <TextField
                                label="Pesquisa"
                                variant="outlined"
                                name="filterValue"
                                size="small"
                                className="margin"
                                sx={{ width: "18rem" }}
                                value={filter.filterValue}
                                onChange={(e) => handleFilterChange(e)}
                                onBlur={() => search()}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        search();
                                    }
                                }}
                            />
                        </div>
                        <DateFilter className="margin">
                            <Tooltip title="clique fora da seleção para buscar as informações">
                                <InputGeneric
                                    fullWidth
                                    size="small"
                                    className="bigInput"
                                    type="date"
                                    label="Data de Criação Início"
                                    name="startDate"
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onChange={(e) => {
                                        handleFilterChange(e);
                                    }}
                                    value={filter.startDate}
                                />
                            </Tooltip>
                            <Tooltip title="clique fora da seleção para buscar as informações">
                                <InputGeneric
                                    fullWidth
                                    size="small"
                                    className="bigInput"
                                    type="date"
                                    label="Até"
                                    name="endDate"
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onChange={(e) => handleFilterChange(e)}
                                    value={filter.endDate}
                                />
                            </Tooltip>
                        </DateFilter>
                        <Tooltip title="clique fora da seleção para buscar as informações">
                            <SelectComponent
                                label="período"
                                fullWidth
                                items={periodsItems}
                                value={Number(filter.period)}
                                name="period"
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        period: Number(e.target.value),
                                        startDate: getDateToFormat(
                                            e.target.value
                                        ),
                                    });
                                }}
                            />
                        </Tooltip>
                        <div>
                            <Button
                                color="error"
                                variant="contained"
                                size="small"
                            >
                                Limpar filtros
                                <Tooltip
                                    title="limpar filtros"
                                    className="clear-filter"
                                    onClick={() => clearFilters()}
                                >
                                    <FilterAltOff
                                        fontSize="large"
                                        style={{
                                            fontSize: "20px",
                                        }}
                                    />
                                </Tooltip>
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => search()}
                                color="secondary"
                                size="small"
                            >
                                <Search />
                                Buscar
                            </Button>
                        </div>
                    </SearchContent>
                </Popover>
            </SearchDiv>
            {!hideAddClient && (
                <ActionLeft>
                    <If condition={hasPermissions("cliente.criar")}>
                        <Button
                            variant="contained"
                            onClick={() =>
                                navigate("/comercial/customers/customers-add")
                            }
                            size="small"
                        >
                            <span>Adicionar cliente</span>
                        </Button>
                    </If>
                </ActionLeft>
            )}
        </FilterContent>
    );
}
