import { Edit, Check, Block } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ModalBlockImplementation from "../../components/BlockImplementationModal";
import { If } from "../../components/If";
import ModalEditImplementationPrice from "../../components/ImplementationEditPriceModal";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { api } from "../../services/api";

export interface ImplementationsProps {
    color: string;
    description: string;
    id: number;
    name: string;
    implementation_id: number;
}

function ImplementationsPage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataImplementationsList, setDataImplementationsList] = useState<
        ImplementationsProps[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("implementacoes.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [openBlockImplementationModal, setOpenBlockImplementationModal] =
        useState(false);
    const handleCloseBlockImplementationModal = () => {
        setOpenBlockImplementationModal(false);
    };

    const [
        openEditImplementationPriceModal,
        setOpenEditImplementationPriceModal,
    ] = useState(false);
    const handleCloseEditImplementationPriceModal = () => {
        setOpenEditImplementationPriceModal(false);
    };
    const [selectedRowImplementation, setSelectedRowImplementation] =
        useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const getAllImplementations = async () => {
        try {
            const responseAllImpelementations = await api.get(
                "/implementations/prices/all"
            );

            if (responseAllImpelementations.status) {
                setDataImplementationsList(
                    responseAllImpelementations.data.response
                );
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setLoadingTable(false);
        }
    };

    useEffect(() => {
        getAllImplementations();
    }, []);

    const columns: GridColDef[] = [
        { field: "implementation_id", headerName: "ID", flex: 5 },
        {
            field: "implementation.description",
            headerName: "Descrição",
            flex: 25,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.implementation.description}`,
            renderCell: (params) => (
                <p>{params.row.implementation.description}</p>
            ),
        },
        {
            field: "implementation.app_module.description",
            headerName: "Módulo",
            align: "center",
            headerAlign: "center",
            flex: 20,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.implementation.app_module.description}`,
            renderCell: (params) => (
                <p>{params.row.implementation.app_module.description}</p>
            ),
        },
        {
            field: "price",
            headerName: "Preço",
            align: "center",
            headerAlign: "center",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.price}`,
            renderCell: (params) => (
                <p>{formatCurrencyToShow(params.row.price)}</p>
            ),
        },
        {
            field: "is_manual",
            headerName: "Instalação",
            align: "center",
            headerAlign: "center",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.price}`,
            renderCell: (params) => (
                <p>
                    {params.row.implementation.manual_installation === 1
                        ? "Manual"
                        : "Automático"}
                </p>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 20,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderCell: (params: GridRenderCellParams<any, any, any>) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If
                        condition={
                            hasPermissions("implementacoes.editar") &&
                            params.row.price !== null
                        }
                    >
                        {params.row.implementation?.is_active === 0 ? (
                            <Block
                                className="cursorPointerGlobal"
                                onClick={() => {
                                    setOpenBlockImplementationModal(true);
                                }}
                            />
                        ) : (
                            <Check
                                className="cursorPointerGlobal"
                                onClick={() => {
                                    setOpenBlockImplementationModal(true);
                                }}
                            />
                        )}
                    </If>
                    <If condition={hasPermissions("implementacoes.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenEditImplementationPriceModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal" />
                    <TableDataGrid
                        rows={dataImplementationsList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowImplementation(rowParams.row);
                        }}
                        loading={loadingTable}
                        getRowId={(row) => row.implementation_id}
                    />
                    <ModalEditImplementationPrice
                        getAllImplementations={getAllImplementations}
                        openEditImplementationPriceModal={
                            openEditImplementationPriceModal
                        }
                        handleCloseEditImplementationPriceModal={
                            handleCloseEditImplementationPriceModal
                        }
                        selectedRowImplementation={selectedRowImplementation}
                    />
                    <ModalBlockImplementation
                        openBlockImplementationModal={
                            openBlockImplementationModal
                        }
                        handleCloseBlockImplementationModal={
                            handleCloseBlockImplementationModal
                        }
                        selectedRowImplementation={selectedRowImplementation}
                        setDataImplementationsList={setDataImplementationsList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default ImplementationsPage;
