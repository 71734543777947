/* eslint-disable @typescript-eslint/no-explicit-any */
import { Send, MoreVert } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState } from "react";

import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import TextEditor from "../../components/TextEditor";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import DeleteNoteModal from "./DeleteNoteModal";
import EditNoteModal from "./EditNoteModal";
import { NotesCustomerStyled } from "./styles";
import { NotePadProps } from "./types";

interface Props {
    myParam: string | null;
}

function NotesCustomer({ myParam }: Props) {
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [textEditorValue, setTextEditorValue] = useState<string>("");
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
    const handleCloseEditNoteModal = () => {
        setOpenEditNoteModal(false);
    };

    const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState(false);
    const handleCloseDeleteNoteModal = () => {
        setOpenDeleteNoteModal(false);
    };

    const inputRef = useRef<any>(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const resetFileInput = () => {
        inputRef.current.value = null;
    };

    // hamburguer menu configs

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    // CONFIGURAÇÃO PARA FAZER O RICH TEXT FICAR SEM A TOOLBAR
    const textEditorOptions = {
        modules: {
            toolbar: false,
        },
    };

    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");

    const [isLoading, setIsLoading] = useState(true);

    const [notePadData, setNotepadData] = useState<NotePadProps>();
    const [paginationData, setPaginationData] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number | string>(1);

    const [noteIndividualId, setNoteIndividualId] = useState<number>();
    const [noteIndividualContent, setNoteIndividualContent] =
        useState<number>();

    useEffect(() => {
        setIsLoading(true);
        api.get(`/customers/notepad/${myParam}?page=${currentPage}`).then(
            (response) => {
                setPaginationData(response.data.response);
                setNotepadData(response.data.response.data);
                setIsLoading(false);
            }
        );
    }, [currentPage]);

    function getUserName(user: any, name: any): any {
        if (!user) return "sistema";

        if (user.name === name) {
            return "você";
        }
        return user.name;
    }

    // Formatar a data no formato desejado
    function converDate(dataToFormat: string) {
        const data = new Date(dataToFormat);
        const dia = data.getDate();
        const mes = data.getMonth() + 1; // O mês começa a partir do zero, por isso é necessário adicionar 1
        const ano = data.getFullYear();
        const hora = data.getHours();
        const minutos = data.getMinutes();

        return `${dia < 10 ? `0${dia}` : dia}/${
            mes < 10 ? `0${mes}` : mes
        }/${ano} às ${hora < 10 ? `0${hora}` : hora}:${
            minutos < 10 ? `0${minutos}` : minutos
        }`;
    }

    const sendMesage = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // validação do formulário
        if (textEditorValue !== "") {
            setIsLoadingButton(true);
            api.post(
                `/customers/notepad/${myParam}`,
                {
                    content: textEditorValue,
                    attachment: selectedFile,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then((response) => {
                    setIsLoadingButton(false);
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                    setTextEditorValue("");
                    resetFileInput();
                })
                .then(() => {
                    api.get(`/customers/notepad/${myParam}`).then(
                        (response) => {
                            setNotepadData(response.data.response.data);
                            setIsLoading(false);
                        }
                    );
                })
                .catch((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(response.response.data.response.name);
                    setIsLoadingButton(false);
                });
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Campo de observação vazio!");
        }
    };

    return (
        <>
            <NotesCustomerStyled>
                <h2>Observações</h2>
                {isLoading ? (
                    <PreloadFb />
                ) : (
                    <div className="chatMainContainer">
                        {notePadData && notePadData.length > 0 ? (
                            notePadData?.map(
                                ({
                                    attachment,
                                    content,
                                    user,
                                    id,
                                    created_at,
                                    deleted_at,
                                    type,
                                    updated_date,
                                }: any) => (
                                    <div
                                        key={id}
                                        className="chatsContainer"
                                        style={{
                                            alignSelf:
                                                user !== null &&
                                                user.name === name
                                                    ? "flex-end"
                                                    : "flex-start",
                                        }}
                                    >
                                        <div className="topChatContainer">
                                            <p className="nameUserNote">
                                                {getUserName(user, name)}{" "}
                                                <span>comentou em:</span>
                                            </p>
                                            <span>
                                                {converDate(created_at)}
                                            </span>
                                            <p className="editedNoteText">
                                                {updated_date ?? updated_date
                                                    ? "Editado"
                                                    : ""}
                                            </p>
                                            {deleted_at ? (
                                                ""
                                            ) : (
                                                <div className="hamburgerMenuContainer">
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        value={id}
                                                        aria-haspopup="true"
                                                        onClick={(
                                                            event: React.MouseEvent<HTMLElement>
                                                        ) => {
                                                            setAnchorEl(
                                                                event.currentTarget
                                                            );
                                                        }}
                                                        onFocus={(event) => {
                                                            setNoteIndividualId(
                                                                Number(
                                                                    event.target
                                                                        .value
                                                                )
                                                            );
                                                            setNoteIndividualContent(
                                                                content
                                                            );
                                                        }}
                                                    >
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "right",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={
                                                            open &&
                                                            noteIndividualId ===
                                                                id
                                                        }
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem
                                                            onClick={() => {
                                                                setOpenEditNoteModal(
                                                                    true
                                                                );
                                                                handleClose();
                                                            }}
                                                        >
                                                            Editar
                                                        </MenuItem>

                                                        <MenuItem
                                                            onClick={() => {
                                                                setOpenDeleteNoteModal(
                                                                    true
                                                                );
                                                                handleClose();
                                                            }}
                                                        >
                                                            Excluir
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            )}
                                        </div>
                                        <div className="chatsContents">
                                            {deleted_at ? (
                                                <p className="deletedNoteMessage">
                                                    Observação excluída em{" "}
                                                    {converDate(deleted_at)}
                                                </p>
                                            ) : (
                                                <TextEditor
                                                    readOnly
                                                    value={content}
                                                    modules={
                                                        textEditorOptions.modules
                                                    }
                                                    style={{ padding: 0 }}
                                                />
                                            )}
                                        </div>
                                        {attachment && !deleted_at ? (
                                            <div className="fileContainer">
                                                {type === "png" ||
                                                type === "jpg" ||
                                                type === "jpeg" ? (
                                                    <img
                                                        width="400px"
                                                        src={attachment}
                                                        alt="attachment"
                                                    />
                                                ) : (
                                                    ""
                                                )}

                                                <p>
                                                    Arquivo anexado:{" "}
                                                    <a
                                                        target="_blank"
                                                        href={attachment}
                                                        rel="noreferrer"
                                                        style={{
                                                            textDecoration:
                                                                "underline",
                                                        }}
                                                    >
                                                        Visualizar arquivo{" "}
                                                        {type || "sem tipo"} em
                                                        outra guia
                                                    </a>
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                )
                            )
                        ) : (
                            <p>SEM OBSERVAÇÕES</p>
                        )}
                    </div>
                )}
                {notePadData && notePadData.length > 1 ? (
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Stack spacing={2}>
                            <Pagination
                                hideNextButton
                                hidePrevButton
                                disabled={isLoading}
                                onChange={(event: any) =>
                                    setCurrentPage(
                                        Number(event.target.innerText)
                                    )
                                }
                                size="large"
                                count={paginationData?.last_page}
                            />
                        </Stack>
                    </div>
                ) : (
                    ""
                )}

                <p className="newNoteTitle">Criar nova observação</p>
                <form onSubmit={sendMesage} encType="multipart/form-data">
                    <TextEditor
                        placeholder="Escreva uma observação"
                        value={textEditorValue}
                        onChange={(value: string) => setTextEditorValue(value)}
                    />
                    <input
                        className="inputFile"
                        type="file"
                        onChange={handleFileChange}
                        ref={inputRef}
                    />
                    <div className="buttonContainer">
                        <PreloadButton
                            text="Enviar"
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            type="submit"
                            endIcon={isLoadingButton ? "" : <Send />}
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        />
                    </div>
                </form>
            </NotesCustomerStyled>
            <EditNoteModal
                openEditNoteModal={openEditNoteModal}
                handleCloseEditNoteModal={handleCloseEditNoteModal}
                noteIndividualId={noteIndividualId}
                myParam={myParam}
                setNotepadData={setNotepadData}
                noteIndividualContent={noteIndividualContent}
            />
            <DeleteNoteModal
                openDeleteNoteModal={openDeleteNoteModal}
                handleCloseDeleteNoteModal={handleCloseDeleteNoteModal}
                noteIndividualId={noteIndividualId}
                myParam={myParam}
                setNotepadData={setNotepadData}
            />
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default NotesCustomer;
