import Box from "@mui/material/Box";
import CircularProgress, {
    CircularProgressProps,
} from "@mui/material/CircularProgress";

export default function Loading({ ...props }: CircularProgressProps) {
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                margin: "15% 0",
            }}
        >
            <CircularProgress {...props} />
        </Box>
    );
}
