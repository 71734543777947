import styled from "styled-components";

export const DrawerStyle = styled.div`
    .logoInDrawerMenu {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logoInDrawerMenu img {
        width: 100px;
        border-radius: 50%;
    }
`;

export const NavbarStyle = styled.div`
    .navbarContent {
        background-color: var(--primary);
    }

    .logoNavbar {
        width: 50px;
        border-radius: 50%;
    }

    .content-logo {
        margin: 0 1rem;

        @media (max-width: 1100px) {
            display: none;
        }
    }
`;
