import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import * as React from "react";

interface Props {
    isLoadingButton: boolean;
    success: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sx: any;
    onFocus?: () => void;
}

export default function SaveButton({
    isLoadingButton,
    success,
    sx,
    onFocus,
}: Props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ m: 1, position: "relative" }}>
                <Fab
                    style={{ background: "var(--primary)", color: "white" }}
                    aria-label="save"
                    sx={sx}
                    type="submit"
                    onFocus={onFocus}
                    disabled={isLoadingButton}
                >
                    {success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {isLoadingButton && (
                    <CircularProgress
                        size={68}
                        style={{ color: "var(--primary)" }}
                        sx={{
                            position: "absolute",
                            top: -6,
                            left: -6,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}
