import styled from "styled-components";

export const ErrorPublicPageStyle = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .imagemContent {
        width: 15%;
        margin-top: 100px;
        min-width: 350px;
    }

    .informationsText {
        width: 80%;
        text-align: center;
    }

    @media (max-width: 700px) {
        .imagemContent {
            min-width: 250px;
            margin-top: 150px;
        }
        .informationsText {
            font-size: 20px;
        }
        @media (max-width: 400px) {
            h3 {
                text-align: center;
            }

            .inputs {
                width: 260px;
            }
        }
    }
`;
