import styled from "styled-components";

export const SettingsConfigPageStyle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 1280px;
    padding: 10px 10px;
    border: 1px solid rgba(45, 45, 45, 0.15);
    max-width: 1280px;
    width: 100%;
    min-height: 60vh;
    background-color: #fff !important;

    .topPageContainer {
        display: flex;
        position: relative;
        width: 100%;
    }

    .instructionsContainer {
        cursor: pointer;
        position: absolute;
        right: 5%;
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 5px 10px !important;
        box-shadow: 0px 0px 2px #8888889e;
        transition: 0.3s;
        background-color: #8888883e;
        &:hover {
            background-color: #8888889e;
        }
    }

    .instructionsContainer p {
        margin: 0;
        padding: 0;
    }

    h2 {
        margin-top: 40px;
        text-align: center;
        width: 100%;
    }

    .inputAndLoadingContainer {
        position: relative;
    }

    .inputs {
        width: 600px;
    }

    .toggle {
        display: flex;
        flex-direction: column-reverse;
    }

    @media (max-width: 740px) {
        .inputs {
            width: 100%;
        }

        @media (max-width: 400px) {
            box-shadow: none;
        }
    }
`;
