/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable */
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Add,
    CalendarMonth,
    CheckCircle,
    Delete,
    LocationCity,
    RadioButtonUnchecked,
    RememberMe,
} from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    AlertColor,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    FormHelperText,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ModalAlert from "../../components/ModalAlert";
import SelectSearch from "../../components/SelectSearch";
import ButtonGeneric from "../../components/ButtonGeneric";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import SaveButton from "../../components/SaveButton";
import ModalSignatoryPartners from "../../components/SignatoryPartnersModal";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { documentMask } from "../../helpers/document";
import { phoneMask } from "../../helpers/phone";
import { api, urlViaCep } from "../../services/api";
import { ContractsProps } from "../ContractsPage";
import { CustomersCategoriesProps } from "../CustomersCategoriesPage";
import { GraceDaysProps } from "../GraceDaysPage";
import { LeadsSourceProps } from "../LeadsSourcePage";
import { FormProposalRegisterPage, ContentInput } from "./styles";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { SelectComponent } from "../../components/Select/Select";

import { useZipCodeMask } from "../../helpers/useMaskZipCode";

import {
    holderFormTypeValues,
    ImplementationPlan,
    extraImplementationsProps,
    holderDataTypes,
    plansDataTypes,
    CountriesProps,
    OptionsSelectProps,
} from "./types";

import {
    holderFormSchemaNational,
    holderFormSchemaInternational,
} from "./config";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";

const codeNumbersCountries = require("../../assets/mock/countries.json");

const typesHolder = [
    {
        value: 0,
        label: "Pessoa Física",
    },
    {
        value: 1,
        label: "Pessoa Jurídica",
    },
];

function ProposalRegisterPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");

    // BOTÃO DE SALVAR PROPOSTA
    const [success, setSuccess] = useState(false);
    const sxSavebutton = {
        success,
    };

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [showModalClientOwning, setShowModalClientOwning] =
        useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const [isProposalInternational, setIsProposalInternational] =
        useState<boolean>(false);

    const [countrySelected, setContrySelected] = useState<OptionsSelectProps>();
    const [listCountries, setListCountries] = useState<OptionsSelectProps[]>(
        []
    );

    const [dataPlansList, setDataPlansList] = useState<plansDataTypes[]>([]);

    const [codeNumberSeleceted, setCodeNumberSelected] =
        useState<OptionsSelectProps>();

    const [typeHolderSelected, setTypeHolderSelected] = useState<number>(0);
    const [selectPlanOptions, setSelectPlanOptions] = useState([]);

    // ATUALIZA OS VALORES DOS PLANOS E MUDA OS VALORES DOS INPUTS DE ACORDO COM A FORMA DE PAGAMENTO SELECIONADA

    const [
        listImplementationsSelectedPlan,
        setListImplementationsSelectedPlan,
    ] = useState<ImplementationPlan[]>([]);

    const [activationPlanPrice, setActivationPlanPrice] = useState<number>(0);

    const [implementationsList, setImplementationsList] = useState<
        extraImplementationsProps[]
    >([]);

    const [filteredListImplementations, setFilteredListImplementations] =
        useState<extraImplementationsProps[]>([]);

    const [currentTab, setCurrentTab] = useState("3");

    const [dataGraceDaysList, setDataGraceDaysList] = useState<
        GraceDaysProps[]
    >([]);

    const [dataCustomersCategoriesList, setDataCustomersCategoriesList] =
        useState<CustomersCategoriesProps[]>([]);

    const [selectedExtraImplementation, setSelectedExtraImplementation] =
        useState<any>({});

    const [extraImplementationsSelected, setExtraImplementationsSelected] =
        useState<extraImplementationsProps[]>([]);

    const [currentPlan, setCurrentPlan] = useState<any>();

    const [dataContractsList, setDataContractsList] = useState<
        ContractsProps[]
    >([]);

    const [installmentPayment, setInstallmentPayment] = useState<number>(1);

    const [dataLeadsSourceList, setDataLeadsSourceList] = useState<
        LeadsSourceProps[]
    >([]);

    // LISTA DE SIGNATÁRIOS
    const [signatoryPartnersList, setSignatoryPartnersList] = useState<
        holderFormTypeValues[]
    >([]);

    const [installmentsValue, setInstallmentsValue] = useState<number>(1);

    useEffect(() => {
        if (
            hasPermissions("propostas.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const [selectHolderTypeOptions, setSelectHolderTypeOptions] = useState([]);
    const {
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useForm<holderFormTypeValues>({
        resolver: yupResolver(
            !isProposalInternational
                ? holderFormSchemaNational
                : holderFormSchemaInternational
        ),
    });

    const getGraceDay = async () => {
        try {
            setIsLoading(true);
            const responseGraceDay = await api.get("graceDay");

            if (responseGraceDay.status) {
                setDataGraceDaysList(responseGraceDay.data.response);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    function filterListImplementations() {
        setFilteredListImplementations(
            implementationsList?.filter((item) => {
                return !listImplementationsSelectedPlan?.some((otherItem) => {
                    return (
                        item.implementation_id === otherItem.implementation_id
                    );
                });
            })
        );
    }

    const handleChangeSelectedPlanOption = (event: any) => {
        const valor = Number(event.target.value);
        setCurrentPlan(
            dataPlansList.find((item: plansDataTypes) => item.id === valor)
        );
        setValue("plan", event.target.value as string);
    };

    // FUNÇÃO QUE ATUALIZA OS VALORES DE ACORDO COM O PLANO SELECIONADO
    const handleCheckAndChangeValuesToCurrentPlanEffect = () => {
        const event = { target: { value: getValues("plan") } };

        setSelectedExtraImplementation({});
        setValue("implementations", "");
        setListImplementationsSelectedPlan(currentPlan?.implementation_plans);
        setExtraImplementationsSelected([]);
        setValue("priceImplementation", "");
        setValue("periodInMonths", currentPlan?.period_in_months);
        setValue(
            "driversQuantity",
            currentPlan?.mobility_plan.drivers_quantity
        );
        setValue(
            "valuePerSurplusDriver",
            currentPlan?.mobility_plan.value_per_surplus_driver
                ? formatCurrencyToShow(
                      currentPlan?.mobility_plan.value_per_surplus_driver
                  )
                : ""
        );

        setValue(
            "surplusValueCeiling",
            currentPlan?.mobility_plan.surplus_value_ceiling
                ? formatCurrencyToShow(
                      currentPlan?.mobility_plan.surplus_value_ceiling
                  )
                : ""
        );
        setValue("cities", currentPlan?.mobility_plan.cities);

        setActivationPlanPrice(currentPlan?.standard_payment_price);

        handleChangeSelectedPlanOption(event);
    };

    // FUNÇÃO QUE ATUALIZA OS VALORES DE ACORDO COM O MÉTODO DE PAGAMENTO SELECIONADO
    const handleCheckAndChangeValuesCurrentTabEffect = () => {
        if (currentPlan && currentTab === "3") {
            setActivationPlanPrice(currentPlan.standard_payment_price);
            setValue(
                "subscriptionValue",
                formatCurrencyToShow(
                    currentPlan.subscription_standard_payment_price
                )
            );
            setValue("installment", "");
            setValue(
                "price",
                formatCurrencyToShow(currentPlan.standard_payment_price)
            );
            setInstallmentsValue(currentPlan.installment_standard);
        } else if (currentPlan && (currentTab === "1" || currentTab === "2")) {
            setActivationPlanPrice(currentPlan.recurring_payment_price);
            setValue(
                "subscriptionValue",
                formatCurrencyToShow(
                    currentPlan.subscription_recurring_payment_price
                )
            );
            setValue("installment", "");
            setValue(
                "price",
                formatCurrencyToShow(currentPlan.recurring_payment_price)
            );
            setInstallmentsValue(currentPlan.installment_recurring);
        }
    };

    const handleChangeSelectedImplementationOption = (
        event: SelectChangeEvent
    ) => {
        const valor = Number(event.target.value);

        const dataItem = implementationsList.find(
            (item: { implementation_id: number }) =>
                item.implementation_id === valor
        );
        if (dataItem) {
            setValue(
                "priceImplementation",
                formatCurrencyToShow(dataItem.price)
            );

            setSelectedExtraImplementation(dataItem);
            setValue("implementations", event.target.value as string);
        }
    };

    const getHolderLevels = async () => {
        try {
            setIsLoading(true);
            const responseHolderLevels = await api.get("holderLevels/all");

            if (responseHolderLevels.status) {
                setSelectHolderTypeOptions(responseHolderLevels.data.response);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getLeadSource = async () => {
        try {
            setIsLoading(true);
            const responseLeadSource = await api.get("leadSource");
            if (responseLeadSource.status) {
                setDataLeadsSourceList(responseLeadSource.data.response);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getAndSetAddress = async () => {
        try {
            setIsLoading(true);
            const responseDataAddresses = await urlViaCep.get(
                `/${getValues("zipCode")}/json/`
            );

            if (responseDataAddresses.status) {
                setValue("street", responseDataAddresses.data.logradouro);
                setValue("city", responseDataAddresses.data.localidade);
                setValue("state", responseDataAddresses.data.uf);
                setValue("neighborhood", responseDataAddresses.data.bairro);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getPlansDetails = async () => {
        try {
            setIsLoading(true);
            const responsePlansDetails = await api.get("plans/details");

            if (responsePlansDetails.status) {
                setDataPlansList(responsePlansDetails.data.response);
                setSelectPlanOptions(responsePlansDetails.data.response);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getImplementationsPrices = async () => {
        try {
            setIsLoading(true);
            const responseImplementationsPrices = await api.get(
                "implementations/prices/all"
            );

            if (responseImplementationsPrices.status) {
                setImplementationsList(
                    responseImplementationsPrices.data.response
                );
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getContracts = async () => {
        try {
            setIsLoading(true);
            const responseContracts = await api.get("contracts");
            if (responseContracts.status) {
                setDataContractsList(responseContracts.data.response.data);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getHolderExistents = async (document: string) => {
        try {
            setIsLoading(true);
            const responseHolder = await api.get(
                `holders?document=${document}`
            );

            if (responseHolder.status) {
                if (responseHolder.data.response.length > 0) {
                    const dataHolder: holderDataTypes =
                        responseHolder.data.response[0];
                    setValue(
                        "fullName",
                        dataHolder.full_name ? dataHolder.full_name : ""
                    );
                    setValue("name", dataHolder.name ? dataHolder.name : "");
                    setValue("email", dataHolder.email ? dataHolder.email : "");
                    setValue("phone", dataHolder.phone ? dataHolder.phone : "");
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getCustomersCategories = async () => {
        try {
            setIsLoading(true);
            const responseCustomersCategories = await api.get(
                "customersCategory"
            );

            if (responseCustomersCategories.status) {
                setDataCustomersCategoriesList(
                    responseCustomersCategories.data.response
                );
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    function handleChangePhoneMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setValue("phone", !isProposalInternational ? phoneMask(value) : value);
    }

    function handleChangeDocumentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue(
            "document",
            !isProposalInternational ? documentMask(value) : value
        );
    }

    const zipCodeMask = (value: string) => {
        let newValue = value.replace(/\D/g, "");
        newValue = newValue.replace(/(\d{5})(\d)/, "$1-$2");
        return newValue;
    };

    // function setCoupon(
    //     event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // ) {
    //     const { value } = event.target;

    //     setValue("discountCoupon", value);
    // }

    function handleChangePriceMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue("price", formatCurrencyToShow(valueNumberWithTwoDecimals));
    }

    function handleChangeSubscriptionValueMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "document",
            !isProposalInternational ? documentMask(value) : value
        );
    }

    function handleChangeZipCodeMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue(
            "zipCode",
            !isProposalInternational ? useZipCodeMask(value) : value
        );
    }

    // function setCoupon(
    //     event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    // ) {
    //     const { value } = event.target;

    //     setValue("discountCoupon", value);
    // }

    const handleChangeMaskInputs = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        nameInput:
            | "price"
            | "subscriptionValue"
            | "valuePerSurplusDriver"
            | "surplusValueCeiling"
    ) => {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(nameInput, formatCurrencyToShow(valueNumberWithTwoDecimals));
    };

    const handleVerifyDocument = async (document: string) => {
        if (document) {
            try {
                const responseVerifyDocument = await api.get(
                    `holders/validation/${document}`
                );

                if (responseVerifyDocument.status) {
                    if (!responseVerifyDocument.data.status) {
                        setShowModalClientOwning(true);
                        setDisableButton(true);
                    } else {
                        setDisableButton(false);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeInputsAndGetDocumentValueLabel = () => {
        if (getValues("document")) {
            const currentDocument = getValues("document")
                .replaceAll(".", "")
                .replaceAll("-", "")
                .replaceAll("/", "");
            getHolderExistents(currentDocument as string);
        }
    };

    // DELETA O SIGNATÁRIO SELECIONADO
    const handleRemoveSignatory = (index: number) => {
        setSignatoryPartnersList(
            signatoryPartnersList.filter((_: unknown, i: number) => i !== index)
        );
    };

    // DELETA A IMPLEMENTAÇÃO EXTRA SELECIONADA

    const handleRemoveExtraImplementation = (index: number) => {
        setExtraImplementationsSelected(
            extraImplementationsSelected.filter(
                (_: unknown, i: number) => i !== index
            )
        );
    };

    const ImplementationsExtrasSum = extraImplementationsSelected.reduce(
        (total, item) => total + item.price,
        0
    );

    // FUNÇÕES PARA ABRIR E FECHAR O MODAL PARA ADICIONAR SIGNATÁRIOS
    const [openSignatoryPartnersModal, setOpenSignatoryPartnersModal] =
        useState(false);
    const handleCloseSignatoryPartnersModal = () => {
        setOpenSignatoryPartnersModal(false);
    };

    const [saveAndSendContract, setSaveAndSendContract] = useState(false);
    const [amountWithDiscont, setAmountWithDiscont] = useState(0.0);
    const [amountDiscont, setAmountDiscont] = useState(0.0);
    const [entryAmount, setEntryAmount] = useState<number>(1.0);

    const getAllCountries = async () => {
        try {
            setIsLoading(true);
            const responseAllContries = await api.get("location/countries");
            if (responseAllContries.status) {
                const responseCountriesFormated =
                    responseAllContries.data.response.map(
                        (value: CountriesProps) => {
                            return {
                                label: value.name,
                                value: value.id,
                            };
                        }
                    );

                setListCountries(responseCountriesFormated);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validCoupon = async () => {
        const formValues = getValues();
        const total = activationPlanPrice + ImplementationsExtrasSum;
        api.get(`coupons/code/${formValues.discountCoupon}/${total}`)
            .then((response) => {
                if (response.data.response === null) {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast("Cupom não encontrado");
                    setIsLoadingButton(false);
                }

                setAmountDiscont(
                    total - response.data.response.amount_with_discount
                );
                setAmountWithDiscont(
                    response.data.response.amount_with_discount
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("cupom não disponível");
                setIsLoadingButton(false);
                setAmountDiscont(0);
                setAmountWithDiscont(0);
                setValue("discountCoupon", "");
            });
    };

    function getCoupons(discountCoupon: string) {
        if (
            discountCoupon === "" ||
            discountCoupon === null ||
            discountCoupon === undefined
        ) {
            return [];
        }

        return [discountCoupon];
    }

    const createOrSaveProposal: SubmitHandler<holderFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);

        api.post(`/proposal`, {
            type_id: !isProposalInternational ? 1 : 2,
            seller: name,
            full_name: formValues.fullName,
            name: formValues.name,
            document: formValues.document?.replaceAll(/\D/g, ""),
            email: formValues.email,
            phone: String(formValues.phone?.replaceAll(/\D/g, "")),
            ddi: formValues.ddi.replaceAll("0", ""),
            is_company: typeHolderSelected === 0 ? false : true,
            holder_level_id: Number(formValues.holderLevel),
            street: formValues.street,
            street_number: formValues.number,
            complement: formValues.complement,
            neighborhood: formValues.neighborhood,
            city: formValues.city,
            state: formValues.state,
            country_id: Number(formValues.country),
            zip_code: formValues.zipCode.replace("-", ""),
            plan_id: Number(formValues.plan),
            grace_day_id: Number(formValues.graceDay),
            contract_id: Number(formValues.contract),
            lead_source_id: Number(formValues.lead),
            proposal_informations: formValues.proposalInformations,
            customer_category_id: Number(formValues.customerCategory),
            submission_proposal: saveAndSendContract,
            form_payment: {
                entry_amount: formValues.entryAmount,
                due_date: formValues.dueDate,
                number_installments:
                    currentTab === "1" ? null : Number(formValues.installment),
                type_form_payment_type_id: Number(currentTab),
                description:
                    formValues.urlLink === ""
                        ? (formValues.urlLink = null)
                        : formValues.urlLink,
            },

            signatories: signatoryPartnersList,

            amount: activationPlanPrice + ImplementationsExtrasSum,
            implementation_extra: extraImplementationsSelected?.map(
                ({ implementation_id }: { implementation_id: number }) => ({
                    implementation_id,
                })
            ),
            coupons: getCoupons(formValues.discountCoupon),
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
                setSuccess(true);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/comercial/proposal");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {

                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.status === 400 ? response.response.data.response : response.response.data.message);
                setIsLoadingButton(false);
            });
    };

    const handleFilterChange = (idTypeHolderSelected: number) => {
        setTypeHolderSelected(idTypeHolderSelected);
        setValue("document", "");
    };

    const getQuantitiesInstallMents = (
        typeInstallments: "allNumber" | "capado" = "allNumber"
    ) => {
        let listQuantitiesInstallments: number[] = [];

        const valueInitial = 1;
        // const valueInitial = typeInstallments === "allNumber" ? 1 : 2;

        for (let i = valueInitial; i < installmentsValue + 1; i++) {
            listQuantitiesInstallments.push(i);
        }

        return listQuantitiesInstallments;
    };

    useEffect(() => {
        handleCheckAndChangeValuesCurrentTabEffect();
    }, [dataPlansList, currentTab, currentPlan]);

    useEffect(() => {
        handleCheckAndChangeValuesToCurrentPlanEffect();
    }, [dataPlansList, currentPlan]);

    useEffect(() => {
        filterListImplementations();
    }, [listImplementationsSelectedPlan]);

    useEffect(() => {
        setValue("country", countrySelected?.value);
        setValue("ddi", codeNumberSeleceted?.value);
    }, [countrySelected, codeNumberSeleceted]);

    useEffect(() => {
        if (!isProposalInternational) {
            setValue("country", 26);
            setValue("ddi", "0055");
        } else {
            getAllCountries();
        }
    }, [isProposalInternational]);

    useEffect(() => {
        getAllCountries();
        getGraceDay();
        getContracts();
        getCustomersCategories();
        getImplementationsPrices();
        getPlansDetails();
        getLeadSource();
        getHolderLevels();
    }, [isProposalInternational]);

    const formatCodeNumber = isProposalInternational
        ? codeNumbersCountries.map((value: any) => {
              return {
                  value: value.fone,
                  label: `+${String(value.fone).replaceAll("0", "")} - ${
                      value.nome
                  }`,
              };
          })
        : [
              {
                  value: 55,
                  label: `+55 - Brasil`,
              },
          ];

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <FormProposalRegisterPage>
                    <ModalAlert
                        typeModal="warning"
                        text="Não é possível efetuar venda para esse cliente por que o mesmo possui divídas pendentes."
                        visible={showModalClientOwning}
                        setVisible={setShowModalClientOwning}
                    />
                    <div className="topFormPage">
                        <h2>Nova Proposta Comercial</h2>
                    </div>
                    <form onSubmit={handleSubmit(createOrSaveProposal)}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isProposalInternational}
                                    onChange={(event) => {
                                        const isChecked = event.target.checked;
                                        setCurrentTab("1");
                                        setValue("document", "");
                                        setIsProposalInternational(isChecked);
                                    }}
                                />
                            }
                            label="Venda Internacional"
                        />
                        <h3>Dados do Titular</h3>
                        <ContentInput>
                            <SelectComponent
                                fullWidth
                                label=""
                                size={20}
                                items={typesHolder}
                                value={typeHolderSelected}
                                name="type-account"
                                onChange={(e) => {
                                    handleFilterChange(e.target.value);
                                }}
                            />
                            <Controller
                                control={control}
                                name="document"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onBlur={
                                            handleChangeInputsAndGetDocumentValueLabel
                                        }
                                        value={value}
                                        size="small"
                                        label={
                                            !isProposalInternational
                                                ? typeHolderSelected === 0
                                                    ? "CPF"
                                                    : "CNPJ"
                                                : "Documento"
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangeDocumentMask(event)}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            maxLength:
                                                typeHolderSelected === 0
                                                    ? 14
                                                    : 18,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="fullName"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label={
                                            typeHolderSelected === 0
                                                ? "Nome Completo"
                                                : "Nome Fantasia"
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="name"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label={
                                            typeHolderSelected === 0
                                                ? "Apelido"
                                                : "Nome Empresarial"
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="ddi"
                                render={({ fieldState: { error } }) => (
                                    <SelectSearch
                                        value={codeNumberSeleceted}
                                        setValue={setCodeNumberSelected}
                                        options={formatCodeNumber}
                                        label="Código do País"
                                        placeholder="Escolha o código"
                                        error={Boolean(error)}
                                        textError={error && error.message}
                                        fullWidth
                                        width="320px"
                                    />
                                )}
                            />
                            {/* <If condition={!isProposalInternational}>
                                <Controller
                                    control={control}
                                    name="ddd"
                                    render={({
                                        field: { onChange, value = "" },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            fullWidth
                                            onChange={(e) => {
                                                setValue("ddd", e.target.value.slice(0, 2).replace(/[^0-9]/g, ''));
                                            }}
                                            value={value}
                                            size="small"
                                            className="smallInput"
                                            label="DDD"
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />
                            </If> */}

                            <Controller
                                control={control}
                                name="phone"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangePhoneMask(event)}
                                        inputProps={{ maxLength: 14 }}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Telefone para Contato"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="email"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Digite o email"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="holderLevel"
                                render={({
                                    field: { value = "", onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Tipo de dados
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={onChange}
                                            label="Tipo de Dados"
                                        >
                                            {selectHolderTypeOptions?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: string;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </ContentInput>
                        <h3>Endereço</h3>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="zipCode"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangeZipCodeMask(event)}
                                        onBlur={async () => {
                                            if (
                                                getValues("zipCode").length > 7
                                            ) {
                                                getAndSetAddress();
                                            }
                                        }}
                                        inputProps={{ maxLength: 9 }}
                                        value={value}
                                        size="small"
                                        label="Digite o Cep"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="street"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Rua/Logradouro"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="complement"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Complemento"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="neighborhood"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Bairro"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="number"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Número"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="country"
                                render={({ fieldState: { error } }) => (
                                    <SelectSearch
                                        // disabled={!isProposalInternational}
                                        fullWidth
                                        value={countrySelected}
                                        setValue={setContrySelected}
                                        options={
                                            isProposalInternational
                                                ? listCountries
                                                : [
                                                      {
                                                          label: "Brasil",
                                                          value: 26,
                                                      },
                                                  ]
                                        }
                                        label="País"
                                        placeholder="Escolha o país"
                                        error={Boolean(error)}
                                        textError={error && error.message}
                                        width="320px"
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="state"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label={
                                            isProposalInternational
                                                ? "Estado ou Província"
                                                : "Estado"
                                        }
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="city"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Cidade"
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                    />
                                )}
                            />
                        </ContentInput>
                        <h3>Seleção de Contrato</h3>
                        <Controller
                            control={control}
                            name="contract"
                            render={({
                                field: { value = "", onChange },
                                fieldState: { error },
                            }) => (
                                <FormControl fullWidth error={Boolean(error)}>
                                    <InputLabel
                                        size="small"
                                        id="demo-simple-select-label"
                                    >
                                        Selecione o Contrato
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        id="demo-simple-select"
                                        value={value}
                                        onChange={onChange}
                                        label="Selecione o Contrato"
                                    >
                                        {dataContractsList.map(
                                            ({
                                                id,
                                                title,
                                            }: {
                                                id: number;
                                                title: string;
                                            }) => (
                                                <MenuItem key={id} value={id}>
                                                    {title}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <h3>Signatários</h3>
                        <div className="signatoryListMainContainer">
                            {signatoryPartnersList?.map(
                                (
                                    {
                                        full_name,
                                        document,
                                    }: holderFormTypeValues,
                                    index: number
                                ) => (
                                    <div
                                        className="signatoryListContainer"
                                        key={document}
                                    >
                                        <p>{full_name}</p>
                                        <p>{documentMask(document)}</p>
                                        <Delete
                                            onClick={() => {
                                                handleRemoveSignatory(index);
                                            }}
                                            className="iconDelete"
                                        />
                                    </div>
                                )
                            )}
                        </div>
                        <ButtonGeneric
                            onclick={() => {
                                setOpenSignatoryPartnersModal(true);
                            }}
                            buttonColor="var(--primary)"
                            text="Adicionar"
                            icon={<Add />}
                            className="addButton"
                        />
                        <ModalSignatoryPartners
                            openSignatoryPartnersModal={
                                openSignatoryPartnersModal
                            }
                            handleCloseSignatoryPartnersModal={
                                handleCloseSignatoryPartnersModal
                            }
                            signatoryPartnersList={signatoryPartnersList}
                            setSignatoryPartnersList={setSignatoryPartnersList}
                        />
                        <h3>Plano Contratado</h3>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="plan"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Selecione o Plano
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedPlanOption
                                            }
                                            label="Selecione o Plano"
                                        >
                                            {selectPlanOptions?.map(
                                                ({
                                                    id,
                                                    description,
                                                }: {
                                                    id: string;
                                                    description: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {description}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="price"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(event) =>
                                            handleChangeMaskInputs(
                                                event,
                                                "price"
                                            )
                                        }
                                        value={value}
                                        size="small"
                                        label="Ativação"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="subscriptionValue"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeMaskInputs(
                                                event,
                                                "subscriptionValue"
                                            );
                                        }}
                                        value={value}
                                        size="small"
                                        label="Valor da Assinatura"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="valuePerSurplusDriver"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeMaskInputs(
                                                event,
                                                "valuePerSurplusDriver"
                                            );
                                        }}
                                        value={Number.isNaN(value) ? "" : value}
                                        size="small"
                                        label="Valor por motorista excedente"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="driversQuantity"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        label="Quantidade de motoristas"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            min: 1,
                                        }}
                                        type="number"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <RememberMe />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="cities"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        type="number"
                                        label="Quantidade de Cidades"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            min: 1,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCity />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="surplusValueCeiling"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeMaskInputs(
                                                event,
                                                "surplusValueCeiling"
                                            );
                                        }}
                                        value={value}
                                        size="small"
                                        label="Valor máximo por excedentes"
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="periodInMonths"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        inputProps={{ min: 1, max: 24 }}
                                        value={value}
                                        size="small"
                                        type="number"
                                        label="Validade do Plano em Meses"
                                        disabled
                                        placeholder="Valor máximo 24"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarMonth />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="graceDay"
                                render={({
                                    field: { value = "", onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Carência em Dias
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={onChange}
                                            label="Carência em Dias"
                                        >
                                            {dataGraceDaysList?.map(
                                                ({
                                                    id,
                                                    days,
                                                }: {
                                                    id: number;
                                                    days: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {days}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </ContentInput>
                        <h3>Implementações Inclusas no Plano</h3>
                        <div className="implementationsIncludesContainer">
                            <ul className="includesImplementationsMaxHeight">
                                {listImplementationsSelectedPlan?.map(
                                    ({
                                        implementation_id,
                                        implementations,
                                    }: {
                                        implementation_id: number;
                                        implementations: {
                                            description: string;
                                        }[];
                                    }) => (
                                        <li
                                            key={implementation_id}
                                            value={
                                                implementations[0].description
                                            }
                                        >
                                            <div className="liContainer">
                                                <p>
                                                    {
                                                        implementations[0]
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        
                        <h3>Implementações Extras</h3>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="implementations"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <FormControl fullWidth>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Selecione a Implementação Extra
                                        </InputLabel>
                                        <Select
                                            error={Boolean(error)}
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedImplementationOption
                                            }
                                            label="Selecione a Implementação Extra"
                                        >
                                            {filteredListImplementations
                                                ?.filter(
                                                    (item) =>
                                                        item.price !== null
                                                )
                                                .filter(
                                                    (item) =>
                                                        item.implementation
                                                            .is_active === 1
                                                )
                                                ?.map(
                                                    ({
                                                        implementation_id,
                                                        implementation,
                                                    }: extraImplementationsProps) => (
                                                        <MenuItem
                                                            key={
                                                                implementation_id
                                                            }
                                                            value={
                                                                implementation_id
                                                            }
                                                        >
                                                            {
                                                                implementation.description
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <ContentInput>
                                <Controller
                                    control={control}
                                    name="priceImplementation"
                                    render={({
                                        field: { value = "" },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            fullWidth
                                            onChange={(event) =>
                                                handleChangeMaskInputs(
                                                    event,
                                                    "price"
                                                )
                                            }
                                            value={value}
                                            size="small"
                                            label="Ativação"
                                            disabled
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <ButtonGeneric
                                    onclick={() => {
                                        if (
                                            extraImplementationsSelected.includes(
                                                selectedExtraImplementation
                                            ) &&
                                            selectedExtraImplementation
                                                .implementation.can_repeat !== 1
                                        ) {
                                            setOpenToast(true);
                                            setToastRequisitionResult("error");
                                            setTextToast(
                                                "A implementação já foi selecionada!"
                                            );
                                            return;
                                        }
                                        setExtraImplementationsSelected(
                                            (prev) => [
                                                ...prev,
                                                selectedExtraImplementation,
                                            ]
                                        );
                                    }}
                                    buttonColor="var(--primary)"
                                    text="Adicionar"
                                    disabled={
                                        Object.keys(selectedExtraImplementation)
                                            .length === 0
                                    }
                                    icon={<Add />}
                                    className="addButton"
                                />
                            </ContentInput>
                        </ContentInput>
                        <div className="implementationsIncludesContainer">
                            <ul>
                                {extraImplementationsSelected?.map(
                                    (
                                        {
                                            implementation_id,
                                            implementation,
                                            price,
                                        }: {
                                            implementation_id: number;
                                            price: number;
                                            implementation: {
                                                description: string;
                                            };
                                        },
                                        index: number
                                    ) => (
                                        <li
                                            key={index}
                                            value={implementation_id}
                                        >
                                            <div className="liContainer">
                                                <p>
                                                    {implementation.description}
                                                </p>
                                                <p>
                                                    R${" "}
                                                    {formatCurrencyToShow(
                                                        price
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <Delete
                                                    onClick={() => {
                                                        handleRemoveExtraImplementation(
                                                            index
                                                        );
                                                    }}
                                                    className="iconDelete"
                                                />
                                            </div>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>

                        <h3>Observações da Proposta</h3>
                        <Controller
                            control={control}
                            name="proposalInformations"
                            render={({
                                field: { onChange, value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    fullWidth
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    label="Informações Sobre a Proposta"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                    multiline
                                    minRows="5"
                                    maxRows="6"
                                />
                            )}
                        />

                        <ContentInput>
                            <Controller
                                control={control}
                                name="customerCategory"
                                render={({
                                    field: { value = "", onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        error={Boolean(error)}
                                        fullWidth
                                    >
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Nível de Cliente
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={onChange}
                                            label="Tipo de Titular"
                                        >
                                            {dataCustomersCategoriesList?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: number;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="lead"
                                render={({
                                    field: { value = "", onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(error)}
                                    >
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Origem do Lead
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={onChange}
                                            label="Origem do Lead"
                                        >
                                            {dataLeadsSourceList?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: number;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </ContentInput>
                        <ContentInput>
                            {/* <Controller
                                control={control}
                                name="proposalInformations"
                                render={({
                                    field: {
                                        onBlur,
                                        onChange,
                                        value = "",
                                        ref,
                                        ...field
                                    },
                                    fieldState,
                                }) => (
                                    <InputGeneric
                                        {...field}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="fullWidthInput"
                                        label="Informações Sobre a Proposta"
                                        error={Boolean(fieldState.error)}
                                        inputRef={ref}
                                        multiline
                                        minRows="5"
                                        maxRows="6"
                                    />
                                )}
                            /> */}
                            <Controller
                                control={control}
                                name="discountCoupon"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        onChange={(event) => {
                                            setValue(
                                                "discountCoupon",
                                                event.target.value.toUpperCase()
                                            );
                                            setAmountDiscont(0);
                                            setAmountWithDiscont(0);
                                        }}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Cupom de Desconto"
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                            <ButtonGeneric
                                onclick={() => {
                                    validCoupon();
                                }}
                                buttonColor="var(--primary)"
                                text="Aplicar"
                                icon={<Add />}
                                className="addButton"
                            />
                        </ContentInput>
                        <div>
                            <If condition={amountDiscont > 0}>
                                <Alert severity="success">
                                    Cupom aplicado!
                                </Alert>
                            </If>
                        </div>
                        <h3>Forma de Pagamento - Ativação</h3>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TabContext value={currentTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList className="tabsRadioButtonsContainer">
                                        <Tab
                                            onClick={() => setCurrentTab("1")}
                                            label={
                                                <div className="optionContainerTab">
                                                    <ListItemIcon className="iconTab">
                                                        {currentTab === "1" ? (
                                                            <CheckCircle
                                                                sx={{
                                                                    color: "var(--primary)",
                                                                }}
                                                            />
                                                        ) : (
                                                            <RadioButtonUnchecked />
                                                        )}
                                                    </ListItemIcon>
                                                    Link de Pagamento
                                                </div>
                                            }
                                            value="1"
                                        />

                                        {!isProposalInternational && (
                                            <Tab
                                                onClick={() => {
                                                    setCurrentTab("2");
                                                }}
                                                label={
                                                    <div className="optionContainerTab">
                                                        <ListItemIcon className="iconTab">
                                                            {currentTab ===
                                                            "2" ? (
                                                                <CheckCircle
                                                                    sx={{
                                                                        color: "var(--primary)",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <RadioButtonUnchecked />
                                                            )}
                                                        </ListItemIcon>
                                                        Parcelado
                                                    </div>
                                                }
                                                value="2"
                                            />
                                        )}

                                        {!isProposalInternational && (
                                            <Tab
                                                onClick={() => {
                                                    setCurrentTab("3");
                                                }}
                                                label={
                                                    <div className="optionContainerTab">
                                                        <ListItemIcon className="iconTab">
                                                            {currentTab ===
                                                            "3" ? (
                                                                <CheckCircle
                                                                    sx={{
                                                                        color: "var(--primary)",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <RadioButtonUnchecked />
                                                            )}
                                                        </ListItemIcon>
                                                        Cartão de crédito / PIX
                                                    </div>
                                                }
                                                value="3"
                                            />
                                        )}
                                    </TabList>
                                </Box>
                                <TabPanel
                                    value="1"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        name="urlLink"
                                        render={({
                                            field: { onChange, value = "" },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                onChange={onChange}
                                                value={value}
                                                size="small"
                                                label="Link de Pagamento"
                                                error={Boolean(error)}
                                                helperText={
                                                    error && error.message
                                                }
                                            />
                                        )}
                                    />
                                    {isProposalInternational && (
                                        <ContentInput>
                                            <Controller
                                                control={control}
                                                name="installment"
                                                render={({
                                                    field: {
                                                        value = "",
                                                        onChange,
                                                    },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(error)}
                                                    >
                                                        <InputLabel
                                                            size="small"
                                                            id="demo-simple-select-label"
                                                        >
                                                            Quantidade de
                                                            Parcelas
                                                        </InputLabel>
                                                        <Select
                                                            size="small"
                                                            id="demo-simple-select"
                                                            value={value}
                                                            onChange={onChange}
                                                            label="Quantidade de Parcelas"
                                                        >
                                                            {getQuantitiesInstallMents(
                                                                "allNumber"
                                                            ).map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                        >
                                                                            {
                                                                                value
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                        {error && (
                                                            <FormHelperText>
                                                                {error.message}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name="dueDate"
                                                defaultValue={new Date()
                                                    .toISOString()
                                                    .substr(0, 10)}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        value = "",
                                                    },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        onChange={onChange}
                                                        size="small"
                                                        value={value}
                                                        type="date"
                                                        label="Data de Vencimento"
                                                        error={Boolean(error)}
                                                        helperText={
                                                            error &&
                                                            error.message
                                                        }
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </ContentInput>
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="2"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <ContentInput>
                                        <Controller
                                            control={control}
                                            name="entryAmount"
                                            render={({
                                                field: { onChange, value = "" },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    fullWidth
                                                    onChange={(e) => {
                                                        setValue("entryAmount", Number(e.target.value));
                                                        setEntryAmount(Number(e.target.value));
                                                    }}
                                                    value={value}
                                                    type="number"
                                                    size="small"
                                                    label="Valor de entrada R$"
                                                    error={Boolean(error)}
                                                    helperText={
                                                        error && error.message
                                                    }
                                                />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name="installment"
                                            render={({
                                                field: { value = 0, onChange },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        size="small"
                                                        id="demo-simple-select-label"
                                                    >
                                                        Quantidade de Parcelas
                                                    </InputLabel>
                                                    <Select
                                                        error={Boolean(error)}
                                                        size="small"
                                                        id="demo-simple-select"
                                                        value={value}
                                                        onChange={(e) => {
                                                            setValue("installment", String(e.target.value));
                                                            setInstallmentPayment(Number(e.target.value));
                                                        }}
                                                        label="Quantidade de Parcelas"
                                                    >
                                                        {getQuantitiesInstallMents(
                                                            "capado"
                                                        ).map(
                                                            (value, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {value}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                    
                                                    {error && (
                                                        <FormHelperText>
                                                            {error.message}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}
                                        />
                                        
                                        <Controller
                                            control={control}
                                            name="dueDate"
                                            defaultValue={new Date()
                                                .toISOString()
                                                .substr(0, 10)}
                                            render={({
                                                field: { onChange, value = "" },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    fullWidth
                                                    onChange={onChange}
                                                    size="small"
                                                    value={value}
                                                    type="date"
                                                    label="Data de Vencimento"
                                                    error={!!errors.dueDate}
                                                    helperText={
                                                        error && error.message
                                                    }
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                />
                                            )}
                                        />
                                    </ContentInput>
                                </TabPanel>
                                <TabPanel
                                    value="3"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <ContentInput>
                                        <Controller
                                            control={control}
                                            name="installment"
                                            render={({
                                                field: { value = "", onChange },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        size="small"
                                                        id="demo-simple-select-label"
                                                    >
                                                        Quantidade de Parcelas
                                                    </InputLabel>
                                                    <Select
                                                        error={Boolean(error)}
                                                        size="small"
                                                        id="demo-simple-select"
                                                        value={value}
                                                        onChange={onChange}
                                                        label="Quantidade de Parcelas"
                                                    >
                                                        {getQuantitiesInstallMents().map(
                                                            (value, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            value - 1
                                                                        }
                                                                    >
                                                                        {value}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                    {error && (
                                                        <FormHelperText>
                                                            {error.message}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name="dueDate"
                                            defaultValue={new Date()
                                                .toISOString()
                                                .substr(0, 10)}
                                            render={({
                                                field: { onChange, value = "" },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    fullWidth
                                                    onChange={onChange}
                                                    size="small"
                                                    value={value}
                                                    type="date"
                                                    label="Data de Vencimento"
                                                    error={Boolean(error)}
                                                    helperText={
                                                        error && error.message
                                                    }
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                />
                                            )}
                                        />
                                    </ContentInput>
                                </TabPanel>
                            </TabContext>
                        </Box>
                        <h3>Total da Proposta</h3>
                        <div className="valueProposalDetails">
                            <div className="rowValueProposalDetail">
                                <h4>Valor da ativação:</h4>
                                <h4>
                                    R${" "}
                                    {activationPlanPrice
                                        ? formatCurrencyToShow(
                                              activationPlanPrice
                                          )
                                        : "0,00"}{" "}
                                    +
                                </h4>
                            </div>
                            <If condition={String(currentTab) == "2"}>
                                <div className="rowValueProposalDetail">
                                <span>Valor de entrada: </span>
                                    <span>
                                        R${" "}
                                    {
                                        formatCurrencyToShow(
                                            entryAmount
                                        )
                                    }
                                    </span>
                                </div>
                                <div className="rowValueProposalDetail">
                                    <span>Demais parcelas: </span>
                                    <span>
                                        {installmentPayment} x R${" "}
                                    {
                                        formatCurrencyToShow(
                                            ((activationPlanPrice +
                                                    ImplementationsExtrasSum -
                                                    amountDiscont) - entryAmount) / (installmentPayment)
                                        )
                                    }
                                    </span>
                                </div>
                            </If>
                            <div className="rowValueProposalDetail">
                                <h4>Implementações extras:</h4>
                                <h4>
                                    R$
                                    {formatCurrencyToShow(
                                        ImplementationsExtrasSum
                                    ) || "0,00"}{" "}
                                    +
                                </h4>
                            </div>
                            <div className="rowValueProposalDetail">
                                <h4>Descontos:</h4>
                                <h3 style={{ color: "red" }}>
                                    {" "}
                                    - R$ {formatCurrencyToShow(amountDiscont)}
                                </h3>
                            </div>
                            <div className="rowValueProposalDetail">
                                <h3>Valor Total</h3>
                                <h3 style={{ color: "var(--success)" }}>
                                    R${" "}
                                    {formatCurrencyToShow(
                                        Number.isNaN(
                                            activationPlanPrice +
                                                ImplementationsExtrasSum -
                                                amountDiscont
                                        )
                                            ? 0
                                            : activationPlanPrice +
                                                  ImplementationsExtrasSum -
                                                  amountDiscont
                                    )}
                                </h3>
                            </div>
                        </div>

                        <div className="allButtonsContainer">
                            <div className="saveButtonContainer">
                                <SaveButton
                                    isLoadingButton={isLoadingButton}
                                    success={success}
                                    sx={sxSavebutton}
                                    onFocus={() => {
                                        setSaveAndSendContract(false);
                                    }}
                                />
                            </div>
                            <div className="buttonsContainer">
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate("/comercial/proposal");
                                    }}
                                    buttonColor="var(--cancel)"
                                    text="VOLTAR"
                                />
                                <LoadingButton
                                    loading={isLoadingButton}
                                    variant="contained"
                                    type="submit"
                                    onFocus={() => {
                                        setSaveAndSendContract(true);
                                    }}
                                    disabled={disableButton}
                                >
                                    ENVIAR PROPOSTA
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </FormProposalRegisterPage>
            )}
        </PageWrapper>
    );
}
export default ProposalRegisterPage;
