import styled from "styled-components";

export const Container = styled.form`
    background-color: #fff;
    padding: 32px 35px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 10, 0.05);

    @media (max-width: 768px) {
        padding: 16px 17.5px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin: 1rem 0;

    > div:nth-child(1) {
        max-width: 800px;
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        gap: 4rem;

        > div:nth-child(1) {
            width: 100%;
        }
    }
`;

export const TitlePage = styled.h1`
    font-size: 24px;
    text-align: center;
    margin-bottom: 3rem;
`;

export const ContentRowInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const ContentInput = styled.div`
    width: 100%;
    margin: 0.4rem 0;

    input[type="file"] {
        display: none;
    }
`;

export const ContentBannerAndVideo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LabelText = styled.p``;

export const ButtonAddVideo = styled.button`
    width: 100%;
    margin: 10px 0px;
    padding: 10px 20px;
    background: var(--primary);
    color: white;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    transition: 0.6s;
    font-family: "Montserrat", sans-serif;
    border: none;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
        opacity: 0.9;
        outline: 1px solid gray;
        text-shadow: 0px 0px 2px black;
    }
`;

export const ButtonLabelBanner = styled.label`
    margin: 10px 0px;
    padding: 10px 20px;
    background: var(--primary);
    color: white;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    transition: 0.6s;
    font-family: "Montserrat", sans-serif;
    border: none;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
        opacity: 0.9;
        outline: 1px solid gray;
        text-shadow: 0px 0px 2px black;
    }
`;

export const ContentButtonSendVideo = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
`;

export const ContentCardVideo = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3rem;
`;

export const CardVideo = styled.div`
    width: 150px;
    padding: 0.3rem;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(0.9);

    img {
        width: 100%;
        height: 80px;
    }

    :hover {
        transition: all ease 0.2s;
        transform: scale(1);
    }
`;

export const ContentBtnDeleteVideo = styled.div`
    display: flex;
    justify-content: center;
`;

export const ContentInformationsCustomers = styled.div`
    margin: 1rem 0;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const TitleClientsActives = styled.h3`
    font-size: 20px;

    @media (max-width: 425px) {
        text-align: center;
    }
`;

export const TextFilter = styled.p`
    font-size: 16px;
`;

export const TextTotalClients = styled.span`
    font-size: 16px;

    @media (max-width: 425px) {
        text-align: center;
    }
`;
