import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowId } from "@mui/x-data-grid";
import { useState } from "react";

import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteProfileStyle } from "./styles";

export interface DataProfileProps {
    created_at?: string;
    deleted_at?: null;
    guard_name?: string;
    id?: number;
    module_id?: number;
    name?: string;
    updated_at?: string;
}
interface Props {
    openDeleteProfileModal: boolean;
    handleCloseDeleteProfileModal: () => void;
    selectedRowProfileName: string;
    selectedRowProfileId?: GridRowId;
    dataProfileList: DataProfileProps[];
    setDataProfileList: (values: DataProfileProps[]) => void;
}

function ModalDeleteProfile({
    openDeleteProfileModal,
    handleCloseDeleteProfileModal,
    selectedRowProfileName,
    selectedRowProfileId,
    setDataProfileList,
    dataProfileList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteProfile() {
        setIsLoadingButton(true);
        api.delete(`/roles/${selectedRowProfileId}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteProfileModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataProfileList(
                    dataProfileList.filter((item: DataProfileProps) => {
                        return item.id !== Number(selectedRowProfileId);
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteProfileModal}
            onClose={handleCloseDeleteProfileModal}
        >
            <ModalDeleteProfileStyle>
                <Box className="modalContainer">
                    <p>{`Você realmente deseja excluir o perfil "${selectedRowProfileName}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteProfileModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteProfile()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteProfileStyle>
        </Modal>
    );
}
export default ModalDeleteProfile;
