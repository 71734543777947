import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChangeEvent, ReactNode } from "react";

import { Container } from "./styles";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

interface ButtonFileProps extends ButtonProps {
    icon: ReactNode;
    title: string;
    onChangeInput: (data: ChangeEvent<HTMLInputElement>) => void;
    refInput: any;
    accept?: string | undefined;
}

function ButtonFile({
    icon,
    title,
    onChangeInput,
    refInput,
    accept,
    ...props
}: ButtonFileProps) {
    return (
        <Container>
            <Button
                component="label"
                startIcon={icon}
                variant="contained"
                {...props}
            >
                {title}
                <VisuallyHiddenInput
                    type="file"
                    onChange={onChangeInput}
                    ref={refInput}
                    accept={accept}
                />
            </Button>
        </Container>
    );
}

export default ButtonFile;
