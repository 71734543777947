import { Box, Modal, TextField } from "@mui/material";
import { useState } from "react";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import { ModalDeleteProposalStyle } from "./styles";
import { MonthlyBillingProps } from "./types";

interface Props {
    visible: boolean;
    setVisible: (data: boolean) => void;
    monthlyBilling: MonthlyBillingProps | null;
    getFinancials: () => void;
    typeModal: "write-off" | "cancel";
    setOpenBillingDetailsModal: (data: boolean) => void;
}

function ModalDeleteInvoice({
    visible,
    setVisible,
    monthlyBilling,
    getFinancials,
    typeModal,
    setOpenBillingDetailsModal,
}: Props) {
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [reason, setReason] = useState<string>("");

    const handleWriteOff = async () => {
        if (reason.length <= 0) {
            defaultToast("warning", "Escreva do motivo da baixa da fatura!");
            return;
        }

        try {
            setIsLoadingButton(true);

            const responseWriteOff = await api.post(
                `monthly-billing/write-off/${monthlyBilling?.id}`,
                {
                    reason,
                }
            );

            if (responseWriteOff.status) {
                defaultToast("success", "Fatura paga com sucesso!");
                setVisible(false);
                setReason("");
                getFinancials();
                setOpenBillingDetailsModal(false);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao dar baixa na fatura");
        } finally {
            setIsLoadingButton(false);
        }
    };

    const handleDelete = async () => {
        if (reason.length <= 0) {
            defaultToast("warning", "Escreva do motivo do cancelamento!");
            return;
        }

        try {
            setIsLoadingButton(true);

            const repsonseDelete = await api.put(
                `monthly-billing/cancel/${monthlyBilling?.id}`,
                {
                    reason,
                }
            );

            if (repsonseDelete.status) {
                defaultToast("success", "Fatura cancelada com sucesso!");
                setVisible(false);
                setReason("");
                getFinancials();
                setOpenBillingDetailsModal(false);
            }
        } catch (error: any) {
            console.error(error);
            defaultToast("error", error.response.data.message);
        } finally {
            setIsLoadingButton(false);
        }
    };

    return (
        <Modal open={visible} onClose={() => setVisible(false)}>
            <ModalDeleteProposalStyle>
                <Box className="modalContainer">
                    <p>{`Deseja realmente ${
                        typeModal === "write-off"
                            ? "confirmar o pagamento"
                            : "cancelar"
                    } essa fatura?`}</p>
                    <div>
                        <TextField
                            size="small"
                            label="Motivo"
                            variant="outlined"
                            value={reason}
                            sx={{
                                width: "100%",
                            }}
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => setVisible(false)}
                            buttonColor="var(--cancel)"
                            text="SAIR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background={
                                typeModal === "write-off"
                                    ? "var(--success)"
                                    : "var(--delete)"
                            }
                            loading={isLoadingButton}
                            text={
                                typeModal === "write-off"
                                    ? "CONFIRMAR"
                                    : "CANCELAR"
                            }
                            onClick={() =>
                                typeModal === "write-off"
                                    ? handleWriteOff()
                                    : handleDelete()
                            }
                        />
                    </div>
                </Box>
            </ModalDeleteProposalStyle>
        </Modal>
    );
}
export default ModalDeleteInvoice;
