import { Alert } from "@mui/material";
import styled from "styled-components";

export const AlertStyles = styled(Alert)`
    max-width: 50%;
    width: 270px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.2) !important;
    bottom: 0;
    left: 0;
`;
