import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";

import CancelledImage from "../../assets/images/cancelled-image.png";
import ConfirmedImage from "../../assets/images/confirmed-image.png";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { PaymentCompletedPageStyles, PaymentExpiredPageStyles } from "./styles";

function PaymentExpiredPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const [paymentStatusValue, setPaymentStatusValue] = useState<number>();
    const [paymentStatusMessage, setPaymentStatusMessage] = useState<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        string | any
    >();

    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/checkout/client/${myParam}`)
            .then((response) => {
                setPaymentStatusValue(
                    response.data.response.payment.payment_status?.id
                );
            })
            .catch((error) => {
                setPaymentStatusMessage(
                    error.response.data.response[0] ??
                        error.response.data.message
                );
            });
    }, []);

    return (
        <PaymentExpiredPageStyles>
            {paymentStatusMessage ? (
                <div className="messageContainer">
                    <h2>
                        {paymentStatusMessage} Entre em contato com nosso
                        suporte. <PermPhoneMsgIcon />
                    </h2>
                    <img
                        className="paymentImageStatus"
                        src={CancelledImage}
                        alt=""
                    />
                </div>
            ) : (
                <ContentLoader viewBox="0 0 380 70">
                    {/* Only SVG shapes */}
                    <rect x="40" y="5" rx="4" ry="4" width="300" height="8" />
                </ContentLoader>
            )}
        </PaymentExpiredPageStyles>
    );
}

export default PaymentExpiredPage;
