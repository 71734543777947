import styled from "styled-components";

export const Container = styled.div``;

export const ContentHeaderFilter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
`;

export const ContentFilter = styled.div`
    padding: 1rem;
`;

export const ContentTable = styled.div`
    .css-17jjc08-MuiDataGrid-footerContainer {
        display: none;
    }
    height: 550px;
`;

export const ContentPagination = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;
