import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";

import { LoadingButtonStyle } from "./styles";

interface Props {
    onClick?: () => void;
    loading?: boolean;
    colorText?: string;
    background?: string;
    text?: string;
    type?: "button" | "submit" | "reset" | undefined;
    sx?: object;
    onFocus?: () => void;
    endIcon?: JSX.Element | string;
    disabledButton?: boolean;
}

function PreloadButton({
    onClick,
    loading,
    colorText,
    background,
    text,
    type,
    sx,
    onFocus,
    endIcon,
    disabledButton,
}: Props) {
    //   const [isLoadingButton, setIsLoadingButton] = useState(false);
    //   function handleClick() {
    //     setIsLoadingButton(true);
    //   }
    return (
        <LoadingButtonStyle>
            <LoadingButton
                loadingIndicator={
                    <CircularProgress sx={{ color: "white" }} size={16} />
                }
                onClick={onClick}
                onFocus={onFocus}
                loading={loading}
                variant="outlined"
                className="loadingButton"
                style={{
                    color: `${colorText}`,
                    background: `${background}`,
                }}
                sx={sx}
                type={type}
                endIcon={endIcon}
                disabled={disabledButton}
            >
                {loading ? "" : `${text}`}
            </LoadingButton>
        </LoadingButtonStyle>
    );
}
export default PreloadButton;
