import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { GraceDaysProps } from "../../pages/GraceDaysPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteGraceDayStyle } from "./styles";

interface Props {
    openDeleteGraceDayModal: boolean;
    handleCloseDeleteGraceDayModal: () => void;
    selectedRowGraceDay: GridRowParams["row"];
    setDataGraceDaysList: (values: GraceDaysProps[]) => void;
    dataGraceDaysList: GraceDaysProps[];
}

function ModalDeleteGraceDay({
    openDeleteGraceDayModal,
    handleCloseDeleteGraceDayModal,
    selectedRowGraceDay,
    setDataGraceDaysList,
    dataGraceDaysList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteGraceDay() {
        setIsLoadingButton(true);
        api.delete(`/graceDay/${selectedRowGraceDay.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteGraceDayModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataGraceDaysList(
                    dataGraceDaysList.filter((item: { id: number }) => {
                        return item.id !== selectedRowGraceDay.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteGraceDayModal}
            onClose={handleCloseDeleteGraceDayModal}
        >
            <ModalDeleteGraceDayStyle>
                <Box className="modalContainer">
                    <p>{`Deseja excluir Período de Carência de "${selectedRowGraceDay.days}" dias?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteGraceDayModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteGraceDay()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteGraceDayStyle>
        </Modal>
    );
}
export default ModalDeleteGraceDay;
