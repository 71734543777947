import imageError from "../../assets/images/errorImage.png";
import PageWrapper from "../../components/PageWrapper";
import { ErrorPageStyle } from "./styles";

function ErrorPage() {
    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");

    return (
        <PageWrapper>
            <ErrorPageStyle>
                <img
                    className="imageContent"
                    src={imageError}
                    alt="warning img"
                />
                <h2>
                    Olá, {name}, você está tentando acessar uma rota sem as
                    devidas permissões ou que não existe, por favor, evite
                    digitar a URL manualmente e navegue apenas através dos itens
                    disponíveis no nosso menu.
                </h2>
            </ErrorPageStyle>
        </PageWrapper>
    );
}

export default ErrorPage;
