import styled from "styled-components";

export const Container = styled.div``;

export const HeaderSearch = styled.div`
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
`;

export const ContentTable = styled.div`
    width: 100%;
    height: 550px;

    .css-17jjc08-MuiDataGrid-footerContainer {
        display: none !important;
    }

    .css-1yy0dv2 {
        display: none !important;
    }
`;

export const ContentPaginate = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;
