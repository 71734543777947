import styled from "styled-components";

export const FormLoginStyle = styled.div`
    .circularProgressPosition {
        position: absolute;
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;

    p {
        display: flex;
        width: 100%;
        justify-content: center;
        color: red;
        min-height: 15px;
        font-size: 12px;
        margin: 0;
    }
`;
