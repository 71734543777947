import { VerifiedUser } from "@mui/icons-material";
import { InputLabel } from "@mui/material";

import InputGeneric from "../../components/InputGeneric";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import { ContainerProfile } from "./styles";

export function UserProfile() {
    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Perfil",
                    subtitle: "Atualize seus dados cadastrais",
                    icon: <VerifiedUser sx={{ color: "#fff" }} />,
                }}
                style={{
                    height: "100%",
                }}
            >
                <ContainerProfile>
                    <div className="img">
                        <img
                            src="https://i.pinimg.com/736x/3e/aa/24/3eaa245d923949b6f662b8ba07b7a3b2.jpg"
                            alt=""
                        />
                        <input type="file" />
                    </div>
                    <div className="data-inputs">
                        <h4>Dados pessoais</h4>
                        <InputGeneric
                            fullWidth
                            size="small"
                            className="bigInput"
                            type="text"
                            label="Nome"
                            name="startDate"
                            inputProps={{
                                maxLength: 40,
                            }}
                            value="Manoel"
                        />

                        <InputGeneric
                            fullWidth
                            size="small"
                            className="bigInput"
                            type="text"
                            label="Email"
                            name="startDate"
                            inputProps={{
                                maxLength: 40,
                            }}
                            value="ana@apps.com"
                        />

                        <h4>Cargo: sem cargo</h4>
                    </div>
                </ContainerProfile>
            </PageDefault>
        </PageWrapper>
    );
}
