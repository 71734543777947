/* eslint-disable */

import { Button } from "@mui/material";
import pathBannerDefault from "../../assets/images/banner-default-publicity.png";

import {
    Container,
    Banner,
    Title,
    ContentFooterButtons,
    ButtonSeeMore,
    IframeVideo,
} from "./styles";

interface SimulationModalHelperProps {
    title: string | undefined;
    url_video: string | undefined;
    url_application: string | undefined;
    url_redirect: string | undefined;
}

function SimulationModalHelper({
    title,
    url_video,
    url_application,
    url_redirect,
}: SimulationModalHelperProps) {
    const getImgVideo = (urlVideo: string | undefined) => {
        if (urlVideo) {
            return `https://www.youtube.com/embed/${urlVideo.split("=")[1]}`;
        }
    };

    return (
        <Container>
            <Title>
                {(title && title.length <= 0) || !title
                    ? "Título do Helper"
                    : title}
            </Title>

            {url_video ? (
                <IframeVideo
                    width="420"
                    height="315"
                    src={getImgVideo(url_video)}
                />
            ) : (
                <Banner src={pathBannerDefault} />
            )}

            <hr
                style={{
                    margin: "2rem 0",
                    color: "gray",
                }}
            />

            <ContentFooterButtons>
                <Button>Ativar impelementações</Button>
                <ButtonSeeMore href={url_redirect} target="_blank">
                    Ver mais
                </ButtonSeeMore>
            </ContentFooterButtons>
        </Container>
    );
}

export default SimulationModalHelper;
