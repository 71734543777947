import styled from "styled-components";

export const Container = styled.div``;

export const ContentTable = styled.div`
    height: 600px;
`;

export const ContentButtonAddPulicity = styled.div`
    display: flex;
    justify-content: flex-end;
`;
