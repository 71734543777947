import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;

    @media (max-width: 845px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ContentHeaderAccordion = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
`;

export const ContentInput = styled.div`
    width: 500px;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 845px) {
        width: 100%;
    }
`;

export const LevelsFollowUpProjects = styled.div`
    margin: 1rem 0;
    /* background-color: var(--primary); */
`;

export const ContentTable = styled.div`
    width: 100%;
    height: 450px;
`;

export const ContentButton = styled.div`
    @media (max-width: 845px) {
        width: 100%;
    }
`;
