import styled from "styled-components";

export const Container = styled.div`
    margin: 1rem 0;
    width: 100%;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 950px) {
        flex-direction: column;
    }
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ListVersions = styled.div`
    width: 100%;

    h3 {
        border-bottom: 1px solid #000000;
        padding-bottom: 1rem;
    }
`;

export const ContentVersions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    color: gray;
`;

export const ContentBtnActions = styled.div``;
