/* eslint-disable */

export const MaskOnlyLetters = (value: string) => {
    if (value.length > 1) {
        let valorFiltrado = value.replace(/[^a-zA-Z0-9]/g, "");
        return valorFiltrado;
    } else {
        let valorFiltrado = value.replace(/[^a-zA-Z]/g, "");
        return valorFiltrado;
    }
};
