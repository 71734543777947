import styled from "styled-components";

export const PaymentActivationLinkPageStyles = styled.div<{
    $isLoading: boolean;
}>`
    width: 100%;
    min-width: 380px;
    padding: 10px 0;
    color: #49494c;
    margin: auto auto;
    background: ${(props) =>
        props.$isLoading
            ? "#fff"
            : `
    linear-gradient(
        0deg,
        var(--primary) 0%,
        var(--primary) 48%,
        #fff 48%
    )`};
    min-height: 110vh;

    header {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
    }

    header span {
        font-weight: 700;
    }

    section {
        display: flex;
        justify-content: center;
        margin: 0 0 3rem 0;
    }

    .leftSideContainer {
        margin-top: 25px;
        border-radius: 10px;
        box-shadow: 0px 0px 7px #8888889e;
        width: 800px;
        margin-right: 5%;
        height: 100%;
    }

    .tabInformationsContainer {
        display: flex;
        align-items: center;
    }

    .iconTab {
        margin-right: -25px;
        margin-bottom: 2px;
    }

    .totalValuePixContainer {
        display: flex;
        justify-content: space-between;
    }

    .inputFullWidth {
        width: 100%;
    }

    .threeInputsContainer {
        display: flex;
        gap: 20px;
    }

    .threeInputsContainer .inputs {
        width: 237px;
    }

    .threeInputsContainer .averageInput {
        width: 150px;
    }

    .threeInputsContainer .bigInput {
        width: 435px;
    }

    .threeInputsContainer .smallInput {
        width: 125px;
    }

    .twoInputsContainer {
        display: flex;
        gap: 20px;
    }

    .twoInputsContainer .inputs {
        width: 365px;
    }

    .twoInputsContainer .input {
        width: 342px;
    }

    .expirationDateInput {
        width: 189px;
    }

    .flagInput {
        width: 350px;
    }

    .bigSelect {
        width: 350px;
    }

    .buttonNextContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .pixContainer {
        display: flex;
    }

    .imagePixContainer img {
        width: 250px;
        height: auto;
    }

    .inputFullWidth {
        width: 100%;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .rightSideContainer {
        margin-top: 25px;
        border-radius: 10px;
        box-shadow: 0px 0px 7px #8888889e;
        width: 400px;
        padding: 20px;
        font-size: 0.9rem;
        background-color: #fff;
    }

    .statusPaymentContainer {
        display: flex;
        justify-content: space-between;
    }

    .valueContainerRightSide {
        display: flex;
        justify-content: space-between;
    }

    .valueContainerInvoice {
        p {
            margin: 10px;
        }
    }

    .attentionText {
        color: #fac420;
        font-weight: 600;
    }

    .instructionsPix {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: justify;
    }

    @media (max-width: 1100px) {
        section {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        .leftSideContainer {
            margin-right: 0px;
        }

        .rightSideContainer {
            width: 760px;
        }

        @media (max-width: 840px) {
            .pixContainer {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
            .leftSideContainer {
                margin-right: 0px;
                width: 90%;
            }

            .threeInputsContainer {
                flex-direction: column;
                gap: 0px;
            }

            .threeInputsContainer .inputs {
                width: 100%;
            }

            .threeInputsContainer .averageInput {
                width: 100%;
            }

            .threeInputsContainer .bigInput {
                width: 100%;
            }

            .threeInputsContainer .smallInput {
                width: 100%;
            }

            .twoInputsContainer {
                flex-direction: column;
                gap: 0px;
            }

            .twoInputsContainer .inputs {
                width: 100%;
            }

            .twoInputsContainer .input {
                width: 100%;
            }

            .expirationDateInput {
                width: 100%;
            }

            .flagInput {
                width: 100%;
            }

            .bigSelect {
                width: 100%;
            }

            .rightSideContainer {
                width: 82%;
            }

            .inputs {
                width: 260px;
            }

            .buttonsContainer {
                font-size: 0.7rem;
            }
        }
        @media (max-width: 600px) {
            .pixContainer {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        @media (max-width: 470px) {
            .backButton {
                width: 100px;
            }
        }
    }
`;

export const TitleModal = styled.h2`
    text-align: center;
`;

export const ButtonsContainerModal = styled.div`
    display: flex;
    gap: 3%;
    justify-content: center;
    align-items: center;
`;
