import styled from "styled-components";

export const LogContent = styled.div`
    display: flex;
    max-height: 56vh;
    width: 100%;
    color: #fff;
`;
export const Logs = styled.div`
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    padding: 5px 20px;
    width: 100%;
    max-height: 56vh;
    background-color: var(--primary);

    p {
        margin: 4px;
        display: flex;
    }
`;

interface TypeColorProps {
    textColor?: string;
}

export const TypeColor = styled.div<TypeColorProps>`
    color: ${(props) => props.textColor || "#fff"};
    flex: 2;
`;
