import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormProposalImplementationsDetails } from "./styles";
import { DetailsImplementationsProps } from "./types";

function ProposalImplementationsDetails() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const [openToast, setOpenToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [implementationDetails, setImplementationDetails] =
        useState<DetailsImplementationsProps>();

    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    // const [isLoadingCreateContractButton, setIsLoadingCreateContractButton] =
    //     useState(false);

    const [isDisabledCreateContractButton, setIsDisabledCreateContractButton] =
        useState(false);

    const switchStatus = (id: number) => {
        switch (id) {
            case 1:
                return "SALVA";
            case 2:
                return "ENVIADA";
            case 3:
                return "PAGA";
            case 4:
                return "EXPIRADA";
            default:
                return "";
        }
    };

    const getDetailsImplementations = async () => {
        setIsLoading(true);
        try {
            const responseDetailsImplementations = await api.get(
                `implementationProposal/${myParam}`
            );

            if (responseDetailsImplementations.status) {
                setImplementationDetails(
                    responseDetailsImplementations.data.response
                );
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getDetailsImplementations();
    }, []);

    useEffect(() => {
        if (
            hasPermissions("propostas.detalhar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error", { replace: true });
        }
    }, [permissions]);

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <FormProposalImplementationsDetails
                    isDisabledCreateContractButton={
                        isDisabledCreateContractButton
                    }
                >
                    {implementationDetails && (
                        <>
                            <div>
                                <h1 className="title-content">Dados Gerais</h1>

                                <div className="content">
                                    <span className="label-text">Status: </span>
                                    <span className="text">
                                        {switchStatus(
                                            implementationDetails.status_id
                                        )}
                                    </span>
                                </div>

                                <div className="content">
                                    <span className="label-text">
                                        Cliente:{" "}
                                    </span>
                                    <span className="text">
                                        {implementationDetails.customer
                                            ? implementationDetails.customer
                                                  .name
                                            : "-"}
                                    </span>
                                </div>

                                <div className="content">
                                    <span className="label-text">
                                        Vendedor:{" "}
                                    </span>
                                    <span className="text">
                                        {implementationDetails.user.name}
                                    </span>
                                </div>

                                <div className="content">
                                    <span className="label-text">
                                        Link de Pagamento:{" "}
                                    </span>
                                    <span>
                                        <Link
                                            to={`/checkout?id=${implementationDetails.payment.payment_reference}`}
                                            target="_blank"
                                        >
                                            Ir para pagamento
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <div>
                                <h1 className="title-content">
                                    Forma de Pagamento
                                </h1>

                                <div className="content">
                                    <span className="label-text">
                                        Data de Vencimento:{" "}
                                    </span>
                                    <span className="text">
                                        {new Date(
                                            implementationDetails.payment.form_payment.due_date
                                        ).toLocaleDateString()}
                                    </span>
                                </div>

                                <div className="content">
                                    <span className="label-text">
                                        Parcelas:{" "}
                                    </span>
                                    <span className="text">
                                        {
                                            implementationDetails.payment
                                                .form_payment
                                                .number_installments
                                        }
                                    </span>
                                </div>

                                <div className="content">
                                    <span className="label-text">Total: </span>
                                    <span className="text">
                                        {implementationDetails.payment.total_amount.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div>
                                <h1 className="title-content">
                                    Implementações
                                </h1>

                                <div className="content-implementations">
                                    <ul>
                                        {implementationDetails?.implementations.map(
                                            (value) => {
                                                return (
                                                    <li>
                                                        {
                                                            value.implementation
                                                                .description
                                                        }{" "}
                                                        -{" "}
                                                        {value.implementation.prices[0].price.toLocaleString(
                                                            "pt-br",
                                                            {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }
                                                        )}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {/* <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    /> */}
                </FormProposalImplementationsDetails>
            )}
        </PageWrapper>
    );
}

export default ProposalImplementationsDetails;
