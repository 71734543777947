/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonAdd, Image } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    TextField,
    Divider,
    FormControl,
    Select,
    FormHelperText,
    MenuItem,
    InputLabel,
} from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { debounce } from "lodash";

import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import SelectSearch from "../../components/SelectSearch";
import ButtonFile from "../../components/ButtonFile";
import { maskCpfCnpj } from "../../helpers/cpfOrCnpjValidator";
import { phoneMask } from "../../helpers/phone";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { useResizeLogo } from "../../helpers/useResizeImage";
import { MaskOnlyLetters } from "../../helpers/useOnlyLetters";
import { api } from "../../services/api";
import {
    Container,
    ContentInput,
    Session,
    TitlePage,
    InputColor,
    LabelInput,
    ContentButtonSubmit,
    ImgLogo,
    ContentLogo,
    ContentLogoMobile,
    SessionLogo,
} from "./styles";

import { HolderProps, FormatDefaultListProps, PlansProps } from "./types";

const schemaForms = yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    document: yup.string().required("Este campo é obrigatório"),
    project_name: yup.string().required("Este campo é obrigatório"),
    due_day: yup.number().required("Este campo é obrigatório"),
    plan_id: yup.number().required("Este campo é obrigatório"),
    holder_id: yup.number().required("Este campo é obrigatório"),
    phone: yup.string().required("Este campo é obrigatório"),
    whatsapp: yup.string().required("Este campo é obrigatório"),
    email: yup
        .string()
        .email("Email não é válido")
        .required("Este campo é obrigatório"),
    driver_app: yup.string().required("Este campo é obrigatório"),
    driver_app_main_color: yup.string().required("Este campo é obrigatório"),
    driver_app_secondary_color: yup
        .string()
        .required("Este campo é obrigatório"),
    driver_app_text_color: yup.string().required("Este campo é obrigatório"),
    client_app: yup.string().required("Este campo é obrigatório"),
    client_app_main_color: yup.string().required("Este campo é obrigatório"),
    client_app_secondary_color: yup
        .string()
        .required("Este campo é obrigatório"),
    client_app_text_color: yup.string().required("Este campo é obrigatório"),
});

type SchemaFormData = yup.InferType<typeof schemaForms>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CustomerCreatePage() {
    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const [loadingHolders, setLoadingHolders] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [listHolder, setListHolder] = useState<FormatDefaultListProps[]>([]);
    const [listPlans, setListPlans] = useState<PlansProps[]>([]);

    const [urlLogoDriver, setUrlLogoDriver] = useState<string | null>(null);
    const [urlLogoCustomer, setUrlLogoCustomer] = useState<string | null>(null);

    const [fileLogoDriver, setFileLogoDriver] = useState<File | null>(null);
    const [fileLogoCustomer, setFileLogoCustomer] = useState<File | null>(null);

    const [nameProject, setNameProject] = useState<string>("");

    const refImgLogoDriver = useRef<HTMLInputElement | null>(null);
    const refImgLogoCustomer = useRef<HTMLInputElement | null>(null);

    const pathLogoDefault = require("../../assets/images/logo704LoginPage.png");

    const navigate = useNavigate();

    const setDefaultsValue = () => {
        setValue("client_app_main_color", "#000000");
        setValue("client_app_secondary_color", "#000000");
        setValue("client_app_text_color", "#000000");
        setValue("driver_app_main_color", "#000000");
        setValue("driver_app_secondary_color", "#000000");
        setValue("driver_app_text_color", "#000000");
        setValue("plan_id", 1);
        setValue("due_day", 2);
    };

    const getListNumbers = (quantityInstallments: number) => {
        let listQuantitiesInstallments: number[] = [];

        for (let i = 2; i < quantityInstallments + 1; i++) {
            listQuantitiesInstallments.push(i);
        }

        return listQuantitiesInstallments;
    };

    const getAllHolder = async (nameHolder: string) => {
        try {
            setLoadingHolders(true);
            const responseAllHolder = await api.get(
                `holders/all?full_name=${nameHolder}`
            );

            if (responseAllHolder.status) {
                const listHoldersFormated =
                    responseAllHolder.data.response.data.map(
                        (value: HolderProps) => {
                            return {
                                value: value.id,
                                label: value.full_name,
                            };
                        }
                    );

                setListHolder(listHoldersFormated);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro pegar titulares");
        } finally {
            setLoadingHolders(false);
        }
    };

    const getAllPlans = async () => {
        try {
            const responseAllPlans = await api.get("plans/all");

            if (responseAllPlans.status) {
                setListPlans(responseAllPlans.data.response);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao pegar planos");
        }
    };

    const handleSelectImg = async (
        data: ChangeEvent<HTMLInputElement>,
        type: "client" | "driver"
    ) => {
        try {
            if (data.target.files) {
                const files = data.target.files[0];

                if (!files.type.includes("image/")) {
                    defaultToast("warning", "É apenas permitido imagem");
                    return;
                }

                const responseResizeLink = await useResizeLogo(
                    files,
                    250,
                    250,
                    "PNG",
                    "base64"
                );

                const responseResizeFile = await useResizeLogo(
                    files,
                    512,
                    512,
                    "PNG",
                    "blob"
                );

                if (type === "client") {
                    setFileLogoCustomer(responseResizeFile as File);
                    setUrlLogoCustomer(responseResizeLink as string);
                } else {
                    setFileLogoDriver(responseResizeFile as File);
                    setUrlLogoDriver(responseResizeLink as string);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleGetHolders = debounce((nameHolder: string) => {
        getAllHolder(nameHolder);
    }, 500);

    const handleAddCustomer = async (data: SchemaFormData) => {
        if (!fileLogoCustomer || !fileLogoDriver) {
            defaultToast("warning", "Escolha as logos!");
            return;
        }

        try {
            setLoading(true);

            const listKeysObject = Object.keys(data);
            const listValueObject = Object.values(data);

            const formData = new FormData();

            for (let i = 0; i < listValueObject.length; i++) {
                formData.append(listKeysObject[i], listValueObject[i] as any);
            }

            formData.append("client_app_logo", fileLogoCustomer as File);
            formData.append("driver_app_logo", fileLogoDriver as File);

            const responseAddCustomer = await api.post("customers", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (responseAddCustomer.status) {
                defaultToast("success", "Cliente criado com sucesso!");
                setTimeout(() => {
                    navigate("/comercial/customers");
                }, 2000);
            }
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPlans();
        setDefaultsValue();
    }, []);

    useEffect(() => {
        setValue("client_app_main_color", "#000000");
        setValue("client_app_secondary_color", "#000000");
        setValue("client_app_text_color", "#000000");
        setValue("driver_app_main_color", "#000000");
        setValue("driver_app_secondary_color", "#000000");
        setValue("driver_app_text_color", "#000000");
    }, []);

    useEffect(() => {
        setValue("driver_app", `${nameProject} - Motorista`);
        setValue("client_app", `${nameProject} - Passageiro`);
    }, [nameProject]);

    return (
        <PageWrapper>
            <PageDefault
                heightAuto
                headerPage
                header={{
                    title: "Adicionar Cliente",
                    subtitle: "",
                    icon: <PersonAdd sx={{ color: "#fff" }} />,
                }}
            >
                <Container onSubmit={handleSubmit(handleAddCustomer)}>
                    <Session>
                        <TitlePage>Dados Gerais</TitlePage>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="name"
                                render={({
                                    field: { onChange, name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label="Nome"
                                        variant="outlined"
                                        size="small"
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="phone"
                                render={({
                                    field: { name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="phone"
                                        label="Telefone"
                                        variant="outlined"
                                        size="small"
                                        name={name}
                                        value={value}
                                        onChange={(event) => {
                                            setValue(
                                                "phone",
                                                phoneMask(event.target.value)
                                            );
                                        }}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            maxLength: 14,
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="whatsapp"
                                render={({
                                    field: { name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="whatsapp"
                                        label="Whatsapp"
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            setValue(
                                                "whatsapp",
                                                phoneMask(event.target.value)
                                            );
                                        }}
                                        value={value}
                                        name={name}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            maxLength: 14,
                                        }}
                                    />
                                )}
                            />
                        </ContentInput>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="holder_id"
                                render={({
                                    field: { value },
                                    fieldState: { error },
                                }) => (
                                    <SelectSearch
                                        fullWidth
                                        width="400"
                                        label="Titular"
                                        options={listHolder}
                                        placeholder="Tilular"
                                        setValue={(value) => {
                                            setValue("holder_id", value?.value);
                                        }}
                                        loading={loadingHolders}
                                        onChangeTextField={handleGetHolders}
                                        value={value}
                                        error={Boolean(error)}
                                        textError={error && error.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="plan_id"
                                render={({
                                    field: { name, value = 1, onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        error={Boolean(error)}
                                    >
                                        <InputLabel id="plan-select-label">
                                            Plano
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            defaultValue={1}
                                            value={value}
                                            onChange={onChange}
                                            labelId="plan-select-label"
                                            id="plan-select"
                                            label="Plano"
                                        >
                                            {listPlans.map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value.id}
                                                        value={value.id}
                                                    >
                                                        {value.description}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>

                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="due_day"
                                render={({
                                    field: { name, value = 2, onChange },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        error={Boolean(error)}
                                    >
                                        <InputLabel id="due-day-label">
                                            Dia de vencimento
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            labelId="due-day-label"
                                            id="due-day"
                                            label="Dia de vencimento"
                                            value={value}
                                            onChange={onChange}
                                            MenuProps={MenuProps}
                                        >
                                            {getListNumbers(28).map((value) => {
                                                return (
                                                    <MenuItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </ContentInput>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="project_name"
                                render={({
                                    field: { name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label="Nome do projeto"
                                        variant="outlined"
                                        size="small"
                                        name={name}
                                        value={value}
                                        onChange={(event) => {
                                            setNameProject(event.target.value);
                                            setValue(
                                                "project_name",
                                                MaskOnlyLetters(
                                                    event.target.value
                                                )
                                            );
                                        }}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            maxLength: 30,
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="email"
                                render={({
                                    field: { onChange, name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        onChange={onChange}
                                        name={name}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="document"
                                render={({
                                    field: { name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="document"
                                        label="CPF/CNPJ"
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            setValue(
                                                "document",
                                                maskCpfCnpj(event.target.value)
                                            );
                                        }}
                                        name={name}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                        inputProps={{
                                            maxLength: 18,
                                        }}
                                    />
                                )}
                            />
                        </ContentInput>
                    </Session>

                    <Divider />

                    <Session>
                        <TitlePage>Aplicativo Motorista</TitlePage>
                        <ContentInput>
                            <Controller
                                control={control}
                                name="driver_app"
                                render={({
                                    field: { onChange, name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="driver_app"
                                        label="Nome do aplicativo"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Nome do aplicativo - Motorista"
                                        onChange={onChange}
                                        name={name}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>

                        <ContentInput
                            style={{
                                flexDirection: "row",
                            }}
                        >
                            <Controller
                                control={control}
                                name="driver_app_main_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor primária</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />

                            <Controller
                                control={control}
                                name="driver_app_secondary_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor secundária</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />

                            <Controller
                                control={control}
                                name="driver_app_text_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor texto</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />
                        </ContentInput>

                        <ContentLogoMobile>
                            <ContentInput>
                                <ButtonFile
                                    icon={<Image />}
                                    title="Selecione a Logo"
                                    refInput={refImgLogoDriver}
                                    onChangeInput={(event) =>
                                        handleSelectImg(event, "driver")
                                    }
                                />
                            </ContentInput>

                            <ContentInput>
                                <ImgLogo
                                    src={
                                        urlLogoDriver
                                            ? urlLogoDriver
                                            : pathLogoDefault
                                    }
                                />
                            </ContentInput>
                        </ContentLogoMobile>
                    </Session>

                    <Divider />

                    <Session>
                        <TitlePage>Aplicativo Passageiro</TitlePage>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="client_app"
                                render={({
                                    field: { onChange, name, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="client_app"
                                        label="Nome do aplicativo"
                                        placeholder="Nome do aplicativo - Passageiro"
                                        variant="outlined"
                                        size="small"
                                        onChange={onChange}
                                        name={name}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>

                        <ContentInput
                            style={{
                                flexDirection: "row",
                            }}
                        >
                            <Controller
                                control={control}
                                name="client_app_main_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor primária</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />

                            <Controller
                                control={control}
                                name="client_app_secondary_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor secundária</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />

                            <Controller
                                control={control}
                                name="client_app_text_color"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: 10,
                                        }}
                                    >
                                        <LabelInput>Cor texto</LabelInput>
                                        <InputColor
                                            type="color"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    </div>
                                )}
                            />
                        </ContentInput>

                        <ContentLogoMobile>
                            <ContentInput>
                                <ButtonFile
                                    icon={<Image />}
                                    title="Selecione a Logo"
                                    refInput={refImgLogoCustomer}
                                    onChangeInput={(event) =>
                                        handleSelectImg(event, "client")
                                    }
                                />
                            </ContentInput>

                            <ContentInput>
                                <ImgLogo
                                    src={
                                        urlLogoCustomer
                                            ? urlLogoCustomer
                                            : pathLogoDefault
                                    }
                                />
                            </ContentInput>
                        </ContentLogoMobile>
                    </Session>

                    <Divider />

                    <SessionLogo>
                        <TitlePage>Logo</TitlePage>

                        <ContentLogo>
                            <div>
                                <ContentInput>
                                    <ButtonFile
                                        icon={<Image />}
                                        title="Selecione a Logo Motorista"
                                        refInput={refImgLogoDriver}
                                        onChangeInput={(event) =>
                                            handleSelectImg(event, "driver")
                                        }
                                    />
                                </ContentInput>

                                <ContentInput>
                                    <ImgLogo
                                        src={
                                            urlLogoDriver
                                                ? urlLogoDriver
                                                : pathLogoDefault
                                        }
                                    />
                                </ContentInput>
                            </div>
                            <div>
                                <ContentInput>
                                    <ButtonFile
                                        icon={<Image />}
                                        title="Selecione a Logo Cliente"
                                        refInput={refImgLogoCustomer}
                                        onChangeInput={(event) =>
                                            handleSelectImg(event, "client")
                                        }
                                    />
                                </ContentInput>

                                <ContentInput>
                                    <ImgLogo
                                        src={
                                            urlLogoCustomer
                                                ? urlLogoCustomer
                                                : pathLogoDefault
                                        }
                                    />
                                </ContentInput>
                            </div>
                        </ContentLogo>
                    </SessionLogo>

                    <ContentButtonSubmit>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            loading={loading}
                            type="submit"
                        >
                            Adicionar
                        </LoadingButton>
                    </ContentButtonSubmit>
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
