import styled from "styled-components";

export const ContentTable = styled.div`
    height: 580px;
    .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
        display: none;
    }
`;

export const ContentPagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;
