/* eslint-disable */
import {
    AccountBalanceWallet,
    OutboundRounded,
    SaveAlt,
    Search,
    Send,
    Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    AlertColor,
    Button,
    Divider,
    Pagination,
    Menu,
    Checkbox,
    FormControlLabel,
    Typography,
    TextField,
    Switch,
    Tooltip,
    IconButton,
} from "@mui/material";
import { LineChart, PieValueType, pieArcLabelClasses } from "@mui/x-charts";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { GridColumns } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import ButtonGeneric from "../../components/ButtonGeneric";
import { HeaderPage } from "../../components/Header/HeaderPage";
import PageWrapper from "../../components/PageWrapper";
import { SelectComponent } from "../../components/Select/Select";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import ModalResendInvoicesBulk from "../../components/ModalResendInvoicesBulk";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { api } from "../../services/api";
import BillingDetailsModal from "../CustomerDetailsPage/BillingDetailsModal";
import {
    BillingContainer,
    BillingTable,
    ChartContainer,
    FinancialCard,
    FinancialContainer,
    HeaderFilter,
    Info,
    Page,
    PieContainer,
    ContentButtonSearch,
    ContentPagination,
    ContentSelectStatus,
    ContentPie,
    Pie,
    ExportDiv,
    ContentResendInvoices,
    ItemInput,
    ContentGraph,
    ContentStatus,
} from "./styles";

import { MonthlyBilling, mbPeriod, mbStatus, FinancialProps } from "./types";
import { TransformDate } from "../../helpers/transformDate";
import { statusChip } from "./config";

let idsInvoices: number[] = [];

export function MonthlyBillingReportPage() {
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [loadingResendInvoice, setLoadingResendInvoice] =
        useState<boolean>(false);

    const [titleSendedInvoice, setTitleSendedInvoice] = useState<string>("");
    const [contentSendedInvoice, setContentSendedInvoice] =
        useState<string>("");
    const [viasSended, setViasSended] = useState<string[]>([]);
    const [anchorElVias, setAnchorElVias] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorElVias);

    const [showModalResendInvoices, setShowModalResendInvoices] =
        useState<boolean>(false);

    const [billingSelected, setBillingSelected] =
        useState<BillingsDetailsProps | null>(null);
    const [openBillingDetailsModal, setOpenBillingDetailsModal] =
        useState(false);

    const [billingData, setBillingData] = useState<MonthlyBilling[]>([]);
    const [pieData, setPieData] = useState<PieValueType[]>([
        {
            id: 1,
            value: 1,
            label: "Loading...",
            color: "#bbbbbb",
        },
    ]);
    const [valuesData, setValuesData] = useState<any[]>([
        {
            id: 0,
            label: "carregando...",
            value: 400,
            color: "#bbbbbb",
            bgColor: "#bbbbbb",
        },
    ]);
    const [startDate, setStartDate] = useState<string | null>(
        new Date().toISOString().substr(0, 10)
    );
    const [endDate, setEndDate] = useState<string | null>(
        new Date().toISOString().substr(0, 10)
    );
    const [status, setStatus] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [unfilledInvoices, setUnfilledInvoices] = useState<boolean>(false);

    const [totalPage, setTotalPages] = useState<number | null>(null);

    const navigate = useNavigate();

    const { permissions, hasPermissions } = useAuth();

    const datesInitials = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
    }-01`;

    const handleOpenBillingDetailsModal = (detailsBilling: BillingsDetailsProps) => {
        setBillingSelected(detailsBilling);
        setOpenBillingDetailsModal(true);
    };

    const handleCloseBillingDetailsModal = () => {
        setOpenBillingDetailsModal(false);
    };

    const handleChange = debounce(
        (value: string, type: "title" | "content") => {
            if (type === "title") {
                setTitleSendedInvoice(value);
            }

            if (type === "content") {
                setContentSendedInvoice(value);
            }
        },
        500
    );

    // const handleResendInBulkInvoices = async () => {
    //     if (
    //         titleSendedInvoice.length <= 0 ||
    //         contentSendedInvoice.length <= 0
    //     ) {
    //         defaultToast("warning", "Preencha os campos!");
    //         return;
    //     }

    //     try {
    //         setLoadingResendInvoice(true);
    //         const responseResendInBulkInvoices = await api.post(
    //             "monthly-billing/send",
    //             {
    //                 vias: viasSended,
    //                 ids: idsInvoices,
    //                 title: titleSendedInvoice,
    //                 content: contentSendedInvoice,
    //             }
    //         );

    //         if (responseResendInBulkInvoices.status) {
    //             defaultToast("success", "Enviado com sucesso!");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         defaultToast("error", "Erro ao reenviar faturas!");
    //     } finally {
    //         setLoadingResendInvoice(false);
    //     }
    // };

    const handleSelectVias = (types: "email" | "sms" | "whatsapp") => {
        if (types === "email") {
            const isExistsItem = viasSended.includes("email");
            if (!isExistsItem) {
                setViasSended((oldValue) => [...oldValue, "email"]);
            } else {
                setViasSended((oldValue) =>
                    oldValue.filter((value) => value !== "email")
                );
            }
        } else if (types === "sms") {
            const isExistsItem = viasSended.includes("sms");
            if (!isExistsItem) {
                setViasSended((oldValue) => [...oldValue, "sms"]);
            } else {
                setViasSended((oldValue) =>
                    oldValue.filter((value) => value !== "sms")
                );
            }
        } else if (types === "whatsapp") {
            const isExistsItem = viasSended.includes("whatsapp");
            if (!isExistsItem) {
                setViasSended((oldValue) => [...oldValue, "whatsapp"]);
            } else {
                setViasSended((oldValue) =>
                    oldValue.filter((value) => value !== "whatsapp")
                );
            }
        }
    };

    useEffect(() => {
        if (
            hasPermissions("relatorio.mensalidade.visualizar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const handleChangeDate = (value: any, type: "startDate" | "endDate") => {
        const date = new Date(value).toLocaleDateString().split("/");

        const dateFormated = `${date[2]}-${date[1]}-01`;

        if (type === "endDate") {
            setEndDate(dateFormated);
        } else {
            setStartDate(dateFormated);
        }
    };

    const handleClick = () => {
        // TESTANDO
    };

    const handleSearchReportsMonths = async (
        dateStart: any = datesInitials,
        dateFinal: any = datesInitials,
        page = 1,
        onlyPagination = false
    ) => {
        if (!dateStart && !dateFinal) {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Preencha os campos de datas");

            return;
        }

        setLoading(true);

        if (onlyPagination) {
            try {
                const sDate = `${String(dateStart).substring(0, 7)}-01`;
                const eDate = `${String(dateFinal).substring(0, 7)}-01`;

                const responseMonthlyBilling = await api.get(
                    `/v2/monthlybilling/list/${status}?page=${page}&start_date=${`${sDate}&end_date=${eDate}${
                        unfilledInvoices ? "&only_activate=true" : ""
                    }`}`
                );

                if (responseMonthlyBilling.status) {
                    setBillingData(
                        responseMonthlyBilling.data.response.data.data
                    );
                    setTotalPages(
                        responseMonthlyBilling.data.response.data.last_page
                    );
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("Erro ao buscar dados");
            } finally {
                setLoading(false);
            }
        } else {
            setPieData([
                {
                    id: 0,
                    value: 1,
                    label: "carregando...",
                    color: "#e1e1e1",
                },
            ]);
            setValuesData([
                {
                    id: 1,
                    label: "carregando...",
                    value: 0,
                    color: "#202020",
                    bgColor: "#e1e1e1",
                },
            ]);

            const sDate = `${String(dateStart).substring(0, 7)}-01`;
            const eDate = `${String(dateFinal).substring(0, 7)}-01`;
            const responseDashboard = api.get(
                `/v2/monthlybilling/dashboard?start_date=${`${sDate}&end_date=${eDate}${
                    unfilledInvoices ? "&only_activate=true" : ""
                }`}`
            );

            const responseMonthlyBilling = api.get(
                `/v2/monthlybilling/list/${status}?page=${page}&start_date=${`${sDate}&end_date=${eDate}${
                    unfilledInvoices ? "&only_activate=true" : ""
                }`}`
            );

            Promise.all([responseDashboard, responseMonthlyBilling])
                .then((response) => {
                    if (response[0].status) {
                        const { status } = response[0].data.response;
                        const { amount } = response[0].data.response;
                        setPieData([
                            {
                                id: 0,
                                value: status.is_opening,
                                label: "Abertas",
                                color: "#bbbbbb",
                            },
                            {
                                id: 1,
                                value: status.is_paid,
                                label: "Pagas",
                                color: "var(--secondary)",
                            },
                            {
                                id: 2,
                                value: status.is_closing,
                                label: "Fechadas",
                                color: "var(--primary)",
                            },
                            {
                                id: 3,
                                value: status.is_delayed,
                                label: "Atrasadas",
                                color: "#de5d2a",
                            },
                        ]);
                        const newValueData = [
                            {
                                id: 1,
                                label: "Em Aberto",
                                value: amount.open,
                                color: "#202020",
                                bgColor: "#e7e7e7",
                                total: status.is_opening,
                            },
                            {
                                id: 2,
                                label: "Fechadas",
                                value: amount.closing,
                                color: "var(--primary)",
                                bgColor: "#d5e1ee",
                                total: status.is_closing,
                            },
                            {
                                id: 3,
                                label: "Atrasadas",
                                value: amount.delayed,
                                color: "#de551f",
                                bgColor: "#f4e2db",
                                total: status.is_delayed,
                            },
                            {
                                id: 4,
                                label: "Recebidos",
                                value: amount.paid,
                                color: "var(--secondary)",
                                bgColor: "#dff1df",
                                total: status.is_paid,
                            },
                        ];
                        newValueData.push({
                            id: 5,
                            label: "Total",
                            value: amount.total,
                            color: "#fff",
                            bgColor: "var(--primary)",
                            total: newValueData.reduce(
                                (acc, curr) => Number(acc) + Number(curr.total),
                                0
                            ),
                        });
                        setValuesData(newValueData);
                    }

                    if (response[1].status) {
                        idsInvoices = response[1].data.response.ids;
                        setBillingData(response[1].data.response.data.data);
                        setTotalPages(response[1].data.response.data.last_page);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast("Erro ao buscar dados");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        handleSearchReportsMonths(startDate, endDate);
    }, [status]);

    function exportData() {
        const data = [
            {
                sheet: "Adults",
                columns: [
                    { label: "data", value: "date" },
                    { label: "status", value: "status" },
                    { label: "total", value: "total" },
                    {
                        label: "quantidade",
                        value: (row: any) =>
                            `R$ ${formatCurrencyToShow(row.amount)}`,
                    },
                ],
                content: [
                    {
                        date: `${startDate?.substring(
                            0,
                            7
                        )}/${endDate?.substring(0, 7)}`,
                        status: "abertas",
                        total: valuesData.find((item) => item.id === 1).total,
                        amount: valuesData.find((item) => item.id === 1).value,
                        more: {},
                    },
                    {
                        date: `${startDate?.substring(
                            0,
                            7
                        )}/${endDate?.substring(0, 7)}`,
                        status: "fechadas",
                        total: valuesData.find((item) => item.id === 2).total,
                        amount: valuesData.find((item) => item.id === 2).value,
                        more: {},
                    },
                    {
                        date: `${startDate?.substring(
                            0,
                            7
                        )}/${endDate?.substring(0, 7)}`,
                        status: "atrasadas",
                        total: valuesData.find((item) => item.id === 3).total,
                        amount: valuesData.find((item) => item.id === 3).value,
                        more: {},
                    },
                    {
                        date: `${startDate?.substring(
                            0,
                            7
                        )}/${endDate?.substring(0, 7)}`,
                        status: "pagas",
                        total: valuesData.find((item) => item.id === 4).total,
                        amount: valuesData.find((item) => item.id === 4).value,
                        more: {},
                    },
                    {
                        date: `${startDate?.substring(
                            0,
                            7
                        )}/${endDate?.substring(0, 7)}`,
                        status: "total",
                        total: valuesData.find((item) => item.id === 5).total,
                        amount: valuesData.find((item) => item.id === 5).value,
                        more: {},
                    },
                ],
            },
        ];

        const settings = {
            fileName: "report-monthly-billing",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
        };
        const xls = xlsx(data, settings);
        const blob = new Blob([xls ?? new Blob()], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        // const a = document.createElement("a");
        // a.href = url;
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
    }

    const [monthlyBillingDataLine, setMonthlyBillingDataLine] = useState([]);
    type MonthlyBillingPercent = {
        percent_open: number[];
        percent_closing: number[];
        percent_paid: number[];
        percent_delayed: number[];
        percent_no_peding: number[];
    };
    const [monthlyBillingPercent, setMonthlyBillingPercent] =
        useState<MonthlyBillingPercent>();
    const [period, setPeriod] = useState<number>(3);
    const [arrayMock, setArrayMock] = useState<number[]>(
        Array.from({ length: period }, (_, index) => index)
    );
    const [monthlyBillingDataLineX, setMonthlyBillingDataLineX] =
        useState<number[]>(arrayMock);

    useEffect(() => {
        api.get("/v2/monthlybilling/period/" + period)
            .then((response) => {
                const xData = response.data.response.map(
                    (item: any) => item.competition_date
                );

                const percent_open: number[] = [];
                const percent_closing: number[] = [];
                const percent_paid: number[] = [];
                const percent_delayed: number[] = [];
                const percent_no_peding: number[] = [];

                response.data.response.forEach((item: any) => {
                    percent_open.push(parseInt(item.percent_opened));
                    percent_closing.push(parseInt(item.percent_closing));
                    percent_paid.push(parseInt(item.percent_paid));
                    percent_delayed.push(parseInt(item.percent_delayed));
                    percent_no_peding.push(parseInt(item.percent_no_pending));
                });

                const result = {
                    percent_open: percent_open.reverse(),
                    percent_closing: percent_closing.reverse(),
                    percent_paid: percent_paid.reverse(),
                    percent_delayed: percent_delayed.reverse(),
                    percent_no_peding: percent_no_peding.reverse(),
                };

                setMonthlyBillingPercent(result);
                setMonthlyBillingDataLineX(xData.reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }, [period]);

    useEffect(() => {
        handleSearchReportsMonths(startDate, endDate);
    }, [unfilledInvoices]);

    const canSendedInvoices =
        titleSendedInvoice.length > 0 && contentSendedInvoice.length > 0;

    const columns: GridColumns = [
        {
            field: "customer_name",
            headerName: "Cliente",
            width: 150,
            flex: 1.5,
            valueGetter: (params) =>
                `${params.row.customer_name ?? "(formulário não preenchido)"}`,
        },
        {
            field: "holder_name",
            headerName: "Titular",
            width: 150,
            flex: 2,
            valueGetter: (params) => `${params.row.holder_name ?? "-"}`,
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            flex: 1,
            valueGetter: (params) => `${params.row.status_id}`,
            renderCell: (params) => (
                <div>
                    <span>{statusChip(params.row.status_id)}</span>
                </div>
            ),
        },
        
        {
            field: "date_reference",
            headerName: "Data de Referencia",
            width: 50,
            flex: 1,
            align: "center",
            valueGetter: (params) =>
                `${String(params.row.date_reference).split("-")[1]}/${
                    String(params.row.date_reference).split("-")[0]
                }`,
        },
        {
            field: "due_date",
            headerName: "Data de Vencimento",
            width: 50,
            flex: 1,
            align: "center",
            valueGetter: (params) => `${TransformDate(params.row.due_date)}`,
        },
        {
            field: "value",
            headerName: "Valor",
            width: 150,
            flex: 1,
            valueGetter: (params) => `${params.row.status_id}`,
            renderCell: (params) => (
                <div>
                    <span>R$ {formatCurrencyToShow(params.row.total_amount) }</span>
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Ações",
            width: 150,
            flex: 1,
            headerAlign: "right",
            align: "right",
            disableExport: true,
            renderCell: (params) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                    }}
                >
                    <Tooltip
                        title="Visualizar fatura"
                        onClick={() =>
                            handleOpenBillingDetailsModal(params.row)
                        }
                    >
                        <IconButton>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {/* eslint-disable-next-line */}
                    <a
                        href={
                            params.row.customer_id
                                ? `/comercial/customers/customerDetail?id=${params.row.customer_id}`
                                : `/comercial/proposal/proposalDetails?id=${params.row.proposal_id}`
                        }
                        style={{
                            marginTop: 5,
                            textDecoration: "none",
                            color: params.row.customer_id
                                ? "var(--secondary)"
                                : "var(--primary)",
                        }}
                    >
                        <Tooltip
                            title={
                                params.row.customer_id
                                    ? "ir para cliente"
                                    : "ir para proposta"
                            }
                        >
                            <OutboundRounded />
                        </Tooltip>
                    </a>
                </div>
            ),
        },
    ];

    return (
        <PageWrapper>
            <BillingContainer>
                <Page>
                    <div style={{ padding: "20px 40px 0px 40px" }}>
                        <HeaderPage
                            title="Relatórios de Faturas"
                            subtitle="Relatórios geral de faturas mensais"
                            icon={
                                <AccountBalanceWallet sx={{ color: "#fff" }} />
                            }
                        />
                    </div>
                    <HeaderFilter>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Inicial"
                                format="MM/YYYY"
                                slotProps={{ textField: { size: "small" } }}
                                onChange={(value) =>
                                    handleChangeDate(value, "startDate")
                                }
                                defaultValue={dayjs(
                                    `${new Date().getFullYear()}-${
                                        new Date().getMonth() + 1
                                    }-01`
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Final"
                                format="MM/YYYY"
                                slotProps={{ textField: { size: "small" } }}
                                onChange={(value) =>
                                    handleChangeDate(value, "endDate")
                                }
                                defaultValue={dayjs(
                                    `${new Date().getFullYear()}-${
                                        new Date().getMonth() + 1
                                    }-01`
                                )}
                            />
                        </LocalizationProvider>
                        <ContentButtonSearch>
                            <ButtonGeneric
                                onclick={() =>
                                    handleSearchReportsMonths(
                                        startDate,
                                        endDate
                                    )
                                }
                                buttonColor="var(--primary)"
                                icon={<Search />}
                                text="Pesquisar"
                            />
                        </ContentButtonSearch>
                    </HeaderFilter>
                    <Info>Busque pela data de referencia das faturas</Info>

                    <ChartContainer>
                        <FinancialContainer className="flexing">
                            <h3>
                                Projeção de valores das mensalidades por período
                                selecionado
                            </h3>
                            <ExportDiv>
                                <Button
                                    color="success"
                                    variant="contained"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    size="small"
                                    onClick={() => exportData()}
                                >
                                    <SaveAlt
                                        fontSize="small"
                                        sx={{
                                            marginRight: "5px",
                                        }}
                                    />
                                    <span>Exportar</span>
                                </Button>
                            </ExportDiv>
                            {valuesData.length > 1 &&
                                valuesData.map((item) => {
                                    return (
                                        <FinancialCard
                                            style={{
                                                color: item.color,
                                                backgroundColor:
                                                    item.bgColor ?? "#fff",
                                            }}
                                        >
                                            <span>
                                                R$
                                                {formatCurrencyToShow(
                                                    item.value
                                                )}
                                            </span>
                                            <div>
                                                <span>{item.label}</span>
                                                <span> - {item.total}</span>
                                            </div>
                                        </FinancialCard>
                                    );
                                })}
                            <span style={{ fontSize: "10pt" }}>
                                * estas projeções de valores podem váriar
                                confome o tempo.
                            </span>
                        </FinancialContainer>
                        <PieContainer className="flexing">
                            <h3>Gráfico de status das faturas</h3>
                            <Divider />
                            <ContentPie>
                                <Pie
                                    series={[
                                        {
                                            data: pieData,
                                            innerRadius: 180,
                                            outerRadius: 20,
                                            paddingAngle: 1,
                                            cornerRadius: 5,
                                            arcLabel: (item) =>
                                                `${item.label} (${item.value})`,
                                            arcLabelMinAngle: 45,
                                        },
                                    ]}
                                    width={500}
                                    height={400}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                            fill: "white",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                        },
                                        padding: "30px",
                                    }}
                                />
                            </ContentPie>
                        </PieContainer>
                    </ChartContainer>
                    {/* <ContentGraph>
                        <h3 style={{ margin: 0 }}>
                            Percentual % de faturas por período
                        </h3>
                        <SelectComponent
                            label="Período"
                            size={13}
                            items={mbPeriod}
                            value={period}
                            name="period"
                            onChange={(e) => {
                                setPeriod(e.target.value);
                            }}
                        />
                        <LineChart
                            xAxis={[
                                {
                                    data: monthlyBillingDataLineX ?? arrayMock,
                                    scaleType: "point",
                                    label: "Período em meses",
                                },
                            ]}
                            yAxis={[
                                {
                                    max: 100,
                                    min: 0,
                                    label: "Percentual %",
                                    valueFormatter: (item) => item + "%",
                                },
                            ]}
                            series={[
                                {
                                    data:
                                        monthlyBillingPercent?.percent_open ??
                                        arrayMock,
                                    color: "gray",
                                    label: "Aberto",
                                    valueFormatter: (item) => item + "%",
                                },
                                {
                                    data:
                                        monthlyBillingPercent?.percent_closing ??
                                        arrayMock,
                                    color: "#172b4d",
                                    label: "Fechado",
                                    valueFormatter: (item) => item + "%",
                                },
                                {
                                    data:
                                        monthlyBillingPercent?.percent_paid ??
                                        arrayMock,
                                    color: "green",
                                    label: "Pago",
                                    valueFormatter: (item) => item + "%",
                                },
                                {
                                    data:
                                        monthlyBillingPercent?.percent_delayed ??
                                        arrayMock,
                                    color: "#de551f",
                                    label: "Atrasado",
                                    valueFormatter: (item) => item + "%",
                                },
                                {
                                    data:
                                        monthlyBillingPercent?.percent_no_peding ??
                                        arrayMock,
                                    color: "lightgreen",
                                    label: "Sem pendencia",
                                    valueFormatter: (item) => item + "%",
                                },
                            ]}
                            margin={{ top: 50, bottom: 50 }}
                        />
                    </ContentGraph> */}
                    <ContentSelectStatus>
                        <div>
                            <ContentStatus>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        margin: "20px 0px",
                                    }}
                                >
                                    <SelectComponent
                                        label="Status"
                                        size={11}
                                        items={mbStatus}
                                        value={status}
                                        name="peristatusod"
                                        onChange={(e) => {
                                            setStatus(e.target.value);
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={unfilledInvoices}
                                                onChange={(event) =>
                                                    setUnfilledInvoices(
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Apenas com instalações parceladas"
                                    />
                                </div>
                                <span style={{ fontSize: '9pt'}}>
                                    Selecione o intervalo da data de competência
                                    no topo da página.
                                </span>
                            </ContentStatus>
                        </div>
                        {status === 3 || status === 2 ? (
                            <ContentResendInvoices>
                                <Button
                                    variant="contained"
                                    onClick={(
                                        event: MouseEvent<HTMLButtonElement>
                                    ) => {
                                        setAnchorElVias(event.currentTarget);
                                    }}
                                >
                                    Reenviar faturas em massa &nbsp;
                                    <Send />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElVias}
                                    open={open}
                                    onClose={() => setAnchorElVias(null)}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: "400px",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        align="center"
                                    >
                                        Modos de envio
                                    </Typography>
                                    <Divider />
                                    <div
                                        style={{
                                            marginLeft: "1rem",
                                        }}
                                        onChange={() =>
                                            handleSelectVias("email")
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="email"
                                                    checked={viasSended.includes(
                                                        "email"
                                                    )}
                                                />
                                            }
                                            label="Email"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "1rem",
                                        }}
                                        onChange={() => handleSelectVias("sms")}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="sms"
                                                    checked={viasSended.includes(
                                                        "sms"
                                                    )}
                                                />
                                            }
                                            label="Sms"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "1rem",
                                        }}
                                        onChange={() =>
                                            handleSelectVias("whatsapp")
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="whatsapp"
                                                    checked={viasSended.includes(
                                                        "whatsapp"
                                                    )}
                                                />
                                            }
                                            label="Whatsapp"
                                        />
                                    </div>
                                    <Divider />
                                    <ItemInput>
                                        <TextField
                                            label="Título"
                                            size="small"
                                            variant="outlined"
                                            onChange={(event) =>
                                                handleChange(
                                                    event.target.value,
                                                    "title"
                                                )
                                            }
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                    </ItemInput>
                                    <ItemInput>
                                        <TextField
                                            label="Message"
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            rows={2}
                                            placeholder="Digite uma mensagem curta"
                                            onChange={(event) =>
                                                handleChange(
                                                    event.target.value,
                                                    "content"
                                                )
                                            }
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                    </ItemInput>
                                    <Divider />
                                    <ItemInput>
                                        <LoadingButton
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                setShowModalResendInvoices(true)
                                            }
                                            loading={loadingResendInvoice}
                                            disabled={!canSendedInvoices}
                                        >
                                            Enviar
                                        </LoadingButton>
                                    </ItemInput>
                                </Menu>
                            </ContentResendInvoices>
                        ) : ""}
                    </ContentSelectStatus>

                    <BillingTable>
                        <TableDataGrid
                            columns={columns}
                            rows={billingData}
                            loading={loading}
                            hideFooterPagination
                        />
                    </BillingTable>
                    {totalPage && (
                        <ContentPagination>
                            <Pagination
                                count={totalPage}
                                color="primary"
                                onChange={(event: any) =>
                                    handleSearchReportsMonths(
                                        startDate,
                                        endDate,
                                        event.target.textContent,
                                        true
                                    )
                                }
                                hideNextButton
                                hidePrevButton
                            />
                        </ContentPagination>
                    )}
                </Page>

                <BillingDetailsModal
                    openBillingDetailsModal={openBillingDetailsModal}
                    handleCloseBillingDetailsModal={
                        handleCloseBillingDetailsModal
                    }
                    currentBillingDetails={billingSelected}
                    handleClick={handleClick}
                    setOpenBillingDetailsModal={setOpenBillingDetailsModal}
                />

                <ModalResendInvoicesBulk
                    setAnchorElVias={setAnchorElVias}
                    title={titleSendedInvoice}
                    setTitle={setTitleSendedInvoice}
                    content={contentSendedInvoice}
                    setContent={setContentSendedInvoice}
                    idsMonthlyBilling={idsInvoices}
                    vias={viasSended}
                    setVisible={setShowModalResendInvoices}
                    visible={showModalResendInvoices}
                />

                <Toast
                    open={openToast}
                    onClose={() => setOpenToast(false)}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </BillingContainer>
        </PageWrapper>
    );
}