import styled from "styled-components";

export const HelperGenericModalStyle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1000px;
    max-height: 90%;
    background-color: white;
    box-shadow: 24;
    padding: 25px 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    text-align: center;
    min-width: 270px;
    overflow: auto;

    .topModalContainer {
        width: 90%;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .topModalContainer button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: 0.3s;
        font-family: sans-serif;
        padding: 2px 3px;
        width: auto;
        height: auto;
        position: absolute;
        right: 0;
        &:hover {
            background-color: #8888889e;
        }
    }

    .mainContainer {
        display: flex;
    }

    .imageContainer {
        width: 45%;
    }

    .imageContent {
        width: 100%;
        border-radius: 8px;
    }

    .rightSideContainer {
        width: 50%;
        padding: 0 20px;
    }

    .mainTextContainer {
        max-height: 50%;
        overflow: auto;
    }

    .mainTextContent {
        text-align: justify;
        font-weight: 400;
    }

    .imageVideo {
        width: 30%;
    }

    @media (max-width: 700px) {
        width: 70%;

        .mainContainer {
            flex-direction: column;
        }

        .imageContainer {
            width: 100%;
        }

        .rightSideContainer {
            width: 100%;
            padding: 0;
        }
    }
`;
