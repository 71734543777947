import { Box, Modal, TextField } from "@mui/material";
import { useState } from "react";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import { ModalDeleteProposalStyle } from "./styles";
import { MonthlyBillingProps } from "./types";

interface Props {
    visible: boolean;
    setVisible: (data: boolean) => void;
    monthlyBilling: MonthlyBillingProps | null;
    holder: any;
}

function ModalResendInvoice({
    visible,
    setVisible,
    monthlyBilling,
    holder,
}: Props) {
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [title, setTitle] = useState<string>("Fatura 704apps");
    const [content, setContent] = useState<string>(
        "Lembramos que sua fatura vence em breve caso tenha alguma dúvida ou precise de mais informações, estamos à disposição através do contato (85) 9257-0704. Agradecemos pela parceria!"
    );

    const handleSendInvoice = async () => {
        if (title.length <= 0 || content.length <= 0) {
            defaultToast(
                "warning",
                "Preencha os campos para o enviar a fatura"
            );
            return;
        }

        try {
            setIsLoadingButton(true);

            const responseSendInvoice = await api.post("monthly-billing/send", {
                ids: [monthlyBilling?.id],
                title,
                content,
            });

            if (responseSendInvoice.status) {
                defaultToast("success", "Fatura enviada com sucesso!");
                setTitle("Fatura 704apps");
                setContent(
                    "Lembramos que sua fatura vence em breve caso tenha alguma dúvida ou precise de mais informações, estamos à disposição através do contato (85) 9257-0704. Agradecemos pela parceria!"
                );
                setVisible(false);
            }

            if (holder?.phone) {
                window.open(
                    `https:wa.me/55${holder?.phone.replace(/[\s.()-]/g, "")}`,
                    "_blank"
                );
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao reenviar fatura!");
        } finally {
            setIsLoadingButton(false);
        }
    };

    return (
        <Modal open={visible} onClose={() => setVisible(false)}>
            <ModalDeleteProposalStyle>
                <Box className="modalContainer">
                    <p>Enviar fatura</p>
                    <div
                        style={{
                            marginBottom: "1rem",
                        }}
                    >
                        <TextField
                            size="small"
                            label="Título"
                            variant="outlined"
                            placeholder="Título do email"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            sx={{
                                width: "100%",
                            }}
                        />
                    </div>
                    <div
                        style={{
                            marginBottom: "1rem",
                        }}
                    >
                        <TextField
                            size="small"
                            label="Mensagem"
                            variant="outlined"
                            value={content}
                            onChange={(event) => setContent(event.target.value)}
                            multiline
                            rows={2}
                            placeholder="Adicione uma mensagem curta para todos os meios: email, sms, whatsapp"
                            sx={{
                                width: "100%",
                            }}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => setVisible(false)}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="Enviar"
                            onClick={handleSendInvoice}
                        />
                    </div>
                </Box>
            </ModalDeleteProposalStyle>
        </Modal>
    );
}
export default ModalResendInvoice;
