import styled from "styled-components";

interface Props {
    isDisabledCreateContractButton?: boolean;
}

export const FormProposalDetailsPage = styled.div<Props>`
    width: 100%;
    max-width: 1100px;
    padding: 10px 10%;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;
    position: relative;
    padding-bottom: 30px;

    .mainContainer {
        > h2 {
            text-align: center;
        }
    }

    .container-coupons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .label-text {
        font-size: 16px;
        font-weight: bold;
    }

    .text {
        font-size: 16px;
        font-style: normal;
    }
`;
