import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/PageWrapper";
import { useAuth } from "../../contexts/contexts";
import { HomePageStyle } from "./styles";

function HomePage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (hasPermissions("ana.home") === false && permissions.length > 0) {
            navigate("/Error");
        }
    }, [permissions]);

    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");
    return (
        <PageWrapper>
            <HomePageStyle>
                <h1>Olá, {name}</h1>
            </HomePageStyle>
        </PageWrapper>
    );
}

export default HomePage;
