import { Add, Visibility, NextWeek } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import { If } from "../../components/If";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { StatusCoupons } from "./styles";
import { CouponsProps } from "./types";

function CouponPage() {
    const navigate = useNavigate();
    const { hasPermissions } = useAuth();

    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [selectedRowProposal, setSelectedRowProposal] = useState<string>("");

    const [listCoupons, setListCoupons] = useState<CouponsProps[]>([]);

    const [openToast, setOpenToast] = useState<boolean>(false);

    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const getListCoupons = async () => {
        setLoadingTable(true);
        try {
            const responseListCoupons = await api.get("coupons");

            if (responseListCoupons.status) {
                setListCoupons(responseListCoupons.data.response);
            }
        } catch (error) {
            console.error(error);
        }
        setLoadingTable(false);
    };

    const columns: GridColDef[] = [
        {
            field: "key",
            headerName: "Código",
            flex: 12,
            valueGetter: (params: GridValueGetterParams) => `${params.value}`,
        },
        {
            field: "type_id",
            headerName: "Tipo",
            flex: 10,
            align: "center",
            valueGetter: (params: GridValueGetterParams) => `${params.value}`,
            renderCell: (params: GridRenderCellParams) => (
                <p style={{ color: "green" }}>
                    {params.row.type_id === 1 ? `Percentual %` : `Fixo R$`}
                </p>
            ),
        },
        {
            field: "value",
            headerName: "Valor",
            flex: 10,
            align: "center",
            valueGetter: (params: GridValueGetterParams) => `${params.value}`,
            renderCell: (params: GridRenderCellParams) => (
                <p style={{ color: "green" }}>
                    {params.row.type_id === 1
                        ? `${params.row.value}%`
                        : params.row.value.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                          })}
                </p>
            ),
        },
        {
            field: "max_uses",
            headerName: "Usos máximos",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.max_uses}`,
        },
        {
            field: "uses",
            headerName: "Usos",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.uses}`,
        },
        {
            field: "due_date",
            headerName: "Expira em",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${new Date(params.row.expires_at).toLocaleDateString()}`,
            renderCell: (params: GridRenderCellParams) => (
                <p>{new Date(params.row.expires_at).toLocaleDateString()}</p>
            ),
        },
        {
            field: "enabled",
            headerName: "Status",
            flex: 10,
            align: "center",
            renderCell: (params: GridRenderCellParams) => {
                const isActive = params.row.enabled ? "Ativo" : "Inativo";
                return (
                    <span>
                        <StatusCoupons color={isActive ? "#008000" : "#DC143C"}>
                            {isActive ? "Ativo" : "Inativo"}
                        </StatusCoupons>
                    </span>
                );
            },
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 15,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("cupom.detalhar")}>
                        <Visibility
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/cupons/couponsDetails?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getListCoupons();
    }, []);

    return (
        <>
            <PageWrapper>
                <PageDefault
                    headerPage
                    header={{
                        title: "Cupons",
                        subtitle: "Lista de cupons",
                        icon: <NextWeek sx={{ color: "#fff" }} />,
                    }}
                >
                    <div className="containerListTablePagesGlobal">
                        <div className="containerButtonNewRegisterListTablePagesGlobal">
                            <If condition={hasPermissions("cupom.cadastrar")}>
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate("/settings/cupons/newCoupons");
                                    }}
                                    buttonColor="var(--secondary)"
                                    text="Novo Cupom"
                                    icon={<Add />}
                                />
                            </If>
                        </div>
                        <TableDataGrid
                            style={{ minWidth: "460px !important" }}
                            rows={listCoupons}
                            columns={columns}
                            onRowClick={(rowParams: GridRowParams) => {
                                setSelectedRowProposal(rowParams.row);
                            }}
                            loading={loadingTable}
                        />
                    </div>
                </PageDefault>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => setOpenToast(false)}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default CouponPage;
