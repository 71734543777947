/* eslint-disable @typescript-eslint/no-explicit-any */
import { Close } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import ButtonGeneric from "../../components/ButtonGeneric";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { DeleteNoteModalStyled } from "./styles";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCloseDeleteNoteModal: () => void;
    openDeleteNoteModal: boolean;
    noteIndividualId: number | undefined;
    myParam: string | any;
    setNotepadData: any;
}

function DeleteNoteModal({
    handleCloseDeleteNoteModal,
    openDeleteNoteModal,
    noteIndividualId,
    myParam,
    setNotepadData,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deletePlan() {
        setIsLoadingButton(true);
        api.delete(`/customers/notepad/${myParam}/${noteIndividualId}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                api.get(`/customers/notepad/${myParam}`).then((response) => {
                    setNotepadData(response.data.response.data);
                });
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteNoteModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
                setTimeout(() => {
                    setOpenToast(false);
                }, 1000);
            });
    }

    return (
        <Modal open={openDeleteNoteModal} onClose={handleCloseDeleteNoteModal}>
            <DeleteNoteModalStyled>
                <div className="topModalContainer">
                    <h3>Você realmente deseja deletar a observação?</h3>
                    {/* eslint-disable-next-line */}
                    <button onClick={handleCloseDeleteNoteModal} type="button">
                        <Close />
                    </button>
                </div>
                <div className="buttonsContainer">
                    <ButtonGeneric
                        onclick={handleCloseDeleteNoteModal}
                        buttonColor="var(--cancel)"
                        text="CANCELAR"
                    />
                    <PreloadButton
                        colorText="white"
                        background="var(--delete)"
                        loading={isLoadingButton}
                        text="DELETAR"
                        type="submit"
                        onClick={() => deletePlan()}
                    />
                </div>

                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </DeleteNoteModalStyled>
        </Modal>
    );
}
export default DeleteNoteModal;
