import styled from "styled-components";

export const SettingsPageStyle = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 30px 5%;
    /* border: 1px solid rgba(45, 45, 45, 0.15); */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .cardButtons {
        box-shadow: 0px 0px 12px rgba(32, 32, 32, 0.15);
        border: 1px solid rgba(32, 32, 32, 0.1);
        border-radius: 8px;
        width: 40%;
        padding: 15px 20px;
        background: #fff;
    }

    .topCardButton {
        display: flex;
        align-items: center;
        font-weight: 700;
        gap: 20px;
        font-size: 1.2rem;
    }

    .textButton {
        font-size: 0.9rem;
    }

    .linkContainer {
        display: flex;
        justify-content: flex-end;
    }

    .linkButton {
        border: none;
        padding: 6px 10px;
        border-radius: 6px;
        background-color: var(--primary);
        color: white;
        cursor: pointer;
        transition: 0.3s;
    }

    @media (max-width: 1700px) {
        .cardButtons {
            border-radius: 8px;
            width: 40%;
            padding: 15px 20px;
        }

        @media (max-width: 950px) {
            .cardButtons {
                width: 100%;
            }
        }
    }
`;
