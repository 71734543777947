import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Items } from "./types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface MultipleSelectProps {
    items: Items[];
    valuesSelected: number[];
    setValuesSelected: (data: number[]) => void;
    label: string;
    size: "medium" | "small";
}

export default function MultipleSelect({
    items,
    setValuesSelected,
    valuesSelected,
    label,
    size,
}: MultipleSelectProps) {
    const handleChange = (event: SelectChangeEvent<typeof valuesSelected>) => {
        const {
            target: { value },
        } = event;
        setValuesSelected(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",").map(Number) : value
        );
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }} size={size}>
                <InputLabel
                    id={`multiple-checkbox-${label.toLocaleLowerCase()}`}
                >
                    {label}
                </InputLabel>
                <Select
                    labelId={`multiple-checkbox-${label.toLocaleLowerCase()}`}
                    id={`multiple-checkbox-${label.toLocaleLowerCase()}`}
                    multiple
                    value={valuesSelected}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) =>
                        selected
                            .map(
                                (value) =>
                                    items.find((item) => item.value === value)
                                        ?.label
                            )
                            .join(", ")
                    }
                    MenuProps={MenuProps}
                >
                    {items.map((name) => (
                        <MenuItem key={name.value} value={name.value}>
                            <Checkbox
                                checked={
                                    valuesSelected.indexOf(name.value) > -1
                                }
                            />
                            <ListItemText primary={name.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
