/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Delete, Edit, HowToReg, NoAccounts } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ModalBlockUser from "../../components/BlockUserModal";
import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteUser from "../../components/DeleteUserModal";
import ModalEditUser from "../../components/EditUserModal";
import { If } from "../../components/If";
import ModalNewUser from "../../components/NewUserModal";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

interface RolesProps {
    id: number | string;
    name: string;
}

export interface DataUserProps {
    email?: string;
    id?: number | string;
    is_blocked?: number;
    name?: string;
    roles?: RolesProps[];
}

function UsersPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    const adminUser = "Admin ANA";
    useEffect(() => {
        if (
            hasPermissions("usuario.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataUsersList, setDataUsersList] = useState<DataUserProps[]>([]);
    const [selectedRowUser, setSelectedRowUser] = useState({});
    const [openNewUserModal, setOpenNewUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [openBlockUserModal, setOpenBlockUserModal] = useState(false);
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("/users")
            .then((response) => {
                setLoadingTable(false);
                setDataUsersList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const handleCloseNewUserModal = () => {
        setOpenNewUserModal(false);
    };

    const handleCloseEditUserModal = () => {
        setOpenEditUserModal(false);
        setSelectedRowUser({});
    };

    const handleCloseDeleteUserModal = () => {
        setOpenDeleteUserModal(false);
    };

    const handleCloseBlockUserModal = () => {
        setOpenBlockUserModal(false);
    };

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "name", headerName: "Nome", flex: 20 },
        {
            field: "roles",
            headerName: "Cargos",
            flex: 25,
            disableExport: true,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
                return (
                    <div className="containerActionButtonsListTablePagesGlobal">
                        <p>
                            {params.row.roles
                                .map((item: { id: number; name: string }) => {
                                    return item.name;
                                })
                                .join(", ")}
                        </p>
                    </div>
                );
            },
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 50,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams<any, any, any>) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If
                        condition={
                            hasPermissions("usuario.bloquear") &&
                            params.row.name !== adminUser
                        }
                    >
                        {params.row.is_blocked === 0 ? (
                            <HowToReg
                                className="cursorPointerGlobal"
                                onClick={() => {
                                    setOpenBlockUserModal(true);
                                }}
                            />
                        ) : (
                            <NoAccounts
                                className="cursorPointerGlobal"
                                onClick={() => {
                                    setOpenBlockUserModal(true);
                                }}
                            />
                        )}
                    </If>
                    <If
                        condition={
                            hasPermissions("usuario.editar") &&
                            params.row.name !== adminUser
                        }
                    >
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenEditUserModal(true);
                            }}
                        />
                    </If>

                    <If
                        condition={
                            hasPermissions("usuario.excluir") &&
                            params.row.name !== adminUser
                        }
                    >
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteUserModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If condition={hasPermissions("usuario.cadastrar")}>
                            <ButtonGeneric
                                onclick={() => {
                                    setOpenNewUserModal(true);
                                }}
                                buttonColor="var(--primary)"
                                text="Novo usuário"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataUsersList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowUser(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteUser
                        openDeleteUserModal={openDeleteUserModal}
                        handleCloseDeleteUserModal={handleCloseDeleteUserModal}
                        selectedRowUser={selectedRowUser}
                        dataUsersList={dataUsersList}
                        setDataUsersList={setDataUsersList}
                    />
                    {openEditUserModal && (
                        <ModalEditUser
                            openEditUserModal={openEditUserModal}
                            handleCloseEditUserModal={handleCloseEditUserModal}
                            selectedRowUser={selectedRowUser}
                            dataUsersList={dataUsersList}
                            setDataUsersList={setDataUsersList}
                        />
                    )}
                    <ModalNewUser
                        setDataUsersList={setDataUsersList}
                        openNewUserModal={openNewUserModal}
                        handleCloseNewUserModal={handleCloseNewUserModal}
                    />
                    <ModalBlockUser
                        openBlockUserModal={openBlockUserModal}
                        handleCloseBlockUserModal={handleCloseBlockUserModal}
                        selectedRowUser={selectedRowUser}
                        setDataUsersList={setDataUsersList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default UsersPage;
