import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    max-width: 450px;
    min-width: 450px;
    width: 450px;
    padding: 2rem;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    -moz-box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    border-radius: 5px;

    @media (max-width: 1100px) {
        max-width: 700px;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    word-break: break-all;
`;

export const IframeVideo = styled.iframe`
    width: 100%;
    height: 250px;
`;

export const Banner = styled.img`
    width: 100%;
    height: 250px;
    border-radius: 20px;
    border: 1px solid #000000;
`;

export const ContentFooterButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonSeeMore = styled.a`
    width: 70px;
    display: block;
    padding: 0.5rem 2rem;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    background-color: var(--secondary);
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
`;
