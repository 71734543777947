export function getLastDaysOfMonthsBeforeCurrentDate(
    numMonths: number
): Date[] {
    const currentDate: Date = new Date();
    const lastDaysOfMonths: Date[] = [];

    /* eslint-disable-next-line no-plusplus */
    for (let i = 1; i <= numMonths; i++) {
        const year: number = currentDate.getFullYear();
        const month: number = currentDate.getMonth() - i;
        const lastDayOfMonth: Date = new Date(year, month + 1, 0);
        lastDaysOfMonths.push(lastDayOfMonth);
    }

    return lastDaysOfMonths.reverse(); // Reverses the array to have the oldest date first.
}
