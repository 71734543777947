/* eslint-disable */
import { Pagination } from "@mui/material";

import {
    DataGrid,
    ptBR,
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiContext,
} from "@mui/x-data-grid";

import { FormEvent, ReactNode } from "react";

import { api } from "../../services/api";

import { If } from "../If";
import { ContentFieldsSearch, Content } from "./styles";
import { ColumnsProps } from "./types";

const styleTableButtons = {
    color: "white",
    background: "var(--primary)",
    "&:hover": {
        background: "#172b4de4",
    },
};

interface Props {
    page?: number;
    totalPages?: number;
    onClickPagination?: any;
    isDisabledPagination?: boolean;
}

interface TableDataGridPaginationProps {
    rows: CustomerProps[];
    columns: ColumnsProps[] | any;
    loading: boolean;
    setLoading: (data: boolean) => void;
    page: number;
    totalPages: number | undefined;
    onClickPagination: (data: any) => void;
    headerTableComponent?: ReactNode;
    hideExport: boolean;
}

function CustomPagination({
    page,
    totalPages,
    onClickPagination,
    isDisabledPagination,
}: Props) {
    const apiRef = useGridApiContext();

    return (
        <Pagination
            color="primary"
            count={totalPages}
            page={page}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            onClick={onClickPagination}
            hideNextButton
            hidePrevButton
            disabled={isDisabledPagination}
        />
    );
}

function TableDataGridPagination({
    rows,
    columns,
    loading,
    page,
    totalPages,
    onClickPagination,
    setLoading,
    headerTableComponent,
    hideExport = true,
}: TableDataGridPaginationProps) {
    const handleSearch = async (event: FormEvent<HTMLFormElement>) => {
        setLoading(true);
        try {
            event.preventDefault();

            const formData = new FormData(event.target as HTMLFormElement);

            const valueSearch = formData.get("field-search");

            const responseValueSearch = await api.get(
                `/customers/pagination?search=${valueSearch}`
            );

            // if (responseValueSearch.status) {
            //     setDataCustomersPagesList(
            //         responseValueSearch.data.response.data
            //     );
            // }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            sx={{
                padding: "10px",
                background: "#fff",
                borderRadius: 0,
                marginInline: "30px",
                marginBottom: "10px",
            }}
            rows={rows}
            columns={columns}
            pageSize={15}
            hideFooterSelectedRowCount
            loading={loading}
            disableColumnMenu
            components={{
                Toolbar: () => {
                    return (
                        <Content>
                            <GridToolbarContainer
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                                className="toolbarContainer"
                            >
                                <ContentFieldsSearch onSubmit={handleSearch}>
                                    {headerTableComponent}
                                </ContentFieldsSearch>

                                {/* <GridToolbarFilterButton sx={styleTableButtons} /> */}
                                <div>
                                    <If condition={!hideExport}>
                                        <GridToolbarExport
                                            sx={styleTableButtons}
                                        />
                                    </If>
                                </div>
                            </GridToolbarContainer>
                        </Content>
                    );
                },
                Pagination: () => {
                    return (
                        <CustomPagination
                            page={page}
                            totalPages={totalPages}
                            onClickPagination={onClickPagination}
                            isDisabledPagination={loading}
                        />
                    );
                },
            }}
        />
    );
}

export default TableDataGridPagination;
