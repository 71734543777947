import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { statusItems } from "../CustomersPage/types";

export function getCustomerStatusName(statusId: number) {
    return statusItems.find((item) => item.value === statusId)?.label;
}

export const infraDataColumns: GridColDef[] = [
    {
        field: "project_name",
        headerName: "Cliente - App",
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
            <strong>{params.row.project_name}</strong>
        ),
    },
    {
        field: "customer_status_id",
        headerName: "Status",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
            <span>{getCustomerStatusName(params.row.customer_status_id)}</span>
        ),
    },
    {
        field: "more",
        headerName: "Mais informações",
        flex: 1,
        renderCell: () => <span>-</span>,
    },
];

export const plainDataColumn: GridColDef[] = [
    {
        field: "project_name",
        headerName: "Cliente - App",
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
            <strong>{params.row.project_name}</strong>
        ),
    },
    {
        field: "more",
        headerName: "Mais informações",
        flex: 1,
        renderCell: () => <span>-</span>,
    },
];
