import styled from "styled-components";

export const FilterContent = styled.div`
    display: flex;
    /* align-items: center; */
    width: 100%;
    flex-direction: row;

    .clear-filter {
        cursor: pointer;
    }

    /* @media (max-width: 1250px) {
        width: 100%;
        flex-direction: column;
    } */
`;

export const ActionLeft = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 100%;
    margin: 7px 12px 7px 0px;

    .button {
        padding: 2px;
    }
    justify-content: right;
    justify-items: right;
`;

export const ButtonSearch = styled.div`
    margin-top: 1rem;
    margin-left: 5px;
`;

export const FilterStyled = styled.div`
    width: 100%;
`;

export const SearchDiv = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    margin: 7px 12px 7px 0px;

    .button {
        padding: 2px;
    }
`;

export const DateFilter = styled.div`
    width: 100%;
    display: flex;
    gap: 0 7px;

    > div {
        width: 100%;
        > div {
            margin: 0;
        }
    }
`;

export const SearchContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;

    div {
        display: flex;
        gap: 5px;
    }
`;
