import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 65vh;
`;

export const ContentButtonAdd = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ContentBtnsActions = styled.div``;
