import styled from "styled-components";

export const FormComissionSetupPage = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 0 15%;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h3 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    form {
        width: 900px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    .inputFullWidth {
        width: 884px;
    }

    .inputsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2%;
    }

    .inputs {
        width: 390px;
    }

    .inputs300px {
        width: 300px;
    }

    .iconDelete {
        cursor: pointer;
    }

    .tableRow {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .itensTableRow {
        display: flex;
        align-items: center;
        padding: 0px 8px;
    }

    .selectInput {
        width: 70px;
    }

    .inputsDynamics {
        max-width: 140px;
    }

    .buttonsContainer {
        margin-top: 20px;
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 1050px) {
        form {
            width: 100%;
        }

        .inputFullWidth {
            width: 490px;
        }

        @media (max-width: 700px) {
            .inputFullWidth {
                width: 315px;
            }

            .inputs {
                width: 330px;
            }

            @media (max-width: 400px) {
                h3 {
                    text-align: center;
                }

                .inputFullWidth {
                    width: 230px;
                }

                .selectInput {
                    width: 230px;
                }

                .inputsDynamics {
                    min-width: 230px;
                }

                .checkboxesList {
                    max-height: 100%;
                }

                .inputs {
                    max-width: 230px;
                }

                .inputs300px {
                    max-width: 230px;
                }
            }
        }
    }
`;
