import { Add, AccessTime, ChangeCircle, Edit } from "@mui/icons-material";
import { AlertColor, Chip, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import { If } from "../../components/If";
import ModalEditConfigNotification from "../../components/ModalEditConfigNotification";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import {
    DateReferencesProps,
    TypesNotificationsProps,
} from "../ConfigAddNotificationsPage/types";
import { Container, ContentButtonAdd, ContentBtnsActions } from "./styles";
import { ConfigNotificationsProps } from "./types";

function ConfigNotificationsPage() {
    const navigate = useNavigate();

    const [allNotifications, setAllNotifications] = useState<
        ConfigNotificationsProps[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [textToast, setTextToast] = useState<string>("");
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();

    const [typesNotifications, setTypesNotifications] = useState<
        TypesNotificationsProps[]
    >([]);

    const [datesReferences, setDatesReferences] = useState<
        DateReferencesProps[]
    >([]);

    const [notificationSelected, setNotificationSelected] =
        useState<ConfigNotificationsProps | null>(null);

    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

    const { permissions, hasPermissions } = useAuth();

    const getAllNotifications = async () => {
        try {
            setLoading(true);
            const responseAllNotifications = await api.get("notifications");

            if (responseAllNotifications.status) {
                setAllNotifications(
                    responseAllNotifications.data.response.reverse()
                );
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setTextToast("Erro ao buscar notificações");
            setToastRequisitionResult("error");
        } finally {
            setLoading(false);
        }
    };

    const getTypesNotificationsAndDateReferences = () => {
        setLoading(true);
        const responseTypesNotifications = api.get("notifications/types");
        const responseDateReferences = api.get("notifications/date-references");

        Promise.all([responseTypesNotifications, responseDateReferences])
            .then((response) => {
                if (response[0].status) {
                    setTypesNotifications(response[0].data.response);
                }

                if (response[1].status) {
                    setDatesReferences(response[1].data.response);
                }
            })
            .catch((error) => {
                console.error(error);
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(
                    "Erro ao pegar os tipos de notificações e datas de referentes"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllNotifications();
        getTypesNotificationsAndDateReferences();
    }, []);

    const handleSelectedNotifications = (value: ConfigNotificationsProps) => {
        setShowModalEdit(true);
        setNotificationSelected(value);
    };

    const handleChangeStatus = async (value: ConfigNotificationsProps) => {
        try {
            const {
                title,
                body,
                date_reference_id,
                enabled,
                type_id,
                when,
                details,
            } = value;

            const responseStatus = await api.put(`notifications/${value.id}`, {
                title,
                body,
                when,
                date_reference_id,
                type_id,
                enabled: !enabled,
                vias: {
                    email: !details.email,
                    sms: !details.sms,
                    whatsapp: !details.whatsapp,
                },
            });
            if (responseStatus.status) {
                setOpenToast(true);
                setTextToast("Status da notificação atualizado com sucesso!");
                setToastRequisitionResult("success");
                getAllNotifications();
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setTextToast("Erro ao atualizar status da notificação");
            setToastRequisitionResult("error");
        }
    };

    const switchStatus = (id: number) => {
        switch (id) {
            case 0:
                return {
                    color: "error",
                    text: "Inativo",
                };
            case 1:
                return {
                    color: "success",
                    text: "Ativo",
                };
            default:
                return {
                    color: "black",
                    text: "undefined",
                };
        }
    };
    const switchDateReference = (id: number) => {
        switch (id) {
            case 1:
                return "vencimento";
            case 2:
                return "fechamento";
            default:
                return "-";
        }
    };

    const columns: GridColDef[] = [
        {
            field: "title",
            flex: 10,
            headerName: "Título",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <strong>{String(params.row?.title).toLocaleUpperCase()}</strong>
            ),
        },
        {
            field: "body",
            flex: 12,
            headerName: "Mensagem",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <span>{`${
                    params.row?.body.length > 30
                        ? `${String(params.row?.body).substring(0, 30)}...`
                        : params.row?.body
                }`}</span>
            ),
        },
        {
            field: "when",
            flex: 15,
            headerName: "Quando",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    {Math.abs(params.row?.when)} dias{" "}
                    {params.row?.when < 0 ? "antes do último" : "após o último"}{" "}
                    {switchDateReference(params.row?.date_reference.id)}
                </strong>
            ),
        },
        {
            field: "enabled",
            flex: 8,
            headerName: "Status",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Chip
                        label={`${switchStatus(params.row.enabled)?.text}`}
                        variant="outlined"
                        size="small"
                        color={switchStatus(params.row.enabled)?.color as any}
                        sx={{
                            width: 100,
                        }}
                    />
                );
            },
        },
        {
            field: "tag",
            flex: 13,
            headerName: "Tag",
            renderCell: (params: GridRenderCellParams) => (
                <p>{String(params.row.type.name).toUpperCase()}</p>
            ),
        },
        {
            field: "actions",
            flex: 5,
            headerName: "Ações",
            align: "right",
            renderCell: (params: GridRenderCellParams) => (
                <If condition={hasPermissions("notificacao.editar")}>
                    <ContentBtnsActions>
                        <IconButton
                            title="Alternar status"
                            onClick={() => handleChangeStatus(params.row)}
                        >
                            <ChangeCircle color="info" />
                        </IconButton>

                        <IconButton
                            title="Editar"
                            onClick={() =>
                                handleSelectedNotifications(params.row)
                            }
                        >
                            <Edit color="primary" />
                        </IconButton>
                    </ContentBtnsActions>
                </If>
            ),
        },
    ];

    useEffect(() => {
        if (
            hasPermissions("notificacao.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Gerenciador De Mensagens",
                    subtitle:
                        "Configurações de notificações e lembretes para clientes",
                    icon: <AccessTime sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <If condition={hasPermissions("notificacao.criar")}>
                        <ContentButtonAdd>
                            <ButtonGeneric
                                onclick={() => {
                                    navigate(
                                        "/settings/notifications/newNotifications"
                                    );
                                }}
                                buttonColor="var(--secondary)"
                                text="Nova Notificação"
                                icon={<Add />}
                            />
                        </ContentButtonAdd>
                    </If>

                    <TableDataGrid
                        style={{ minWidth: "460px !important" }}
                        rows={allNotifications}
                        columns={columns}
                        loading={loading}
                    />

                    <Toast
                        onClose={() => setOpenToast(false)}
                        text={textToast}
                        open={openToast}
                        severity={toastRequisitionResult}
                    />
                    <ModalEditConfigNotification
                        getAllNotifications={getAllNotifications}
                        notificationSelected={notificationSelected}
                        datesReferences={datesReferences}
                        typesNotifications={typesNotifications}
                        openModal={showModalEdit}
                        setOpenModal={setShowModalEdit}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}

export default ConfigNotificationsPage;
