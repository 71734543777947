import styled from "styled-components";

export const Container = styled.main`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    max-width: 1350px;
    width: 100%;
    padding: 0 1rem;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #a8a8a8;
    padding: 1rem 0;
    gap: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

export const ContentLogo = styled.div`
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);

    img {
        width: 100px;
    }
`;

export const ItemsChecklists = styled.section``;

export const ContentTable = styled.div`
    margin: 2rem 0;
    overflow: auto;
    padding-bottom: 1rem;

    ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 5px;
    }
`;

export const TableCheckLists = styled.table`
    width: 100%;
    min-width: 1030px;
    border-collapse: collapse;

    > thead {
        margin-bottom: 1rem;

        th {
            text-align: left;
            font-weight: 500;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid gray;
        }

        td {
            font-size: 14px;
            font-weight: 300;
        }
    }
`;

export const TextObs = styled.span`
    font-size: 14px;
    color: gray;
`;

export const SubTitle = styled.h4``;

export const ContentTerms = styled.div``;

export const ContentButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 1rem 0;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;
