import { CustomerDisagreements } from "./types";

export function financialStatusLabel(
    customerDisagreements: CustomerDisagreements
) {
    if (customerDisagreements.account_id === null) {
        return {
            label: "Sem conta",
            color: "#f0b030",
        };
    }

    if (
        customerDisagreements.days_past_due !== null &&
        customerDisagreements.days_past_due > 0
    ) {
        return {
            label: `${customerDisagreements.days_past_due} dias em atraso`,
            color: "#dc2b2b",
        };
    }

    return {
        label: "Adimplente",
        color: "#60dc2b",
    };
}
