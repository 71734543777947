import { Close } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import logoImage from "../../assets/images/logo704LoginPage.png";
import { HelperGenericModalStyle } from "./styles";

interface Props {
    openHelperGenericModal: boolean;
    handleCloseHelperGenericModal: () => void;
    text?: string;
    src?: string;
}

function HelperGenericModal({
    openHelperGenericModal,
    handleCloseHelperGenericModal,
    text,
    src,
}: Props) {
    const [imageSrc, setImageSrc] = useState(src);

    const handleImageError = () => {
        setImageSrc(logoImage);
    };

    return (
        <Modal
            open={openHelperGenericModal}
            onClose={handleCloseHelperGenericModal}
        >
            <HelperGenericModalStyle>
                <div className="topModalContainer">
                    <h1>Instruções</h1>
                    {/* eslint-disable-next-line */}
                    <button
                        onClick={handleCloseHelperGenericModal}
                        type="button"
                    >
                        <Close />
                    </button>
                </div>
                <div className="mainContainer">
                    <div className="imageContainer">
                        <img
                            src={imageSrc}
                            onError={handleImageError}
                            className="imageContent"
                            alt="alt"
                        />
                        <p>Alteração sendo concluída</p>
                    </div>
                    <div className="rightSideContainer">
                        <div className="mainTextContainer">
                            <p className="mainTextContent">{text}</p>
                        </div>
                        <hr />
                        <div>
                            <p>Video relacionado</p>
                            <a
                                href="https://youtube.com.br"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={imageSrc}
                                    onError={handleImageError}
                                    className="imageVideo"
                                    alt="alt"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </HelperGenericModalStyle>
        </Modal>
    );
}
export default HelperGenericModal;
