import styled from "styled-components";

export const Container = styled.div``;

export const ContentBtnNewCheckList = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;

export const ContentTable = styled.div`
    width: 100%;
    height: 550px;
`;
