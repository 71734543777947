import styled from "styled-components";

export const ModalBlockImplementationStyle = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 550px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;

    .topModalText {
        text-align: center;
        margin-bottom: 10%;
    }
    .inputsContainer {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 5%;
    }
    .inputsContainer div {
        margin-bottom: 10px;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 550px) {
        top: 45%;

        .inputsContainer {
            flex-direction: column;
        }
    }
`;
