import {
    OpenInNew,
    Edit,
    CheckCircle,
    RemoveCircle,
    WhatsApp,
    ForwardToInbox,
} from "@mui/icons-material";
import { Button, Divider, LinearProgress, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { If } from "../../../components/If";
import ModalEditCustomer from "../../../components/ModalEditCustomer";
import { documentMask } from "../../../helpers/document";
import { phoneMask } from "../../../helpers/phone";
import { api } from "../../../services/api";
import InstallImplementationModal from "../InstallImplementationModal";
import StabilityCheckModal from "../StabilityCheckModal";
import { CustomersProps } from "../types";
import ValidateCustomerModal from "../ValidateCustomerModal";
import "react-toastify/dist/ReactToastify.css";
import { defaultToast } from "../../../helpers/toast/defaultToast";
import { useAuth } from "../../../contexts/contexts";
import { ModalSendMail } from "./ModalSendMail";

interface GeneralDataContentProps {
    currentCustomer?: CustomersProps;
    setCurrentCustomer: Dispatch<SetStateAction<CustomersProps | undefined>>;
    myParam: string | null;
    getCustomer: () => void;
}

function GeneralDataContent({
    currentCustomer,
    setCurrentCustomer,
    myParam,
    getCustomer,
}: GeneralDataContentProps) {
    const stabilityCheckRef = useRef<any>(null);
    const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
    const [customerHealth, setCustomerHealth] = useState<boolean>(false);
    const [healthServerLoading, setHealthServerLoading] =
        useState<boolean>(true);

    const { hasPermissions } = useAuth();

    const [openValidateCustomerModal, setOpenValidateCustomerModal] =
        useState(false);

    const handleCloseValidateCustomerModal = () => {
        setOpenValidateCustomerModal(false);
    };

    const [openInstallImplementationModal, setOpenInstallImplementationModal] =
        useState(false);

    const handleCloseInstallImplementationModal = () => {
        setOpenInstallImplementationModal(false);
    };

    const [currentImplementationId, setCurrentImplementationId] =
        useState<number>(0);

    const getCustomerHealth = () => {
        setHealthServerLoading(true);
        api.get(`/customerInfra/isActive/${myParam}`)
            .then((res) => {
                setCustomerHealth(res.data.response);
            })
            .catch((err) => {
                defaultToast(
                    "error",
                    "ocorreu um erro ao verificar saúde do cliente"
                );
            })
            .finally(() => {
                setHealthServerLoading(false);
            });
    };

    useEffect(() => {
        getCustomerHealth();
    }, []);

    const [openSendEmail, setOpenSendMail] = useState(false);
    const handleOpenSendMail = () => setOpenSendMail(true);
    const handleCloseSendMail = () => setOpenSendMail(false);

    return (
        <>
            <div>
                <div className="mainContainer">
                    <div className="titlePageContainer">
                        <h1 style={{ marginTop: 0 }}>Dados Gerais</h1>
                    </div>
                    <div>
                        <div className="content-header-status">
                            <h3>Status dos servidores</h3>
                            <div>
                                {customerHealth ? (
                                    <span className="span-item">
                                        <If condition={!healthServerLoading}>
                                            <CheckCircle color="success" />
                                            <span>
                                                Cliente está{" "}
                                                <strong>ONLINE</strong> <br />
                                                <span
                                                    style={{
                                                        fontSize: "0.6rem",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    atualizado a 5 minutos atrás
                                                </span>
                                            </span>
                                        </If>
                                    </span>
                                ) : (
                                    <span className="span-item">
                                        <If condition={!healthServerLoading}>
                                            <span className="icon">
                                                <RemoveCircle color="error" />
                                            </span>
                                            <span>
                                                Cliente está{" "}
                                                <strong>OFFLINE</strong> <br />
                                                <span
                                                    style={{
                                                        fontSize: "0.6rem",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    atualizado a 5 minutos atrás
                                                </span>
                                            </span>
                                        </If>
                                    </span>
                                )}
                            </div>
                        </div>

                        <If condition={healthServerLoading}>
                            <LinearProgress color="secondary" />

                            <span style={{ fontSize: "0.8rem" }}>
                                Verificandos saúde do servidor...
                            </span>
                        </If>

                        <Divider />
                        <div className="content-header-informations">
                            <h3>Informações</h3>
                            <If condition={hasPermissions("cliente.editar")}>
                                <Button
                                    onClick={() => setOpenModalEdit(true)}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                >
                                    <Edit /> Editar dados do cliente
                                </Button>
                            </If>
                        </div>
                        <Divider />
                        <div className="contentFields">
                            <div className="contentRows">
                                <p className="boldText">
                                    <strong>App:</strong>{" "}
                                    {currentCustomer?.project_name ||
                                        "App não informado"}
                                </p>
                            </div>
                            {currentCustomer?.generated !== null ? (
                                <>
                                    <div className="contentRows">
                                        <p className="boldText">
                                            <strong>App gerado por:</strong>{" "}
                                            {currentCustomer?.generated
                                                .generated_by
                                                ? currentCustomer?.generated
                                                      .generated_by.name
                                                : "não informado"}
                                        </p>
                                    </div>
                                    <div className="contentRows">
                                        <p className="boldText">
                                            <strong>Gerado em:</strong>{" "}
                                            {currentCustomer?.generated?.generated_at
                                                .split(" ")[0]
                                                .split("-")
                                                .reverse()
                                                .join("/")}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <div
                                    className="contentRows"
                                    style={{
                                        marginTop: "10px",
                                    }}
                                />
                            )}
                            <div className="contentRows">
                                <p className="boldText">
                                    <strong>Link do monitor:</strong>{" "}
                                    <a
                                        href={`https://monitor-${
                                            currentCustomer?.project_name
                                        }.${
                                            window.env.NODE_ENV ===
                                            "development"
                                                ? "dev"
                                                : "prod"
                                        }.704apps.com.br/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        clique aqui
                                    </a>
                                </p>
                            </div>
                            <div className="contentRows">
                                <p className="boldText">
                                    <strong>Cloud :</strong>{" "}
                                    {currentCustomer?.cloud ||
                                        "Cloud não informado"}
                                </p>
                            </div>

                            <div className="contentRows">
                                <p className="boldText">
                                    <strong>Cliente de migração: </strong>
                                    {currentCustomer?.migrated_client === 0
                                        ? "Não"
                                        : "Sim"}
                                </p>
                            </div>

                            <div className="contentRows">
                                <p className="boldText">
                                    <strong>
                                        Informações do aplicativo do cliente:{" "}
                                    </strong>
                                    <a
                                        href={`${window.location.origin}/ana/listagem-clientes/detalhes-clientes?idCustomer=${myParam}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        clique aqui
                                    </a>
                                </p>
                            </div>

                            {currentCustomer?.validation !== null ? (
                                <>
                                    <div className="contentRows">
                                        <p className="boldText">
                                            <strong>Validado por:</strong>{" "}
                                            {currentCustomer?.validation
                                                .validated_by?.name ?? ""}
                                        </p>
                                    </div>
                                    <div className="contentRows">
                                        <p className="boldText">
                                            <strong>Validado em:</strong>{" "}
                                            {currentCustomer?.validation.validated_at
                                                .split(" ")[0]
                                                .split("-")
                                                .reverse()
                                                .join("/")}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <div className="contentRows">
                                    <Button
                                        type="button"
                                        className="buttonInstall"
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            setOpenValidateCustomerModal(true);
                                        }}
                                    >
                                        Validar Cliente
                                    </Button>
                                </div>
                            )}

                            <div className="contentRows">
                                <If
                                    condition={Boolean(
                                        !currentCustomer?.migrated_client
                                    )}
                                >
                                    <p className="boldText">
                                        <strong>Link do contrato:</strong>{" "}
                                        <a
                                            target="_blank"
                                            href={
                                                currentCustomer?.proposal
                                                    ?.customer_contract
                                                    ?.pdf_link ?? "#"
                                            }
                                            rel="noreferrer"
                                        >
                                            {currentCustomer?.proposal
                                                ?.customer_contract?.pdf_link
                                                ? "clique aqui."
                                                : "indisponível"}
                                        </a>
                                    </p>
                                </If>
                            </div>
                        </div>

                        <h3
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            Informações do Titular{" "}
                            <Tooltip
                                title={`ir para ${currentCustomer?.holder.name}`}
                            >
                                <Link
                                    to={`/comercial/holders/holderEdit?id=${currentCustomer?.customer_holder_id}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "var(--secondary)",
                                    }}
                                >
                                    <OpenInNew
                                        style={{
                                            marginTop: 6,
                                        }}
                                        fontSize="small"
                                    />
                                </Link>
                            </Tooltip>
                        </h3>
                        <Divider />
                        <div className="contentFields">
                            <div className="contentRows">
                                <p className="boldText">
                                    {currentCustomer?.holder?.full_name ||
                                        "Sem nome de titular"}
                                </p>
                            </div>
                            <div className="contentRows">
                                <p className="boldText">
                                    {documentMask(
                                        currentCustomer?.holder?.document ?? ""
                                    ) || "Documento não informado"}
                                </p>
                            </div>
                            <div className="contentRows">
                                <p
                                    className="boldText"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {currentCustomer?.holder?.email ||
                                        "Email não informado"}

                                    <ForwardToInbox
                                        onClick={() => {
                                            handleOpenSendMail();
                                        }}
                                        color="primary"
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </p>
                            </div>
                            <div className="contentRows">
                                <p
                                    className="boldText"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {phoneMask(
                                        currentCustomer?.holder?.phone ?? ""
                                    ) || "Telefone não informado"}
                                    <a
                                        href={`https://wa.me/55${currentCustomer?.holder?.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <WhatsApp
                                            color="secondary"
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </a>
                                </p>
                            </div>
                        </div>

                        {currentCustomer?.customer_implementation &&
                        currentCustomer?.customer_implementation.length > 0 ? (
                            <h3>Implementações</h3>
                        ) : (
                            ""
                        )}

                        {currentCustomer?.customer_implementation?.map(
                            ({ id, implementation, status }: any) => (
                                <div className="contentFields" key={id}>
                                    <div className="contentRows">
                                        <p className="boldText">
                                            {implementation.description}
                                        </p>
                                    </div>
                                    <div className="contentRows">
                                        {status === "installed" ? (
                                            <p className="boldText">
                                                Implementação instalada
                                            </p>
                                        ) : (
                                            <button
                                                className="buttonInstall"
                                                value={id}
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                onClick={(event: any) => {
                                                    setOpenInstallImplementationModal(
                                                        true
                                                    );
                                                    setCurrentImplementationId(
                                                        event?.target.value
                                                    );
                                                }}
                                                type="button"
                                            >
                                                Instalar implementação
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <InstallImplementationModal
                handleCloseInstallImplementationModal={
                    handleCloseInstallImplementationModal
                }
                openInstallImplementationModal={openInstallImplementationModal}
                currentImplementationId={currentImplementationId}
                myParam={myParam ?? ""}
            />
            <StabilityCheckModal
                customerName={currentCustomer?.project_name}
                ref={stabilityCheckRef}
            />
            <ValidateCustomerModal
                setCurrentCustomer={setCurrentCustomer}
                openValidateCustomerModal={openValidateCustomerModal}
                handleCloseValidateCustomerModal={
                    handleCloseValidateCustomerModal
                }
                myParam={myParam ?? ""}
            />

            <ModalEditCustomer
                datasCustomer={currentCustomer}
                visible={openModalEdit}
                setVisible={setOpenModalEdit}
                getCustomer={getCustomer}
            />

            <ModalSendMail
                open={openSendEmail}
                onClose={handleCloseSendMail}
                customer={currentCustomer}
            />
        </>
    );
}

export default GeneralDataContent;
