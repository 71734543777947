import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor, Box, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { DataUserProps } from "../../pages/UsersPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import InputGeneric from "../InputGeneric";
import MultiSelect from "../MultiSelect";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalEditUserStyle } from "./styles";

interface Props {
    openEditUserModal: boolean;
    handleCloseEditUserModal: () => void;
    selectedRowUser: GridRowParams["row"];
    setDataUsersList: (value: DataUserProps[]) => void;
    dataUsersList: DataUserProps[];
}

type EditUserFormTypeValues = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    role_id: { id: number }[];
};

type Payload = {
    name: string;
    email: string;
    role_id: number[];
    password?: string;
    password_confirmation?: string;
};

const EditUserFormSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    password: yup.string(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null, "Senhas diferentes"]),
    role_id: yup
        .array()
        .of(
            yup.object().shape({
                id: yup.number(),
            })
        )
        .required()
        .min(1),
});

function ModalEditUser({
    openEditUserModal,
    handleCloseEditUserModal,
    selectedRowUser,
    setDataUsersList,
}: Props) {
    const [dataRolesList, setDataRolesList] = useState<
        { name: string; id: number }[]
    >([]);
    const [isLoadingMultiSelect, setIsLoadingMultiSelect] = useState(false);
    const [currentUserInformations, setCurrentUserInformations] = useState({
        name: selectedRowUser.name,
        email: selectedRowUser.email,
        roles: [selectedRowUser.roles.id],
        id: selectedRowUser.id,
    });
    const {
        setValue,
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EditUserFormTypeValues>({
        resolver: yupResolver(EditUserFormSchema),
    });
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        setIsLoadingMultiSelect(true);
        api.get("/roles/all")
            .then((response) => {
                setDataRolesList(response.data.response);
                setValue("role_id", selectedRowUser.roles);
            })
            .then(() => {
                setIsLoadingMultiSelect(false);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, [setValue, selectedRowUser.roles]);

    useEffect(() => {
        setCurrentUserInformations(selectedRowUser);
    }, [selectedRowUser]);

    const handleValidateFormAndEditUser: SubmitHandler<
        EditUserFormTypeValues
    > = async (values) => {
        setIsLoadingButton(true);
        // PAYLOAD FOI CRIADO PARA VERIFICAR SE O CAMPO DE PASSWORD ESTA VAZIO OU NÃO
        const payload: Payload = {
            name: values.name,
            email: values.email,
            role_id: values.role_id.map((item: { id: number }) => {
                return item.id;
            }),
        };
        if (values.password !== "") {
            payload.password = values.password;
            payload.password_confirmation = values.password;
        }
        api.put(`/users/${selectedRowUser.id}`, payload)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseEditUserModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                api.get("/users")
                    .then((response) => {
                        setDataUsersList(response.data.response.data);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    };

    return (
        <Modal open={openEditUserModal} onClose={handleCloseEditUserModal}>
            <ModalEditUserStyle>
                <Box>
                    <p>{`Editar usuário "${selectedRowUser.name}".`}</p>
                    <form
                        name="editFormModal"
                        onSubmit={handleSubmit(handleValidateFormAndEditUser)}
                    >
                        <div className="inputsContainer">
                            <InputGeneric
                                className="inputs"
                                size="small"
                                label="Nome do usuário"
                                error={!!errors.name}
                                defaultValue={currentUserInformations.name}
                                {...register("name")}
                            />
                            <InputGeneric
                                className="inputs"
                                size="small"
                                label="Digite o email"
                                error={!!errors.email}
                                defaultValue={currentUserInformations.email}
                                {...register("email")}
                            />
                        </div>
                        <div className="inputsContainer">
                            <InputGeneric
                                className="inputs"
                                size="small"
                                label="Nova senha"
                                type="password"
                                error={!!errors.password}
                                placeholder="Mudança Opcional"
                                {...register("password")}
                            />
                            <InputGeneric
                                className="inputs"
                                size="small"
                                label="Confirme a senha"
                                type="password"
                                error={!!errors.confirmPassword}
                                {...register("confirmPassword")}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="role_id"
                                rules={{ required: "Campo requerido" }}
                                render={({ field: { onChange } }) => (
                                    <MultiSelect
                                        isOptionEqualToValue={(
                                            optionId,
                                            valueId
                                        ) => optionId.id === valueId.id}
                                        autoComplete={false}
                                        loading={isLoadingMultiSelect}
                                        loadingText="Carregando cargos..."
                                        defaultValue={selectedRowUser.roles}
                                        onChange={(event, item) => {
                                            onChange(item);
                                        }}
                                        multiple
                                        limitTags={1}
                                        filterSelectedOptions
                                        options={dataRolesList}
                                        getOptionLabel={(option) =>
                                            typeof option === "object"
                                                ? option.name
                                                : ""
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                className="multiSelectInput"
                                                {...params}
                                                variant="outlined"
                                                label="Selecione os cargos"
                                                size="small"
                                                error={!!errors.role_id}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                className="button"
                                onclick={() => {
                                    handleCloseEditUserModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoadingButton}
                                text="CONFIRMAR"
                                type="submit"
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </ModalEditUserStyle>
        </Modal>
    );
}
export default ModalEditUser;
