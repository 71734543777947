import styled from "styled-components";

export const MonthlyBillingCustomerPage = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    max-width: 900px;

    display: flex;
    justify-content: center;
    margin: auto;
`;

export const Header = styled.div`
    width: 100%;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
`;

export const HeaderContainer = styled.div`
    width: 100%;
    max-width: 900px;
    padding: 5px 0;

    img {
        border-radius: 50%;
    }
`;

export const MonthlybillingTab = styled.div`
    width: 100%;
    background-color: #fff;
    margin: 20px;
    border-radius: 10px;
    padding: 10px;

    h2 {
        margin: 10px 0;
        font-size: 1.5rem;
    }

    p {
        font-size: 10px;
        margin: 6px;
    }
`;

export const AccountInformation = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 8px;
`;

export const AccountInfoTab = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    border-left: 2px solid #3d3d3d;
    flex: 1;
    h3 {
        font-size: 20px;
        font-weight: 600;
        margin: 2px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 2px;
    }
`;
export const MonthlyItem = styled.div`
    margin-top: 15px;
    background-color: #f6f6f6;
    display: flex;
    width: 100%;
    height: 60;
    font-size: 1rem;
    flex-wrap: wrap;

    .fixed-size {
        flex: 3;
    }

    .variant-size {
        flex: 1;
    }

    .strong {
        font-weight: 600;
    }

    div {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .button-container {
        display: flex;
        justify-content: right;
    }

    .monthly-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .money {
        font-weight: 600;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin: 2px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 2px;
    }
`;

export const ContentContactSupport = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 425px) {
        font-size: 14px;
    }
`;
