/* eslint-disable */

import { Modal, TextField, AlertColor } from "@mui/material";

import { useState, ChangeEvent } from "react";

import {
    Container,
    TitleModal,
    ContentVideo,
    LabelText,
    ContentLink,
    ContentButton,
} from "./styles";

import ButtonGeneric from "../ButtonGeneric";

import { useResizeLogo } from "../../helpers/useResizeImage";

import { VideosProps } from "../../pages/NewPublicity/types";
import { useCheckUrlValid } from "../../helpers/useCheckUrlValid";

interface ModalAddNewVideoProps {
    open: boolean;
    setOpen: (data: boolean) => void;
    listVideos: VideosProps[];
    setListVideos: (data: any) => void;
    setOpenToast: (data: boolean) => void;
    setTextToast: (data: string) => void;
    setToastRequisitionResult: (data: AlertColor | undefined) => void;
}

function ModalAddNewVideo({
    open,
    setOpen,
    setOpenToast,
    listVideos,
    setListVideos,
    setTextToast,
    setToastRequisitionResult,
}: ModalAddNewVideoProps) {
    const [urlThumbnail, setUrlThumbnail] = useState<string | null>(null);
    const [link, setLink] = useState<string>("");

    const handleAddVideo = () => {
        // if (!urlThumbnail) {
        //     setOpenToast(true);
        //     setTextToast("Escolha a foto de apresentação do vídeo");
        //     setToastRequisitionResult("warning");
        //     return;
        // }

        if (link.length <= 0) {
            setOpenToast(true);
            setTextToast("Insira o link de redirecionamento do vídeo");
            setToastRequisitionResult("warning");
            return;
        }

        if (!useCheckUrlValid(link)) {
            setOpenToast(true);
            setTextToast("Link inserido é inválido");
            setToastRequisitionResult("warning");
            return;
        }

        const objectVideo = {
            link,
        };

        setListVideos([...listVideos, objectVideo]);
        setOpenToast(true);
        setTextToast("Vídeo adicionado com sucesso!");
        setToastRequisitionResult("success");
        setOpen(false);
        setUrlThumbnail(null);
        setLink("");
    };

    const handleChangeBanner = (data: ChangeEvent<HTMLInputElement>) => {
        if (data.target.files) {
            const files = data.target.files[0];
            useResizeLogo(files, 512, 512, "JPG", "base64")
                .then((response: any) => {
                    if (response) {
                        setUrlThumbnail(response);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-add-new-video"
        >
            <Container>
                <TitleModal>Adicionar vídeo</TitleModal>

                <ContentVideo>
                    {/* <LabelText>Foto para apresentação do vídeo:</LabelText>
                    <ThumbVideo
                        htmlFor="thumb-video"
                        style={{
                            backgroundImage: `url("${
                                urlThumbnail
                                    ? urlThumbnail
                                    : pathThumbnailDefault
                            }")`,
                        }}
                    /> */}
                    <input
                        type="file"
                        id="thumb-video"
                        onChange={handleChangeBanner}
                    />
                </ContentVideo>

                <ContentLink>
                    <LabelText>Link para redirecionamento:</LabelText>
                    <TextField
                        fullWidth
                        id="link-video"
                        label="Link"
                        variant="outlined"
                        size="small"
                        onChange={(event) => setLink(event.target.value)}
                        value={link}
                    />
                </ContentLink>
                <ContentButton>
                    <ButtonGeneric
                        buttonColor="var(--primary)"
                        text="Adicionar"
                        onclick={handleAddVideo}
                    />
                </ContentButton>
            </Container>
        </Modal>
    );
}

export default ModalAddNewVideo;
