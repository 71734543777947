// import { AlertColor } from "@mui/material";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";

import ConfirmedImage from "../../assets/images/confirmed-image.png";
// import PreloadFb from "../../components/Preload";
// import Toast from "../../components/Toast";
// import { api } from "../../services/api";
// import { ContractAssignProps } from "../ContractSingningPage";
import { ContractSignedPageStyles } from "./styles";

function ContractSignedPage() {
    // const urlParams = new URLSearchParams(window.location.search);
    // const myParam = urlParams.get("token");

    // const [contractInformations, setContractInformations] =
    //     useState<ContractAssignProps>();

    // toast configs
    // const [openToast, setOpenToast] = useState(false);
    // function handleCloseToast() {
    //     setOpenToast(false);
    // }
    // const [toastRequisitionResult, setToastRequisitionResult] =
    //     useState<AlertColor>();
    // const [textToast, setTextToast] = useState("");

    // const [isLoading, setIsLoading] = useState(true);

    // const navigate = useNavigate();

    // useEffect(() => {
    //     api.get("/contractSignatories/assign", {
    //         headers: {
    //             Authorization: `Bearer ${myParam}`,
    //         },
    //     })
    //         .then((response) => {
    //             setContractInformations(response.data.response);
    //             if (response.data.response.signed_at) {
    //                 setIsLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             setOpenToast(true);
    //             setToastRequisitionResult("error");
    //             setTextToast(error.response.data.message);
    //             navigate(`/contractSignatories/assign?token=${myParam}`, {
    //                 replace: true,
    //             });
    //         });
    // }, []);

    return (
        <>
            <ContractSignedPageStyles>
                {/* {isLoading ? (
                    <PreloadFb />
                ) : ( */}
                <div className="messageContainer">
                    <h2>
                        {/* Contrato assinado por{" "}
                            {contractInformations?.holder.full_name} */}
                        Contrato assinado com sucesso!
                    </h2>
                    <img
                        className="contractSignedImageStatus"
                        src={ConfirmedImage}
                        alt=""
                    />
                </div>
                {/* )} */}
            </ContractSignedPageStyles>
            {/* <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            /> */}
        </>
    );
}
export default ContractSignedPage;
