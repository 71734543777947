import imageError from "../../assets/images/errorImage.png";
import { ErrorPublicPageStyle } from "./styles";

function ErrorPublicPage() {
    return (
        <ErrorPublicPageStyle>
            <img className="imagemContent" src={imageError} alt="warning img" />
            <h2 className="informationsText">
                Ei, não sei o que você está procurando, mas essa rota não
                existe, tente utilizar um link válido ou verifique se você
                digitou o endereço corretamente.
            </h2>
        </ErrorPublicPageStyle>
    );
}

export default ErrorPublicPage;
