import styled from "styled-components";

export const ErrorPageStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .imageContent {
        width: 15%;
        min-width: 350px;
    }
    @media (max-width: 700px) {
        @media (max-width: 400px) {
        }
    }
`;
