export interface ConstantsProps {
    appTypes: {
        mobilityDriver: {
            id: number;
            name: string;
        };
        mobilityCustomer: {
            id: number;
            name: string;
        };
        deliveryCustomer: {
            id: number;
            name: string;
        };
        deliveryAdmin: {
            id: number;
            name: string;
        };
    };
}

export const Constants: ConstantsProps = {
    appTypes: {
        mobilityDriver: {
            id: 1,
            name: "Mobilidade - Motorista",
        },
        mobilityCustomer: {
            id: 2,
            name: "Mobilidade - Passageiro",
        },
        deliveryCustomer: {
            id: 3,
            name: "Delivery - Cliente",
        },
        deliveryAdmin: {
            id: 4,
            name: "Delivery - Gestor",
        },
    },
};
