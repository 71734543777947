/* eslint-disable */
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Switch,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    AlertColor,
    IconButton,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

import { useState, useEffect } from "react";
import { FormCouponsPage } from "./styles";

import { api } from "../../services/api";

import PageWrapper from "../../components/PageWrapper/index";
import PreloadFb from "../../components/Preload";
import InputGeneric from "../../components/InputGeneric";
import ButtonGeneric from "../../components/ButtonGeneric";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { If } from "../../components/If";

import { TransformDateEUA } from "../../helpers/transformDateEUA";
import { setMaskMoney } from "../../helpers/maskMoney";
import { setRemoveMaskMoney } from "../../helpers/removeMaskMoney";

import { UsersProps } from "./types";

import { useAuth } from "../../contexts/contexts";

export type holderFormTypeValues = {
    type_id: number;
    key?: string;
    value?: string;
    expires_at: string | Date;
    max_uses: number;
    enabled: boolean;
    users: number[];
};

const holderFormSchema = yup.object().shape({
    type_id: yup.string().required("Campo tipo de cupom"),
    key: yup.string().required("Campo código é obrigatório").uppercase(),
    value: yup.string().required("Campo valor do cupom é obrigatório"),
    expires_at: yup.string().required("Campo data de expiração é obrigatório"),
    max_uses: yup
        .string()
        .required("Campo quantidade de vezes a ser usada é obrigatório"),
    enabled: yup.boolean().required("Campo status é obrigatório"),
    users: yup.string(),
});

function CouponsRegisterPage() {
    const { handleSubmit, control, setValue } = useForm<holderFormTypeValues>({
        resolver: yupResolver(holderFormSchema),
    });

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [typeValue, setTypeValue] = useState<"percentual" | "fixe">(
        "percentual"
    );

    const [openToast, setOpenToast] = useState(false);
    const [textToast, setTextToast] = useState("");
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [listUsers, setListUsers] = useState<UsersProps[]>([]);

    const [userSelected, setUserSelected] = useState<UsersProps>();

    const [listUsersSelected, setListUsersSelected] = useState<UsersProps[]>(
        []
    );

    const getUsers = async () => {
        setLoading(true);
        try {
            const responseUsers = await api.get("users/all");

            if (responseUsers.status) {
                setListUsers(responseUsers.data.response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveCoupon: SubmitHandler<holderFormTypeValues> = async (
        datas
    ) => {
        setLoadingButton(true);
        try {
            const { enabled, expires_at, max_uses, type_id, key, value } =
                datas;

            const expirateAtFormated = TransformDateEUA(expires_at as string);

            const idsUsersSelected = listUsersSelected.map((value) => {
                return value.id;
            });

            const valueFormated = setRemoveMaskMoney(value as string);

            const responseCreateCoupon = await api.post("coupons", {
                type_id,
                key,
                value:
                    typeValue === "percentual" ? Number(value) : valueFormated,
                expires_at: expirateAtFormated,
                max_uses,
                enabled,
                users: idsUsersSelected,
            });

            if (responseCreateCoupon.status) {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast("Cupom criado com sucesso!");

                setTimeout(() => {
                    navigate("/settings/cupons");
                    setOpenToast(false);
                }, 2000);
            }
        } catch (error: any) {
            console.error(error);
            if (error.response.status >= 400 && error.response.status <= 500) {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setLoadingButton(false);
            } else {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("Erro ao criar cupom");
                setLoadingButton(false);
            }
        } finally {
            setLoadingButton(false);
        }
    };

    const handleAddUser = () => {
        if (userSelected) {
            if (listUsersSelected.includes(userSelected)) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Este usuário já foi adicionado");
                return;
            }

            setListUsersSelected([...listUsersSelected, userSelected]);
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Escolha um usuário");
        }
    };

    const removeUser = (id: number) => {
        setListUsersSelected(
            listUsersSelected.filter((data) => data.id !== id)
        );
    };

    useEffect(() => {
        setValue("enabled", true);
        getUsers();
    }, []);

    useEffect(() => {
        if (
            hasPermissions("cupom.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    return (
        <>
            <PageWrapper>
                {loading ? (
                    <PreloadFb />
                ) : (
                    <FormCouponsPage>
                        <div>
                            <h3>Novo Cupom</h3>
                        </div>

                        <form onSubmit={handleSubmit(handleSaveCoupon)}>
                            <div className="content">
                                <Controller
                                    control={control}
                                    name="key"
                                    render={({
                                        field: {
                                            value = "",
                                            ref,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <InputGeneric
                                            fullWidth
                                            value={value}
                                            size="small"
                                            className="smallInput"
                                            label="Código"
                                            onChange={(event) => {
                                                setValue("key", event.target.value.toUpperCase());
                                            }}
                                            error={Boolean(error)}
                                            inputRef={ref}
                                            inputProps={{ maxLength: 18 }}
                                            helperText={error?.message}
                                            {...field}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="max_uses"
                                    render={({
                                        field: {
                                            value,
                                            ref,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <InputGeneric
                                            {...field}
                                            type="number"
                                            fullWidth
                                            value={value}
                                            size="small"
                                            className="smallInput"
                                            label="Quantidade de vezes a ser usada?"
                                            onChange={onChange}
                                            error={Boolean(error)}
                                            inputRef={ref}
                                            inputProps={{ maxLength: 18 }}
                                            helperText={error?.message}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="value"
                                    render={({
                                        field: {
                                            value,
                                            ref,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            {typeValue === "percentual" ? (
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div className="icon-input">
                                                        %
                                                    </div>
                                                    <InputGeneric
                                                        {...field}
                                                        fullWidth
                                                        type="number"
                                                        value={value}
                                                        size="small"
                                                        className="smallInput"
                                                        label="Valor"
                                                        onChange={(event) => {
                                                            if (
                                                                Number(
                                                                    event.target
                                                                        .value
                                                                ) > 100
                                                            ) {
                                                                setValue(
                                                                    "value",
                                                                    "100"
                                                                );
                                                            } else {
                                                                setValue(
                                                                    "value",
                                                                    event.target
                                                                        .value
                                                                );
                                                            }
                                                        }}
                                                        error={Boolean(error)}
                                                        inputRef={ref}
                                                        inputProps={{
                                                            maxLength: 18,
                                                            max: 100,
                                                            min: 0,
                                                        }}
                                                        helperText={
                                                            error?.message
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div className="icon-input">
                                                        R$
                                                    </div>
                                                    <InputGeneric
                                                        {...field}
                                                        fullWidth
                                                        value={value}
                                                        size="small"
                                                        className="smallInput"
                                                        label="Valor"
                                                        onChange={(event) => {
                                                            setValue(
                                                                "value",
                                                                setMaskMoney(
                                                                    event.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                        error={Boolean(error)}
                                                        inputRef={ref}
                                                        inputProps={{
                                                            maxLength: 18,
                                                        }}
                                                        helperText={
                                                            error?.message
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="content">
                                <Controller
                                    control={control}
                                    name="type_id"
                                    defaultValue={1}
                                    render={({
                                        field: {
                                            value,
                                            ref,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl fullWidth>
                                            <InputLabel
                                                size="small"
                                                id="type-coupon-select-label"
                                            >
                                                Tipo de cupom
                                            </InputLabel>
                                            <Select
                                                {...field}
                                                labelId="type-coupon-select-label"
                                                id="type-coupon-select"
                                                value={value}
                                                label="Tipo de cupom"
                                                onChange={(event) => {
                                                    setValue(
                                                        "type_id",
                                                        event.target
                                                            .value as any
                                                    );
                                                    setValue("value", "");
                                                    setTypeValue(
                                                        event.target.value === 1
                                                            ? "percentual"
                                                            : "fixe"
                                                    );
                                                    console.log(event.target.value);
                                                }}
                                                size="small"
                                                error={Boolean(error)}
                                            >
                                                <MenuItem value={1}>
                                                    Percentual
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Valor Fixo
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText
                                                error={Boolean(error)}
                                            >
                                                {error?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="expires_at"
                                    render={({
                                        field: { value, ref, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <DatePicker
                                                value={value}
                                                onChange={onChange}
                                                ref={ref}
                                                defaultValue={""}
                                                label="Data de expiração"
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        fullWidth: true,
                                                        helperText:
                                                            error?.message,
                                                        error: Boolean(error),
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="enabled"
                                    render={({
                                        field: {
                                            value = true,
                                            ref,
                                            onChange,
                                            ...field
                                        },
                                    }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                {...field}
                                                value={value}
                                                onChange={onChange}
                                                control={
                                                    <Switch defaultChecked />
                                                }
                                                label="Habilitado?"
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </div>

                            <div className="content">
                                <FormControl fullWidth>
                                    <InputLabel
                                        size="small"
                                        id="users-select-label"
                                    >
                                        Usuários
                                    </InputLabel>
                                    <Select
                                        labelId="users-select-label"
                                        id="users-select"
                                        label="Usuários"
                                        size="small"
                                        onChange={(event) =>
                                            setUserSelected(
                                                JSON.parse(
                                                    event.target.value as any
                                                )
                                            )
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {listUsers.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value.id}
                                                    value={JSON.stringify(
                                                        value
                                                    )}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                <ButtonGeneric
                                    onclick={handleAddUser}
                                    buttonColor="var(--primary)"
                                    text="Adicionar"
                                    icon={<Add />}
                                />
                            </div>

                            <div className="content-list-users">
                                {listUsersSelected.map((value) => {
                                    return (
                                        <div key={value.id}>
                                            <span>{value?.name}</span>
                                            <IconButton
                                                onClick={() =>
                                                    removeUser(value.id)
                                                }
                                            >
                                                <Delete
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "var(--delete)",
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="content-buttons">
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate("/settings/cupons");
                                    }}
                                    buttonColor="var(--cancel)"
                                    text="VOLTAR"
                                />

                                <PreloadButton
                                    colorText="white"
                                    background="var(--confirm)"
                                    loading={loadingButton}
                                    text="ENVIAR"
                                    type="submit"
                                />
                            </div>
                        </form>
                        <Toast
                            open={openToast}
                            onClose={() => setOpenToast(false)}
                            severity={toastRequisitionResult}
                            text={textToast}
                        />
                    </FormCouponsPage>
                )}
            </PageWrapper>
        </>
    );
}

export default CouponsRegisterPage;
