import {
    Add,
    Delete,
    Receipt,
    Visibility,
    Send,
    NextWeek,
    DoDisturb,
} from "@mui/icons-material";
import { AlertColor, Pagination, Tooltip } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteProposal from "../../components/DeleteProposalModal";
import { If } from "../../components/If";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import ConfirmModalDefault from "../../components/Modal/ConfirmModalDefault";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper/index";
import ModalProposalInvoice from "../../components/ProposalInvoiceModal";
import ModalSendSavedProposal from "../../components/SendSavedProposalModal";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { ProposalPaymentStatusChip } from "../../helpers/statusChip/ProposalPaymentStatusChip";
import {
    ProposalStatusChip,
    getLabel,
} from "../../helpers/statusChip/ProposalStatusChip";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { ContentTable, ContentPagination } from "./styles";

export interface ProposalsProps {
    color: string;
    description: string;
    id: number;
    name: string;
}

let last_page: number | null = null;

function getFirstTwoNames(fullName: string | null | undefined): string {
    if (!fullName) return "";
    const names = fullName.split(" ");
    if (names.length < 2 || names[1].length === 2) {
        return names.slice(0, 3).join("");
    }
    return `${names[0]} ${names[1]}`;
}

function ProposalPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("propostas.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataProposalsList, setDataProposalsList] = useState<
        ProposalsProps[]
    >([]);
    const [openDeleteProposalModal, setOpenDeleteProposalModal] =
        useState(false);
    const handleCloseDeleteProposalModal = () => {
        setOpenDeleteProposalModal(false);
    };
    const [selectedRowProposal, setSelectedRowProposal] = useState<any>({});

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [openProposalInvoiceModal, setOpenProposalInvoiceModal] =
        useState(false);
    const handleCloseProposalInvoiceModal = () => {
        setOpenProposalInvoiceModal(false);
    };

    const [openSendSavedProposalModal, setOpenSendSavedProposalModal] =
        useState(false);

    const handleCloseSendSavedProposalModal = () => {
        setOpenSendSavedProposalModal(false);
    };

    const getProposals = async (currentPage = 1) => {
        try {
            setLoadingTable(true);
            const responseProposals = await api.get(
                `/proposal?page=${currentPage}`
            );

            if (responseProposals.status) {
                setLoadingTable(false);
                last_page = responseProposals.data.response.last_page;
                setDataProposalsList(responseProposals.data.response.data);
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            window.location.reload();
        } finally {
            setLoadingTable(false);
        }
    };

    const [openCancelProposalModal, setOpenCancelProposalModal] =
        useState(false);

    function cancelProposal() {
        api.post(`/proposal/cancel/${selectedRowProposal.id}`)
            .then((response) => {
                defaultToast("success", response.data.message);
                getProposals(1);
            })
            .catch((error) => {
                defaultToast("error", error.response.data.message);
            });
    }

    useEffect(() => {
        getProposals();
    }, []);

    const columns: GridColDef[] = [
        {
            field: "holder",
            headerName: "Nome do Titular",
            flex: 12,
            valueGetter: (params: GridValueGetterParams) =>
                `${getFirstTwoNames(params.row.holder?.name)}`,
            renderCell: (params: GridRenderCellParams) => (
                <strong>{getFirstTwoNames(params.row.holder?.name)}</strong>
            ),
        },
        {
            field: "value",
            headerName: "Valor da Proposta",
            flex: 10,
            align: "center",
            valueGetter: (params: GridValueGetterParams) =>
                `R$ ${formatCurrencyToShow(
                    params.row?.payment[0]?.total_amount
                )}`,
            renderCell: (params: GridRenderCellParams) => (
                <p style={{ color: "green" }}>
                    {`R$ ${formatCurrencyToShow(
                        params.row?.payment[0]?.total_amount
                    )}`}
                </p>
            ),
        },
        {
            field: "vendedor",
            headerName: "Vendedor",
            flex: 10,
            valueGetter: (params: GridRenderCellParams) =>
                `${getFirstTwoNames(params.row.user?.name)}`,
            renderCell: (params: GridRenderCellParams) => (
                <strong>{getFirstTwoNames(params.row.user?.name)}</strong>
            ),
        },
        {
            field: "created_at",
            headerName: "Data da Proposta",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.created_at
                    .slice(0, -17)
                    .split("-")
                    .reverse()
                    .join("/")}`,
        },
        {
            field: "due_date",
            headerName: "Data do Vencimento",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.payment[params.row.payment.length - 1]?.due_date
                    .slice(0, -9)
                    .split("-")
                    .reverse()
                    .join("/")}`,
            renderCell: (params: GridRenderCellParams) => (
                <p>
                    {params.row.payment[params.row.payment.length - 1]?.due_date
                        .slice(0, -9)
                        .split("-")
                        .reverse()
                        .join("/")}
                </p>
            ),
        },
        {
            field: "proposal_status",
            headerName: "Status da Proposta",
            flex: 10,
            align: "center",
            valueGetter: (params: GridRenderCellParams) =>
                `${getLabel(params.row.proposal_status_id)}`,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    <ProposalStatusChip
                        id={params.row.proposal_status_id ?? 5}
                    />
                </span>
            ),
        },
        {
            field: "payment_status",
            headerName: "Status do Pagamento",
            flex: 10,
            align: "center",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ProposalPaymentStatusChip
                        id={
                            params.row.payment[params.row.payment.length - 1]
                                ?.payment_status?.id ?? 5
                        }
                    />
                </span>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 15,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If
                        condition={
                            hasPermissions("propostas.cadastrar") &&
                            params.row.proposal_status.name === "Salva"
                        }
                    >
                        <Send
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenSendSavedProposalModal(true);
                            }}
                        />
                    </If>
                    <If
                        condition={hasPermissions("nota.fiscal.gerar.proposta")}
                    >
                        <Receipt
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenProposalInvoiceModal(true);
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("propostas.detalhar")}>
                        <Visibility
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/comercial/proposal/proposalDetails?id=${params.id}`
                                );
                            }}
                        />
                    </If>

                    <If
                        condition={
                            hasPermissions("propostas.excluir") &&
                            params.row.proposal_status.name !== "Paga"
                        }
                    >
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteProposalModal(true);
                            }}
                        />
                    </If>
                    <If
                        condition={
                            hasPermissions("propostas.excluir") &&
                            params.row.proposal_status.id === 2
                        }
                    >
                        <Tooltip title="cancelar proposta e contrato">
                            <DoDisturb
                                className="cursorPointerGlobal"
                                onClick={() => {
                                    setOpenCancelProposalModal(
                                        !openCancelProposalModal
                                    );
                                    console.log(selectedRowProposal);
                                }}
                            />
                        </Tooltip>
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <PageDefault
                    headerPage
                    header={{
                        title: "Propostas",
                        subtitle: "Lista de propostas",
                        icon: <NextWeek sx={{ color: "#fff" }} />,
                    }}
                >
                    <div className="containerListTablePagesGlobal">
                        <div className="containerButtonNewRegisterListTablePagesGlobal">
                            <If
                                condition={hasPermissions(
                                    "propostas.cadastrar"
                                )}
                            >
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate(
                                            "/comercial/proposal/newProposal"
                                        );
                                    }}
                                    buttonColor="var(--secondary)"
                                    text="Nova Proposta"
                                    icon={<Add />}
                                />
                            </If>
                        </div>
                        <ContentTable>
                            <TableDataGrid
                                style={{ minWidth: "460px !important" }}
                                rows={dataProposalsList}
                                columns={columns}
                                onRowClick={(rowParams: GridRowParams) => {
                                    setSelectedRowProposal(rowParams.row);
                                }}
                                loading={loadingTable}
                                hideFooterPagination
                            />
                        </ContentTable>
                        <ContentPagination>
                            <Pagination
                                color="primary"
                                count={last_page as number}
                                onChange={(event: any) =>
                                    getProposals(
                                        Number(event.target.textContent)
                                    )
                                }
                                hideNextButton
                                hidePrevButton
                            />
                        </ContentPagination>
                        <ModalDeleteProposal
                            openDeleteProposalModal={openDeleteProposalModal}
                            handleCloseDeleteProposalModal={
                                handleCloseDeleteProposalModal
                            }
                            selectedRowProposal={selectedRowProposal}
                            dataProposalsList={dataProposalsList}
                            setDataProposalsList={setDataProposalsList}
                        />
                        <ModalProposalInvoice
                            openProposalInvoiceModal={openProposalInvoiceModal}
                            handleCloseProposalInvoiceModal={
                                handleCloseProposalInvoiceModal
                            }
                            selectedRowProposal={selectedRowProposal}
                        />
                        <ModalSendSavedProposal
                            openSendSavedProposalModal={
                                openSendSavedProposalModal
                            }
                            handleCloseSendSavedProposalModal={
                                handleCloseSendSavedProposalModal
                            }
                            selectedRowProposal={selectedRowProposal}
                            setDataProposalsList={setDataProposalsList}
                            setLoadingTable={setLoadingTable}
                        />
                        <ConfirmModalDefault
                            loading={false}
                            onClick={() => {
                                cancelProposal();
                            }}
                            isOpen={openCancelProposalModal}
                            handleClose={() => {
                                setOpenCancelProposalModal(false);
                            }}
                            buttonColor="error"
                            title="Realmente deseja cancelar a proposta? essa ação não pode ser desfeita!"
                        />
                    </div>
                </PageDefault>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default ProposalPage;
