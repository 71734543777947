import { TypeOptions, toast } from "react-toastify";

export const defaultToast = (type: TypeOptions, message: string) => {
    toast(message, {
        type,
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
    });
};
