import { Done, Warning, Error, Info } from "@mui/icons-material";
import { Modal, Button } from "@mui/material";

import { Content, Text, ContentIcon, ContentButton } from "./styles";

type TypeModal = "success" | "warning" | "error" | "info";

interface ModalAlertProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
    text: string;
    typeModal: TypeModal;
}

export default function ModalAlert({
    visible,
    setVisible,
    text,
    typeModal,
}: ModalAlertProps) {
    const switchIcon = () => {
        switch (typeModal) {
            case "success":
                return (
                    <Done
                        sx={{
                            fontSize: 40,
                        }}
                        color="success"
                    />
                );
            case "warning":
                return (
                    <Warning
                        sx={{
                            fontSize: 40,
                        }}
                        color="warning"
                    />
                );
            case "error":
                return (
                    <Error
                        sx={{
                            fontSize: 40,
                        }}
                        color="error"
                    />
                );
            case "info":
                return (
                    <Info
                        sx={{
                            fontSize: 40,
                        }}
                        color="info"
                    />
                );

            default:
                return "";
        }
    };

    return (
        <Modal
            open={visible}
            onClose={() => setVisible(false)}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Content>
                <ContentIcon>{switchIcon()}</ContentIcon>
                <Text>{text}</Text>
                <ContentButton>
                    <Button
                        variant="contained"
                        onClick={() => setVisible(false)}
                    >
                        Fechar
                    </Button>
                </ContentButton>
            </Content>
        </Modal>
    );
}
