import styled from "styled-components";

export const PdfViewerStyle = styled.div`
    width: 85%;
    height: 100%;

    .linkPdf {
        color: white;
        transition: 0.3s;
        text-decoration: none;
    }

    .linkPdf:hover {
        text-decoration: underline;
    }

    border: 1px solid #7c8791;
    .pdfContainer {
        width: 100%;
        height: 100%;
        margin-bottom: 50px;
    }
    @media (max-width: 1100px) {
        height: 600px;

        @media (max-width: 600px) {
            width: 96%;
        }
    }
`;
