import {
    Button,
    Popover,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    IconButton,
} from "@mui/material";
import { FilterAlt, Search } from "@mui/icons-material";

import { useState, MouseEvent, useEffect } from "react";

import { Container, ContentFilter } from "./styles";

import { ItemsSelectProps, ValueProps } from "./types";

interface ButtonFilterProps {
    items: ItemsSelectProps[];
    labelSelect: string;
    onClick: () => void;
    value: ValueProps;
    setValue: (data: ValueProps) => void;
}

export default function ButtonFilter({
    items,
    labelSelect,
    onClick,
    setValue,
    value,
}: ButtonFilterProps) {
    const [openFilter, setOpenFilter] = useState<HTMLButtonElement | null>(
        null
    );

    const handleOpenFilter = (event: MouseEvent<HTMLButtonElement>) => {
        setOpenFilter(event.currentTarget);
    };

    const open = Boolean(openFilter);
    const id = open ? "simple-popover" : undefined;

    return (
        <Container>
            <Button variant="contained" size="small" onClick={handleOpenFilter}>
                <FilterAlt />
                Filtrar
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={openFilter}
                onClose={() => setOpenFilter(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <ContentFilter>
                    <FormControl
                        sx={{
                            width: 270,
                        }}
                    >
                        <InputLabel id="select-btn-filter-label">
                            {labelSelect}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="select-btn-filter-label"
                            id="select-btn-filter"
                            label={labelSelect}
                            value={value.key}
                            onChange={(event) =>
                                setValue({
                                    key: event.target.value,
                                    value: value?.value,
                                })
                            }
                        >
                            {items.map((value) => {
                                return (
                                    <MenuItem
                                        key={value.value}
                                        value={value.value}
                                    >
                                        {value.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Pesquisa"
                        name="name-holder"
                        size="small"
                        value={value.value}
                        onChange={(event) => {
                            setValue({
                                key: value.key,
                                value: event.target.value,
                            });
                        }}
                    />
                    <Button variant="contained" size="small" onClick={onClick}>
                        <Search
                            sx={{
                                color: "#ffffff",
                            }}
                        />
                    </Button>
                </ContentFilter>
            </Popover>
        </Container>
    );
}
