import styled from "styled-components";

export const GraphDiv = styled.div`
    width: 100%;
    height: 400px;
`;
export const Grid = styled.div`
    width: 100%;
    height: 600px;
`;
export const Header = styled.div``;
