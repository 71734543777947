import styled from "styled-components";

export const InvoiceContent = styled.div`
    .product {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 20px 0px;
        border-bottom: 2px dashed #ccc;
    }

    .product-div {
        width: 50%;
        flex-direction: row;
    }

    .info {
        font-weight: 400;
        margin: 7px;
    }

    .bottom {
        display: flex;
        justify-content: right;
    }
`;
