import { CreditCard } from "@mui/icons-material";
import { Divider, Chip } from "@mui/material";

import amex from "../../../assets/brands_cards/amex.svg";
import code from "../../../assets/brands_cards/code.svg";
import elo from "../../../assets/brands_cards/elo.svg";
import mastercard from "../../../assets/brands_cards/mastercard.svg";
import visa from "../../../assets/brands_cards/visa.svg";
import { CardContent, CardItem } from "./styles";

interface CardProps {
    last_digits: string;
    brand_id: number;
    is_linked_customer: number | boolean;
    brand: {
        name: string;
    };
}

interface Props {
    cards: CardProps[];
}

export function CustomerCards({ cards }: Props) {
    const brands = [
        {
            id: 2,
            name: "Visa",
            image: visa,
        },
        {
            id: 1,
            name: "Mastercard",
            image: mastercard,
        },
        {
            id: 3,
            name: "American Express",
            image: amex,
        },
        {
            id: 4,
            name: "Elo",
            image: elo,
        },
    ];

    return (
        <CardContent>
            <h2>
                Cartões Salvos &nbsp;
                <CreditCard fontSize="medium" />
            </h2>
            <Divider />
            {cards.map((card) => {
                return (
                    <CardItem>
                        <div className="brand">
                            <img
                                src={
                                    brands.find(
                                        (brand) => brand.id === card.brand_id
                                    )?.image ?? code
                                }
                                alt=""
                                className="brand-img"
                            />
                            <span>
                                {card.brand.name[0].toUpperCase() +
                                    card.brand.name.substring(1)}
                            </span>
                        </div>
                        <span className="linked">
                            {card.is_linked_customer === 1
                                ? "Vinculado ao Cliente"
                                : "Vinculado ao Titular"}
                        </span>
                        <span>Terminado em {card.last_digits}</span>
                    </CardItem>
                );
            })}
            {cards.length === 0 && (
                <CardItem>
                    <div className="brand">
                        <img src={code} alt="" className="brand-img" />
                        <span>Nenhum cartão cadastrado</span>
                    </div>
                    <span className="linked">Sem Vinculo</span>
                    <span>Terminado em 0000</span>
                </CardItem>
            )}
        </CardContent>
    );
}
