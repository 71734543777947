/* eslint-disable */

import {
    Modal,
    Box,
    TextField,
    FormControl,
    Select,
    FormHelperText,
    MenuItem,
    InputLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    FormControlLabel,
    AlertColor,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";

import {
    Container,
    TitleModal,
    ContentInput,
    ContentButtonSave,
} from "./styles";

import PreloadButton from "../PreloadButton";

import { ConfigNotificationsProps } from "../../pages/ConfigNotificationsPage/types";

import {
    TypesNotificationsProps,
    DateReferencesProps,
} from "../../pages/ConfigAddNotificationsPage/types";

import { api } from "../../services/api";
import Toast from "../Toast";

const schemaForms = yup.object({
    titleMessage: yup.string().required("O campo título é obrigatório"),
    bodyMessage: yup.string().required("O campo mensagem é obrigatório"),
    when: yup.string().required("O campo quantidade de dias é obrigatório"),
    dateReference: yup
        .number()
        .required("O campo de data de referência é obrigatório"),
    type: yup.number().required("O campo tipo de notificação é obrigatório"),
    sendTo: yup
        .object()
        .shape({
            email: yup.boolean(),
            sms: yup.boolean(),
            whatsapp: yup.boolean(),
        })
        .required("O campo de modos de envio é obrigatório"),
});

interface ModalEditConfigNotificationProps {
    openModal: boolean;
    setOpenModal: (data: boolean) => void;
    typesNotifications: TypesNotificationsProps[];
    datesReferences: DateReferencesProps[];
    notificationSelected: ConfigNotificationsProps | null;
    getAllNotifications: () => void;
}

type SchemaFormData = yup.InferType<typeof schemaForms>;

function ModalEditConfigNotification({
    openModal,
    setOpenModal,
    datesReferences,
    typesNotifications,
    notificationSelected,
    getAllNotifications,
}: ModalEditConfigNotificationProps) {
    let objectFormSend = {
        email: !!notificationSelected?.details.email,
        sms: !!notificationSelected?.details.sms,
        whatsapp: !!notificationSelected?.details.whatsapp,
    };

    const [loading, setLoading] = useState<boolean>(false);

    const [openToast, setOpenToast] = useState<boolean>(false);
    const [textToast, setTextToast] = useState<string>("");
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();

    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const translateDateReference = (value: string) => {
        switch (value) {
            case "due_date":
                return "Data de Vencimento";
            case "closing_date":
                return "Data de Fechamento";
            default:
                return "not found";
        }
    };

    const handleSaveNotifications = async (data: SchemaFormData) => {
        try {
            setLoading(true);
            const responseUpdateNotifications = await api.put(
                `notifications/${notificationSelected?.id}`,
                {
                    title: data.titleMessage,
                    body: data.bodyMessage,
                    when: Number(data.when),
                    date_reference_id: data.dateReference,
                    type_id: data.type,
                    enabled: notificationSelected?.enabled,
                    vias: data.sendTo,
                }
            );

            if (responseUpdateNotifications.status) {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast("Configuração editada com sucesso");

                setTimeout(() => {
                    setOpenModal(false);
                    setOpenToast(false);
                    setLoading(false);
                    getAllNotifications();
                }, 2000);
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast("Erro ao atualizar notificação");

            setTimeout(() => {
                setOpenModal(false);
                setOpenToast(false);
                setLoading(false);
            }, 2000);
        }
    };

    const handleChangeFormsSend = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target as HTMLInputElement;

        if (value === "email") {
            objectFormSend.email
                ? (objectFormSend.email = false)
                : (objectFormSend.email = true);
        }

        if (value === "sms") {
            objectFormSend.sms
                ? (objectFormSend.sms = false)
                : (objectFormSend.sms = true);
        }

        if (value === "whatsapp") {
            objectFormSend.whatsapp
                ? (objectFormSend.whatsapp = false)
                : (objectFormSend.whatsapp = true);
        }

        setValue("sendTo", objectFormSend as any);
    };

    useEffect(() => {
        if (openModal && notificationSelected) {
            setValue("bodyMessage", notificationSelected?.body);
            setValue("dateReference", notificationSelected?.date_reference.id);
            setValue("titleMessage", notificationSelected?.title);
            setValue("when", String(notificationSelected?.when));
            setValue("type", notificationSelected?.type.id);
            setValue("sendTo", {
                email: notificationSelected.details.email,
                sms: notificationSelected.details.sms,
                whatsapp: notificationSelected.details.whatsapp,
            });
        }
    }, [openModal]);

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-edit-config-notifications"
        >
            <Container onSubmit={handleSubmit(handleSaveNotifications)}>
                <Box>
                    <TitleModal>Editar Notificação</TitleModal>

                    <ContentInput
                        style={{
                            display: "flex",
                            gap: 10,
                        }}
                    >
                        <Controller
                            control={control}
                            name="titleMessage"
                            render={({
                                field: { onChange, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    fullWidth
                                    ref={ref}
                                    name={name}
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    className="smallInput"
                                    label="Título da mensagem"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="when"
                            render={({
                                field: { onChange, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    type="number"
                                    fullWidth
                                    ref={ref}
                                    name={name}
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    className="smallInput"
                                    label="Quantos dias antes ou depois notificar, ex: 5, -9, 1"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                />
                            )}
                        />
                    </ContentInput>
                    <ContentInput>
                        <Controller
                            control={control}
                            name="type"
                            render={({
                                field: { name, onChange, ref, value },
                                fieldState: { error },
                            }) => (
                                <FormControl fullWidth error={Boolean(error)}>
                                    <InputLabel
                                        size="small"
                                        id="type-notifications-select-label"
                                    >
                                        Tipo de Notificação
                                    </InputLabel>
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        ref={ref}
                                        value={value}
                                        size="small"
                                        labelId="type-notifications-select-label"
                                        id="type-notifications-select"
                                        label="Tipo de Notificação"
                                    >
                                        {typesNotifications.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name.toLocaleUpperCase()}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />

                        <Controller
                            control={control}
                            name="dateReference"
                            render={({
                                field: { name, onChange, ref, value },
                                fieldState: { error },
                            }) => (
                                <FormControl fullWidth error={Boolean(error)}>
                                    <InputLabel
                                        size="small"
                                        id="date-reference-select-label"
                                    >
                                        Data Referente
                                    </InputLabel>
                                    <Select
                                        name={name}
                                        onChange={onChange}
                                        ref={ref}
                                        value={value}
                                        size="small"
                                        labelId="date-reference-select-label"
                                        id="date-reference-select"
                                        label="Data Referente"
                                    >
                                        {datesReferences.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {translateDateReference(
                                                        value.column_name
                                                    ).toLocaleUpperCase()}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </ContentInput>

                    <ContentInput>
                        <Controller
                            control={control}
                            name="sendTo"
                            render={({
                                field: { ref, value },
                                fieldState: { error },
                            }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormLabel>Formas de envio</FormLabel>
                                    <FormGroup
                                        row
                                        ref={ref}
                                        onChange={handleChangeFormsSend}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="email"
                                                    defaultChecked={value.email}
                                                />
                                            }
                                            label="Email"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="sms"
                                                    defaultChecked={value.sms}
                                                />
                                            }
                                            label="SMS"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value="whatsapp"
                                                    defaultChecked={
                                                        value.whatsapp
                                                    }
                                                />
                                            }
                                            label="Whatsapp"
                                        />

                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormGroup>
                                </div>
                            )}
                        />
                    </ContentInput>

                    <ContentInput>
                        <Controller
                            control={control}
                            name="bodyMessage"
                            render={({
                                field: { onChange, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    fullWidth
                                    ref={ref}
                                    name={name}
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    className="smallInput"
                                    label="Mensagem"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                    multiline
                                    rows={5}
                                />
                            )}
                        />
                    </ContentInput>

                    <ContentButtonSave>
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={loading}
                            text="EDITAR NOTIFICAÇÃO"
                            type="submit"
                            sx={{
                                whiteSpace: "nowrap",
                                fontSize: "13px !important",
                            }}
                        />
                    </ContentButtonSave>
                </Box>

                <Toast
                    open={openToast}
                    onClose={() => setOpenToast(false)}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </Container>
        </Modal>
    );
}

export default ModalEditConfigNotification;
