import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px 5px;
    height: 650px;
`;

export const CardsStyled = styled.div`
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    padding: 30px;
    gap: 20px;
    flex-wrap: 100px;

    @media (max-width: 700px) {
        display: none;
    }
`;

export const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .tableIcon {
        background-color: "#123345";
    }

    .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const ContentFilterTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 30px;

    @media (max-width: 1350px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const ContentBtnAddCustomer = styled.div`
    @media (max-width: 1350px) {
        width: 100%;
    }
`;
