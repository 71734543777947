import styled from "styled-components";

export const Container = styled.div``;

export const Form = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    gap: 1rem;

    > h2 {
        text-align: center;
    }
`;
