import { Download } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ModalDeleteProposal from "../../components/DeleteProposalModal";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface ProposalsProps {
    color: string;
    description: string;
    id: number;
    name: string;
}

function InvoicesPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("nota.fiscal.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataProposalsList, setDataProposalsList] = useState<
        ProposalsProps[]
    >([]);
    const [openDeleteProposalModal, setOpenDeleteProposalModal] =
        useState(false);
    const handleCloseDeleteProposalModal = () => {
        setOpenDeleteProposalModal(false);
    };
    const [selectedRowProposal, setSelectedRowProposal] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = `0${dataAtual.getMonth() + 1}`.slice(-2); // adiciona um zero à esquerda para os meses menores que 10
    const dia = `0${dataAtual.getDate()}`.slice(-2); // adiciona um zero à esquerda para os dias menores que 10

    const dataFormatada = `${ano}-${mes}-${dia}`;

    useEffect(() => {
        api.get(`/serviceInvoice/2023-04-01 00:00:00/${dataFormatada} 23:59:59`)
            .then((response) => {
                setLoadingTable(false);
                setDataProposalsList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [currentUrlPdf, setCurrentUrlPdf] = useState<string | any>("");
    // FUNÇÃO QUE CHECA SE O LINK DE FATO É VÁLIDO, SE SIM, FAZ O DOWNLOAD DO PDF DA NOTA, SE NÃO, EXIBE UMA MENSAGEM QUE NÃO FOI POSSÍVEL FAZER O DOWNLOAD
    const checkIfIsWebSiteAndDownloadPdf = () => {
        api.get(currentUrlPdf, {
            responseType: "blob",
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", currentUrlPdf?.split("/").pop());
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("Nota não disponível para download");
            });
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            flex: 5,
        },
        {
            field: "proposal",
            headerName: "Proposta",
            flex: 12,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.proposal_id}`,
            renderCell: (params: GridRenderCellParams) => (
                <p>{params.row.proposal_id}</p>
            ),
        },
        {
            field: "issued_on",
            headerName: "Data da Emissão",
            flex: 17,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.created_at
                    .slice(0, -17)
                    .split("-")
                    .reverse()
                    .join("/")}`,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 12,
            // renderCell: (params: GridRenderCellParams) => (
            //     <p>{params.row.holder.name}</p>
            // ),
        },
        {
            field: "pdf",
            headerName: "PDF",
            flex: 10,
            headerAlign: "center",
            sortable: false,
            align: "center",
            renderCell: (params: GridRenderCellParams) => (
                // params.row.download_link_pdf
                <button
                    type="button"
                    onFocus={() => {
                        setCurrentUrlPdf(
                            params.row.download_link_pdf.replace(
                                "http://ana-back-teste-ke6b6.ondigitalocean.app",
                                ""
                            )
                        );
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        checkIfIsWebSiteAndDownloadPdf();
                    }}
                    style={{
                        border: "1px solid gray",
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "80%",
                        flexWrap: "wrap",
                        cursor: "pointer",
                        color: "white",
                        background: "var(--primary)",
                        borderRadius: "4px",
                        textDecoration: "none",
                    }}
                >
                    Baixar
                    <Download />
                </button>
            ),
        },
        {
            field: "xml",
            headerName: "XML",
            flex: 9,
            headerAlign: "center",
            sortable: false,
            align: "center",
            renderCell: (params: GridRenderCellParams) => (
                <a
                    href={params.row.download_link_xml}
                    target="_blank"
                    style={{
                        border: "1px solid gray",
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "80%",
                        flexWrap: "wrap",
                        cursor: "pointer",
                        color: "white",
                        background: "var(--primary)",
                        borderRadius: "4px",
                        textDecoration: "none",
                    }}
                    rel="noreferrer"
                >
                    Baixar
                    <Download />
                </a>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal" />
                    <TableDataGrid
                        style={{ minWidth: "460px !important" }}
                        rows={dataProposalsList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowProposal(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteProposal
                        openDeleteProposalModal={openDeleteProposalModal}
                        handleCloseDeleteProposalModal={
                            handleCloseDeleteProposalModal
                        }
                        selectedRowProposal={selectedRowProposal}
                        dataProposalsList={dataProposalsList}
                        setDataProposalsList={setDataProposalsList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default InvoicesPage;
