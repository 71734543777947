import BreadcrumbsHeader from "../BreadcrumbsHeader";
import Navbar from "../Navbar";
import { UnderNavbarHeader } from "./styles";

function Header() {
    return (
        <>
            <Navbar />
            <UnderNavbarHeader className="no-print">
                <BreadcrumbsHeader />
            </UnderNavbarHeader>
        </>
    );
}

export default Header;
