export const mbStatus = [
    {
        value: 1,
        label: "Abertas",
    },
    {
        value: 2,
        label: "Fechadas",
    },
    {
        value: 3,
        label: "Atrasadas",
    },
    {
        value: 4,
        label: "Pagas",
    },
    {
        value: 5,
        label: "Sem Pendências",
    },
];
