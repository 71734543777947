import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 720px;
    background-color: white;
    box-shadow: 24;
    padding: 1rem;
    border-radius: 8px;

    @media (max-width: 768px) {
        max-width: 420px;
    }
`;

export const TitleModal = styled.h1`
    font-size: 24px;
    font-weight: 400;
    text-align: center;
`;

export const LabelText = styled.p``;

export const ContentVideo = styled.div`
    input {
        display: none;
    }
`;

export const ThumbVideo = styled.label`
    display: block;
    width: 512px;
    height: 300px;
    border: 1px solid #000000;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

export const ContentLink = styled.div`
    margin: 2rem 0;
`;

export const ContentButton = styled.div`
    display: flex;
    justify-content: center;
`;
