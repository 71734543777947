/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import {
    checkIfCNPJIsValid,
    validatorCpf,
} from "../../helpers/cpfOrCnpjValidator";
import { documentMask } from "../../helpers/document";
import { phoneMask } from "../../helpers/phone";
import ButtonGeneric from "../ButtonGeneric";
import InputGeneric from "../InputGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalSignatoryPartnersStyle } from "./styles";

interface Props {
    openSignatoryPartnersModal: boolean;
    handleCloseSignatoryPartnersModal: () => void;
    signatoryPartnersList: any;
    setSignatoryPartnersList: any;
}

type holderFormTypeValues = {
    // TITULAR
    fullName?: string;
    name: string;
    email?: string;
    phone?: string;
    document: string;
    holderLevel: string;
};

const holderFormSchema = yup.object().shape({
    fullName: yup.string().required("Nome é obrigatório"),
    name: yup.string().required("Nome é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    phone: yup.string().min(14).required(),
    document: yup.string().min(14).max(18).required(),
    holderLevel: yup.string(),
});

function ModalSignatoryPartners({
    openSignatoryPartnersModal,
    handleCloseSignatoryPartnersModal,
    signatoryPartnersList,
    setSignatoryPartnersList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const {
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useForm<holderFormTypeValues>({
        resolver: yupResolver(holderFormSchema),
    });

    function handleChangeDocumentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue("document", documentMask(value));
    }

    function handleChangePhoneMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setValue("phone", phoneMask(value));
    }

    const [fullNameLabelInput, setFullNameLabelInput] =
        useState("Nome Completo");
    const [nameLabelInput, setNameLabelInput] = useState("Apelido");
    const handleChangeInputsAndGetDocumentValueLabel = () => {
        if (getValues("document").length > 14) {
            setFullNameLabelInput("Razão Social");
            setNameLabelInput("Nome de Fantasia");
        } else {
            setFullNameLabelInput("Nome Completo");
            setNameLabelInput("Apelido");
        }
    };

    // PEGA OS VALORES DOS INPUTS E JOGA PARA O ARRAY DE SIGNATÁRIOS
    const setSignatoriesPartnersList = () => {
        const checkDocumentCpf = validatorCpf(getValues("document") as any);
        const checkDocumentCnpj = checkIfCNPJIsValid(getValues("document"));
        if (getValues("document").replaceAll(/\D/g, "").length <= 11) {
            if (checkDocumentCpf === false) {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("O CPF é invalido");
                setIsLoadingButton(false);
                return;
            }
        } else if (checkDocumentCnpj === false) {
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast("O CNPJ é invalido");
            setIsLoadingButton(false);
            return;
        }

        const payload = {
            document: getValues("document").replace(/[^0-9]/g, ""),
            full_name: getValues("fullName"),
            name: getValues("name"),
            email: getValues("email"),
            phone: getValues("phone")?.replace(/[^0-9]/g, ""),
        };

        // Verifica se algum signatário existente já tem o mesmo valor de documento
        const verifySignatory = (signatoryList: any, newSignatory: any) => {
            setIsLoadingButton(true);
            const verifySignatory = signatoryList.some(
                (signatoryCurrentList: any) =>
                    signatoryCurrentList.document === newSignatory.document
            );

            // Se um signatário já existir, retorne verdadeiro
            if (verifySignatory) {
                return true;
            }

            // Se o signatário for novo, retorne falso
            return false;
        };

        const signatoryAlreadyAdd = verifySignatory(
            signatoryPartnersList,
            payload
        );
        if (signatoryAlreadyAdd) {
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast("O signatário já foi adicionado!");
            setIsLoadingButton(false);

            return;
        }

        setTimeout(() => {
            handleCloseSignatoryPartnersModal();
            setOpenToast(false);
            setIsLoadingButton(false);
            setSignatoryPartnersList((prev: any) => [...prev, payload]);
            setValue("document", "");
            setValue("email", "");
            setValue("fullName", "");
            setValue("name", "");
            setValue("phone", "");
        }, 700);
    };

    return (
        <Modal
            open={openSignatoryPartnersModal}
            onClose={handleCloseSignatoryPartnersModal}
        >
            <ModalSignatoryPartnersStyle>
                <Box className="modalContainer">
                    <p>Dados do Signatário</p>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="document"
                            render={({ field: { value = "", ref } }) => (
                                <InputGeneric
                                    onBlur={
                                        handleChangeInputsAndGetDocumentValueLabel
                                    }
                                    value={value}
                                    size="small"
                                    className="mediumInput"
                                    label="CPF/CNPJ"
                                    error={!!errors.document}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => handleChangeDocumentMask(event)}
                                    ref={ref}
                                    inputProps={{ maxLength: 18 }}
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="fullName"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="bigInput"
                                    label={fullNameLabelInput}
                                    error={!!errors.fullName}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="smallInput"
                                    label={nameLabelInput}
                                    error={!!errors.name}
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="email"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="mediumInput"
                                    label="Digite o email"
                                    error={!!errors.email}
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="phone"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => handleChangePhoneMask(event)}
                                    inputProps={{ maxLength: 14 }}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="mediumInput"
                                    label="Telefone para Contato"
                                    error={!!errors.phone}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => handleCloseSignatoryPartnersModal()}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--primary)"
                            loading={isLoadingButton}
                            text="Adicionar"
                            onClick={handleSubmit(setSignatoriesPartnersList)}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalSignatoryPartnersStyle>
        </Modal>
    );
}
export default ModalSignatoryPartners;
