import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { LeadsSourceProps } from "../../pages/LeadsSourcePage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteLeadSourceStyle } from "./styles";

interface Props {
    openDeleteLeadSourceModal: boolean;
    handleCloseDeleteLeadSourceModal: () => void;
    selectedRowLeadSource: GridRowParams["row"];
    setDataLeadsSourceList: (values: LeadsSourceProps[]) => void;
    dataLeadsSourceList: LeadsSourceProps[];
}

function ModalDeleteLeadSource({
    openDeleteLeadSourceModal,
    handleCloseDeleteLeadSourceModal,
    selectedRowLeadSource,
    setDataLeadsSourceList,
    dataLeadsSourceList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteLeadSource() {
        setIsLoadingButton(true);
        api.delete(`/leadSource/${selectedRowLeadSource.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteLeadSourceModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataLeadsSourceList(
                    dataLeadsSourceList.filter((item: { id: number }) => {
                        return item.id !== selectedRowLeadSource.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteLeadSourceModal}
            onClose={handleCloseDeleteLeadSourceModal}
        >
            <ModalDeleteLeadSourceStyle>
                <Box className="modalContainer">
                    <p>{`Deseja excluir o Lead de Origem "${selectedRowLeadSource.name}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteLeadSourceModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteLeadSource()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteLeadSourceStyle>
        </Modal>
    );
}
export default ModalDeleteLeadSource;
