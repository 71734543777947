import { WhatsApp, Delete, Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import pathBannerDefault from "../../assets/images/banner-default-publicity.png";
import pathYoutubeIcon from "../../assets/images/youtube-thumb.png";
import { AdvertisingVideosProps } from "../../pages/EditPublicity/types";
import { VideosProps } from "../../pages/NewPublicity/types";
import {
    Container,
    Banner,
    CardVideos,
    ContentVideos,
    Description,
    Phone,
    Title,
    ButtonSeeMore,
    ContentButtonExit,
} from "./styles";

interface SimulationModalPublicityProps {
    title: string | undefined;
    urlImgBanner: string | null;
    description: string | undefined;
    phone: string | undefined;
    link: string | undefined;
    listVideo: VideosProps[] | AdvertisingVideosProps[];
    setListVideo: (data: any) => void;
}

function SimulationModalPublicity({
    description,
    phone,
    title,
    urlImgBanner,
    listVideo,
    setListVideo,
    link,
}: SimulationModalPublicityProps) {
    const handleDeleteVideo = (link: string) => {
        const newListVideo = listVideo.filter((data) => data.link !== link);
        setListVideo(newListVideo);
    };

    return (
        <Container>
            <Title>
                {(title && title.length <= 0) || !title
                    ? "Título da publicidade"
                    : title}
            </Title>

            <Banner src={!urlImgBanner ? pathBannerDefault : urlImgBanner} />

            <span>Vídeos relacionados: </span>
            <ContentVideos>
                {listVideo.map((value) => {
                    return (
                        <CardVideos>
                            <img
                                src={pathYoutubeIcon}
                                alt={`card-video-${value.link}`}
                            />
                            <IconButton
                                title="Deletar vídeo"
                                onClick={() => handleDeleteVideo(value.link)}
                            >
                                <Delete color="error" />
                            </IconButton>
                        </CardVideos>
                    );
                })}
            </ContentVideos>

            <Description>
                {(description && description.length <= 0) || !description
                    ? "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis error voluptas cum quaerat aliquid tenetur iure iste a quisquam, illum ducimus possimus sapiente iusto nesciunt voluptatum dolorum laboriosam voluptate modi."
                    : description}
            </Description>

            <Phone>
                Contato direto:{" "}
                {(phone && phone.length <= 0) || !phone
                    ? "(00)00000-0000"
                    : phone}
                <WhatsApp color="primary" fontSize="small" />
            </Phone>

            <hr
                style={{
                    margin: "2rem 0",
                    color: "gray",
                }}
            />

            <ButtonSeeMore href={link} target="_blank">
                Ver Mais
            </ButtonSeeMore>

            <ContentButtonExit>
                <IconButton>
                    <Clear />
                </IconButton>
            </ContentButtonExit>
        </Container>
    );
}

export default SimulationModalPublicity;
