export const statusCustomer = [
    {
        value: 1,
        label: "Ok",
    },
    {
        value: 2,
        label: "Aviso",
    },
    {
        value: 3,
        label: "Bloqueado",
    },
    {
        value: 4,
        label: "Em fila de exclusão",
    },
    {
        value: 5,
        label: "Excluído",
    },
    {
        value: 6,
        label: "Aguardando Ativação",
    },
    {
        value: 7,
        label: "Validado",
    },
    {
        value: 8,
        label: "Desligado",
    },
];

export const generateStepStatus = {
    AGUARDANDO_GERAÇÃO_SERVIDORES: 1,
    GERANDO_SERVIDORES: 2,
    AGUARDANDO_GERAÇÃO_DE_APPS: 3,
    GERANDO_APPS: 4,
    OK: 5,
};
