import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    max-width: 450px;
    min-width: 450px;
    padding: 2rem;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    -moz-box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    box-shadow: 0px 0px 8px 1px rgba(5, 5, 5, 0.3);
    border-radius: 5px;

    @media (max-width: 1100px) {
        max-width: 500px;
        min-width: 270px;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    word-break: break-all;
`;

export const Banner = styled.img`
    width: 100%;
    height: 250px;
    border-radius: 20px;
    border: 1px solid #000000;
`;

export const ContentVideos = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0rem;
    max-height: 200px;
    overflow: auto;
    margin: 1rem 0;
`;

export const CardVideos = styled.div`
    width: 125px;
    padding: 0.1rem 0.2rem;
    border: 1px solid #00000030;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(0.9);
    text-align: center;

    img {
        width: 70%;
        height: 60px;
    }

    :hover {
        transition: all ease 0.2s;
        transform: scale(1);
    }
`;

export const Description = styled.p`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
`;

export const Phone = styled.span`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    word-break: break-all;
`;

export const ButtonSeeMore = styled.a`
    width: 70px;
    display: block;
    padding: 0.5rem 2rem;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    background-color: var(--secondary);
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
`;

export const ContentButtonExit = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
`;
