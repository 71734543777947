import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor, InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { formatCurrencyToShow } from "../../helpers/currency";
import { ImplementationsProps } from "../../pages/ImplementationsPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import InputGeneric from "../InputGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalEditImplementationPriceStyle } from "./styles";

type implementationTypes = {
    price: string;
    description: string;
};

const holderFormSchema = yup.object().shape({
    price: yup.string().required("O campo preço é obrigatório"),
    description: yup.string().required("O campo descrição é obrigatório"),
});

interface Props {
    openEditImplementationPriceModal: boolean;
    handleCloseEditImplementationPriceModal: () => void;
    selectedRowImplementation: GridRowParams["row"];
    getAllImplementations: () => void;
}

function ModalEditImplementationPrice({
    openEditImplementationPriceModal,
    handleCloseEditImplementationPriceModal,
    selectedRowImplementation,
    getAllImplementations,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const { handleSubmit, control, setValue } = useForm<implementationTypes>({
        resolver: yupResolver(holderFormSchema),
    });

    // ATUALIZA O VALOR DO INPUT TODAS AS VEZES QUE O MODAL É ABERTO

    useEffect(() => {
        const newValue = formatCurrencyToShow(selectedRowImplementation.price);
        setValue("price", newValue);
        setValue(
            "description",
            selectedRowImplementation.implementation?.description
        );
    }, [selectedRowImplementation]);

    function handleChangePriceMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;

        setValue("price", formatCurrencyToShow(valueNumberWithTwoDecimals));
    }

    const editImplementationPrice: SubmitHandler<implementationTypes> = async (
        formValues
    ) => {
        try {
            setIsLoadingButton(true);

            const responseUpdatePrice = api.post(`/implementations/prices/`, {
                implementation_id: selectedRowImplementation.implementation_id,
                price: parseFloat(
                    formValues.price.replaceAll(".", "").replace(",", ".")
                ),
            });

            const { manual_installation, can_repeat, is_active } =
                selectedRowImplementation.implementation;

            const responseUpdateDescription = api.put(
                `implementations/${selectedRowImplementation.implementation_id}`,
                {
                    manual_installtion: manual_installation,
                    can_repeat,
                    is_active,
                    description: formValues.description,
                }
            );

            Promise.all([responseUpdatePrice, responseUpdateDescription])
                .then((response) => {
                    if (response[0].status && response[1].status) {
                        setOpenToast(true);
                        setToastRequisitionResult("success");
                        setTextToast("Dados atualizados com sucesso!");

                        getAllImplementations();

                        setTimeout(() => {
                            handleCloseEditImplementationPriceModal();
                            setOpenToast(false);
                            setIsLoadingButton(false);
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(error.response.data.message);
                    setIsLoadingButton(false);
                });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingButton(false);
        }
    };

    return (
        <Modal
            open={openEditImplementationPriceModal}
            onClose={handleCloseEditImplementationPriceModal}
        >
            <ModalEditImplementationPriceStyle>
                <Box className="modalContainer">
                    <p>{`Editar o preço da Implementação "${selectedRowImplementation.implementation?.description}"?`}</p>
                    <form onSubmit={handleSubmit(editImplementationPrice)}>
                        <Controller
                            control={control}
                            name="price"
                            render={({
                                field: { onBlur, value = "", ref },
                                fieldState: { error },
                            }) => (
                                <InputGeneric
                                    onChange={(event) =>
                                        handleChangePriceMask(event)
                                    }
                                    fullWidth
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Preço"
                                    error={!!error}
                                    ref={ref}
                                    autoFocus
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={error && error.message}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="description"
                            render={({
                                field: { onBlur, value = "", ref, onChange },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    onChange={onChange}
                                    fullWidth
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Descrição"
                                    error={!!error}
                                    ref={ref}
                                    helperText={error && error?.message}
                                />
                            )}
                        />
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={
                                    handleCloseEditImplementationPriceModal
                                }
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoadingButton}
                                text="Confirmar"
                                type="submit"
                            />
                        </div>
                    </form>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalEditImplementationPriceStyle>
        </Modal>
    );
}
export default ModalEditImplementationPrice;
