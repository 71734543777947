import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormLeadSourceRegisterPage } from "./styles";

type leadSourceFormTypeValues = {
    name: string;
};

const leadSourceFormSchema = yup.object().shape({
    name: yup.string().required(),
});

function LeadSourceRegisterPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("origem.lead.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<leadSourceFormTypeValues>({
        resolver: yupResolver(leadSourceFormSchema),
    });

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const createNewHolderType: SubmitHandler<leadSourceFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.post(`/leadSource`, {
            name: formValues.name,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/settings/leadsSource");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            <FormLeadSourceRegisterPage>
                <div className="topFormPage">
                    <h2>Cadastrar Nova Origem de Lead</h2>
                </div>
                <form onSubmit={handleSubmit(createNewHolderType)}>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Nome"
                                    error={!!errors.name}
                                    ref={ref}
                                    autoFocus
                                />
                            )}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/leadsSource")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormLeadSourceRegisterPage>
        </PageWrapper>
    );
}
export default LeadSourceRegisterPage;
