import { AlertProps, Snackbar, Stack } from "@mui/material";

import { AlertStyles } from "./styles";

interface Props {
    open?: boolean;
    severity?: AlertProps["severity"];
    onClose?: () => void;
    text?: string;
}

function Toast({ open, severity, onClose, text }: Props) {
    return (
        <Stack spacing={0} sx={{ width: "100%" }}>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={onClose}
                id="toast"
                sx={{
                    margin: "0",
                    position: "fixed",
                    left: "0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "10%",
                    height: "60px",
                    textAlign: "center",
                }}
            >
                <AlertStyles
                    onClose={onClose}
                    // As requisições podem ser "success, error, warning, info"
                    severity={severity}
                >
                    {text}
                </AlertStyles>
            </Snackbar>
        </Stack>
    );
}

export default Toast;
