export function findMaxValue(numbers: number[]): number | null {
    if (numbers.length === 0) {
        return null; // Return null if the array is empty
    }

    let maxValue: number = numbers[0]; // Initialize with the first number
    /* eslint-disable */ /* prettier-ignore */
    for (let i = 1; i < numbers.length; i++) {
        if (numbers[i] > maxValue) {
            maxValue = numbers[i];
        }
    }

    return maxValue;
}

export function findMinValue(numbers: number[]): number | null {
    if (numbers.length === 0) {
      return null; // Retorna null se o array estiver vazio
    }
  
    let minValue: number = numbers[0]; // Inicializa com o primeiro número
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] < minValue) {
        minValue = numbers[i];
      }
    }
  
    return minValue;
}