import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAuth } from "../contexts/contexts";
import {
    HomePage,
    ProfilesPage,
    UsersPage,
    ProfileRegisterPage,
    ProfileEditPage,
    ContractsPage,
    ContractRegisterPage,
    ContractEditPage,
    PlansPage,
    PlanRegisterPage,
    PlanEditPage,
    HoldersTypePage,
    HolderTypeRegisterPage,
    HolderTypeEditPage,
    HoldersPage,
    HolderRegisterPage,
    HolderEditPage,
    CustomersCategoriesPage,
    CustomerCategoryRegisterPage,
    CustomerCategoryEditPage,
    CompaniesListPage,
    ReminderSettingPage,
    ComissionSetupPage,
    ErrorPage,
    ImplementationsPage,
    ProposalPage,
    ProposalRegisterPage,
    GraceDaysPage,
    GraceDayRegisterPage,
    GraceDayEditPage,
    LeadSourceRegisterPage,
    LeadSourceEditPage,
    // ProposalEditPage,
    ProposalDetailsPage,
    PaymentCompletedPage,
    InvoicesPage,
    SettingsPage,
    ContractSingningPage,
    ContractSignedPage,
    CustomersPage,
    CustomerDetailsPage,
    PaymentActivationLinkPage,
} from "../pages";
import CheckListAppPage from "../pages/CheckListAppPage";
import CheckListFinalized from "../pages/CheckListFinalized";
import ConfigAddNotificationsPage from "../pages/ConfigAddNotificationsPage";
import ConfigNotificationsPage from "../pages/ConfigNotificationsPage";
import { ContractAdjustment } from "../pages/ContractAdjustment";
import CouponsDetailsPage from "../pages/CouponsDetailsPage";
import CouponsPage from "../pages/CouponsPage";
import CouponsRegisterPage from "../pages/CouponsRegisterPage";
import { CustomerCentral } from "../pages/CustomerCentral";
import CustomerCreatePage from "../pages/CustomerCreatePage";
import EditPublicity from "../pages/EditPublicity";
import FollowUpProjects from "../pages/FollowUpProjects";
import FullInvoices from "../pages/FullInvoices";
import ImplementationProposalPage from "../pages/ImplementationProposal";
import ImplementationProposalDetailsPage from "../pages/ImplementationProposalDetailsPage";
import ImplementationProposalRegisterPage from "../pages/ImplementationProposalRegister";
import ItemsCheckListPage from "../pages/ItemsCheckListPage";
import LeadsSourcePage from "../pages/LeadsSourcePage";
import { MonthlyBilling } from "../pages/MonthlyBilling";
import { MonthlyBillingReportPage } from "../pages/MonthlyBillingReportPage";
import NewInvoicesFull from "../pages/NewInvoicesFull";
import NewPublicity from "../pages/NewPublicity";
import PaymentExpiredPage from "../pages/PaymentCompletedPage/expired";
import Publicity from "../pages/Publicity";
import { FinancialReport } from "../pages/ReportFinancial";
import SettingsConfigPage from "../pages/SettingsConfigPage";
import HelpersMonitorPage from "../pages/HelpersMonitorPage";
import HelpersMonitorAddPage from "../pages/HelpersMonitorAddPage";
import HelpersMonitorEditPage from "../pages/HelpersMonitorEditPage";
import { UserProfile } from "../pages/UserProfile";
import ValidationsAppPage from "../pages/ValidationsAppPages";
import { AnaPage } from "../pages/AnaPage";
import { AnaDetailsPage } from "../pages/AnaDetailsPage";
import { VersionsApplicationsPage } from "../pages/VersionsApplicationsPage";

export function PrivateRoutes() {
    const { signed, token } = useAuth();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [token, signed]);

    return (
        <Routes>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/home" element={<HomePage />} />

            <Route path="/comercial/proposal" element={<ProposalPage />} />
            <Route
                path="/comercial/proposal/newProposal"
                element={<ProposalRegisterPage />}
            />
            {/* <Route
                path="/comercial/proposal/proposalEdit"
                element={<ProposalEditPage />}
            /> */}
            <Route
                path="/comercial/proposal/proposalDetails"
                element={<ProposalDetailsPage />}
            />
            <Route
                path="/comercial/implementationProposal"
                element={<ImplementationProposalPage />}
            />
            <Route
                path="/comercial/implementationProposal/proposalImplementatinDetails"
                element={<ImplementationProposalDetailsPage />}
            />
            <Route
                path="/comercial/implementationProposal/newImplementationProposal"
                element={<ImplementationProposalRegisterPage />}
            />
            <Route
                path="financial/clientes/central"
                element={<CustomerCentral />}
            />
            <Route path="/comercial/holders" element={<HoldersPage />} />
            <Route path="/comercial/followUp" element={<FollowUpProjects />} />
            <Route
                path="/comercial/acompanhamento"
                element={<FollowUpProjects />}
            />
            <Route
                path="/comercial/holders/holderRegister"
                element={<HolderRegisterPage />}
            />
            <Route
                path="/comercial/holders/holderEdit"
                element={<HolderEditPage />}
            />
            <Route path="/settings/users" element={<UsersPage />} />
            <Route path="/settings/profiles" element={<ProfilesPage />} />
            <Route
                path="/settings/profiles/profileRegister"
                element={<ProfileRegisterPage />}
            />
            <Route
                path="/settings/profiles/profileEdit"
                element={<ProfileEditPage />}
            />
            <Route path="/settings/contracts" element={<ContractsPage />} />
            <Route
                path="/settings/contracts/contractRegister"
                element={<ContractRegisterPage />}
            />
            <Route
                path="/settings/contracts/contractEdit"
                element={<ContractEditPage />}
            />
            <Route path="/settings/graceDays" element={<GraceDaysPage />} />
            <Route
                path="/settings/graceDays/graceDayRegister"
                element={<GraceDayRegisterPage />}
            />
            <Route
                path="/settings/graceDays/graceDayEdit"
                element={<GraceDayEditPage />}
            />
            <Route path="/settings/leadsSource" element={<LeadsSourcePage />} />
            <Route
                path="/settings/leadsSource/leadSourceRegister"
                element={<LeadSourceRegisterPage />}
            />
            <Route
                path="/settings/leadsSource/leadSourceEdit"
                element={<LeadSourceEditPage />}
            />
            <Route
                path="/settings/reminderSetting"
                element={<ReminderSettingPage />}
            />
            <Route
                path="/settings/notifications"
                element={<ConfigNotificationsPage />}
            />
            <Route
                path="/settings/notifications/newNotifications"
                element={<ConfigAddNotificationsPage />}
            />
            <Route path="/settings/products" element={<PlansPage />} />
            <Route
                path="/settings/products/newPlan"
                element={<PlanRegisterPage />}
            />
            <Route
                path="/settings/products/editPlan"
                element={<PlanEditPage />}
            />
            <Route
                path="/settings/implementations"
                element={<ImplementationsPage />}
            />

            <Route
                path="/settings/holdersTypes"
                element={<HoldersTypePage />}
            />
            <Route
                path="/settings/holdersTypes/holderTypeRegister"
                element={<HolderTypeRegisterPage />}
            />
            <Route
                path="/settings/holdersTypes/holderTypeEdit"
                element={<HolderTypeEditPage />}
            />
            <Route
                path="/settings/customersCategories"
                element={<CustomersCategoriesPage />}
            />
            <Route
                path="/settings/customersCategories/customerCategoryRegister"
                element={<CustomerCategoryRegisterPage />}
            />
            <Route
                path="/settings/customersCategories/customerCategoryEdit"
                element={<CustomerCategoryEditPage />}
            />
            <Route path="/settings/publicity" element={<Publicity />} />
            <Route
                path="/settings/publicity/newPublicity"
                element={<NewPublicity />}
            />
            <Route
                path="/settings/publicity/editPublicity"
                element={<EditPublicity />}
            />
            <Route
                path="/settings/helper-monitor"
                element={<HelpersMonitorPage />}
            />
            <Route
                path="/settings/helper-monitor/helpersAdd"
                element={<HelpersMonitorAddPage />}
            />
            <Route
                path="/settings/helper-monitor/helperEdit"
                element={<HelpersMonitorEditPage />}
            />
            <Route
                path="/settings/checklists"
                element={<ItemsCheckListPage />}
            />
            <Route
                path="/companies/companiesList"
                element={<CompaniesListPage />}
            />
            <Route
                path="/comissions/comissionSetup"
                element={<ComissionSetupPage />}
            />

            <Route path="/checkout" element={<PaymentActivationLinkPage />} />
            <Route
                path="/payment/completed"
                element={<PaymentCompletedPage />}
            />
            {/* rota de relatórios */}
            <Route
                path="/financial/faturas-mensais"
                element={<MonthlyBillingReportPage />}
            />
            <Route
                path="/reports/listagem-relatorios"
                element={<FullInvoices />}
            />
            <Route
                path="/reports/listagem-relatorios/geracao-relatorios"
                element={<NewInvoicesFull />}
            />
            <Route
                path="/reports/validations"
                element={<ValidationsAppPage />}
            />
            <Route path="/financial/invoices" element={<InvoicesPage />} />
            <Route path="/financial/financial" element={<FinancialReport />} />
            {/* ------------------ */}

            <Route path="/ana/listagem-clientes" element={<AnaPage />} />
            <Route
                path="/ana/listagem-clientes/detalhes-clientes"
                element={<AnaDetailsPage />}
            />
            <Route path="/settings" element={<SettingsPage />} />
            <Route
                path="/contractSignatories/assign"
                element={<ContractSingningPage />}
            />
            <Route
                path="/signedContractPage"
                element={<ContractSignedPage />}
            />

            <Route path="/settings/configs" element={<SettingsConfigPage />} />
            <Route path="/comercial/customers" element={<CustomersPage />} />
            <Route
                path="/comercial/customers/customers-add"
                element={<CustomerCreatePage />}
            />
            <Route
                path="/comercial/customers/customerDetail"
                element={<CustomerDetailsPage />}
            />
            <Route path="/paymentExpired" element={<PaymentExpiredPage />} />

            <Route path="/settings/cupons" element={<CouponsPage />} />
            <Route
                path="/settings/cupons/newCoupons"
                element={<CouponsRegisterPage />}
            />

            <Route
                path="/settings/cupons/couponsDetails"
                element={<CouponsDetailsPage />}
            />

            <Route path="/checklist-app" element={<CheckListAppPage />} />
            <Route
                path="/formulario-checklist-preenchido"
                element={<CheckListFinalized />}
            />

            <Route
                path="/settings/versoes-aplicativos"
                element={<VersionsApplicationsPage />}
            />
            <Route path="/perfil" element={<UserProfile />} />
            <Route path="/faturas-mensais" element={<MonthlyBilling />} />
            <Route
                path="settings/contracts/renovacao"
                element={<ContractAdjustment />}
            />
        </Routes>
    );
}
