import {
    AccountCircle,
    CallMissedOutgoing,
    Storage,
    Troubleshoot,
    Visibility,
    WarningAmber,
} from "@mui/icons-material";
import {
    Divider,
    Modal,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Tabs,
    Tab,
    SelectChangeEvent,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { CustomerStatus as CustomerStatusComponent } from "./CustomerStatus";
import { CustomerStatusContent } from "./CustomerStatusContent";
import { financialStatusLabel } from "./functions";
import { infraDataColumns, plainDataColumn } from "./GridColumns";
import { ModalCustomerCentral } from "./Modal";
import {
    Content,
    Title,
    TableContent,
    InfraCustomers,
    CustomerAdmin,
} from "./styles";
import {
    CustomerDisagreements,
    CustomerStatus,
    InfraXCustomerOption,
    ServerStatus,
} from "./types";

export function CustomerCentral() {
    const [customerStatus, setCustomerStatus] = useState<CustomerStatus>({
        ok: 0,
        warning: 0,
        blocked: 0,
        in_delete_queue: 0,
        deleted: 0,
        waiting_activate: 0,
        validated: 0,
        off: 0,
    });

    const [loading, setLoading] = useState(true);

    const infraXCustomerOptions = [
        {
            id: 1,
            label: "Clientes ATIVOS presentes no Admin",
            url: "/customerInfra/customersActivesAndInAdmin",
            grid: infraDataColumns,
        },
        {
            id: 2,
            label: "Clientes ATIVOS fora do Admin",
            url: "/customerInfra/customerActivesAndNotInAdmin",
            grid: plainDataColumn,
        },
        {
            id: 3,
            label: "Clientes DESATIVADOS presentes no Admin",
            url: "/customerInfra/customerDisablesAndInAdmin",
            grid: infraDataColumns,
        },
        {
            id: 4,
            label: "Clientes DESATIVADOS e fora do Admin",
            url: "/customerInfra/customerDisablesAndNotInAdmin",
            grid: plainDataColumn,
        },
    ];

    const [loadingInfra, setLoadingInfra] = useState<boolean>(true);
    const [infraData, setInfraData] = useState<any[]>([]);

    function handleDataInfra(url: string) {
        setLoadingInfra(true);
        api.get(url)
            .then((response) => {
                setInfraData(response.data.response);
                setLoadingInfra(false);
            })
            .catch((error) => {
                defaultToast("error", error.response.data.message);
                setInfraData([]);
                setLoadingInfra(false);
            });
    }

    const [infraXCustomerOptionsCurrent, setInfraXCustomerOptionsCurrent] =
        useState<InfraXCustomerOption>(infraXCustomerOptions[0]);

    function handleChangeOptionInfraCustomer(index: number) {
        setInfraXCustomerOptionsCurrent(infraXCustomerOptions[Number(index)]);
        handleDataInfra(infraXCustomerOptions[Number(index)].url);
    }

    function handleCustomerStatus() {
        api.get("/v2/customer/status").then((response) => {
            setCustomerStatus(response.data.response);
        });
    }

    const navigate = useNavigate();
    const [currentCustomer, setCurrentCustomer] =
        useState<CustomerDisagreements>();

    const [disagreements, setDisagreements] = useState<[]>([]);
    function handleCustomerDisagreement() {
        api.get("/v2/customer/disagreement").then((response) => {
            setDisagreements(response.data.response);
        });
    }

    const [tabIndex, setTabIndex] = useState(0);

    const columnsCustomerDisagreements: GridColDef[] = [
        { field: "name", headerName: "Cliente", flex: 3 },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <strong>{params.row?.status.toUpperCase()}</strong>
            ),
        },
        {
            field: "days_past_due",
            headerName: "Situação financeira",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        color: financialStatusLabel(params.row).color,
                    }}
                >
                    {financialStatusLabel(params.row).label}
                </span>
            ),
        },
        {
            field: "disagreements",
            headerName: "Inconsistências",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    {`${params.row?.disagreements.length} inconsistencias`}
                </strong>
            ),
        },
        {
            field: "actions",
            headerName: "Ações",
            flex: 1,
            align: "right",
            headerAlign: "right",
            renderCell: (params: GridRenderCellParams) => (
                <div
                    style={{
                        display: "flex",
                        gap: "0 6px",
                    }}
                >
                    <CallMissedOutgoing
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate(
                                `/comercial/customers/customerDetail?id=${params.row.id}`
                            );
                        }}
                    />
                    <Visibility
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            // handleOpenModal();
                            setCurrentCustomer(params.row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const [customerServerStatus, setCustomerServerStatus] = useState<
        ServerStatus[]
    >([]);

    function handleGetServers() {
        api.get("/customerInfra/allInAdmin")
            .then((response) => {
                setCustomerServerStatus(response.data.response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        Promise.all([
            handleCustomerStatus(),
            handleCustomerDisagreement(),
            // handleGetServers(),
            handleDataInfra(infraXCustomerOptionsCurrent.url),
        ])
            .then(() => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Central de clientes",
                    subtitle: "Análise geral de clientes",
                    icon: <Troubleshoot sx={{ color: "#fff" }} />,
                }}
                heightAuto
            >
                {/* <ReactApexChart
                    options={options}
                    series={series}
                    type="heatmap"
                    height={350}
                /> */}
                <Content>
                    <Title>Visão geral Clientes</Title>
                    <CustomerStatusComponent customerStatus={customerStatus} />
                    {/* <Title>Servidores</Title>
                    <Divider />
                    <CustomerStatusContent
                        onLoading={loading}
                        serverStatus={customerServerStatus}
                    /> */}
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleChangeTab}
                            aria-label="tab-divergentes"
                        >
                            <Tab
                                label="Divergências na Infra"
                                {...a11yProps(0)}
                            />
                            <Tab
                                label="Divergências no Admin"
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </Box>
                    {tabIndex === 0 ? (
                        <InfraCustomers>
                            <div className="filter">
                                <FormControl fullWidth size="small">
                                    <InputLabel id="name">
                                        selecione uma opção
                                    </InputLabel>
                                    <Select
                                        name="name"
                                        defaultValue="1"
                                        value={String(
                                            infraXCustomerOptionsCurrent.id
                                        )}
                                        labelId="plan-select-label"
                                        id="plan-select"
                                        label="selecione uma opção"
                                        size="small"
                                        onChange={(event) => {
                                            handleChangeOptionInfraCustomer(
                                                Number(event.target.value) - 1
                                            );
                                        }}
                                    >
                                        {infraXCustomerOptions.map((option) => (
                                            <MenuItem
                                                value={option.id}
                                                key={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <TableContent>
                                <TableDataGrid
                                    hideFooter
                                    disableColumnMenu
                                    rows={infraData}
                                    columns={infraXCustomerOptionsCurrent.grid}
                                    loading={loadingInfra}
                                    hideColumnMenu
                                />
                            </TableContent>
                        </InfraCustomers>
                    ) : (
                        <CustomerAdmin>
                            <Divider />
                            <TableContent>
                                <TableDataGrid
                                    hideFooter
                                    disableColumnMenu
                                    rows={disagreements}
                                    columns={columnsCustomerDisagreements}
                                    loading={loading}
                                    hideColumnMenu
                                />
                            </TableContent>
                            <ModalCustomerCentral
                                currentCustomer={currentCustomer}
                            />
                        </CustomerAdmin>
                    )}
                </Content>
            </PageDefault>
        </PageWrapper>
    );
}
