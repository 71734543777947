import styled from "styled-components";

export const FormHolderTypeRegisterPage = styled.div`
    width: 100%;
    max-width: 1280px;
    border-radius: 8px;
    padding: 10px 10px;
    box-shadow: 0px 0px 7px #8888889e;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputsContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .inputs {
        width: 320px;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 700px) {
        .variablesContainer {
            height: auto;
        }

        @media (max-width: 400px) {
            h3 {
                text-align: center;
            }

            .inputs {
                width: 260px;
            }
        }
    }
`;
