import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete, Edit, SecurityUpdate } from "@mui/icons-material";

import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { PageDefault } from "../../components/PageDefault";
import ModalConfirm from "../../components/ModalConfirm";

import {
    Container,
    Content,
    Form,
    ListVersions,
    ContentVersions,
    ContentBtnActions,
} from "./styles";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";

import { ModalEditVersions } from "../../components/ModalEditVersions";
import { If } from "../../components/If";

import { Constants } from "../../configs/constants";

import { VersionsApplicationsPageProps } from "./types";

import { useAuth } from "../../contexts/contexts";

const formVersionApplication = yup.object().shape({
    typeApp: yup.string().required("Este campo é obrigatório"),
    versionApp: yup.string().required("Este campo é obrigatório"),
    descriptionApp: yup.string().required("Este campo é obrigatório"),
});

interface FormVersionApplicationProps {
    typeApp: string;
    versionApp: string;
    descriptionApp: string;
}

export function VersionsApplicationsPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingLastVersions, setLoadingLastVersions] =
        useState<boolean>(false);

    const [modalDelete, setModalDelete] = useState<boolean>(false);
    const [modalEdit, setModalEdit] = useState<boolean>(false);

    const navigate = useNavigate();

    const { hasPermissions, permissions } = useAuth();

    useEffect(() => {
        if (
            hasPermissions("validators.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const { control, handleSubmit, setValue } =
        useForm<FormVersionApplicationProps>({
            resolver: yupResolver(formVersionApplication),
        });

    const [lastVersions, setLastVersions] = useState<
        VersionsApplicationsPageProps[]
    >([]);
    const [versionSelected, setVersionSelected] =
        useState<VersionsApplicationsPageProps | null>(null);

    const [versionSelectedEdit, setVersionSelectedEdit] =
        useState<VersionsApplicationsPageProps | null>(null);

    const formatLastVersions = (
        versions: VersionsApplicationsPageProps | undefined
        // eslint-disable-next-line
    ) => {
        if (versions) {
            const defaultDate = new Date(versions.created_at as string);

            const date = defaultDate.toLocaleDateString();

            const hours = defaultDate.getHours();
            const minutes = defaultDate.getMinutes();
            const seconds = defaultDate.getSeconds();

            const hoursRegistred = `${hours}:${minutes}:${seconds}`;

            return `${date} - ${hoursRegistred} - ${versions.title}: ${versions.version}`;
        }
    };

    const handleOpenModalDeleteVersion = (
        datasVersion: VersionsApplicationsPageProps
    ) => {
        setModalDelete(true);
        setVersionSelected(datasVersion);
    };

    const handleOpenModalEdit = (
        datasVersion: VersionsApplicationsPageProps
    ) => {
        setModalEdit(true);
        setVersionSelectedEdit(datasVersion);
    };

    const getLastVersions = async () => {
        try {
            setLoadingLastVersions(true);
            const responseLastVersions = await api.get("appVersion");

            if (responseLastVersions.status) {
                setLastVersions(responseLastVersions.data.response);
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoadingLastVersions(false);
        }
    };

    const handleDeleteVersion = async () => {
        try {
            setLoading(true);
            const responseDeleteVersion = await api.delete(
                `appVersion/${versionSelected?.id}`
            );

            if (responseDeleteVersion.status) {
                defaultToast("success", "Versão deletada com sucesso");
                setModalDelete(false);
                getLastVersions();
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveVersion = async (datas: FormVersionApplicationProps) => {
        try {
            setLoading(true);
            const responseSaveVersion = await api.post("appVersion", {
                title: JSON.parse(datas.typeApp).name,
                version: datas.versionApp,
                app_type_id: JSON.parse(datas.typeApp).id,
                description: datas.descriptionApp,
            });

            if (responseSaveVersion.status) {
                defaultToast("success", "Versão cadastrada com sucesso!");
                setValue("descriptionApp", "");
                setValue("versionApp", "");
                setValue(
                    "typeApp",
                    JSON.stringify(Constants.appTypes.mobilityDriver)
                );
                getLastVersions();
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLastVersions();

        setValue("typeApp", JSON.stringify(Constants.appTypes.mobilityDriver));
    }, []);

    useEffect(() => {
        if (lastVersions.length > 0) {
            // eslint-disable-next-line
            lastVersions.map((value) => {
                formatLastVersions(value);
            });
        }
    }, [lastVersions]);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Versões de Aplicativos",
                    subtitle: "",
                    icon: <SecurityUpdate sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <Content>
                        <If condition={hasPermissions("versions.criar")}>
                            <Form onSubmit={handleSubmit(handleSaveVersion)}>
                                <Controller
                                    control={control}
                                    name="typeApp"
                                    render={({
                                        field: {
                                            onChange,
                                            value = JSON.stringify(
                                                Constants.appTypes
                                                    .mobilityDriver
                                            ),
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="type-application-select-label">
                                                Tipo do aplicativo
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                labelId="type-application-select-label"
                                                id="type-application-select"
                                                label="Tipo do aplicativo"
                                                onChange={onChange}
                                                value={value}
                                                error={Boolean(error)}
                                            >
                                                <MenuItem
                                                    value={JSON.stringify(
                                                        Constants.appTypes
                                                            .mobilityDriver
                                                    )}
                                                >
                                                    {
                                                        Constants.appTypes
                                                            .mobilityDriver.name
                                                    }
                                                </MenuItem>
                                                <MenuItem
                                                    value={JSON.stringify(
                                                        Constants.appTypes
                                                            .mobilityCustomer
                                                    )}
                                                >
                                                    {
                                                        Constants.appTypes
                                                            .mobilityCustomer
                                                            .name
                                                    }
                                                </MenuItem>
                                                <MenuItem
                                                    value={JSON.stringify(
                                                        Constants.appTypes
                                                            .deliveryAdmin
                                                    )}
                                                >
                                                    {
                                                        Constants.appTypes
                                                            .deliveryAdmin.name
                                                    }
                                                </MenuItem>
                                                <MenuItem
                                                    value={JSON.stringify(
                                                        Constants.appTypes
                                                            .deliveryCustomer
                                                    )}
                                                >
                                                    {
                                                        Constants.appTypes
                                                            .deliveryCustomer
                                                            .name
                                                    }
                                                </MenuItem>
                                            </Select>
                                            {error && (
                                                <FormHelperText error>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="versionApp"
                                    render={({
                                        field: { onChange, value = "" },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            size="small"
                                            label="Versão"
                                            onChange={onChange}
                                            value={value}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="descriptionApp"
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            size="small"
                                            multiline
                                            rows={10}
                                            placeholder="Melhorias"
                                            onChange={onChange}
                                            value={value}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />

                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    loading={loading}
                                >
                                    Salvar
                                </LoadingButton>
                            </Form>
                        </If>
                        <ListVersions>
                            <div>
                                <h3>Últimas versões cadastradas:</h3>

                                {loadingLastVersions ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    lastVersions.map((value) => {
                                        return (
                                            <ContentVersions key={value.id}>
                                                {formatLastVersions(value)}

                                                <ContentBtnActions>
                                                    <If
                                                        condition={hasPermissions(
                                                            "versions.editar"
                                                        )}
                                                    >
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() =>
                                                                handleOpenModalEdit(
                                                                    value
                                                                )
                                                            }
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </If>

                                                    <If
                                                        condition={hasPermissions(
                                                            "versions.deletar"
                                                        )}
                                                    >
                                                        <IconButton
                                                            color="error"
                                                            onClick={() =>
                                                                handleOpenModalDeleteVersion(
                                                                    value
                                                                )
                                                            }
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </If>
                                                </ContentBtnActions>
                                            </ContentVersions>
                                        );
                                    })
                                )}
                            </div>
                        </ListVersions>
                    </Content>
                    <ModalConfirm
                        titleModal="Deseja realmente executar esta ação?"
                        onClick={handleDeleteVersion}
                        isLoadingButton={loading}
                        titleButton="Confirmar"
                        setVisible={setModalDelete}
                        visible={modalDelete}
                    />

                    <ModalEditVersions
                        getLastVersions={getLastVersions}
                        versionSelected={versionSelectedEdit}
                        setVersionSelected={setVersionSelectedEdit}
                        visible={modalEdit}
                        setVisible={setModalEdit}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
