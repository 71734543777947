import Chip, { ChipProps } from "@mui/material/Chip";

const PENDENTE = 1;
const PAGA = 2;
const EXPIRADA = 3;
const CANCELADA = 4;
const ENVIADA = 5;
const SALVA = 6;
const DEFAULT = 7;

interface Props {
    id: number;
}

export function getLabel(id: number): string {
    switch (id) {
        case PENDENTE:
            return "pendente";
        case PAGA:
            return "paga";
        case EXPIRADA:
            return "expirada";
        case CANCELADA:
            return "cancelada";
        case ENVIADA:
            return "enviada";
        case SALVA:
            return "salva";
        default:
            return "default";
    }
}

export function ProposalStatusChip({ id }: Props) {
    let label: string;
    let color: ChipProps["color"];

    switch (id) {
        case PENDENTE:
            label = "pendente";
            color = "default";
            break;
        case SALVA:
            label = "salva";
            color = "primary";
            break;
        case ENVIADA:
            label = "enviada";
            color = "warning";
            break;
        case EXPIRADA:
            label = "expirada";
            color = "error";
            break;
        case PAGA:
            label = "paga";
            color = "success";
            break;
        case CANCELADA:
            label = "cancelada";
            color = "error";
            break;
        case DEFAULT:
            label = "Default";
            color = "default";
            break;
        default:
            label = "Desconhecido";
            color = "error";
    }

    return (
        <div
            style={{
                width: "100px",
            }}
        >
            <Chip
                label={label}
                color={color}
                variant="outlined"
                size="small"
                sx={{
                    width: "100%",
                }}
            />
        </div>
    );
}
