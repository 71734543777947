import { Chip } from "@mui/material";

export function statusChip(status: number): any {
    switch (status) {
        case 1:
            return <Chip label="Em Aberto" size="small" />;
        case 2:
            return <Chip label="Fechada" size="small" color="primary" />;
        case 3:
            return <Chip label="Atrasada" size="small" color="warning" />;
        case 4:
            return <Chip label="Recebido" size="small" color="success" />;
        case 5:
            return (
                <Chip label="Sem Pendências" size="small" color="secondary" />
            );
        default:
            return <Chip label="desconhecido" size="small" />;
    }

    return <span />;
}
