import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormHolderTypeRegisterPage } from "./styles";

type holderTypeFormTypeValues = {
    name: string;
    description?: string;
    color?: string;
};

const holderTypeFormSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    color: yup.string().required(),
});

function HolderTypeRegisterPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("tipos.titulares.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<holderTypeFormTypeValues>({
        resolver: yupResolver(holderTypeFormSchema),
    });

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const createNewHolderType: SubmitHandler<holderTypeFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.post(`/holderLevels`, {
            name: formValues.name,
            description: formValues.description,
            color: formValues.color,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/settings/holdersTypes");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            <FormHolderTypeRegisterPage>
                <div className="topFormPage">
                    <h2>Cadastrar Tipo de Titular</h2>
                </div>
                <form onSubmit={handleSubmit(createNewHolderType)}>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Novo tipo de titular"
                                    error={!!errors.name}
                                    ref={ref}
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="description"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    minRows="3"
                                    maxRows="5"
                                    multiline
                                    size="small"
                                    className="inputs"
                                    label="Digite uma descrição"
                                    error={!!errors.description}
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="color"
                            render={({ field: { onChange, onBlur, ref } }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    type="color"
                                    defaultValue="#000000"
                                    size="small"
                                    className="inputs"
                                    label="Defina uma cor para o tipo"
                                    error={!!errors.color}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/holdersTypes")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormHolderTypeRegisterPage>
        </PageWrapper>
    );
}
export default HolderTypeRegisterPage;
