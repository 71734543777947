import styled from "styled-components";

export const Content = styled.div`
    padding: 0px 20px;
    @media (max-width: 768px) {
        .toolbarContainer.MuiDataGrid-toolbarContainer.css-1l0v19b-MuiDataGrid-toolbarContainer {
            flex-direction: column;
            gap: 20px;
        }
    }
`;

export const ContentFieldsSearch = styled.form`
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const FilterStyled = styled.div`
    display: flex;
    width: 80%;

    .status {
        width: 150px;
    }
`;

export const ButtonSearch = styled.button`
    padding: 0.2rem 1.7rem;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--shape);
    border: none;
    border-radius: 5px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SearchDiv = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
`;
