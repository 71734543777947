import styled from "styled-components";

export const Container = styled.form`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24px;
    padding: 15px;
    border-radius: 8px;
`;

export const TitleModal = styled.h2`
    text-align: center;
    font-size: 30px;
`;

export const ContentInput = styled.div`
    margin: 1rem 0;
`;

export const ContentButton = styled.div`
    margin: 1rem 0;
`;
