import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";

// interface Props {
//   size?: "small" | "medium" | undefined;
//   name?: string;
//   label?: string;
//   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   error?: boolean;
//   type?: string;
//   value?: string;
//   autoFocus?: boolean;
//   defaultValue?: string;
//   helperText?: string;
//   style?: {};
//   multiline?: boolean;
//   minRows?: string;
//   maxRows?: string;
//   onBlur?: ChangeHandler;
// }

const InputGeneric = React.forwardRef(
    (props: TextFieldProps, ref: TextFieldProps["ref"]) => {
        return (
            <Box component="div">
                <TextField
                    sx={{ mb: 2 }}
                    className="inputGenericGlobalStyle"
                    variant="outlined"
                    {...props}
                    ref={ref}
                />
            </Box>
        );
    }
);
export default InputGeneric;
