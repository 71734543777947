import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./global/GlobalStyle/index.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";

import theme from "./configs/theme";
import { AppProvider } from "./contexts";
// import GlobalStyle from "./global/GlobalStyle";
import RouterGlobal from "./routes";

import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                {/* <GlobalStyle /> */}
                <AppProvider>
                    <RouterGlobal />
                    <ToastContainer />
                </AppProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
