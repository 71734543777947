import { Add, Delete, Edit } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteCustomerCategory from "../../components/DeleteCustomerCategoryModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface CustomersCategoriesProps {
    color: string;
    description: string;
    id: number;
    name: string;
}

function CustomersCategoriesPage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataCustomersCategoriesList, setDataCustomersCategoriesList] =
        useState<CustomersCategoriesProps[]>([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("categorias.clientes.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [
        openDeleteCustomerCategoryModal,
        setOpenDeleteCustomerCategoryModal,
    ] = useState(false);
    const handleCloseDeleteCustomerCategoryModal = () => {
        setOpenDeleteCustomerCategoryModal(false);
    };
    const [selectedRowCustomerCategory, setSelectedRowCustomerCategory] =
        useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("/customersCategory")
            .then((response) => {
                setLoadingTable(false);
                setDataCustomersCategoriesList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "name", headerName: "Categoria de Cliente", flex: 20 },
        { field: "description", headerName: "Descrição", flex: 25 },
        {
            field: "color",
            headerName: "Cor",
            flex: 5,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableExport: true,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.color}`,
            renderCell: (params: GridRenderCellParams) => (
                <div
                    style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: `${params.row.color}`,
                        borderRadius: "50%",
                        border: "1px solid gray",
                    }}
                />
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 25,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If
                        condition={hasPermissions("categorias.clientes.editar")}
                    >
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/customersCategories/customerCategoryEdit?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If
                        condition={hasPermissions(
                            "categorias.clientes.excluir"
                        )}
                    >
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteCustomerCategoryModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If
                            condition={hasPermissions(
                                "categorias.clientes.cadastrar"
                            )}
                        >
                            <ButtonGeneric
                                onclick={() => {
                                    navigate(
                                        "/settings/customersCategories/customerCategoryRegister"
                                    );
                                }}
                                buttonColor="var(--primary)"
                                text="Nova Categoria de Cliente"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataCustomersCategoriesList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowCustomerCategory(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteCustomerCategory
                        openDeleteCustomerCategoryModal={
                            openDeleteCustomerCategoryModal
                        }
                        handleCloseDeleteCustomerCategoryModal={
                            handleCloseDeleteCustomerCategoryModal
                        }
                        selectedRowCustomerCategory={
                            selectedRowCustomerCategory
                        }
                        dataCustomersCategoriesList={
                            dataCustomersCategoriesList
                        }
                        setDataCustomersCategoriesList={
                            setDataCustomersCategoriesList
                        }
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default CustomersCategoriesPage;
