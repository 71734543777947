/* eslint-disable */
import {
    Add,
    Edit,
    SafetyCheck,
    Send,
    FormatListBulleted,
    MoreVert,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { ChangeEvent, MouseEvent, useState } from "react";
import ModalDeleteInvoice from "../../../components/ModalDeleteInvoice";

import { If } from "../../../components/If";
import InputGeneric from "../../../components/InputGeneric";
import ModalResendInvoice from "../../../components/ModalResendInvoice";
import { useAuth } from "../../../contexts/contexts";
import { formatCurrencyToShow } from "../../../helpers/currency";
import { api } from "../../../services/api";
import { InvoiceContent } from "./styles";
import { Props, BillingProduct } from "./types";

export function Invoice({
    billing,
    monthlyBilling,
    updated,
    holder,
    getFinancial,
    setOpenBillingDetailsModal,
}: Props) {
    const [currentProduct, setCurrentProduct] = useState(billing[0]);
    const [products, setProducts] = useState(billing);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [loadingButtonResend, setLoadingButtonResend] =
        useState<boolean>(false);
    const { hasPermissions, permissions } = useAuth();
    const [anchorElActionsInvoices, setAnchorElActionsInvoices] =
        useState<null | HTMLElement>(null);
    const open = Boolean(anchorElActionsInvoices);

    const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
    const [showModalWriteOff, setShowModalWriteOff] = useState<boolean>(false);

    const [showModalResendInvoice, setShowModalResendInvoice] =
        useState<boolean>(false);

    function editProduct(index: number) {
        const newProducts: [BillingProduct] = [...products];
        newProducts[index].editing = !newProducts[index].editing;
        newProducts[index].savely = true;
        setProducts(newProducts);

        setCurrentProduct(newProducts[index]);
    }

    function saveProduct(index: number) {
        const newProducts: [BillingProduct] = [...products];
        newProducts[index].savely = false;
        setProducts(newProducts);

        setCurrentProduct(newProducts[index]);
    }

    function updateProduct(index: number, amount: number) {
        const newProducts: [BillingProduct] = [...products];
        newProducts[index].amount = amount;
        setProducts(newProducts);

        setCurrentProduct(newProducts[index]);
    }

    const updateBilling = () => {
        setIsLoadingButton(true);
        api.put(`/monthly-billing/billingProduct/${currentProduct.id}`, {
            installments_number: 1,
            amount: currentProduct.amount,
        })
            .then((res) => {
                updated();
            })
            .catch((err) => {
                console.log("erro ao atualizar");
            })
            .finally(() => {
                setIsLoadingButton(false);
                saveProduct(
                    products.findIndex(
                        (product) => product.id === currentProduct.id
                    )
                );
            });
    };

    function calculateDateDifference(
        startDateStr: string,
        endDateStr: string
    ): number {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        const timeDifference = endDate.getTime() - startDate.getTime();

        const daysDifference = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
        );

        return daysDifference;
    }

    const isShowWriteOff =
        monthlyBilling?.status_id === 1 ||
        monthlyBilling?.status_id === 2 ||
        monthlyBilling?.status_id === 3;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 15,
                    width: "100%",
                }}
            >
                <h2 style={{ margin: "5px" }}>Fatura</h2>
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    {(monthlyBilling?.status_id === 3 ||
                        monthlyBilling?.status_id === 2) && (
                        <LoadingButton
                            variant="contained"
                            loading={loadingButtonResend}
                            onClick={() => setShowModalResendInvoice(true)}
                            size="small"
                        >
                            Reenviar fatura &nbsp;
                            <Send fontSize="small" />
                        </LoadingButton>
                    )}
                    {monthlyBilling?.status_id !== 6 && (
                        <div>
                            <IconButton
                                onClick={(
                                    event: MouseEvent<HTMLButtonElement>
                                ) =>
                                    setAnchorElActionsInvoices(
                                        event.currentTarget
                                    )
                                }
                                title="Ações"
                            >
                                <MoreVert />
                            </IconButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorElActionsInvoices}
                                open={open}
                                onClose={() => setAnchorElActionsInvoices(null)}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                {isShowWriteOff && (
                                    <MenuItem
                                        onClick={() =>
                                            setShowModalWriteOff(true)
                                        }
                                    >
                                        Confirmar pagamento
                                    </MenuItem>
                                )}

                                {monthlyBilling?.status_id !== 6 && (
                                    <MenuItem
                                        onClick={() => setShowModalCancel(true)}
                                    >
                                        Cancelar fatura
                                    </MenuItem>
                                )}
                            </Menu>
                        </div>
                    )}
                </div>
            </div>
            <Divider />
            <InvoiceContent>
                {products.map((product, index) => (
                    <div key={product.id}>
                        <div className="product">
                            <div className="product-div">
                                <p className="info">
                                    <strong>Cobrança:</strong> {product.name}
                                </p>
                                <p className="info" style={{ fontSize: "9pt" }}>
                                    {product.description}
                                </p>
                                {calculateDateDifference(
                                    product.grace_period,
                                    monthlyBilling?.closing_date as string
                                ) < 30 && product.grace_period ? (
                                    <p
                                        className="info"
                                        style={{ fontSize: "9pt" }}
                                    >
                                        {`Dias cobrados: ${calculateDateDifference(
                                            product.grace_period,
                                            monthlyBilling?.closing_date as string
                                        )}`}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="product-div">
                                <p className="info">
                                    <strong>Parcela:</strong>{" "}
                                    {true ? (
                                        `${product.installment_number ?? 1}/${
                                            product.total_installments ?? "N"
                                        }`
                                    ) : (
                                        <InputGeneric
                                            variant="standard"
                                            size="small"
                                            defaultValue={
                                                product.installment_number ?? 1
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setCurrentProduct({
                                                    ...currentProduct,
                                                    installment_number: Number(
                                                        e.target.value
                                                    ),
                                                });
                                            }}
                                        />
                                    )}
                                </p>
                                <p className="info">
                                    <strong>Valor:</strong> R$
                                    {!product.editing ? (
                                        formatCurrencyToShow(product.amount)
                                    ) : (
                                        <InputGeneric
                                            variant="standard"
                                            size="small"
                                            defaultValue={product.amount}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                updateProduct(
                                                    index,
                                                    Number(e.target.value)
                                                );
                                            }}
                                        />
                                    )}
                                </p>
                                {product.grace_period ? (
                                    <p className="info">
                                        <strong>Valor com carência:</strong>
                                        {product.amount_with_grace
                                            ? ` R$ ${formatCurrencyToShow(
                                                  product.amount_with_grace
                                              )}`
                                            : " R$ ---"}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div>
                                <If
                                    condition={hasPermissions(
                                        "fatura.item.editar"
                                    )}
                                >
                                    <LoadingButton
                                        color={
                                            !product.editing
                                                ? "inherit"
                                                : "success"
                                        }
                                        size="small"
                                        variant="contained"
                                        startIcon={
                                            !product.editing ? (
                                                <Edit />
                                            ) : (
                                                <SafetyCheck />
                                            )
                                        }
                                        onClick={() => {
                                            editProduct(index);
                                            if (!product.editing) {
                                                updateBilling();
                                            }
                                        }}
                                        loading={
                                            product.savely && isLoadingButton
                                        }
                                    >
                                        {!product.editing ? "editar" : "salvar"}
                                    </LoadingButton>
                                    {/* <div
                                    style={{
                                        padding: "5px",
                                    }}
                                >
                                    <Chip
                                        label="Assinatura"
                                        color="success"
                                        size="small"
                                    />
                                </div> */}
                                </If>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="bottom">
                    <p>
                        Total: R$
                        {formatCurrencyToShow(monthlyBilling?.total_amount)}
                    </p>
                </div>
                <ModalResendInvoice
                    visible={showModalResendInvoice}
                    setVisible={setShowModalResendInvoice}
                    monthlyBilling={monthlyBilling}
                    holder={holder}
                />
                <ModalDeleteInvoice
                    setOpenBillingDetailsModal={setOpenBillingDetailsModal}
                    typeModal="write-off"
                    getFinancials={getFinancial}
                    monthlyBilling={monthlyBilling}
                    visible={showModalWriteOff}
                    setVisible={setShowModalWriteOff}
                />
                <ModalDeleteInvoice
                    setOpenBillingDetailsModal={setOpenBillingDetailsModal}
                    typeModal="cancel"
                    getFinancials={getFinancial}
                    monthlyBilling={monthlyBilling}
                    visible={showModalCancel}
                    setVisible={setShowModalCancel}
                />
            </InvoiceContent>
        </>
    );
}
