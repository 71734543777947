import styled from "styled-components";

export const Container = styled.form`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
`;

export const TitleModal = styled.div`
    font-size: 22px;
    font-weight: bold;
    text-align: center;
`;

export const ContentButtonModal = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const SubTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
`;

export const Body = styled.div`
    width: 100%;
`;

export const ContentInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
`;
