import styled from "styled-components";

export const Container = styled.form`
    > h2 {
        text-align: center;
    }

    .field-buttons {
        width: 50%;
    }

    @media screen and (max-width: 980px) {
        .field-buttons {
            width: 100%;
        }
    }
`;

export const ContentInput = styled.div`
    width: 100%;
    margin: 1rem 0;
`;

export const LabelInput = styled.span`
    display: block;
    font-size: 18px;
    margin-bottom: 0.5rem;
`;

export const DivShowPanel = styled.div`
    width: 100%;
    border: 1px solid #888888;
    padding: 10px;
    display: flex;
    align-items: center;
`;

export const DivInputs = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin: 1rem 0;

    @media screen and (max-width: 980px) {
        flex-direction: column;
    }
`;

export const ContentBtnsSubmit = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
`;
