import { Box, Modal } from "@mui/material";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";

import { ModalDeleteProposalStyle } from "./styles";

interface Props {
    visible: boolean;
    setVisible: (data: boolean) => void;
    isLoadingButton: boolean;
    onClick: any;
    titleModal: string;
    titleButton: string;
}

function ModalConfirm({
    setVisible,
    visible,
    isLoadingButton,
    onClick,
    titleModal,
    titleButton,
}: Props) {
    return (
        <Modal open={visible} onClose={() => setVisible(false)}>
            <ModalDeleteProposalStyle>
                <Box className="modalContainer">
                    <p>{titleModal}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => setVisible(false)}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text={titleButton}
                            onClick={onClick}
                        />
                    </div>
                </Box>
            </ModalDeleteProposalStyle>
        </Modal>
    );
}
export default ModalConfirm;
