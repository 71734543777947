import { Add, Delete, Edit, PeopleAlt } from "@mui/icons-material";
import { AlertColor, Chip, Tooltip, Pagination } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import ButtonFilter from "../../components/ButtonFilter";
import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteHolder from "../../components/DeleteHolderModal";
import { If } from "../../components/If";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import {
    ContentTable,
    ContentPagination,
    Container,
    ContentHeaderFilter,
    ContentFilter,
} from "./styles";
import { ValueProps } from "./types";

export interface App {
    id: number;
    project_name: string;
}
export interface HoldersProps {
    color: string;
    description: string;
    id: number;
    name: string;
    apps: App[] | null;
}

const items = [
    {
        value: "full_name",
        label: "Nome",
    },
    {
        value: "document",
        label: "Documento",
    },
    {
        value: "email",
        label: "Email",
    },
];

let totalPage: number | null = null;

function HoldersPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("titulares.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataHoldersList, setDataHoldersList] = useState<HoldersProps[]>([]);
    const [openDeleteHolderModal, setOpenDeleteHolderModal] = useState(false);
    const handleCloseDeleteHolderModal = () => {
        setOpenDeleteHolderModal(false);
    };
    const [selectedRowHolder, setSelectedRowHolder] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [nameItemFiltered, setNameItemFiltered] = useState<ValueProps>({
        key: "full_name",
        value: "",
    });

    const handleFilter = async () => {
        if (nameItemFiltered.key === "" || nameItemFiltered.value === "") {
            return;
        }

        try {
            setLoadingTable(true);
            const responseHolderFiltered = await api.get(
                `holders/all?${nameItemFiltered.key}=${nameItemFiltered.value}`
            );

            if (responseHolderFiltered.status) {
                totalPage = responseHolderFiltered.data.response.last_page;
                setDataHoldersList(responseHolderFiltered.data.response.data);
            }
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoadingTable(false);
        }
    };

    const getHolders = async (page = 1) => {
        try {
            setLoadingTable(true);
            const responseHolders = await api.get(`/holders/all?page=${page}`);

            if (responseHolders.status) {
                totalPage = responseHolders.data.response.last_page;
                setDataHoldersList(responseHolders.data.response.data);
            }
        } catch (error: any) {
            console.error(error);
            setOpenToast(true);
            setToastRequisitionResult("error");
            setTextToast(error.response.data.message);
        } finally {
            setLoadingTable(false);
        }
    };

    useEffect(() => {
        getHolders();
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        {
            field: "name",
            headerName: "Nome",
            flex: 15,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row?.full_name ?? "-"}`,
        },
        { field: "email", headerName: "Email", flex: 30 },
        {
            field: "is_signatory",
            headerName: "Titular/Sócio",
            flex: 12,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row?.is_signatory}`,
            renderCell: (params: GridRenderCellParams) => (
                <p>{params.row?.is_signatory === 0 ? "Titular" : "Sócio"}</p>
            ),
        },
        {
            field: "holder_level",
            headerName: "Tipo de titular",
            flex: 12,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row?.holder_level?.name ?? "-"}`,
        },
        {
            field: "apps",
            headerName: "Aplicativos",
            flex: 20,
            align: "center",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.row?.apps[0]
                        ? params.row?.apps.map((app: App) => (
                              <Tooltip title="ir para cliente">
                                  <span
                                      onClick={() =>
                                          navigate(
                                              `/comercial/customers/customerDetail?id=${app.id}`
                                          )
                                      }
                                      role="button"
                                      aria-hidden="true"
                                      style={{
                                          margin: "3px",
                                          cursor: "pointer",
                                      }}
                                  >
                                      <Chip
                                          label={app.project_name}
                                          color="primary"
                                          size="small"
                                      />
                                  </span>
                              </Tooltip>
                          ))
                        : "-"}
                </div>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 10,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("titulares.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/comercial/holders/holderEdit?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("titulares.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteHolderModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Titulares",
                    subtitle: "Todos os titulares",
                    icon: <PeopleAlt sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <div className="containerListTablePagesGlobal">
                        <ContentHeaderFilter>
                            <ContentHeaderFilter>
                                <ButtonFilter
                                    value={nameItemFiltered}
                                    setValue={setNameItemFiltered}
                                    onClick={handleFilter}
                                    items={items}
                                    labelSelect="Campo"
                                />
                            </ContentHeaderFilter>
                            <If
                                condition={hasPermissions(
                                    "titulares.cadastrar"
                                )}
                            >
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate(
                                            "/comercial/holders/holderRegister"
                                        );
                                    }}
                                    buttonColor="var(--primary)"
                                    text="Novo Titular"
                                    icon={<Add />}
                                />
                            </If>
                        </ContentHeaderFilter>
                        <ContentTable>
                            <TableDataGrid
                                rows={dataHoldersList}
                                columns={columns}
                                onRowClick={(rowParams: GridRowParams) => {
                                    setSelectedRowHolder(rowParams.row);
                                }}
                                loading={loadingTable}
                                hideFooterPagination
                                hideFooter
                            />
                        </ContentTable>
                        {totalPage && (
                            <ContentPagination>
                                <Pagination
                                    color="primary"
                                    count={totalPage}
                                    hideNextButton
                                    hidePrevButton
                                    onChange={(event: any) =>
                                        getHolders(
                                            Number(event.target.textContent)
                                        )
                                    }
                                />
                            </ContentPagination>
                        )}
                        <ModalDeleteHolder
                            openDeleteHolderModal={openDeleteHolderModal}
                            handleCloseDeleteHolderModal={
                                handleCloseDeleteHolderModal
                            }
                            selectedRowHolder={selectedRowHolder}
                            dataHoldersList={dataHoldersList}
                            setDataHoldersList={setDataHoldersList}
                        />
                    </div>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
export default HoldersPage;
