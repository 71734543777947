import { yupResolver } from "@hookform/resolvers/yup";
import {
    AlertColor,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { defaultToast } from "../../helpers/toast/defaultToast";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { documentMask } from "../../helpers/document";
import { phoneMask } from "../../helpers/phone";
import { api, urlViaCep } from "../../services/api";
import { FormHolderRegisterPage } from "./styles";

type holderFormTypeValues = {
    fullName?: string;
    name: string;
    email?: string;
    phone?: string;
    document: string;
    holderLevel: string;
    zipCode: string;
    street?: string;
    number?: string;
    city?: string;
    state?: string;
    neighborhood?: string;
    complement?: string;
    country?: string;
};

const holderFormSchema = yup.object().shape({
    fullName: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    email: yup
        .string()
        .required("O campo é email é obrigatório")
        .email("Insira um email válido"),
    phone: yup
        .string()
        .min(14, "O mínimo de caracteres é 14")
        .required("O campo telefone é obrigatório"),
    document: yup
        .string()
        .min(14)
        .max(18)
        .required("O campo documento é obrigatório"),
    holderLevel: yup.string().required("O campo tipo de titular é obrigatório"),
    zipCode: yup.string().required("O campo cep é obrigatório"),
    street: yup.string().required("O campo rua é obrigatório"),
    number: yup.string().required("O campo número é obrigatório"),
    city: yup.string().required("O campo cidade é obrigatório"),
    state: yup.string().required("O campo estado é obrigatório"),
    neighborhood: yup.string().required("O campo bairro é obrigatório"),
    complement: yup.string(),
    country: yup.string().required("O campo país é obrigatório"),
});

function HolderRegisterPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("titulares.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [selectOptions, setSelectOptions] = useState([]);
    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useForm<holderFormTypeValues>({
        resolver: yupResolver(holderFormSchema),
    });
    const handleChangeSelectedOption = (event: SelectChangeEvent) => {
        setValue("holderLevel", event.target.value as string);
    };
    const [fullNameLabelInput, setFullNameLabelInput] =
        useState("Nome Completo");
    const [nameLabelInput, setNameLabelInput] = useState("Apelido");

    const handleChangeInputsLabel = () => {
        if (getValues("document").length > 14) {
            setFullNameLabelInput("Razão Social");
            setNameLabelInput("Nome de Fantasia");
        } else {
            setFullNameLabelInput("Nome Completo");
            setNameLabelInput("Apelido");
        }
    };

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        api.get("holderLevels/all")
            .then((response) => {
                setSelectOptions(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const getAndSetAddress = async () => {
        urlViaCep.get(`/${getValues("zipCode")}/json/`).then((response) => {
            setValue("street", response.data.logradouro);
            setValue("city", response.data.localidade);
            setValue("state", response.data.uf);
            setValue("neighborhood", response.data.bairro);
            setValue("country", "Brasil");
        });
    };

    function handleChangePhoneMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setValue("phone", phoneMask(value));
    }

    function handleChangeDocumentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue("document", documentMask(value));
    }

    const zipCodeMask = (value: string) => {
        let newValue = value.replace(/\D/g, "");
        newValue = newValue.replace(/(\d{5})(\d)/, "$1-$2");
        return newValue;
    };

    function handleChangeZipCodeMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue("zipCode", zipCodeMask(value));
    }

    const createNewHolder: SubmitHandler<holderFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.post(`/holders`, {
            full_name: formValues.fullName,
            name: formValues.name,
            document: formValues.document?.replaceAll(/\D/g, ""),
            email: formValues.email,
            phone: formValues.phone?.replaceAll(/\D/g, ""),
            holder_level_id: formValues.holderLevel,
            address: {
                street: formValues.street,
                street_number: formValues.number,
                complement: formValues.complement,
                neighborhood: formValues.neighborhood,
                city: formValues.city,
                state: formValues.state,
                country: formValues.country,
                zip_code: formValues.zipCode.replace("-", ""),
            },
        })
            .then((response) => {
                if (response.status) {
                    defaultToast("success", "Titular criado com sucesso");
                }
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/comercial/holders");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                defaultToast(
                    "error",
                    response.response.data.response.exception
                );
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            <FormHolderRegisterPage>
                <div className="topFormPage">
                    <h2>Cadastrar Novo Titular</h2>
                </div>
                <form onSubmit={handleSubmit(createNewHolder)}>
                    <h3>Dados Pessoais</h3>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="document"
                            render={({ field: { value = "", ref } }) => (
                                <InputGeneric
                                    onBlur={handleChangeInputsLabel}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="CPF/CNPJ"
                                    error={!!errors.document}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => handleChangeDocumentMask(event)}
                                    ref={ref}
                                    inputProps={{ maxLength: 18 }}
                                    autoFocus
                                    helperText={
                                        !!errors.document &&
                                        errors.document.message
                                    }
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="fullName"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label={fullNameLabelInput}
                                    error={!!errors.fullName}
                                    ref={ref}
                                    helperText={
                                        !!errors.fullName &&
                                        errors.fullName.message
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label={nameLabelInput}
                                    error={!!errors.name}
                                    ref={ref}
                                    helperText={
                                        !!errors.name && errors.name.message
                                    }
                                />
                            )}
                        />
                        <InputGeneric
                            size="small"
                            className="inputs"
                            label="Digite o email"
                            error={!!errors.email}
                            {...register("email")}
                            helperText={!!errors.email && errors.email.message}
                        />
                    </div>
                    <div className="inputsContainer">
                        <InputGeneric
                            size="small"
                            className="inputs"
                            label="Telefone para Contato"
                            error={!!errors.phone}
                            {...register("phone")}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangePhoneMask(event)}
                            inputProps={{ maxLength: 14 }}
                            helperText={!!errors.phone && errors.phone.message}
                        />
                        <Controller
                            control={control}
                            name="holderLevel"
                            render={({ field: { value = "" } }) => (
                                <FormControl sx={{ height: "56px" }}>
                                    <InputLabel
                                        size="small"
                                        id="demo-simple-select-label"
                                    >
                                        Tipo de Titular
                                    </InputLabel>
                                    <Select
                                        error={!!errors.holderLevel}
                                        className="inputs"
                                        size="small"
                                        placeholder="teste"
                                        id="demo-simple-select"
                                        value={value}
                                        onChange={handleChangeSelectedOption}
                                        label="Tipo de Titular"
                                    >
                                        {selectOptions?.map(
                                            ({
                                                id,
                                                name,
                                            }: {
                                                id: string;
                                                name: string;
                                            }) => (
                                                <MenuItem key={id} value={id}>
                                                    {name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    {!!errors.holderLevel && (
                                        <FormHelperText error>
                                            {errors.holderLevel.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </div>
                    <h3>Endereço</h3>
                    <div className="inputsContainer">
                        <InputGeneric
                            size="small"
                            className="inputs"
                            label="Digite o Cep"
                            error={!!errors.zipCode}
                            {...register("zipCode")}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeZipCodeMask(event)}
                            onBlur={async () => {
                                if (getValues("zipCode").length > 7) {
                                    getAndSetAddress();
                                }
                            }}
                            inputProps={{ maxLength: 9 }}
                            helperText={
                                !!errors.zipCode && errors.zipCode.message
                            }
                        />
                        <Controller
                            control={control}
                            name="street"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Rua/Logradouro"
                                    error={!!errors.street}
                                    ref={ref}
                                    helperText={
                                        !!errors.street && errors.street.message
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="inputsContainer">
                        <InputGeneric
                            size="small"
                            className="inputs"
                            label="Número"
                            error={!!errors.number}
                            {...register("number")}
                            helperText={
                                !!errors.number && errors.number.message
                            }
                        />
                        <InputGeneric
                            size="small"
                            className="inputs"
                            label="Complemento"
                            error={!!errors.complement}
                            {...register("complement")}
                            helperText={
                                !!errors.complement && errors.complement.message
                            }
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="neighborhood"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Bairro"
                                    error={!!errors.neighborhood}
                                    helperText={
                                        !!errors.neighborhood &&
                                        errors.neighborhood.message
                                    }
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="city"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Cidade"
                                    error={!!errors.city}
                                    helperText={
                                        !!errors.city && errors.city.message
                                    }
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="state"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    variant="outlined"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Estado"
                                    error={!!errors.state}
                                    helperText={
                                        !!errors.state && errors.state.message
                                    }
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="country"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    variant="outlined"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="País"
                                    error={!!errors.country}
                                    helperText={
                                        !!errors.country &&
                                        errors.country.message
                                    }
                                    ref={ref}
                                />
                            )}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => {
                                navigate("/comercial/holders");
                            }}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormHolderRegisterPage>
        </PageWrapper>
    );
}
export default HolderRegisterPage;
