import {
    Block,
    CheckBox,
    CloudOff,
    Delete,
    HourglassTop,
    Warning,
} from "@mui/icons-material";

import { InfoServers, Info } from "./styles";

export function CustomerStatus({ customerStatus }: { customerStatus: any }) {
    return (
        <InfoServers>
            <Info bgColor="#edf9e0" color="#3fb80a">
                <span>
                    <CheckBox color="success" />
                    <h1>Ok</h1>
                </span>
                <h2>{customerStatus.ok ?? 0}</h2>
            </Info>
            <Info bgColor="#f4e7cd" color="#e1a10a">
                <span color="success">
                    <Warning color="warning" />
                    <h1>Aviso</h1>
                </span>
                <h2>{customerStatus.warning ?? 0}</h2>
            </Info>
            <Info bgColor="#f2c7b3" color="#f07c16">
                <span>
                    <Block color="warning" />
                    <h1>Bloqueado</h1>
                </span>
                <h2>{customerStatus.blocked ?? 0}</h2>
            </Info>

            <Info bgColor="#f8b3b3" color="#e92929">
                <span>
                    <CloudOff color="error" />
                    <h1>Desligado</h1>
                </span>
                <h2>{customerStatus.off ?? 0}</h2>
            </Info>
            <Info bgColor="#c3daea" color="#3198f1">
                <span>
                    <HourglassTop color="primary" />
                    <h1>Aguardado Instalação</h1>
                </span>
                <h2>{customerStatus.waiting_activate ?? 0}</h2>
            </Info>
            <Info bgColor="#d7d7d7" color="#363636">
                <span>
                    <Delete />
                    <h1>Deletados</h1>
                </span>
                <h2>{customerStatus.deleted ?? 0}</h2>
            </Info>
        </InfoServers>
    );
}
