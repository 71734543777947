import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
`;

export const ContentRegisterInvoices = styled.div`
    height: 100%;
    margin: 10px 0px;
`;

export const ContentInputs = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 1009px) {
        flex-direction: column;
    }
`;

export const ContentButtonGeneratedInvoice = styled.div`
    width: 400px;
    margin: 0 1rem;

    @media (max-width: 1009px) {
        width: 100%;
    }
`;
