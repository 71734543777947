import styled from "styled-components";

export const ContractSingningPageStyles = styled.div`
    width: 100%;
    height: 100vh;
    min-width: 330px;
    border-radius: 8px;
    padding: 10px 10px;
    color: #49494c;
    display: flex;
    justify-content: space-around;
    color: white;
    background: #363638;
    overflow: none;

    .boldText {
        font-size: 0.8rem;

        font-weight: 700;
    }

    .verySmallText {
        font-size: 0.6rem;
    }

    .smallText {
        font-size: 0.7rem;
    }

    .leftContainer {
        border: 1px solid #7c8791;
        width: 25%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 0px;
        padding-top: 20px;
        background: #242426;
        height: 90%;
        overflow: auto;
    }

    .leftContent {
        width: 90%;
    }

    .headerLeftContainer {
        min-height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .informationsBox {
        padding: 5px 25px;
        border: 1px solid #7c8791;
        border-radius: 8px;
        margin-top: 20px;
        box-shadow: 0px 0px 2px 1px #7c8791;
        flex-wrap: wrap;
        background: #363638;
        max-height: 40vh;
    }

    .marginBottom20px {
        margin-bottom: 20px;
    }

    .rightContainer {
        width: 74%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 92%;
        padding-top: 20px;
    }

    .headerRightContainer {
        min-height: 65px;
        width: 80%;
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        height: 100%;

        .leftContainer {
            width: 80%;
        }
        .leftContainer {
            padding: 20px;
        }

        .rightContainer {
            width: 100%;
        }
        section {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        @media (max-width: 600px) {
            .leftContainer {
                width: 90%;
            }
        }
        .headerLeftContainer {
            min-height: 30px;
        }
    }
`;
