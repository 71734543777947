import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ChildrenType } from "../../@types";
import { api } from "../../services/api";
import { IAuthContext } from "./types";

const AUTH_TOKEN_LOCAL_STORAGE_KEY = "@Auth:token";
const AUTH_EMAIL_LOCAL_STORAGE_KEY = "@Auth:email";
const AUTH_NAME_LOCAL_STORAGE_KEY = "@Auth:name";

export const AuthContext = createContext({} as IAuthContext);

export function AuthProvider({ children }: ChildrenType) {
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [permissions, setPermissions] = useState<any>([]);

    function loadPermissions() {
        api.get("permissions/me", {}).then((response) => {
            setPermissions(
                response.data.response.permissions.map(
                    (item: { name: string }) => item.name
                )
            );
        });
    }

    function hasPermissions(permission: string) {
        return permissions.includes(permission);
    }

    const [token, setToken] = useState<string>("");

    useEffect(() => {
        const loadingStorageData = async () => {
            const storageToken = localStorage.getItem(
                AUTH_TOKEN_LOCAL_STORAGE_KEY
            );
            const storageEmail = localStorage.getItem(
                AUTH_EMAIL_LOCAL_STORAGE_KEY
            );
            const storageName = localStorage.getItem(
                AUTH_NAME_LOCAL_STORAGE_KEY
            );

            if (storageToken && storageEmail && storageName) {
                setToken(storageToken);
                api.defaults.headers.common.Authorization = `Bearer ${storageToken}`;
                loadPermissions();
            }
        };
        loadingStorageData();
    }, []);
    const signIn = async (email: string, password: string) => {
        const response = await api.post("/login", {
            email,
            password,
        });

        if (response.data.error) {
            console.log(response.data.error);
        } else {
            setToken(response.data);
            api.defaults.headers.common.Authorization = `Bearer ${response.data.response.token}`;

            localStorage.setItem(
                AUTH_TOKEN_LOCAL_STORAGE_KEY,
                response.data.response.token
            );
            localStorage.setItem(
                AUTH_EMAIL_LOCAL_STORAGE_KEY,
                JSON.stringify(response.data.response.user.email)
            );
            localStorage.setItem(
                AUTH_NAME_LOCAL_STORAGE_KEY,
                JSON.stringify(response.data.response.user.name)
            );
        }
        loadPermissions();
    };

    const signOut = () => {
        api.post("/logout");
        localStorage.clear();
        setToken("");
        navigate("/login");
        window.location.reload();
    };

    function interceptorAxios() {
        api.interceptors.response.use(
            (response) => {
                return response;
            },
            // eslint-disable-next-line func-names
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    useEffect(() => {
        interceptorAxios();
    }, []);

    return (
        <AuthContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                token,
                signed: !!token,
                signIn,
                signOut,
                hasPermissions,
                permissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
export const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};
