import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    CircularProgress,
    LinearProgress,
    Chip,
} from "@mui/material";
import { useEffect, useState } from "react";

import { If } from "../../../components/If";
import { useAuth } from "../../../contexts/contexts";
import { formatCurrencyToShow } from "../../../helpers/currency";
import { defaultToast } from "../../../helpers/toast/defaultToast";
import { api } from "../../../services/api";
import { CustomersProps } from "../types";
import { Plan } from "./types";

export interface Props {
    currentCustomer: CustomersProps | undefined;
}

function PlanContent({ currentCustomer }: Props) {
    const { hasPermissions } = useAuth();
    const [plans, setPlans] = useState<Plan[]>([]);
    const [planSelected, setPlanSelected] = useState<Plan>({
        id: currentCustomer?.account?.plan?.id ?? 0,
        product_id: currentCustomer?.account?.plan.product_id || 0,
        description: currentCustomer?.account?.plan.description || "string",
        standard_payment_price:
            currentCustomer?.account?.plan.standard_payment_price || 0,
        installment_standard:
            currentCustomer?.account?.plan.installment_standard || 0,
        recurring_payment_price:
            currentCustomer?.account?.plan.recurring_payment_price || 0,
        installment_recurring:
            currentCustomer?.account?.plan.installment_recurring || 0,
        subscription_standard_payment_price:
            currentCustomer?.account?.plan
                .subscription_standard_payment_price || 0,
        subscription_recurring_payment_price:
            currentCustomer?.account?.plan
                .subscription_recurring_payment_price || 0,
        subscription_annual_payment_price:
            currentCustomer?.account?.plan.subscription_annual_payment_price ||
            0,
        period_in_months: currentCustomer?.account?.plan.period_in_months || 0,
        is_active: currentCustomer?.account?.plan.is_active || 0,
        deleted_at: null,
    });

    function fetchPlans() {
        api.get("/plans/all").then((response) => {
            setPlans(response.data.response);
            // setPlanSelected(
            //     plans.find(
            //         (plan) => plan.id === currentCustomer?.account.app_plan_id
            //     ) as Plan
            // );
        });
    }

    function handleChangePlan(planId: number) {
        setPlanSelected(plans.find((plan) => plan.id === planId) as Plan);
        api.patch(
            `/account/update-plan/${currentCustomer?.account_id}/${planId}`
        )
            .then(() => {
                defaultToast("success", "Plano atualizado com sucesso!");
            })
            .catch((error) => {
                defaultToast(
                    "error",
                    error.response.data.message || "Erro ao atualizar plano!"
                );
            });
    }

    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <div className="mainContainer">
            <div className="titlePageContainer">
                <h2>Plano do Cliente</h2>
            </div>
            <div>
                <h4 style={{ marginBottom: "10px" }}>Plano</h4>

                <If condition={hasPermissions("produtos.editar")}>
                    <FormControl>
                        <InputLabel id="plan_select">
                            Selecionar Plano
                        </InputLabel>
                        <Select
                            labelId="plan_select"
                            id="demo-simple-select"
                            value={planSelected.id}
                            label="Selecionar Plano"
                            onChange={(e) => {
                                handleChangePlan(Number(e.target.value));
                            }}
                            size="small"
                            sx={{ width: "250px" }}
                            disabled={
                                plans.length === 0 || !currentCustomer?.account
                            }
                        >
                            {plans.map((plan: Plan) => {
                                return (
                                    <MenuItem key={plan.id} value={plan.id}>
                                        {plan.description}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <If condition={plans.length === 0}>
                            <LinearProgress />
                        </If>
                    </FormControl>
                </If>
            </div>
            <h4 style={{ marginBottom: "10px" }}>Detalhes do plano</h4>
            <Divider />
            {currentCustomer?.account ? (
                <div>
                    <div className="contentFields">
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>
                                    {planSelected.description ||
                                        "Plano não identificado"}
                                </strong>
                            </p>
                        </div>
                        <div className="contentRows">
                            <p>
                                <Chip
                                    label={
                                        planSelected.is_active === 1
                                            ? "Plano ativo"
                                            : "Plano inativo"
                                    }
                                    color={
                                        planSelected.is_active === 1
                                            ? "success"
                                            : "error"
                                    }
                                    size="small"
                                />
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>
                                    Parcelas disponíveis recorrente:{" "}
                                </strong>
                                {planSelected.installment_recurring ||
                                    "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Parcelas disponíveis padrão: </strong>
                                {planSelected.installment_standard ||
                                    "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Período do plano: </strong>
                                {planSelected.period_in_months} meses
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Valor da ativação recorrente: </strong>
                                {formatCurrencyToShow(
                                    planSelected.recurring_payment_price
                                ) || "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Valor da ativação padrão: </strong>R$
                                {formatCurrencyToShow(
                                    planSelected.standard_payment_price
                                ) || "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Valor assinatura recorrente: </strong>
                                {formatCurrencyToShow(
                                    planSelected.subscription_recurring_payment_price
                                ) || "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Valor assinatura padrão: </strong>
                                {formatCurrencyToShow(
                                    planSelected.subscription_standard_payment_price
                                ) || "Não informado"}
                            </p>
                        </div>
                        <div className="contentRows">
                            <p className="boldText">
                                <strong>Valor assinatura anual: </strong>
                                {formatCurrencyToShow(
                                    planSelected.subscription_annual_payment_price
                                ) || "Não informado"}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Cliente Sem detalhes de plano</p>
            )}
        </div>
    );
}
export default PlanContent;
