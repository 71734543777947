import { Add, ViewCarousel, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import TableDataGrid from "../../components/TableDataGrid";
import { Container, ContentButtonAddPulicity, ContentTable } from "./styles";

import PageWrapper from "../../components/PageWrapper";
import { PageDefault } from "../../components/PageDefault";
import { If } from "../../components/If";
import ButtonGeneric from "../../components/ButtonGeneric";
import ModalConfirm from "../../components/ModalConfirm";

import { useAuth } from "../../contexts/contexts";

import { api } from "../../services/api";

import { PublicityProps } from "./types";
import { defaultToast } from "../../helpers/toast/defaultToast";

function Publicity() {
    const { hasPermissions, permissions } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasPermissions("publicidade.listar") && permissions.length > 0) {
            navigate("/Error");
        }
    }, [permissions]);

    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [publicities, setPublicities] = useState<PublicityProps[]>([]);
    const [idPublicitySelected, setIdPublicitySelected] = useState<
        number | null
    >(null);

    const getAllPublicities = async () => {
        try {
            setLoading(true);

            const responseAllPublicities = await api.get("advertising");

            if (responseAllPublicities.status) {
                setPublicities(responseAllPublicities.data.response);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao pegar publicidades");
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePublicity = async () => {
        try {
            setLoading(true);
            const responseDeletePublicity = await api.delete(
                `advertising/${idPublicitySelected}`
            );

            if (responseDeletePublicity.status) {
                defaultToast("success", "Publicidade deletada com sucesso");
                getAllPublicities();
                setShowModalDelete(false);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao deletar publicidade");
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "title",
            flex: 15,
            headerName: "Título",
            align: "left",
        },
        {
            field: "link",
            flex: 20,
            headerName: "Link",
            align: "left",
        },
        {
            field: "phone",
            flex: 10,
            headerName: "Telefone",
            align: "left",
        },
        {
            field: "description",
            flex: 20,
            headerName: "Descrição",
            align: "left",
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 10,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <If condition={hasPermissions("publicidade.editar")}>
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `/settings/publicity/editPublicity?id=${params.row.id}`
                                    )
                                }
                            >
                                <Edit />
                            </IconButton>
                        </If>
                        <If condition={hasPermissions("publicidade.excluir")}>
                            <IconButton
                                onClick={() => {
                                    setIdPublicitySelected(params.row.id);
                                    setShowModalDelete(true);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </If>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getAllPublicities();
    }, []);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Publicidades",
                    subtitle: "Configurações de publicidades do monitor",
                    icon: <ViewCarousel sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <ContentButtonAddPulicity>
                        <ButtonGeneric
                            onclick={() => {
                                navigate("/settings/publicity/newPublicity");
                            }}
                            buttonColor="var(--primary)"
                            text="Criar publicidade"
                            icon={<Add />}
                        />
                    </ContentButtonAddPulicity>

                    <ContentTable>
                        <TableDataGrid
                            hideExports
                            columns={columns}
                            rows={publicities}
                            loading={loading}
                        />
                    </ContentTable>
                    <ModalConfirm
                        titleButton="DELETAR"
                        visible={showModalDelete}
                        setVisible={setShowModalDelete}
                        titleModal="Deseja realmente deletar esta publicidade?"
                        isLoadingButton={loading}
                        onClick={handleDeletePublicity}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}

export default Publicity;
