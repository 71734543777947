import { LoadingButton } from "@mui/lab";
import { AxiosError } from "axios";
import { Help } from "@mui/icons-material";
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import imageTest from "../../assets/images/general-settings-instructions.jpg";
import HelperGenericModal from "../../components/HelperGenericModal";
import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import {
    GeneralConfigsProps,
    SettingConfigSection,
} from "./SettingConfigSection";
import { SettingsConfigPageStyle } from "./styles";

import { ValuesInputsProps } from "./types";
import { defaultToast } from "../../helpers/toast/defaultToast";

function SettingsConfigPage() {
    const [isLoading, setIsLoading] = useState(true);

    // helper modal
    const [openHelperGenericModal, setOpenHelperGenericModal] = useState(false);
    const handleCloseHelperGenericModal = () => {
        setOpenHelperGenericModal(false);
    };

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("configuracao.lembrete.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const [configsData, setConfigsData] = useState<GeneralConfigsProps[]>();
    const [valuesInputs, setValuesInputs] = useState<ValuesInputsProps[]>([]);
    const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

    const getSettings = async () => {
        try {
            setIsLoading(true);
            const responseSettings = await api.get("/settings");

            if (responseSettings.status) {
                setConfigsData(responseSettings.data.response);
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getSettings();
    }, []);

    const handleChangeInputs = (key: string, value: any) => {
        const existingIndex = valuesInputs.findIndex(
            // eslint-disable-next-line
            (props) => props.key === key
        );

        if (existingIndex !== -1) {
            if (valuesInputs[existingIndex].value !== value) {
                const updatedValuesInputs = [...valuesInputs];
                updatedValuesInputs[existingIndex] = {
                    ...updatedValuesInputs[existingIndex],
                    // eslint-disable-next-line
                    value: value,
                };
                setValuesInputs(updatedValuesInputs);
            }
        } else {
            const objectInput = {
                key,
                value,
            };
            setValuesInputs([...valuesInputs, objectInput]);
        }
    };

    const handleSaveConfig = async () => {
        try {
            setLoadingButtonSave(true);
            const responseSaveConfig = await api.put("settings", {
                settings: valuesInputs,
            });

            if (responseSaveConfig.status) {
                defaultToast("success", "Alteração concluída");
                setValuesInputs([]);
                getSettings();
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoadingButtonSave(false);
        }
    };

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <SettingsConfigPageStyle>
                    <div className="topPageContainer">
                        <button
                            type="button"
                            onClick={() => setOpenHelperGenericModal(true)}
                            className="instructionsContainer"
                        >
                            <p>Ajuda</p>
                            <Help />
                        </button>
                    </div>
                    <h2>Configurações Gerais</h2>
                    <div>
                        {configsData?.map((config) => (
                            <SettingConfigSection
                                key={config.key}
                                config={config}
                                handleChangeInputs={handleChangeInputs}
                            />
                        ))}
                    </div>

                    {valuesInputs.length > 0 && (
                        <div>
                            <LoadingButton
                                variant="contained"
                                onClick={handleSaveConfig}
                                loading={loadingButtonSave}
                            >
                                Salvar
                            </LoadingButton>
                        </div>
                    )}
                    <HelperGenericModal
                        openHelperGenericModal={openHelperGenericModal}
                        handleCloseHelperGenericModal={
                            handleCloseHelperGenericModal
                        }
                        text="Para configurar o valor dos campos, basta que você clique dentro do input que você deseja alterar o valor, faça as devidas mudanças, e para confirmar basta dar um clique fora do campo alterado, ao fazer a mudança, o sistema retornará uma mensagem de alteração concluída. Em relação aos campos de ativar/desativar configuração, basta um clique para que ocorra a mudança do valor, quando o switch está azul e para a direita, quer dizer que a configuração está ativa, caso contrário, inativa."
                        src={imageTest}
                    />
                </SettingsConfigPageStyle>
            )}
        </PageWrapper>
    );
}

export default SettingsConfigPage;
