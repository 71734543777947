import { LoadingButton } from "@mui/lab";
import { Modal, TextField } from "@mui/material";
import { useState } from "react";

import { Container, ContentInput, TitleModal, ContentButton } from "./styles";

interface ModalAddHelperProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
}

export default function ModalAddHelper({
    visible,
    setVisible,
}: ModalAddHelperProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const handleAddHelper = async () => {
        try {
            setLoading(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <TitleModal>Adicionar Helper</TitleModal>
                <ContentInput>
                    <TextField
                        fullWidth
                        id="title-basic"
                        label="Título"
                        variant="outlined"
                        size="small"
                    />
                </ContentInput>
                <ContentButton>
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={handleAddHelper}
                    >
                        Adicionar
                    </LoadingButton>
                </ContentButton>
            </Container>
        </Modal>
    );
}
