import styled from "styled-components";

export const DrawerStyle = styled.div`
    .logoInDrawerMenu {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logoInDrawerMenu img {
        width: 100px;
        border-radius: 50%;
    }
`;

export const NavbarStyle = styled.div`
    display: flex;
    flex-direction: row;
    width: 14vw;
    height: 100vh;
    /* position: absolute; */
    z-index: 1000;
    position: fixed;

    .navbarContent {
        background-color: var(--primary);
    }

    .logoNavbar {
        width: 50px;
        border-radius: 50%;
        margin-top: 20px;
    }
`;
