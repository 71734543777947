/* eslint-disable */
import {
    FollowTheSigns,
    ExpandMore,
    WhatsApp,
    Delete,
    Search,
    CheckCircle,
    DoneAll,
    Edit,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    IconButton,
    Button,
    TextField,
    Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { debounce } from "lodash";

import { useEffect, useState } from "react";

import {
    Container,
    LevelsFollowUpProjects,
    ContentTable,
    ContentButton,
    Header,
    ContentHeaderAccordion,
    ContentInput,
} from "./styles";

import PreloadFb from "../../components/Preload";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import ModalAddOrEditStep from "../../components/ModalAddOrEditStep";
import ModalConfirm from "../../components/ModalConfirm";

import { defaultToast } from "../../helpers/toast/defaultToast";

import {
    StepsFollowUpProjectsProps,
    ProjectsProps,
    ProjectAccompaniment,
} from "./types";

import { api } from "../../services/api";
import { If } from "../../components/If";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/contexts";

export default function FollowUpProjects() {
    const [visibleModalAddStep, setVisibleModalAddStep] =
        useState<boolean>(false);
    const [visibleModalDeleteStep, setVisibleModalDeleteStep] =
        useState<boolean>(false);
    const [valueSearch, setValueSearch] = useState<string>("");
    const [stepsFollowUpProjects, setStepsFollowUpProjects] = useState<
        StepsFollowUpProjectsProps[]
    >([]);
    const [projectSelected, setProjectSelected] =
        useState<StepsFollowUpProjectsProps>();
    const [idStepSelected, setIsStepSelected] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingBtnDeleteStep, setLoadingBtnDeleteStep] =
        useState<boolean>(false);

    const { hasPermissions } = useAuth();

    const getStepsFollowUp = async () => {
        try {
            setLoading(true);
            const responseStepsFollowUp = await api("projectStepAccompaniment");

            if (responseStepsFollowUp.status) {
                const listStepsFollowUp = responseStepsFollowUp.data.response;

                const compareByOrder = (a: any, b: any) => a.order - b.order;

                const sortedStepsFollowUpProjects =
                    listStepsFollowUp.sort(compareByOrder);

                setStepsFollowUpProjects(sortedStepsFollowUpProjects);
            }
        } catch (error) {
            console.error(error);
            defaultToast(
                "error",
                "Erro ao pegar dados de passos de acompanhamento do aplicativo"
            );
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (id: number) => {
        setVisibleModalDeleteStep(true);
        setIsStepSelected(id);
    };

    const valueOrderSteps = stepsFollowUpProjects.map((value) => {
        return value.order;
    });

    const compareByOrder = (a: any, b: any) => a - b;

    const stepsNumberOrdened = valueOrderSteps.sort(compareByOrder).pop();

    const handleCheckApp = async (idApp: number, currentStep: number) => {
        try {
            const responseCheckApp = await api.put(
                `projectStepAccompaniment/project/${idApp}`,
                {
                    step_id: currentStep + 1,
                }
            );

            if (responseCheckApp.status) {
                defaultToast(
                    "success",
                    "Aplicativo transferido para o step seguinte"
                );
                getStepsFollowUp();
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao atualizar app");
        }
    };

    const handleFinalizeApp = async (idApp: number) => {
        try {
            const responseFinalizeApp = await api.get(
                `projectStepAccompaniment/project/complete/1/${idApp}`
            );

            if (responseFinalizeApp.status) {
                defaultToast(
                    "success",
                    "Status do aplicativo alterado com sucesso"
                );
                getStepsFollowUp();
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao atualizar status do aplicativo");
        }
    };

    const handleDeleteStep = async () => {
        try {
            setLoadingBtnDeleteStep(true);
            const responseDeleteStep = await api.delete(
                `projectStepAccompaniment/${idStepSelected}`
            );

            if (responseDeleteStep.status) {
                setVisibleModalDeleteStep(false);
                defaultToast("success", "Step deletado com sucesso!");
                getStepsFollowUp();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingBtnDeleteStep(false);
        }
    };

    function daysPassedSinceDate(pastDate: Date | string): number {
        const millisecondsPerDay: number = 24 * 60 * 60 * 1000; // Miliseconds in a day

        const datePassed: Date = new Date(pastDate);
        const currentDate: Date = new Date();

        // Calculating the difference in milliseconds between the current date and the passed date
        const timeDifference: number =
            currentDate.getTime() - datePassed.getTime();

        // Calculating the number of days
        const daysPassed: number = Math.floor(
            timeDifference / millisecondsPerDay
        );

        return daysPassed;
    }

    const columns: GridColDef[] = [
        {
            field: "updated_at",
            flex: 15,
            headerName: "Início",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span>
                        {params.row?.created_at
                            ? new Date(
                                  params.row?.updated_at
                              ).toLocaleDateString()
                            : "-"}
                    </span>
                );
            },
        },
        {
            field: "days",
            flex: 15,
            headerName: "Dias passados",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span>
                        {daysPassedSinceDate(params.row?.updated_at) > 0
                            ? `Há ${daysPassedSinceDate(
                                  params.row?.updated_at
                              )} dias atrás`
                            : "Atualizado recente"}
                    </span>
                );
            },
        },
        {
            field: "name_app",
            flex: 15,
            headerName: "Nome App",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span>{params.row?.customer?.name ?? "(sem nome)"}</span>
                );
            },
        },
        {
            field: "holder_name",
            flex: 15,
            headerName: "Responsável",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <a
                            href={`https://api.whatsapp.com/send?phone=55${params.row?.data?.holder?.phone}`}
                            target="_blank"
                        >
                            <IconButton title="Link de contato">
                                <WhatsApp />
                            </IconButton>
                        </a>
                        {params.row?.data?.holder?.name}
                    </div>
                );
            },
        },
        {
            field: "seller",
            flex: 20,
            headerName: "Vendedor",
            align: "left",
            renderCell: (params: GridRenderCellParams) => {
                return <span>{params.row?.data?.user?.name}</span>;
            },
        },
        // {
        //     field: "status",
        //     flex: 20,
        //     headerName: "Status",
        //     align: "left",
        //     renderCell: (params: GridRenderCellParams) => {
        //         return (
        //             <span>
        //                 {String(
        //                     params.row.customer?.customer_status?.name ??
        //                         "sem status"
        //                 ).toLocaleUpperCase()}
        //             </span>
        //         );
        //     },
        // },
        {
            field: "actions",
            flex: 10,
            headerName: "Ações",
            align: "right",
            headerAlign: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <If condition={hasPermissions("projetos.confirmar")}>
                            {stepsNumberOrdened !== params.row.step_id && (
                                <IconButton
                                    color="primary"
                                    title="Pŕoximo step"
                                    onClick={() =>
                                        handleCheckApp(
                                            params.row.id,
                                            params.row.step_id
                                        )
                                    }
                                >
                                    <CheckCircle />
                                </IconButton>
                            )}
                        </If>

                        {stepsNumberOrdened === params.row.step_id && (
                            <IconButton
                                color="primary"
                                title="Concluir"
                                onClick={() => handleFinalizeApp(params.row.id)}
                            >
                                <DoneAll />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
    ];

    const handleOpenModalEditStep = (
        stepSelected: StepsFollowUpProjectsProps
    ) => {
        setProjectSelected(stepSelected);
        setVisibleModalAddStep(true);
    };

    const navigate = useNavigate();

    const handleChangeValueSearch = debounce((value: string) => {
        setValueSearch(value);
    }, 100);

    useEffect(() => {
        getStepsFollowUp();
        if (!hasPermissions("projetos.visualizar")) {
            navigate("/not-found");
        }
    }, []);

    const handleSearchApps = async () => {
        try {
            setLoading(true);
            const responseAppsFinded = await api.get(
                `projectStepAccompaniment${
                    valueSearch.length > 0 ? `?query=${valueSearch}` : ""
                }`
            );

            if (responseAppsFinded.status) {
                const listStepsFollowUp = responseAppsFinded.data.response;

                const compareByOrder = (a: any, b: any) => a.order - b.order;

                const sortedStepsFollowUpProjects =
                    listStepsFollowUp.sort(compareByOrder);

                setStepsFollowUpProjects(sortedStepsFollowUpProjects);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao pesquisar por aplicativos");
        } finally {
            setLoading(false);
        }
    };

    function countLessThanTwoDays(arrayOfObjects: ProjectsProps[]): number {
        const today: Date = new Date(); // Current date
        const twelveHoursMS: number = 12 * 60 * 60 * 1000;

        const objectsLessThanTwoDays: ProjectsProps[] = arrayOfObjects.filter(
            (object) => {
                const updatedAt: Date = new Date(object.updated_at);
                const timeDifference: number =
                    today.getTime() - updatedAt.getTime();
                return timeDifference < twelveHoursMS;
            }
        );

        return objectsLessThanTwoDays.length;
    }

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Acompanhamento",
                    subtitle: "Acompanhamento das fases dos aplicativos",
                    icon: <FollowTheSigns sx={{ color: "#fff" }} />,
                }}
                heightAuto
            >
                <>
                    <Container>
                        <Header>
                            <ContentInput>
                                <TextField
                                    fullWidth
                                    id="search-field"
                                    label="Pesquisa"
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) =>
                                        handleChangeValueSearch(
                                            event.target.value
                                        )
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleSearchApps();
                                        }
                                    }}
                                />
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleSearchApps}
                                    loading={loading}
                                >
                                    Buscar
                                    <Search />
                                </LoadingButton>
                            </ContentInput>
                            {/* <ContentButton>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => setVisibleModalAddStep(true)}
                                >
                                    Adicionar Step
                                </Button>
                            </ContentButton> */}
                        </Header>
                        {loading ? (
                            <PreloadFb />
                        ) : (
                            <LevelsFollowUpProjects>
                                {stepsFollowUpProjects.map((value, index) => {
                                    return (
                                        <Accordion
                                            key={index}
                                            sx={{
                                                borderBottom:
                                                    "1px solid var(--primary)",
                                                marginBottom: "10px",
                                            }}
                                            disabled={
                                                value.projects.length === 0
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls={`collapse-item-${index}-content`}
                                                id={`collapse-item-${index}-heading`}
                                            >
                                                <ContentHeaderAccordion>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <Typography>
                                                            {`${value.name}`}
                                                        </Typography>
                                                        <If
                                                            condition={
                                                                countLessThanTwoDays(
                                                                    value.projects
                                                                ) > 0
                                                            }
                                                        >
                                                            <Chip
                                                                color="success"
                                                                label={`+ ${countLessThanTwoDays(
                                                                    value.projects
                                                                )} atualizado recentemente`}
                                                                size="small"
                                                            />
                                                        </If>
                                                    </div>

                                                    <div>
                                                        {/* <IconButton
                                                            onClick={() =>
                                                                handleOpenModalEditStep(
                                                                    value
                                                                )
                                                            }
                                                            color="primary"
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleOpenModal(
                                                                    value.id
                                                                )
                                                            }
                                                        >
                                                            <Delete color="error" />
                                                        </IconButton> */}
                                                        <Chip
                                                            label={`Total - ${value.projects.length}`}
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        />
                                                    </div>
                                                </ContentHeaderAccordion>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ContentTable>
                                                    <TableDataGrid
                                                        hideExports
                                                        hideFooter
                                                        columns={columns}
                                                        rows={value.projects}
                                                        disableColumnMenu={true}
                                                        toolBar={false}
                                                    />
                                                </ContentTable>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </LevelsFollowUpProjects>
                        )}
                    </Container>
                    <ModalConfirm
                        titleButton="Confirmar"
                        titleModal="Deseja realmente realizar deletar este step?"
                        isLoadingButton={loadingBtnDeleteStep}
                        visible={visibleModalDeleteStep}
                        setVisible={setVisibleModalDeleteStep}
                        onClick={handleDeleteStep}
                    />
                    <ModalAddOrEditStep
                        setProjectSelected={setProjectSelected}
                        projectSelected={projectSelected}
                        lastNumberOrder={
                            stepsNumberOrdened ? stepsNumberOrdened : 0
                        }
                        getStepsFollowUp={getStepsFollowUp}
                        visible={visibleModalAddStep}
                        setVisible={setVisibleModalAddStep}
                    />
                </>
            </PageDefault>
        </PageWrapper>
    );
}
