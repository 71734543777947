import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { HoldersProps } from "../../pages/HoldersPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteHolderStyle } from "./styles";

interface Props {
    openDeleteHolderModal: boolean;
    handleCloseDeleteHolderModal: () => void;
    selectedRowHolder: GridRowParams["row"];
    setDataHoldersList: (values: HoldersProps[]) => void;
    dataHoldersList: HoldersProps[];
}

function ModalDeleteHolder({
    openDeleteHolderModal,
    handleCloseDeleteHolderModal,
    selectedRowHolder,
    setDataHoldersList,
    dataHoldersList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteHolder() {
        setIsLoadingButton(true);
        api.delete(`holders/${selectedRowHolder.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteHolderModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataHoldersList(
                    dataHoldersList.filter((item: { id: number }) => {
                        return item.id !== selectedRowHolder.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteHolderModal}
            onClose={handleCloseDeleteHolderModal}
        >
            <ModalDeleteHolderStyle>
                <Box className="modalContainer">
                    <p>{`Deseja excluir o Titular "${selectedRowHolder.name}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteHolderModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteHolder()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteHolderStyle>
        </Modal>
    );
}
export default ModalDeleteHolder;
