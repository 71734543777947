import styled from "styled-components";
import Lottie from "lottie-react";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Message = styled.h2`
    text-align: center;
`;

export const Animation = styled(Lottie)`
    width: 400px;

    @media (max-width: 425px) {
        width: 300px;
    }
`;
