import { Help, Edit, Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { If } from "../../components/If";
import ModalAddHelper from "../../components/ModalAddHelper";
import ModalConfirm from "../../components/ModalConfirm";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import { useAuth } from "../../contexts/contexts";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { Container, ContentTable, Header } from "./styles";
import { HelpersMonitorsProps } from "./types";

export default function HelpersMonitorPage() {
    const [visibleModalHelper, setVisibleModalHelper] =
        useState<boolean>(false);

    const [helpersMonitors, setHelpersMonitors] = useState<
        HelpersMonitorsProps[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [visibleModalConfirm, setVisibleModalConfirm] =
        useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState<boolean>(false);
    const [idHelperSelected, setIdHelperSelected] = useState<number | null>(
        null
    );

    const { permissions, hasPermissions } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (permissions.length > 0) {
            if (!hasPermissions("helpers.listar")) {
                navigate("/Error");
            }
        }
    }, [permissions]);

    const getAllHelpers = async () => {
        try {
            setLoading(true);
            const responseAllHelpers = await api.get("helperMonitor/all");

            if (responseAllHelpers.status) {
                setHelpersMonitors(responseAllHelpers.data.response);
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.response[0]);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteHelper = async () => {
        try {
            setLoadingModal(true);
            setLoading(true);
            const responseDeleteHelper = await api.delete(
                `helperMonitor/deleteHelper/${idHelperSelected}`
            );

            if (responseDeleteHelper.status) {
                defaultToast("success", "Helper deletado com sucesso!");
                getAllHelpers();
                setVisibleModalConfirm(false);
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.response[0]);
        } finally {
            setLoadingModal(false);
        }
    };

    const handleOpenModalDelete = (idSelected: number | null) => {
        setVisibleModalConfirm(true);
        setIdHelperSelected(idSelected);
    };

    const columns: GridColDef[] = [
        {
            field: "title",
            flex: 10,
            headerName: "Título",
            align: "left",
        },
        {
            field: "url_video",
            flex: 20,
            headerName: "Url do vídeo",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <a
                        href={params.row.url_video}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {params.row.url_video}
                    </a>
                </div>
            ),
        },
        {
            field: "url_application",
            flex: 20,
            headerName: "Link da tela do helper",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <a
                        href={params.row.url_application}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {params.row.url_application}
                    </a>
                </div>
            ),
        },
        {
            field: "url_redirect",
            flex: 20,
            headerName: "Url de redirecionamento",
            align: "left",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <a
                        href={params.row.url_redirect}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {params.row.url_redirect}
                    </a>
                </div>
            ),
        },
        {
            field: "actions",
            flex: 10,
            headerName: "Ações",
            align: "right",
            headerAlign: "right",
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <If condition={hasPermissions("helpers.editar")}>
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/settings/helper-monitor/helperEdit?id=${params.row.id}`
                                )
                            }
                        >
                            <Edit color="info" />
                        </IconButton>
                    </If>
                    <If condition={hasPermissions("helpers.deletar")}>
                        <IconButton
                            onClick={() => handleOpenModalDelete(params.row.id)}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </If>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getAllHelpers();
    }, []);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Helper Monitor",
                    subtitle: "Configurações de helper do monitor",
                    icon: <Help sx={{ color: "#fff" }} />,
                }}
            >
                <>
                    <Container>
                        <Header>
                            <If condition={hasPermissions("helpers.criar")}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(
                                            "/settings/helper-monitor/helpersAdd"
                                        )
                                    }
                                >
                                    Adicionar Helper
                                </Button>
                            </If>
                        </Header>
                        <ContentTable>
                            <TableDataGrid
                                hideExports
                                columns={columns}
                                rows={helpersMonitors}
                                loading={loading}
                            />
                        </ContentTable>
                    </Container>
                    <ModalConfirm
                        onClick={handleDeleteHelper}
                        isLoadingButton={loadingModal}
                        titleButton="Confirmar"
                        titleModal="Deseja realmente deletar este helper?"
                        setVisible={setVisibleModalConfirm}
                        visible={visibleModalConfirm}
                    />
                    <ModalAddHelper
                        visible={visibleModalHelper}
                        setVisible={setVisibleModalHelper}
                    />
                </>
            </PageDefault>
        </PageWrapper>
    );
}
