import Chip, { ChipProps } from "@mui/material/Chip";

const EM_ABERTO = 1;
const FECHADA = 2;
const ATRASO = 3;
const PAGA = 4;
const SEM_PENDENCIA = 5;
const CANCELADA = 6;
const INATIVO = 7;

export function getStatusChip(id: number) {
    let label: string;
    let color: ChipProps["color"];

    switch (id) {
        case EM_ABERTO:
            label = "Em Aberto";
            color = "default";
            break;
        case FECHADA:
            label = "Fechada";
            color = "primary";
            break;
        case ATRASO:
            label = "Atraso";
            color = "warning";
            break;
        case PAGA:
            label = "Paga";
            color = "success";
            break;
        case SEM_PENDENCIA:
            label = "Sem Pendência";
            color = "secondary";
            break;
        case CANCELADA:
            label = "Cancelada";
            color = "error";
            break;
        case INATIVO:
            label = "Inativo";
            color = "default";
            break;
        default:
            label = "Desconhecido";
            color = "error";
    }

    return <Chip label={label} color={color} size="small" />;
}
