/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { CopyAll, CreditCard, PixOutlined } from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    ListItemIcon,
    AlertColor,
    Modal,
    SelectChangeEvent,
    Divider,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import creditCardType from "credit-card-type";
import * as React from "react";
import { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import { If } from "../../components/If";
import InputGeneric from "../../components/InputGeneric";
import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { creditCardMask } from "../../helpers/creditCard";
import { formatCurrencyToShow } from "../../helpers/currency";
import { cvvMask } from "../../helpers/cvvCode";
import { documentMask } from "../../helpers/document";
import { expirationDateMask } from "../../helpers/expirationDateCard";
import { phoneMask } from "../../helpers/phone";
import { api } from "../../services/api";
import {
    ButtonsContainerModal,
    PaymentActivationLinkPageStyles,
    TitleModal,
} from "./styles";
import { personalDataInfos, creditCardDataInfos, fullTypes } from "./types";

const SUCCESS_STATUS_ID = 1;
const CANCELED_STATUS_ID = 3;
const REVERSED_STATUS_ID = 4;

function PaymentActivationLinkPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const [currentTab, setCurrentTab] = useState("1");

    const [paymentMethod, setPaymentMethod] = useState("1");
    const handleChangePaymentMethod = (
        event: React.SyntheticEvent,
        newValue: string
    ) => {
        setPaymentMethod(newValue);
    };

    const personalDataInfosSchema = yupResolver(
        yup.object().shape({
            name: yup.string().required(),
            email: yup.string().email().required(),
            document: yup.string().required(),
            phone: yup.string().required(),
            date: yup.string(),
            zipCode: yup.string(),
            street: yup.string(),
            number: yup.string(),
            city: yup.string(),
            state: yup.string(),
            neighborhood: yup.string(),
            complement: yup.string(),
            country: yup.string(),
        })
    );

    const creditCardDataInfosSchema = yupResolver(
        yup.object().shape({
            nameOwnerCard: yup.string().required(),
            namePrintedCard: yup.string().required(),
            numberCard: yup.string().required(),
            expirationDate: yup.string().required(),
            cvv: yup.number().required(),
            flagCard: yup.string(),
            installment: yup.string().required(),
        })
    );

    const {
        setValue: setPersonalDataValue,
        handleSubmit: handlePersonalDataSubmit,
        control: personalDataControl,
        formState: { errors: personalDataErrors },
    } = useForm<personalDataInfos>({
        resolver: personalDataInfosSchema,
    });

    const {
        // getValues: getCreditCardDataValues,
        watch: watchCreditCardData,
        register: registerCreditCardData,
        setValue: setCreditCardDataValue,
        handleSubmit: handleCreditCardDataSubmit,
        control: creditCardDataControl,
        formState: { errors: creditCardDataErrors },
    } = useForm<creditCardDataInfos>({
        resolver: creditCardDataInfosSchema,
    });

    const [personalFormData, setPersonalFormData] = useState({});

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const handleSendPersonalDataInfos: SubmitHandler<
        personalDataInfos
    > = async (formValues) => {
        setCurrentTab("2");
        setPersonalFormData(formValues);
    };
    const [creditCardFormData, setCreditCardFormData] = useState({});

    const [dataFullInformations, setDataFullInformations] = useState<fullTypes>(
        {}
    );
    useEffect(() => {
        setCreditCardDataValue("installment", "1");
        setDataFullInformations({ ...personalFormData, ...creditCardFormData });
    }, [personalFormData, creditCardFormData, setDataFullInformations]);

    // MODAL CONFIGS
    const [confirmPaymentModal, setConfirmPaymentModal] = useState(false);
    const handleCloseConfirmPaymentModal = () => {
        setConfirmPaymentModal(false);
    };

    const handleSendCreditCardDataInfos: SubmitHandler<
        creditCardDataInfos
    > = async (formValues) => {
        setCreditCardFormData(formValues);
        setConfirmPaymentModal(true);
    };

    const [currentProposal, setCurrentProposal] = useState<any>({});

    const [monitoring, setMonitoring] = useState(0);
    const [time, setTime] = useState<any>(null);

    // FUNÇÃO QUE FICA FAZENDO NOVAS REQUISIÇÕES
    const timeout = () => {
        setTime(
            setTimeout(() => {
                setMonitoring((prev) => prev + 1);
            }, 300000)
        );
    };

    const [installments, setInstallments] = useState<number>(0);

    const handleChangeSelectedInstallmentOption = (
        event: SelectChangeEvent
    ) => {
        setCreditCardDataValue("installment", event.target.value as string);
    };

    function productPayment(id: number): string {
        switch (id) {
            case 1:
                return "Avulso";
            case 2:
                return "de Ativação";
            case 3:
                return "de Fatura Mensal";
            case 4:
                return "de Implementação";
            default:
                return "-";
        }
    }

    // Estados que preciso se o pagamento for recorrente

    const [currentQrCode, setCurrentQrCode] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/checkout/client/${myParam}`)
            .then((response) => {
                // setIsLoading(false);
                setCurrentProposal(response.data.response);
                setIsLoading(false);
                return response.data.response;
            })
            .then((currentProposal) => {
                // verifica se é recorrente, se sim, seta os valores corretamente

                if (
                    currentProposal?.payment.form_payment
                        .type_form_payment_type_id === 2
                ) {
                    setInstallments(1);
                } else {
                    setInstallments(
                        currentProposal.payment?.form_payment
                            .number_installments
                    );
                }

                setCurrentQrCode(currentProposal?.qrCodeContent);

                setPersonalDataValue(
                    "name",
                    currentProposal.payment?.holder?.full_name
                );
                setPersonalDataValue(
                    "email",
                    currentProposal.payment?.holder?.email
                );
                setPersonalDataValue(
                    "document",
                    documentMask(currentProposal?.payment?.holder?.document)
                );
                setPersonalDataValue(
                    "phone",
                    phoneMask(currentProposal?.payment?.holder.phone)
                );
                setPersonalDataValue(
                    "zipCode",
                    currentProposal.payment?.holder?.address?.zip_code
                );

                setPersonalDataValue(
                    "street",
                    currentProposal.payment?.holder?.address?.street
                );
                setPersonalDataValue(
                    "number",
                    currentProposal.payment?.holder?.address?.street_number
                );

                setPersonalDataValue(
                    "neighborhood",
                    currentProposal.payment?.holder?.address?.neighborhood
                );

                setPersonalDataValue(
                    "city",
                    currentProposal.payment?.holder?.address?.city
                );

                setPersonalDataValue(
                    "state",
                    currentProposal.payment?.holder?.address?.state
                );

                setPersonalDataValue(
                    "country",
                    currentProposal.payment?.holder?.address?.country
                );

                if (
                    currentProposal.payment?.holder?.address?.complement !==
                    null
                ) {
                    setPersonalDataValue(
                        "complement",
                        currentProposal.payment?.holder?.address?.complement
                    );
                }
            })
            .catch((error) => {
                if (error.response.data.status === false) {
                    navigate(`/paymentExpired?id=${myParam}`);
                }
            });
        timeout();
    }, [monitoring]);

    // FUNÇÃO QUE VERIFICA O STATUS DE PAGAMENTO E DEFINE O QUE SERÁ MOSTRADO NA TELA.
    useEffect(() => {
        if (
            [
                SUCCESS_STATUS_ID,
                CANCELED_STATUS_ID,
                REVERSED_STATUS_ID,
            ].includes(currentProposal.payment?.payment_status?.id)
        ) {
            clearTimeout(time);
            navigate(`/payment/completed?id=${myParam}`, { replace: true });
        }
    }, [currentProposal]);

    const confirmPayment = () => {
        setIsLoadingButton(true);
        api.post(`/checkout/payment/`, {
            payment_reference: myParam,
            payment: {
                type: "credit",
                installments: Number(dataFullInformations.installment),
                capture: true,
                card: {
                    holder: dataFullInformations.nameOwnerCard,

                    number: dataFullInformations.numberCard?.replaceAll(
                        " ",
                        ""
                    ),
                    expiry_month:
                        dataFullInformations.expirationDate?.split("/")[0],
                    expiry_year: `20${
                        dataFullInformations.expirationDate?.split("/")[1]
                    }`,
                    brand: dataFullInformations.flagCard,
                    cvv: dataFullInformations.cvv?.toString(),
                },
            },
            customer: {
                name: currentProposal.payment?.holder.full_name,
                document: currentProposal.payment?.holder.document,
                email: currentProposal.payment?.holder.email,
            },
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.response.transaction_status_id);
                handleCloseConfirmPaymentModal();
                navigate(`/payment/completed?id=${myParam}`, { replace: true });
            })
            .then(() => {
                setTimeout(() => {
                    setOpenToast(false);
                    setIsLoadingButton(false);
                    handleCloseConfirmPaymentModal();
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.message);
                setIsLoadingButton(false);
                handleCloseConfirmPaymentModal();
            });
    };

    function handleChangePhoneMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setPersonalDataValue("phone", phoneMask(value));
    }

    function handleChangeDocumentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setPersonalDataValue("document", documentMask(value));
    }

    function handleChangeCreditCardMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setCreditCardDataValue("numberCard", creditCardMask(value));
    }

    function handleChangeCvvMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setCreditCardDataValue("cvv", cvvMask(value));
    }

    function handleChangeExpirationDateMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setCreditCardDataValue("expirationDate", expirationDateMask(value));
    }

    const zipCodeMask = (value: string) => {
        let newValue = value.replace(/\D/g, "");
        newValue = newValue.replace(/(\d{5})(\d)/, "$1-$2");
        return newValue;
    };

    function handleChangeZipCodeMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setPersonalDataValue("zipCode", zipCodeMask(value));
    }

    const cardNumber: any = watchCreditCardData("numberCard"); // obter o valor atual do campo cardNumber
    const cardType = creditCardType(cardNumber)[0]?.type; // detectar o tipo de cartão de crédito com base no valor atual do campo cardNumber

    useEffect(() => {
        setCreditCardDataValue("flagCard", cardType);
    }, [cardNumber]);

    const isHideAddress = currentProposal?.payment?.holder?.address == null;

    return (
        <PaymentActivationLinkPageStyles $isLoading={isLoading}>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <>
                    <header>
                        <h1>Checkout</h1>

                        <p>
                            Você poderá parcelar este valor de{" "}
                            <span>
                                R$
                                {formatCurrencyToShow(currentProposal?.amount)}
                            </span>{" "}
                            em até {installments}{" "}
                            {installments > 1 ? "vezes" : "vez"} no cartão de
                            crédito.
                        </p>
                    </header>
                    <section>
                        <div className="leftSideContainer">
                            <Box
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                }}
                            >
                                <TabContext value={currentTab}>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <TabList aria-label="lab API tabs example">
                                            <Tab
                                                onClick={() =>
                                                    setCurrentTab("1")
                                                }
                                                label="Seus Dados"
                                                value="1"
                                            />
                                            <Tab
                                                onClick={handlePersonalDataSubmit(
                                                    handleSendPersonalDataInfos
                                                )}
                                                label="Pagamento"
                                                value="2"
                                            />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <p>Verifique seus dados atentamente.</p>
                                        <form
                                            onSubmit={handlePersonalDataSubmit(
                                                handleSendPersonalDataInfos
                                            )}
                                        >
                                            <Controller
                                                control={personalDataControl}
                                                name="name"
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value = "",
                                                        ref,
                                                    },
                                                }) => (
                                                    <InputGeneric
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        size="small"
                                                        className="inputFullWidth"
                                                        label="Nome Completo"
                                                        error={
                                                            !!personalDataErrors.name
                                                        }
                                                        ref={ref}
                                                        disabled
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={personalDataControl}
                                                name="email"
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value = "",
                                                        ref,
                                                    },
                                                }) => (
                                                    <InputGeneric
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        size="small"
                                                        className="inputFullWidth"
                                                        label="Email"
                                                        error={
                                                            !!personalDataErrors.email
                                                        }
                                                        ref={ref}
                                                        disabled
                                                    />
                                                )}
                                            />
                                            <div className="twoInputsContainer">
                                                <Controller
                                                    control={
                                                        personalDataControl
                                                    }
                                                    name="document"
                                                    render={({
                                                        field: {
                                                            value = "",
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputGeneric
                                                            value={value}
                                                            size="small"
                                                            className="inputs"
                                                            label="CPF/CNPJ"
                                                            error={
                                                                !!personalDataErrors.document
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>
                                                            ) =>
                                                                handleChangeDocumentMask(
                                                                    event
                                                                )
                                                            }
                                                            ref={ref}
                                                            inputProps={{
                                                                maxLength: 18,
                                                            }}
                                                            disabled
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    control={
                                                        personalDataControl
                                                    }
                                                    name="phone"
                                                    render={({
                                                        field: {
                                                            value = "",
                                                            ref,
                                                        },
                                                    }) => (
                                                        <InputGeneric
                                                            value={value}
                                                            size="small"
                                                            className="inputs"
                                                            label="Telefone para Contato"
                                                            error={
                                                                !!personalDataErrors.phone
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>
                                                            ) =>
                                                                handleChangePhoneMask(
                                                                    event
                                                                )
                                                            }
                                                            ref={ref}
                                                            inputProps={{
                                                                maxLength: 14,
                                                            }}
                                                            disabled
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {isHideAddress ? null : (
                                                <>
                                                    <div className="threeInputsContainer">
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="zipCode"
                                                            render={({
                                                                field: {
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="averageInput"
                                                                    label="Digite o Cep"
                                                                    error={
                                                                        !!personalDataErrors.zipCode
                                                                    }
                                                                    onChange={(
                                                                        event: React.ChangeEvent<HTMLInputElement>
                                                                    ) =>
                                                                        handleChangeZipCodeMask(
                                                                            event
                                                                        )
                                                                    }
                                                                    inputProps={{
                                                                        min: 1,
                                                                        maxLength: 9,
                                                                    }}
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="street"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="bigInput"
                                                                    label="Rua/Logradouro"
                                                                    error={
                                                                        !!personalDataErrors.street
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="number"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="smallInput"
                                                                    label="Número"
                                                                    error={
                                                                        !!personalDataErrors.number
                                                                    }
                                                                    ref={ref}
                                                                    inputProps={{
                                                                        min: 1,
                                                                    }}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="threeInputsContainer">
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="neighborhood"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputs"
                                                                    label="Bairro"
                                                                    error={
                                                                        !!personalDataErrors.neighborhood
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="city"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputs"
                                                                    label="Cidade"
                                                                    error={
                                                                        !!personalDataErrors.city
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="state"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    variant="outlined"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputs"
                                                                    label="Estado"
                                                                    error={
                                                                        !!personalDataErrors.state
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="twoInputsContainer">
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="country"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    variant="outlined"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputs"
                                                                    label="País"
                                                                    error={
                                                                        !!personalDataErrors.country
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={
                                                                personalDataControl
                                                            }
                                                            name="complement"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    variant="outlined"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputs"
                                                                    label="Complemento"
                                                                    error={
                                                                        !!personalDataErrors.complement
                                                                    }
                                                                    ref={ref}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="buttonNextContainer">
                                                <ButtonGeneric
                                                    text="PRÓXIMO"
                                                    typeButton="submit"
                                                    buttonColor="var(--primary)"
                                                />
                                            </div>
                                        </form>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <p>Pagamento</p>
                                        <p>
                                            Escolha o método e insira os dados
                                            para finalizar o seu pagamento.
                                        </p>
                                        <form
                                            onSubmit={handleCreditCardDataSubmit(
                                                handleSendCreditCardDataInfos
                                            )}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    typography: "body1",
                                                }}
                                            >
                                                <TabContext
                                                    value={paymentMethod}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderBottom: 1,
                                                            borderColor:
                                                                "divider",
                                                        }}
                                                    >
                                                        <TabList
                                                            onChange={
                                                                handleChangePaymentMethod
                                                            }
                                                            aria-label="lab API tabs example"
                                                        >
                                                            <Tab
                                                                label={
                                                                    <div className="tabInformationsContainer">
                                                                        <ListItemIcon className="iconTab">
                                                                            <CreditCard
                                                                                sx={{
                                                                                    color:
                                                                                        paymentMethod ===
                                                                                        "1"
                                                                                            ? "primary.main"
                                                                                            : "inherit",
                                                                                }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        Cartão
                                                                        de
                                                                        Crédito
                                                                    </div>
                                                                }
                                                                value="1"
                                                            />
                                                            <Tab
                                                                label={
                                                                    <div className="tabInformationsContainer">
                                                                        <ListItemIcon className="iconTab">
                                                                            <PixOutlined
                                                                                sx={{
                                                                                    color:
                                                                                        paymentMethod ===
                                                                                        "2"
                                                                                            ? "primary.main"
                                                                                            : "inherit",
                                                                                }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        PIX
                                                                    </div>
                                                                }
                                                                value="2"
                                                            />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1">
                                                        <Controller
                                                            control={
                                                                creditCardDataControl
                                                            }
                                                            name="nameOwnerCard"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <InputGeneric
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    onBlur={
                                                                        onBlur
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                    size="small"
                                                                    className="inputFullWidth"
                                                                    label="Nome do Titular do Cartão"
                                                                    error={
                                                                        !!creditCardDataErrors.nameOwnerCard
                                                                    }
                                                                    ref={ref}
                                                                />
                                                            )}
                                                        />
                                                        <div className="twoInputsContainer">
                                                            <Controller
                                                                control={
                                                                    creditCardDataControl
                                                                }
                                                                name="namePrintedCard"
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        onBlur,
                                                                        value = "",
                                                                        ref,
                                                                    },
                                                                }) => (
                                                                    <InputGeneric
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        size="small"
                                                                        className="input"
                                                                        label="Nome Impresso no Cartão"
                                                                        error={
                                                                            !!creditCardDataErrors.namePrintedCard
                                                                        }
                                                                        ref={
                                                                            ref
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <Controller
                                                                control={
                                                                    creditCardDataControl
                                                                }
                                                                name="numberCard"
                                                                render={({
                                                                    field: {
                                                                        value = "",
                                                                        ref,
                                                                    },
                                                                }) => (
                                                                    <InputGeneric
                                                                        value={
                                                                            value
                                                                        }
                                                                        size="small"
                                                                        inputProps={{
                                                                            min: 1,
                                                                            maxLength: 19,
                                                                        }}
                                                                        className="input"
                                                                        label="Número do cartão"
                                                                        onChange={(
                                                                            event: React.ChangeEvent<HTMLInputElement>
                                                                        ) =>
                                                                            handleChangeCreditCardMask(
                                                                                event
                                                                            )
                                                                        }
                                                                        error={
                                                                            !!creditCardDataErrors.numberCard
                                                                        }
                                                                        ref={
                                                                            ref
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="threeInputsContainer">
                                                            <Controller
                                                                control={
                                                                    creditCardDataControl
                                                                }
                                                                name="expirationDate"
                                                                render={({
                                                                    field: {
                                                                        onBlur,
                                                                        value = "",
                                                                        ref,
                                                                    },
                                                                }) => (
                                                                    <InputGeneric
                                                                        onChange={(
                                                                            event: React.ChangeEvent<HTMLInputElement>
                                                                        ) =>
                                                                            handleChangeExpirationDateMask(
                                                                                event
                                                                            )
                                                                        }
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        size="small"
                                                                        className="expirationDateInput"
                                                                        placeholder="MM/AA"
                                                                        label="Data de expiração"
                                                                        {...registerCreditCardData}
                                                                        inputProps={{
                                                                            min: 1,
                                                                        }}
                                                                        error={
                                                                            !!creditCardDataErrors.expirationDate
                                                                        }
                                                                        ref={
                                                                            ref
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <Controller
                                                                control={
                                                                    creditCardDataControl
                                                                }
                                                                name="cvv"
                                                                render={({
                                                                    field: {
                                                                        onBlur,
                                                                        value = "",
                                                                        ref,
                                                                    },
                                                                }) => (
                                                                    <InputGeneric
                                                                        onChange={(
                                                                            event: React.ChangeEvent<HTMLInputElement>
                                                                        ) =>
                                                                            handleChangeCvvMask(
                                                                                event
                                                                            )
                                                                        }
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        size="small"
                                                                        className="smallInput"
                                                                        label="CVV"
                                                                        error={
                                                                            !!creditCardDataErrors.cvv
                                                                        }
                                                                        ref={
                                                                            ref
                                                                        }
                                                                        inputProps={{
                                                                            min: 1,
                                                                            maxLength: 3,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <Controller
                                                                control={
                                                                    creditCardDataControl
                                                                }
                                                                name="flagCard"
                                                                render={({
                                                                    field: {
                                                                        onBlur,
                                                                        value = "",
                                                                        ref,
                                                                    },
                                                                }) => (
                                                                    <InputGeneric
                                                                        onBlur={
                                                                            onBlur
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        size="small"
                                                                        className="flagInput"
                                                                        label="Bandeira"
                                                                        disabled
                                                                        error={
                                                                            !!creditCardDataErrors.flagCard
                                                                        }
                                                                        ref={
                                                                            ref
                                                                        }
                                                                        inputProps={{
                                                                            min: 1,
                                                                            maxLength: 3,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                        <Controller
                                                            control={
                                                                creditCardDataControl
                                                            }
                                                            name="installment"
                                                            render={({
                                                                field: {
                                                                    value = "",
                                                                    ref,
                                                                },
                                                            }) => (
                                                                <FormControl
                                                                    sx={{
                                                                        height: "56px",
                                                                    }}
                                                                    className="inputFullWidth"
                                                                >
                                                                    <InputLabel
                                                                        size="small"
                                                                        id="demo-simple-select-label"
                                                                    >
                                                                        Quantidade
                                                                        de
                                                                        Parcelas
                                                                    </InputLabel>
                                                                    <Select
                                                                        error={
                                                                            !!creditCardDataErrors.installment
                                                                        }
                                                                        inputRef={
                                                                            ref
                                                                        }
                                                                        className="bigInput"
                                                                        size="small"
                                                                        placeholder="teste"
                                                                        id="demo-simple-select"
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={
                                                                            handleChangeSelectedInstallmentOption
                                                                        }
                                                                        label="Quantidade de Parcelas"
                                                                    >
                                                                        {Array(
                                                                            installments +
                                                                                1
                                                                        )
                                                                            .fill(
                                                                                ""
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    res,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            key={
                                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                                index
                                                                                            }
                                                                                            value={
                                                                                                index >
                                                                                                0
                                                                                                    ? index
                                                                                                    : undefined
                                                                                            }
                                                                                        >
                                                                                            {index >
                                                                                            0
                                                                                                ? index
                                                                                                : "Quantidade de Parcelas"}
                                                                                        </MenuItem>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <div className="buttonsContainer">
                                                            <ButtonGeneric
                                                                className="backButton"
                                                                text="VOLTAR"
                                                                onclick={() =>
                                                                    setCurrentTab(
                                                                        "1"
                                                                    )
                                                                }
                                                                buttonColor="var(--cancel)"
                                                            />
                                                            <PreloadButton
                                                                type="submit"
                                                                colorText="white"
                                                                background="var(--success)"
                                                                text="Pagar"
                                                            />
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <h4>Pagar com Pix</h4>
                                                        <p>
                                                            Pagamento
                                                            instantâneo e
                                                            prático, lembrando
                                                            que pode ser feito
                                                            em poucos segundos,
                                                            em
                                                            <br /> qualquer dia
                                                            da semana e com
                                                            total segurança.
                                                        </p>
                                                        <div className="pixContainer">
                                                            <div className="imagePixContainer">
                                                                <img
                                                                    alt="Imagem de um qrcode com pix"
                                                                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${
                                                                        currentQrCode ??
                                                                        currentQrCode
                                                                    }&amp;size=100x100`}
                                                                />
                                                                <InputGeneric
                                                                    value={
                                                                        currentQrCode &&
                                                                        currentQrCode
                                                                            ? currentQrCode
                                                                            : "PIX NÃO DISPONÍVEL"
                                                                    }
                                                                    id="qrCodeInput"
                                                                    InputProps={{
                                                                        startAdornment:
                                                                            (
                                                                                <CopyAll className="cursorPointerGlobal">
                                                                                    R$
                                                                                </CopyAll>
                                                                            ),
                                                                    }}
                                                                    onClick={() => {
                                                                        const copyText:
                                                                            | HTMLInputElement
                                                                            | any =
                                                                            document.getElementById(
                                                                                "qrCodeInput"
                                                                            );
                                                                        copyText?.select();

                                                                        document.execCommand(
                                                                            "copy"
                                                                        );
                                                                        setOpenToast(
                                                                            true
                                                                        );
                                                                        setToastRequisitionResult(
                                                                            "success"
                                                                        );
                                                                        setTextToast(
                                                                            "Chave pix copiada"
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                    className="inputFullWidth"
                                                                    label="COPIA E COLA"
                                                                />
                                                            </div>
                                                            <ol className="instructionsPix">
                                                                <li>
                                                                    Abra o app
                                                                    do seu banco
                                                                    ou
                                                                    instituição
                                                                    <br />
                                                                    financeira e
                                                                    entre no
                                                                    ambiente Pix
                                                                </li>
                                                                <li>
                                                                    Escolha a
                                                                    opção pagar
                                                                    com qr code
                                                                    e escaneie
                                                                    ou utilize a
                                                                    opção copia
                                                                    e cola
                                                                    <br /> o
                                                                    código
                                                                </li>
                                                                <li>
                                                                    Confirme as
                                                                    informações,
                                                                    faça o
                                                                    pagamento e
                                                                    aguarde
                                                                    enquanto
                                                                    verificamos
                                                                    seu
                                                                    pagamento.
                                                                </li>
                                                                <li>
                                                                    Ao pagar
                                                                    aguarde,
                                                                    você
                                                                    receberá um
                                                                    email de
                                                                    confirmação
                                                                    e será
                                                                    redirecionado
                                                                    a tela de
                                                                    status de
                                                                    pagamento.
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <hr />
                                                        <div className="totalValuePixContainer">
                                                            <h3>Total:</h3>
                                                            <h3>
                                                                R${" "}
                                                                {formatCurrencyToShow(
                                                                    currentProposal?.amount
                                                                )}
                                                            </h3>
                                                        </div>
                                                    </TabPanel>
                                                </TabContext>
                                            </Box>
                                        </form>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                        <div className="rightSideContainer">
                            <h2>Informações da Empresa</h2>
                            <p>704 Do Brasil Ltda 23.342.649/0001-96</p>
                            <p>https://www.fabrica704.com.br</p>
                            <p>Avenida Filomeno Gomes, 821, Jacarecanga</p>
                            <p>60010281</p>
                            <p>Fortaleza - CE</p>
                            <hr />
                            <div>
                                <div className="statusPaymentContainer">
                                    <p>Status</p>
                                    <p className="attentionText">
                                        {
                                            currentProposal.payment
                                                ?.payment_status.name
                                        }
                                    </p>
                                </div>

                                {currentProposal?.product_info && (
                                    <div className="statusPaymentContainer">
                                        <p>Data de competência </p>
                                        <p>
                                            {String(
                                                currentProposal?.product_info?.date_reference
                                                    .split("-")
                                                    .reverse()
                                                    .join("/")
                                            ).substring(3, 10) || ""}
                                        </p>
                                    </div>
                                )}

                                {currentProposal?.payment && (
                                    <div className="statusPaymentContainer">
                                        <p>Data de vencimento</p>
                                        <p>
                                            {new Date(
                                                currentProposal.payment?.due_date
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div className="valueContainerRight">
                                <h3>
                                    <strong>
                                        Pagamento{" "}
                                        {productPayment(
                                            currentProposal.payment
                                                .product_payment_id
                                        )}
                                        :
                                    </strong>
                                </h3>
                            </div>
                            {currentProposal?.product_info &&
                                currentProposal?.payment?.product_payment_id ===
                                    4 && (
                                    <div className="valueContainerInvoice">
                                        <ul>
                                            {currentProposal?.product_info?.implementations.map(
                                                (value: any) => {
                                                    return (
                                                        <li>
                                                            {" "}
                                                            {
                                                                value
                                                                    .implementation
                                                                    .description
                                                            }
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                )}

                            {currentProposal?.product_info &&
                                currentProposal?.payment?.product_payment_id ===
                                    3 && (
                                    <div className="valueContainerInvoice">
                                        <ul>
                                            {currentProposal?.product_info?.billing_products.map(
                                                (product: any) => {
                                                    return (
                                                        <li>
                                                            <span>
                                                                <strong>
                                                                    {" "}
                                                                    {
                                                                        product.description
                                                                    }{" "}
                                                                    :{" "}
                                                                </strong>
                                                            </span>

                                                            <span>
                                                                R$
                                                                {formatCurrencyToShow(
                                                                    product.amount
                                                                )}
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                )}
                            <div className="valueContainerRightSide">
                                <p>Valor</p>

                                <p>
                                    <strong>
                                        R${" "}
                                        {formatCurrencyToShow(
                                            Number(currentProposal?.amount) -
                                                (Number(
                                                    currentProposal
                                                        ?.product_info?.fees
                                                ) +
                                                    Number(
                                                        currentProposal
                                                            ?.product_info?.fine
                                                    ))
                                        )}
                                    </strong>
                                </p>
                            </div>
                            <Divider />
                            <If condition={currentProposal?.product_info}>
                                <div className="valueContainerRightSide">
                                    <p>Juros e Multas</p>

                                    <p>
                                        <strong>
                                            R${" "}
                                            {formatCurrencyToShow(
                                                Number(
                                                    currentProposal
                                                        ?.product_info?.fees
                                                ) +
                                                    Number(
                                                        currentProposal
                                                            ?.product_info?.fine
                                                    )
                                            )}
                                        </strong>
                                    </p>
                                </div>
                            </If>
                            <Divider />
                            <div className="valueContainerRightSide">
                                <p>Valor Total</p>
                                <p>
                                    <strong>
                                        R${" "}
                                        {formatCurrencyToShow(
                                            currentProposal?.amount
                                        )}
                                    </strong>
                                </p>
                            </div>
                            <Divider />
                            <span
                                style={{
                                    fontSize: "10px",
                                }}
                            >
                                multa de{" "}
                                {currentProposal?.product_info?.fine_percent}%
                                por atraso, juros{" "}
                                {currentProposal?.product_info?.fees_percent}%
                                a.m.{" "}
                            </span>
                        </div>
                    </section>
                    <Modal
                        id="modalConfirmPayment"
                        open={confirmPaymentModal}
                        onClose={handleCloseConfirmPaymentModal}
                    >
                        <Box
                            sx={{
                                position: "absolute" as const,
                                top: "35%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80%",
                                maxWidth: "600px",
                                bgcolor: "background.paper",
                                border: "none",
                                borderRadius: "10px",
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            <TitleModal>Confirmar Pagamento?</TitleModal>
                            <ButtonsContainerModal>
                                <ButtonGeneric
                                    onclick={() => {
                                        handleCloseConfirmPaymentModal();
                                    }}
                                    buttonColor="var(--cancel)"
                                    text="CANCELAR"
                                />
                                <PreloadButton
                                    colorText="white"
                                    background="var(--confirm)"
                                    loading={isLoadingButton}
                                    text="Confirmar"
                                    type="button"
                                    onClick={() => {
                                        confirmPayment();
                                    }}
                                    sx={{ minWidth: "140px" }}
                                />
                            </ButtonsContainerModal>
                        </Box>
                    </Modal>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </>
            )}
        </PaymentActivationLinkPageStyles>
    );
}
export default PaymentActivationLinkPage;
