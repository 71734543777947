import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";

function TextEditor({ ...props }: ReactQuillProps) {
    return (
        <ReactQuill theme="snow" style={{ background: "white" }} {...props} />
    );
}

export default TextEditor;
