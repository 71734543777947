import * as yup from "yup";

export const schemaModalInputs = yup
    .object({
        nameCompany: yup.string().required("Este campo é obrigatório"),
        email: yup
            .string()
            .email("Email não é válido")
            .required("Este campo é obrigatório"),
        phone: yup.string().required("Este campo é obrigatório"),
        id_cluster_mongo: yup.number(),
        id_cluster_mysql: yup.number(),
        cloud: yup.string().required("Este campo é obrigatório"),
        holder: yup
            .object({
                label: yup.string().required("Label é obrigatório"),
                value: yup.number().required("Value é obrigatório"),
            })
            .required("Este campo é obrigatório"),
    })
    .required();
