import styled from "styled-components";

export const FormContractEditPage = styled.div`
    width: 100%;
    max-width: 1280px;
    border-radius: 8px;
    padding: 10px 10px;
    box-shadow: 0px 0px 7px #8888889e;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--weakBorder);
    }

    .variablesContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 200px;
        border-bottom: 1px solid var(--weakBorder);
        padding-bottom: 40px;
        justify-content: center;
    }

    .variablesContent {
        display: flex;
        height: 25px;
        padding: 2px 0;
    }

    .bolderText {
        font-weight: 700;
    }

    .smallSizeText {
        font-weight: 100;
        font-size: 12px;
    }

    h4 {
        text-align: center;
    }

    .inputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputContainer p {
        text-align: center;
        color: red;
        font-size: 13px;
        height: 14px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inputs {
        width: 400px;
    }

    .contractActiveContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contractContainer {
        width: 100%;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 700px) {
        .variablesContainer {
            height: auto;
        }

        @media (max-width: 420px) {
            h3 {
                text-align: center;
            }

            .variablesContent {
                margin-bottom: 10px;
            }

            .inputs {
                width: 260px;
            }
        }
    }
`;
