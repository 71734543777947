import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import Loading from "../../components/Loading";
import PdfViewer from "../../components/PdfViewer";
import PreloadFb from "../../components/Preload";
import ModalSigningContract from "../../components/SigningContractModal";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { ContractSingningPageStyles } from "./styles";

export interface ContractAssignProps {
    ip_address: string;
    signed_at: string;

    holder: {
        id: number;
        full_name: string;
        name: string;
        document: string;
        is_company: number;
        is_active: number;
        email: string;
        phone: string;
        is_signatory: number;
        deleted_at: string;
    };
    customer_contract: {
        id: 4;
        pdf_link: string;

        created_at: string;
        updated_at: string;
        proposal: {
            user: {
                name: string;
                email: string;
                is_blocked: number;
                created_at: string;
                updated_at: string;
                deleted_at: string | null;
            };
        };
        internal_companies: {
            fantasy_name: string;
            corporate_name: string;
            cnpj: string;
        };
    };
    contract_signatories: [
        {
            customer_contract_id: 1;
            signed_at: string;
            status_id: number;
            holder_id: number;
            ip_address: string;
            signed_user_agent: null;
            uuid: string;
            holder: {
                name: string;
                fullname: string;
                email: string;
                document: string;
                is_active: number;
                phone: string;
                is_signatory: number;
            };
        }
    ];
}

function ContractSingningPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("token");

    const navigate = useNavigate();

    const [openSigningContractModal, setOpenSigningContractModal] =
        useState(false);
    const handleCloseSigningContractModal = () => {
        setOpenSigningContractModal(false);
    };
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [contractInformations, setContractInformations] =
        useState<ContractAssignProps>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [position, setPosition] = useState<any>({});
    // VOU PEGAR O TOKEN DA URL E JOGAR NO HEADERS

    const [isLoading, setIsLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        api.get("/contractSignatories/assign", {
            headers: {
                Authorization: `Bearer ${myParam}`,
            },
        })
            .then((response) => {
                setContractInformations(response.data.response);
                setIsLoading(false);
                if (response.data.response.signed_at) {
                    navigate(`/signedContractPage?token=${myParam}`, {
                        replace: true,
                    });
                    setIsDisabled(true);
                }
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    useEffect(() => {
        setOpenToast(true);
        setToastRequisitionResult("warning");
        setTextToast(
            "Para a validação do contrato, precisamos saber sua localização, é obrigatório que essa permissão seja concedida ao navegador."
        );
    }, []);

    return (
        <>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <ContractSingningPageStyles>
                    <div className="leftContainer">
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <div className="leftContent">
                                <div className="headerLeftContainer">
                                    {
                                        contractInformations?.customer_contract
                                            .internal_companies.fantasy_name
                                    }
                                </div>
                                <div className="informationsBox">
                                    <p>Criador</p>

                                    <p className="boldText">
                                        {
                                            contractInformations
                                                ?.customer_contract
                                                .internal_companies
                                                .corporate_name
                                        }
                                    </p>
                                    <p className="smallText">
                                        {
                                            contractInformations
                                                ?.customer_contract
                                                .internal_companies.cnpj
                                        }
                                    </p>
                                    <hr />
                                    <p className="verySmallText">
                                        Criou e Assinou o documento
                                    </p>
                                    <p className="verySmallText">
                                        {`Em 
                                    ${contractInformations?.customer_contract.created_at
                                        .slice(0, -17)
                                        .split("-")
                                        .reverse()
                                        .join("/")}`}
                                    </p>
                                </div>
                                <div className="informationsBox">
                                    <div className="marginBottom20px">
                                        <p className="smallText">Titular</p>
                                    </div>
                                    <p className="boldText">
                                        {contractInformations?.holder.full_name}
                                    </p>
                                    <p className="smallText">
                                        {contractInformations?.holder.name}
                                    </p>
                                    <p className="smallText">
                                        {contractInformations?.holder.email}
                                    </p>
                                    <hr />
                                    <p className="verySmallText">
                                        {contractInformations?.signed_at
                                            ? `Contrato assinado no dia: 
                                ${contractInformations?.signed_at
                                    .split(" ")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}`
                                            : "Aguardando Assinatura"}
                                    </p>
                                    <p className="verySmallText">
                                        {contractInformations?.ip_address
                                            ? `No endereço de IP: 
                                ${contractInformations?.ip_address}`
                                            : ""}
                                    </p>
                                </div>
                                {contractInformations?.contract_signatories?.map(
                                    ({
                                        holder_id,
                                        holder,
                                        ip_address,
                                        signed_at,
                                    }) => (
                                        <div
                                            key={holder_id}
                                            className="informationsBox"
                                        >
                                            <div className="marginBottom20px">
                                                <p className="smallText">
                                                    Signatário
                                                </p>
                                            </div>
                                            <p className="boldText">
                                                {holder.fullname}
                                            </p>
                                            <p className="smallText">
                                                {holder.name}
                                            </p>
                                            <p className="smallText">
                                                {holder.email}
                                            </p>
                                            <hr />
                                            <p className="verySmallText">
                                                {signed_at !== null
                                                    ? `Contrato assinado no dia ${signed_at
                                                          ?.split(" ")[0]
                                                          .split("-")
                                                          .reverse()
                                                          .join("/")}
                                        `
                                                    : "Aguardando assinatura"}
                                            </p>

                                            <p className="verySmallText">
                                                {ip_address
                                                    ? `No endereço de IP: 
                                    ${ip_address}`
                                                    : ""}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>

                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="rightContainer">
                            <div className="headerRightContainer">
                                <p className="boldText">CONTRATO</p>
                                <p className="smallText">
                                    {`Contrato criado em ${contractInformations?.customer_contract.created_at
                                        .slice(0, -17)
                                        .split("-")
                                        .reverse()
                                        .join("/")}`}
                                </p>
                            </div>

                            <PdfViewer
                                pdfLink={
                                    contractInformations?.customer_contract
                                        .pdf_link
                                }
                            />
                            <div>
                                <ButtonGeneric
                                    className="signingButton"
                                    onclick={() => {
                                        setOpenSigningContractModal(true);
                                    }}
                                    disabled={isDisabled}
                                    typeButton="button"
                                    buttonColor="green"
                                    text="ASSINAR"
                                />
                            </div>
                        </div>
                    )}

                    <ModalSigningContract
                        openSigningContractModal={openSigningContractModal}
                        handleCloseSigningContractModal={
                            handleCloseSigningContractModal
                        }
                        position={position}
                        myParam={myParam}
                        setPosition={setPosition}
                        contractInformations={contractInformations}
                    />
                </ContractSingningPageStyles>
            )}
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}

export default ContractSingningPage;
