import { Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import iconWhatsapp from "../../assets/images/whatsapp.png";
import logoImage from "../../assets/images/logo704LoginPage.png";
import PreloadFb from "../../components/Preload";
import { formatCurrencyToShow } from "../../helpers/currency";
import { getStatusChip } from "../../helpers/statusChip/statusChip";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { TransformDate } from "../../helpers/transformDate";
import { api } from "../../services/api";
import { CustomersProps } from "../CustomerDetailsPage/types";
import { BillingDataProps as MonthlyBillingType } from "../MonthlyBillingReportPage/types";
import {
    AccountInfoTab,
    AccountInformation,
    Container,
    Header,
    HeaderContainer,
    MonthlyBillingCustomerPage,
    MonthlyItem,
    MonthlybillingTab,
    ContentContactSupport,
} from "./styles";

export type Account = {
    id: number;
    enabled: boolean;
    monthly_billings: MonthlyBillingType[];
    customer: CustomersProps;
    plan: any;
};

export function MonthlyBilling() {
    const [account, setAccount] = useState<Account>();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const contact = urlParams.get("contato");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const getMonthlybilling = () => {
        api.get(`/customer/account`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setAccount(response.data.response);
            })
            .catch(() => {
                defaultToast("error", "Não foi possível carregar a página.");
                navigate("/not-found");
            })
            .finally(() => setLoading(true));
    };

    useEffect(() => {
        getMonthlybilling();
    }, []);
    return !loading ? (
        <PreloadFb />
    ) : (
        <MonthlyBillingCustomerPage>
            <Header>
                <HeaderContainer>
                    <img
                        src={logoImage}
                        alt="logo"
                        width="60px"
                        style={{
                            margin: "10px",
                        }}
                    />{" "}
                </HeaderContainer>
            </Header>
            <Container>
                <MonthlybillingTab>
                    <div>
                        <h2>{account?.customer.name} - Faturas</h2>
                        <p>
                            Matenha suas faturas em dias para evitar bloqueios e
                            juros.
                        </p>
                    </div>
                    <Divider />
                    <AccountInformation>
                        <AccountInfoTab>
                            <h3>Email de contato</h3>
                            <p>
                                {account?.customer?.holder?.email ??
                                    account?.customer?.email}
                            </p>
                        </AccountInfoTab>
                        <AccountInfoTab>
                            <h3>Plano contratado</h3>
                            <p>{account?.plan.description}</p>
                        </AccountInfoTab>
                        <span>
                            {contact
                                ? `para dúvidas ou sugestões entre em contato o suporte: ${contact}`
                                : ""}
                        </span>
                    </AccountInformation>

                    <ContentContactSupport>
                        <span>
                            Para dúvidas ou sugestões entre em contrato o
                            suporte: 55859677-7458
                        </span>
                        <img src={iconWhatsapp} alt="logo-whatsapp" />
                    </ContentContactSupport>

                    <Divider />
                    <div>
                        {account?.monthly_billings.map((billing) => (
                            <MonthlyItem key={billing.id}>
                                <div className="monthly-info">
                                    <span className="fixed-size">
                                        <strong>
                                            {billing.month_reference} -{" "}
                                        </strong>
                                        Venc. {TransformDate(billing.due_date)}
                                    </span>
                                    <span className="money fixed-size">
                                        R${" "}
                                        {formatCurrencyToShow(
                                            billing.total_amount
                                        )}
                                    </span>
                                    <span className="variant-size">
                                        {getStatusChip(billing.status_id)}
                                    </span>
                                    <Button
                                        className="paid-button"
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        href={`${window.env.REACT_APP_LINK_PAYMENT}checkout?id=${billing?.payment?.payment_reference}`}
                                        target="_blank"
                                    >
                                        Pagar
                                    </Button>
                                </div>
                            </MonthlyItem>
                        ))}
                    </div>
                </MonthlybillingTab>
            </Container>
        </MonthlyBillingCustomerPage>
    );
}
