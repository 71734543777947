import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ContractAssignProps } from "../../pages/ContractSingningPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalSigningContractStyle } from "./styles";

interface Props {
    openSigningContractModal: boolean;
    handleCloseSigningContractModal: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position: { latitude: number; longitude: number };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPosition: any;
    myParam?: string | null;
    contractInformations?: ContractAssignProps;
}

function ModalSigningContract({
    openSigningContractModal,
    handleCloseSigningContractModal,
    position,
    setPosition,
    myParam,
    contractInformations,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition(position.coords);
                },
                (response) => {
                    if (response) {
                        setOpenToast(true);
                        setToastRequisitionResult("warning");
                        setTextToast(
                            "Para a validação do contrato, precisamos saber sua localização, é obrigatório que essa permissão seja concedida ao navegador"
                        );
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                setPosition(position.coords);
                            },
                            (response) => {
                                if (response) {
                                    console.log("nao aceitou");
                                }
                            }
                        );
                    }
                }
            );
        }
    }, []);

    useEffect(() => {
        setOpenToast(true);
        setToastRequisitionResult("warning");
        setTextToast(
            "Para a validação do contrato, precisamos saber sua localização, é obrigatório que essa permissão seja concedida ao navegador"
        );
    }, []);

    function signContract() {
        setIsLoadingButton(true);
        if (
            position.latitude !== undefined &&
            position.longitude !== undefined
        ) {
            api.post(
                `/contractSignatories/assign/`,
                {
                    latitude: position.latitude,
                    longitude: position.longitude,
                },
                {
                    headers: {
                        Authorization: `Bearer ${myParam}`,
                    },
                }
            )
                .then(() => {
                    setTimeout(() => {
                        setOpenToast(true);
                        setToastRequisitionResult("success");
                        setTextToast(
                            "Contrato assinado com sucesso, aguarde a confirmação do sistema"
                        );
                        navigate(`/signedContractPage?token=${myParam}`, {
                            replace: true,
                        });
                    }, 2000);
                })
                .then(() => {
                    setTimeout(() => {
                        handleCloseSigningContractModal();
                        setOpenToast(false);
                        setIsLoadingButton(false);
                    }, 2000);
                })
                .catch((error) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(error.response.data.message);
                    setIsLoadingButton(false);
                });
        } else {
            setOpenToast(true);
            setTextToast(
                "Para a validação do contrato, precisamos saber sua localização, é obrigatório que essa permissão seja concedida ao navegador"
            );
            setIsLoadingButton(false);
        }
    }

    return (
        <Modal
            open={openSigningContractModal}
            onClose={handleCloseSigningContractModal}
        >
            <ModalSigningContractStyle>
                <Box className="modalContainer">
                    <p>
                        {`Eu, ${contractInformations?.holder.full_name}, ao clicar em assinar confirmo que
                        li e concordo com todos os termos do contrato.`}
                    </p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseSigningContractModal}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="green"
                            loading={isLoadingButton}
                            text="ASSINAR"
                            type="submit"
                            onClick={() => signContract()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalSigningContractStyle>
        </Modal>
    );
}
export default ModalSigningContract;
