import styled from "styled-components";

interface Props {
    isDisabledCreateContractButton?: boolean;
}

export const FormCouponsPage = styled.div<Props>`
    width: 100%;
    max-width: 1100px;
    padding: 10px 10%;
    box-shadow: 0px 0px 7px #8888889e;
    background-color: #fff;

    div > h3 {
        font-size: 22px;
        text-align: center;
    }

    .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        > div {
            width: 100%;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 1rem;
        }
    }

    .content-list-users {
        overflow: auto;
        max-height: 300px;

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.5rem;
        }
    }

    .content-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 2rem 0;
    }

    .icon-input {
        position: absolute;
        right: 0;
        margin: 0.7rem;
        right: 25px;
        color: gray;
    }
`;
