/* eslint-disable */
import { InsertDriveFile, Add, CloudDownload } from "@mui/icons-material";
import { Chip, Pagination, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";

import { ReactNode, useEffect, useState } from "react";

import { api } from "../../services/api";

import PageWrapper from "../../components/PageWrapper";
import { PageDefault } from "../../components/PageDefault";
import { defaultToast } from "../../helpers/toast/defaultToast";
import TableDataGrid from "../../components/TableDataGrid";

import { Container, ContentTable, ContentPagination } from "./styles";

import { InvoicesProps } from "./types";

import { useAuth } from "../../contexts/contexts";

let total_page: number | undefined = undefined;

function FullInvoices() {
    const { permissions, hasPermissions } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasPermissions("relatorio.listar") && permissions.length > 0) {
            navigate("/Error");
        }
    }, [permissions]);

    // const [status, setStatus] = useState<number>(1);
    const [invoices, setInvoices] = useState<InvoicesProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    function statusChip(status: number): ReactNode {
        switch (status) {
            case 1:
                return <Chip label="Pendente" size="small" color="warning" />;
            case 2:
                return <Chip label="Cancelada" size="small" color="error" />;
            case 3:
                return <Chip label="Concluída" size="small" color="success" />;
            default:
                return <Chip label="desconhecido" size="small" />;
        }
    }

    const getInvoices = async (page = 1) => {
        try {
            setLoading(true);
            const responseInvoices = await api(`v2/reports?page=${page}`);

            total_page = responseInvoices.data.last_page;

            if (responseInvoices.status) {
                setInvoices(responseInvoices.data.data);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao pegar os relatórios");
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nome",
            flex: 10,
        },
        {
            field: "status_id",
            headerName: "Status",
            flex: 5,

            renderCell: (params) => (
                <div>
                    <span>{statusChip(params.row.status_id)}</span>
                </div>
            ),
        },
        {
            field: "created_at",
            headerName: "Data de criação",
            flex: 4,
            renderCell: (params) => (
                <div>
                    {new Date(params.row.created_at).toLocaleDateString()}
                </div>
            ),
        },
        {
            field: "link",
            headerName: "Download",
            flex: 2,
            renderCell: (params) => (
                <div>
                    <IconButton
                        title="Download"
                        color="primary"
                        onClick={() => (window.location.href = params.row.link)}
                    >
                        <CloudDownload />
                    </IconButton>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getInvoices();
    }, []);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Todos os Relatórios",
                    subtitle: "Listagens de relatórios pendetes e concluídos",
                    icon: <InsertDriveFile sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    {/* <ContentButton>
                        <ButtonGeneric
                            onclick={() => {
                                navigate(
                                    "/reports/listagem-relatorios/geracao-relatorios"
                                );
                            }}
                            buttonColor="var(--primary)"
                            text="Novo Relatório"
                            icon={<Add />}
                        />
                    </ContentButton> */}
                    {/* <ContentInputs>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Inicial"
                                format="MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                    },
                                }}
                                defaultValue={dayjs(
                                    `${new Date().getFullYear()}-${new Date().getMonth()}-01`
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Final"
                                format="MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                    },
                                }}
                                defaultValue={dayjs(
                                    `${new Date().getFullYear()}-${
                                        new Date().getMonth() + 1
                                    }-01`
                                )}
                            />
                        </LocalizationProvider>

                        <SelectComponent
                            label="Tipo de fatura"
                            size={11}
                            items={TypesInvoices}
                            value={typesInvoices}
                            name="typesInvoices"
                            onChange={(e) => {
                                setTypesInvoices(e.target.value);
                            }}
                            fullWidth
                        />

                        <SelectComponent
                            label="Status"
                            size={11}
                            items={mbStatus}
                            value={status}
                            name="statusInvoices"
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            fullWidth
                        />

                        <ContentBtnSearch>
                            <LoadingButton
                                variant="contained"
                                fullWidth
                                startIcon={<Search />}
                            >
                                Pesquisar
                            </LoadingButton>
                        </ContentBtnSearch>
                    </ContentInputs> */}

                    <ContentTable>
                        <TableDataGrid
                            columns={columns}
                            rows={invoices}
                            loading={loading}
                            hideFooterPagination
                            hideExports
                        />
                    </ContentTable>
                    <ContentPagination>
                        <Pagination
                            count={total_page}
                            color="primary"
                            onChange={(event: any) => {
                                getInvoices(Number(event.target.textContent));
                            }}
                            hideNextButton
                            hidePrevButton
                        />
                    </ContentPagination>
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}

export default FullInvoices;
