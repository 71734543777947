import { Add, Delete, Edit } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteHolderType from "../../components/DeleteHolderTypeModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface HoldersTypesProps {
    color: string;
    description: string;
    id: number;
    name: string;
}

function HoldersTypePage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataHoldersTypesList, setDataHoldersTypesList] = useState<
        HoldersTypesProps[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("usuario.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [openDeleteHolderTypeModal, setOpenDeleteHolderTypeModal] =
        useState(false);
    const handleCloseDeleteHolderTypeModal = () => {
        setOpenDeleteHolderTypeModal(false);
    };
    const [selectedRowHolderType, setSelectedRowHolderType] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("holderLevels/all")
            .then((response) => {
                setLoadingTable(false);
                setDataHoldersTypesList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "name", headerName: "Tipo de Titular", flex: 20 },
        { field: "description", headerName: "Descrição", flex: 25 },
        {
            field: "color",
            headerName: "Cor",
            flex: 5,
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div
                    style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: `${params.row.color}`,
                        borderRadius: "50%",
                        border: "1px solid gray",
                    }}
                />
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 25,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("tipos.titulares.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/holdersTypes/holderTypeEdit?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("tipos.titulares.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteHolderTypeModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If
                            condition={hasPermissions(
                                "tipos.titulares.cadastrar"
                            )}
                        >
                            <ButtonGeneric
                                onclick={() => {
                                    navigate(
                                        "/settings/holdersTypes/holderTypeRegister"
                                    );
                                }}
                                buttonColor="var(--primary)"
                                text="Novo Tipo de Titular"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataHoldersTypesList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowHolderType(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteHolderType
                        openDeleteHolderTypeModal={openDeleteHolderTypeModal}
                        handleCloseDeleteHolderTypeModal={
                            handleCloseDeleteHolderTypeModal
                        }
                        selectedRowHolderType={selectedRowHolderType}
                        dataHoldersTypesList={dataHoldersTypesList}
                        setDataHoldersTypesList={setDataHoldersTypesList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default HoldersTypePage;
