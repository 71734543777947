import { SettingConfigInput, SettingsProps } from "./SettingConfigInput";

export type GeneralConfigsProps = {
    id: number;
    key: string;
    name: string;
    settings: SettingsProps[];
};

export function SettingConfigSection({
    config,
    handleChangeInputs,
}: {
    config: GeneralConfigsProps;
    handleChangeInputs: (key: string, value: any) => void;
}) {
    return (
        <div>
            <h3>{config.name}</h3>
            {config.settings.map((setting) => (
                <SettingConfigInput
                    key={setting.id}
                    setting={setting}
                    handleChangeInputs={handleChangeInputs}
                />
            ))}
        </div>
    );
}
