import styled from "styled-components";

export const Container = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 720px;
    background-color: white;
    box-shadow: 24;
    padding: 1rem;
    border-radius: 8px;

    @media (max-width: 768px) {
        max-width: 420px;
    }
`;

export const TitleModal = styled.h2`
    text-align: center;
`;

export const ContentInput = styled.div`
    margin: 1rem 0;
`;

export const FooterModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
`;
