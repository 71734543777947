import styled from "styled-components";

export const ModalEditUserStyle = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1200px;
    min-width: 280px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    justify-content: center;

    .topModalText {
        text-align: center;
        margin-bottom: 10%;
    }

    form {
        width: 100%;
    }

    .inputsContainer {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3%;
    }
    .inputsContainer div {
        margin-bottom: 5px;
    }

    .inputs {
        min-width: 320px;
    }

    .multiSelectInput {
        width: 450px;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 820px) {
        top: 45%;
        .inputsContainer {
            flex-direction: column;
        }

        .multiSelectInput {
            width: 320px;
        }
    }
    @media (max-width: 500px) {
        top: 45%;

        .inputs {
            min-width: 230px;
        }

        .multiSelectInput {
            width: 230px;
        }

        .buttonsContainer {
            display: flex;
            gap: 1%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .button {
            width: 100px;
        }
    }
`;
