import styled from "styled-components";

export const FinacialDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
        flex: 0 0 calc(50% - 20px);
        box-sizing: border-box;

        @media (max-width: 850px) {
            flex: 1;
            box-sizing: border-box;
        }
    }
`;

export const OptionStyled = styled.div`
    margin: 15px;
    display: flex;
    gap: 10px;
`;

export const Chips = styled.div`
    display: flex;
    gap: 10px;
`;
export const CardContent = styled.div`
    margin: 40px 0px 0px 0px;
    max-height: 450px;
    overflow-y: auto;
`;

export const CardItem = styled.div`
    background-color: #00000010;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    border-bottom: 4px solid var(--primary);
    margin: 10px 0px;
    gap: 15px;

    .brand {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .brand-img {
        width: 48px;
        height: 48px;
    }
    .linked {
        font-size: 13px;
        color: var(--success);
        font-weight: 700;
    }
`;

export const ContainerProposalCustomer = styled.div`
    margin: 15px;
`;

export const ContentProposalCustomer = styled.div`
    display: flex;
    gap: 10px;
    margin: 0.5rem 0;

    span:nth-child(2) {
        color: green;
        font-weight: bold;
    }
`;
