import { Routes, Route } from "react-router-dom";

import {
    ContractSignedPage,
    ContractSingningPage,
    ErrorPublicPage,
    LoginPage,
    PaymentActivationLinkPage,
    PaymentCompletedPage,
} from "../pages";
import CheckListAppPage from "../pages/CheckListAppPage";
import CheckListFinalized from "../pages/CheckListFinalized";
import { MonthlyBilling } from "../pages/MonthlyBilling";
import PaymentExpiredPage from "../pages/PaymentCompletedPage/expired";

function PublicRoutes() {
    return (
        <Routes>
            <Route path="*" element={<ErrorPublicPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/checkout" element={<PaymentActivationLinkPage />} />
            <Route
                path="/payment/completed"
                element={<PaymentCompletedPage />}
            />
            <Route
                path="/contractSignatories/assign"
                element={<ContractSingningPage />}
            />
            <Route
                path="/signedContractPage"
                element={<ContractSignedPage />}
            />
            <Route path="/paymentExpired" element={<PaymentExpiredPage />} />
            <Route path="/checklist-app" element={<CheckListAppPage />} />
            <Route
                path="/formulario-checklist-preenchido"
                element={<CheckListFinalized />}
            />
            <Route path="/faturas-mensais" element={<MonthlyBilling />} />
        </Routes>
    );
}
export default PublicRoutes;
