import styled from "styled-components";

export const NavBar = styled.div`
    display: flex;
    justify-content: right;

    align-items: center;
    width: 100%;
    height: 4rem;
    background-color: #fff;
    position: absolute;
    box-shadow: 5px 5px 5px #00000010;
    z-index: 1;
`;
