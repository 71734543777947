import styled from "styled-components";

export const Container = styled.div``;

export const TitleSection = styled.h2`
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 768px) {
        text-align: center;
        margin: 0.2rem 0;
    }
`;

export const ContentInputsUploads = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0rem 0;

    @media (max-width: 768px) {
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0.1rem 0;
    }
`;

export const ContentInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
`;

export const ContentFieldJson = styled.div`
    margin: 1rem 0;
`;

export const ContentButton = styled.div`
    margin-top: 12px;
`;

export const ContentSwitchOnOffCustomer = styled.div``;

export const ContentInformationsFiles = styled.div`
    display: flex;
    align-items: center;
`;
