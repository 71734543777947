import { AlertColor, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { ImplementationsProps } from "../../pages/ImplementationsPage";
// import { DataUserProps } from "../../pages/ImplementationsPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalBlockImplementationStyle } from "./styles";

interface Props {
    openBlockImplementationModal: boolean;
    handleCloseBlockImplementationModal: () => void;
    selectedRowImplementation: GridRowParams["row"];
    setDataImplementationsList: (value: ImplementationsProps[]) => void;
}

function ModalBlockImplementation({
    openBlockImplementationModal,
    handleCloseBlockImplementationModal,
    selectedRowImplementation,
    setDataImplementationsList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function editImplementationBlockState() {
        setIsLoadingButton(true);
        api.put(
            `/implementations/${selectedRowImplementation?.implementation?.id}`,
            {
                is_active:
                    selectedRowImplementation.implementation?.is_active === 0
                        ? 1
                        : 0,
                description:
                    selectedRowImplementation?.implementation?.description,
                can_repeat:
                    selectedRowImplementation?.implementation?.can_repeat,
                manual_installtion:
                    selectedRowImplementation?.implementation
                        ?.manual_installation,
            }
        )
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseBlockImplementationModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                api.get("/implementations/prices/all")
                    .then((response) => {
                        setDataImplementationsList(response.data.response);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openBlockImplementationModal}
            onClose={handleCloseBlockImplementationModal}
        >
            <ModalBlockImplementationStyle>
                <Box>
                    <p className="topModalText">
                        {selectedRowImplementation.implementation?.is_active ===
                        1
                            ? `Deseja desativar Implementação "${selectedRowImplementation.implementation?.description}"?`
                            : `Deseja ativar Implementação "${selectedRowImplementation.implementation?.description}"?`}
                    </p>
                    <form name="editFormModal">
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => {
                                    handleCloseBlockImplementationModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background={
                                    selectedRowImplementation.implementation
                                        ?.is_active === 1
                                        ? `var(--delete)`
                                        : `var(--confirm)`
                                }
                                loading={isLoadingButton}
                                text={
                                    selectedRowImplementation.implementation
                                        ?.is_active === 1
                                        ? `Desativar`
                                        : `Ativar`
                                }
                                type="submit"
                                onClick={() => {
                                    editImplementationBlockState();
                                }}
                                sx={{ minWidth: "140px" }}
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </ModalBlockImplementationStyle>
        </Modal>
    );
}
export default ModalBlockImplementation;
