import styled from "styled-components";

export const Container = styled.div``;

export const Section = styled.section``;

export const ContentInfoCustomer = styled.div`
    margin: 1rem 0;

    > div {
        margin: 1rem 0;
        display: flex;
        gap: 1rem;

        > span:nth-child(1) {
            font-weight: bold;
        }
    }
`;

export const ContentActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const ContentApps = styled.div``;

export const ContentColorsApps = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        padding: 0.3rem;
        border-radius: 10px;
    }
`;

export const ContainerServers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
`;

export const ContentHealthCustomer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
`;
