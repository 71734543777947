import InputGeneric from "../../components/InputGeneric";
import Toggle from "../../components/Toggle";

export type SettingsProps = {
    id: number;
    key: string;
    description: string;
    enabled: number;
    type: string;
    value: string;
    group_id: number;
};

export function SettingConfigInput({
    setting,
    handleChangeInputs,
}: {
    setting: SettingsProps;
    handleChangeInputs: (key: string, value: any) => void;
}) {
    const isNumber = setting.type === "integer" || setting.type === "float";

    switch (setting.type) {
        case "boolean":
            return (
                <Toggle
                    className="toggle"
                    label={setting.description}
                    defaultChecked={Boolean(setting.value)}
                    onChange={(event) => {
                        handleChangeInputs(setting.key, event.target.checked);
                    }}
                />
            );
        default:
            return (
                <div className="inputAndLoadingContainer">
                    <InputGeneric
                        className="inputs"
                        size="small"
                        label={setting.description}
                        onChange={(event) => {
                            handleChangeInputs(setting.key, event.target.value);
                        }}
                        defaultValue={setting.value}
                        type={isNumber ? "number" : "text"}
                    />
                </div>
            );
    }
}
