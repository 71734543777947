import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormHolderTypeEditPage } from "./styles";

type holderTypeFormTypeValues = {
    name: string;
    description?: string;
    color?: string;
};

const holderTypeFormSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    description: yup.string().required("Nome é obrigatório"),
    color: yup.string().required("Nome é obrigatório"),
});

function HolderTypeEditPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("usuario.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<holderTypeFormTypeValues>({
        resolver: yupResolver(holderTypeFormSchema),
    });

    useEffect(() => {
        api.get(`/holderLevels/${myParam}`)
            .then((response) => {
                setValue("name", response.data.response.name);
                setValue("description", response.data.response.description);
                setValue("color", response.data.response.color);
                setIsLoading(false);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.message);
            });
    }, [myParam, setValue]);

    const editHolderType: SubmitHandler<holderTypeFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.put(`/holderLevels/${myParam}`, {
            name: formValues.name,
            description: formValues.description,
            color: formValues.color,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/settings/holdersTypes");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <>
            <PageWrapper>
                {isLoading ? (
                    <PreloadFb />
                ) : (
                    <FormHolderTypeEditPage>
                        <div className="topFormPage">
                            <h2>Editar o Tipo de Titular</h2>
                        </div>
                        <form onSubmit={handleSubmit(editHolderType)}>
                            <div className="inputsContainer">
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value = "",
                                            ref,
                                        },
                                    }) => (
                                        <InputGeneric
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            size="small"
                                            className="inputs"
                                            label="Tipo de titular"
                                            error={!!errors.name}
                                            ref={ref}
                                            autoFocus
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value = "",
                                            ref,
                                        },
                                    }) => (
                                        <InputGeneric
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            minRows="3"
                                            maxRows="5"
                                            multiline
                                            size="small"
                                            className="inputs"
                                            label="Descrição"
                                            error={!!errors.description}
                                            ref={ref}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="color"
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value = "#000000",
                                            ref,
                                        },
                                    }) => (
                                        <InputGeneric
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            type="color"
                                            value={value}
                                            size="small"
                                            className="inputs"
                                            label="Cor do tipo de titular"
                                            error={!!errors.color}
                                            ref={ref}
                                        />
                                    )}
                                />
                            </div>
                            <div className="buttonsContainer">
                                <ButtonGeneric
                                    onclick={() =>
                                        navigate("/settings/holdersTypes")
                                    }
                                    buttonColor="var(--cancel)"
                                    text="VOLTAR"
                                />
                                <PreloadButton
                                    colorText="white"
                                    background="var(--confirm)"
                                    loading={isLoadingButton}
                                    text="CONFIRMAR"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </FormHolderTypeEditPage>
                )}
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default HolderTypeEditPage;
