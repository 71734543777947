import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Modal, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { ItemsChecklistListProps } from "../../pages/ItemsCheckListPage/types";
import { api } from "../../services/api";
import { Container, TitleModal, ContentInput, FooterModal } from "./styles";

const schemaForms = yup.object({
    nameCheckList: yup.string().required("Nome é obrigatório"),
    order: yup.string().required("Ordem do checklist é obrigatório"),
});

type SchemaFormData = yup.InferType<typeof schemaForms>;

interface ModalAddCheckListProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
    getAllItemsCheckLists: () => void;
    itemChecklistSelected: ItemsChecklistListProps | null;
    setItemChecklistSelected: (data: ItemsChecklistListProps | null) => void;
}

export default function ModalAddEditCheckList({
    visible,
    setVisible,
    getAllItemsCheckLists,
    itemChecklistSelected,
    setItemChecklistSelected,
}: ModalAddCheckListProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const handleCloseModal = () => {
        setVisible(false);
        setItemChecklistSelected(null);
    };

    const handleEditItemChecklist = async (data: SchemaFormData) => {
        try {
            setLoading(true);

            const responseEditItem = await api.put(
                `customerValidationForm/${itemChecklistSelected?.id}`,
                {
                    description: data.nameCheckList,
                    order: Number(data.order),
                }
            );

            if (responseEditItem.status) {
                defaultToast("success", "Item editado com sucesso!");
                handleCloseModal();
                getAllItemsCheckLists();
            }

            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleAddItemCheckList = async (data: SchemaFormData) => {
        try {
            setLoading(true);
            const responseAddItemChecklist = await api.post(
                "customerValidationForm/",
                {
                    description: data.nameCheckList,
                    order: Number(data.order),
                }
            );

            if (responseAddItemChecklist.status) {
                defaultToast("success", "Item de checklist salvo com sucesso");
                getAllItemsCheckLists();
                handleCloseModal();
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (itemChecklistSelected) {
            setValue("nameCheckList", itemChecklistSelected.description);
            setValue("order", String(itemChecklistSelected.order));
        } else {
            setValue("nameCheckList", "");
            setValue("order", "");
        }
    }, [itemChecklistSelected]);

    const submitForm = itemChecklistSelected
        ? handleSubmit(handleEditItemChecklist)
        : handleSubmit(handleAddItemCheckList);

    return (
        <Modal
            open={visible}
            onClose={handleCloseModal}
            aria-labelledby="modal-add-checklist"
        >
            <Container onSubmit={submitForm}>
                <TitleModal>
                    {itemChecklistSelected
                        ? "Editar item de checklist"
                        : "Adicionar novo item de checklist"}
                </TitleModal>

                <ContentInput>
                    <Controller
                        control={control}
                        name="nameCheckList"
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <TextField
                                fullWidth
                                size="small"
                                label="Nome checklist"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={error?.message}
                            />
                        )}
                    />
                </ContentInput>

                <ContentInput>
                    <Controller
                        control={control}
                        name="order"
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                label="Ordem de item"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={error?.message}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                            />
                        )}
                    />
                </ContentInput>

                <FooterModal>
                    <Button
                        variant="contained"
                        onClick={handleCloseModal}
                        style={{
                            backgroundColor: "var(--cancel)",
                        }}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                    >
                        {itemChecklistSelected ? "Editar" : "Adicionar"}
                    </LoadingButton>
                </FooterModal>
            </Container>
        </Modal>
    );
}
