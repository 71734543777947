import { yupResolver } from "@hookform/resolvers/yup";
import { Add, Delete } from "@mui/icons-material";
import { AlertColor } from "@mui/lab";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { api } from "../../services/api";
import { FormComissionSetupPage } from "./styles";

interface ItemProps {
    firstMoreThan?: string;
    secondMoreThan?: string;
    appAmount?: string;
    value: string;
    comissionValue: string;
    comissionPayment?: string;
    andOr?: string;
    index?: number;
}

type comissionSetupFormTypeValues = {
    description?: string;
    weeklyMoreThan?: string;
    weeklyAppAmount: string;
    weeklyComissionValue: string;
    globalFirstMoreThan: string;
    globalSecondMoreThan: string;
    globalAppAmount: string;
    globalAndOr: string;
    globalComissionValue: string;
    globalValue: string;
};

const planFormSchema = yup.object().shape({
    description: yup.string().required(),
    weeklyMoreThan: yup.number(),
    weeklyAppAmount: yup.string().required(),
    weeklyComissionValue: yup.string().required(),
    globalFirstMoreThan: yup.number().required(),
    globalSecondMoreThan: yup.number().required(),
    globalAppAmount: yup.string().required(),
    globalAndOr: yup.string().required(),
    globalComissionValue: yup.string().required(),
    globalValue: yup.string().required(),
});

function ComissionSetupPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("comissoes.visualizar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<comissionSetupFormTypeValues>({
        resolver: yupResolver(planFormSchema),
    });

    function handleChangeWeeklyComissionValueMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "weeklyComissionValue",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeGlobalComissionValueMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "globalComissionValue",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeGlobalValueMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "globalValue",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    const itemsProps = [""];

    const [items, setItems] = useState<ItemProps[]>(
        itemsProps !== undefined
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              itemsProps.map((item: any) => {
                  return {
                      firstMoreThan: item.firstMoreThan ?? "1",
                      secondMoreThan: item.secondMoreThan ?? "1",
                      appAmount: item.appAmount ?? "",
                      value: item.value ?? "",
                      comissionValue: item.comissionValue ?? "",
                      comissionPayment: item.comissionPayment ?? "1",
                      andOr: item.andOr ?? "1",
                  };
              })
            : []
    );

    useEffect(() => {
        setValue("weeklyMoreThan", "1");
        setValue("globalFirstMoreThan", "1");
        setValue("globalAndOr", "1");
        setValue("globalSecondMoreThan", "1");
    }, [items]);

    const handleAdd = () => {
        setItems([
            ...items,
            {
                firstMoreThan: "1",
                secondMoreThan: "1",
                appAmount: "",
                value: "",
                comissionValue: "",
                comissionPayment: "1",
                andOr: "1",
            },
        ]);
    };
    const handleRemove = (index: number) => {
        setItems(items.filter((_, i) => i !== index));
    };

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const createNewComission: SubmitHandler<
        comissionSetupFormTypeValues
    > = async (formValues) => {
        setIsLoadingButton(true);
        api.post(`/teste123`, {
            description: formValues.description,
            weekly_more_than: Number(formValues.weeklyMoreThan),
            weekly_app_amount: Number(formValues.weeklyAppAmount),
            weekly_comission_value: Number(
                formValues.weeklyComissionValue
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            global_first_more_than: formValues.globalFirstMoreThan,
            global_second_more_than: formValues.globalSecondMoreThan,
            global_app_amount: Number(formValues.globalAppAmount),
            global_and_or: Number(formValues.globalAndOr),
            global_comission_value: Number(
                formValues.globalComissionValue
                    .replaceAll(".", "")
                    .replaceAll(",", ".")
            ),
            global_value: Number(
                formValues.globalValue.replaceAll(".", "").replaceAll(",", ".")
            ),

            dynamic_goals: items.map((item) => ({
                first_more_than: Number(item.firstMoreThan),
                app_amount: Number(item.appAmount),
                and_or: Number(item.andOr),
                second_more_than: Number(item.secondMoreThan),
                value: Number(
                    item.value.replaceAll(".", "").replaceAll(",", ".")
                ),
                comission_value: Number(
                    item.comissionValue.replaceAll(".", "").replaceAll(",", ".")
                ),
                comission_payment: Number(item.comissionPayment),
            })),
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/settings/products");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            <FormComissionSetupPage>
                <div className="topFormPage">
                    <h2>Configuração de Comissão</h2>
                </div>

                <form onSubmit={handleSubmit(createNewComission)}>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="description"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputFullWidth"
                                    label="Nome"
                                    error={!!errors.description}
                                    ref={ref}
                                    autoFocus
                                />
                            )}
                        />
                    </div>
                    <Table className="table">
                        <TableBody className="tableBody">
                            {items.map((item, index) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                    >
                                        <TableCell className="itensTableRow">
                                            <FormControl
                                                sx={{ height: "56px" }}
                                            >
                                                <InputLabel
                                                    size="small"
                                                    id="demo-simple-select-label"
                                                >
                                                    &gt;=/&lt;=
                                                </InputLabel>
                                                <Select
                                                    className="selectInput"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    value={item.firstMoreThan}
                                                    onChange={(event) => {
                                                        const newItem = {
                                                            ...item,
                                                            firstMoreThan:
                                                                event.target
                                                                    .value,
                                                        };
                                                        items[index] = newItem;
                                                        setItems([...items]);
                                                    }}
                                                    label=">=/<="
                                                >
                                                    <MenuItem value={1}>
                                                        &gt;=
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        &lt;=
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <InputGeneric
                                                className="inputsDynamics"
                                                size="small"
                                                value={item.appAmount}
                                                inputProps={{ min: 1 }}
                                                label="Qtd Apps"
                                                type="number"
                                                onChange={(event) => {
                                                    const newItem = {
                                                        ...item,
                                                        appAmount:
                                                            event.target.value,
                                                    };
                                                    items[index] = newItem;
                                                    setItems([...items]);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <FormControl
                                                sx={{ height: "56px" }}
                                            >
                                                <InputLabel
                                                    size="small"
                                                    id="demo-simple-select-label"
                                                >
                                                    ou/e
                                                </InputLabel>
                                                <Select
                                                    className="selectInput"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    value={item.andOr}
                                                    onChange={(event) => {
                                                        const newItem = {
                                                            ...item,
                                                            andOr: event.target
                                                                .value,
                                                        };
                                                        items[index] = newItem;
                                                        setItems([...items]);
                                                    }}
                                                    label="ou/e"
                                                >
                                                    <MenuItem value={1}>
                                                        ou
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        e
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <FormControl
                                                sx={{ height: "56px" }}
                                            >
                                                <InputLabel
                                                    size="small"
                                                    id="demo-simple-select-label"
                                                >
                                                    &gt;=/&lt;=
                                                </InputLabel>
                                                <Select
                                                    className="selectInput"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    value="1"
                                                    onChange={(event) => {
                                                        const newItem = {
                                                            ...item,
                                                            moreThan:
                                                                event.target
                                                                    .value,
                                                        };
                                                        items[index] = newItem;
                                                        setItems([...items]);
                                                    }}
                                                    label=">=/<="
                                                >
                                                    <MenuItem value={1}>
                                                        &gt;=
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        &lt;=
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <InputGeneric
                                                className="inputsDynamics"
                                                size="small"
                                                value={item.value}
                                                inputProps={{ min: 0 }}
                                                label="Valor Vendido"
                                                onChange={(event) => {
                                                    const newItem = {
                                                        ...item,
                                                        value: formatCurrencyToShow(
                                                            event.target.value,
                                                            true
                                                        ),
                                                    };
                                                    items[index] = newItem;
                                                    setItems([...items]);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <InputGeneric
                                                className="inputsDynamics"
                                                size="small"
                                                value={item.comissionValue}
                                                inputProps={{ min: 1 }}
                                                label="Valor comissão"
                                                onChange={(event) => {
                                                    const newItem = {
                                                        ...item,
                                                        comissionValue:
                                                            formatCurrencyToShow(
                                                                event.target
                                                                    .value,
                                                                true
                                                            ),
                                                    };
                                                    items[index] = newItem;
                                                    setItems([...items]);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className="itensTableRow">
                                            <FormControl
                                                sx={{ height: "56px" }}
                                            >
                                                <InputLabel
                                                    size="small"
                                                    id="demo-simple-select-label"
                                                >
                                                    % / R$
                                                </InputLabel>
                                                <Select
                                                    className="selectInput"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    value={
                                                        item.comissionPayment
                                                    }
                                                    onChange={(event) => {
                                                        const newItem = {
                                                            ...item,
                                                            comissionPayment:
                                                                event.target
                                                                    .value,
                                                        };
                                                        items[index] = newItem;
                                                        setItems([...items]);
                                                    }}
                                                    label="%/R$"
                                                >
                                                    <MenuItem value={1}>
                                                        %
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        R$
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Delete
                                                color="error"
                                                className="iconDelete"
                                                onClick={() =>
                                                    handleRemove(index)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="flex-end"
                        marginTop="-20px"
                    >
                        <ButtonGeneric
                            buttonColor="var(--primary)"
                            icon={<Add />}
                            text="Nova Meta"
                            onclick={() => handleAdd()}
                        />
                    </Box>

                    <h2>Semanal</h2>

                    <div className="inputsContainer">
                        <FormControl sx={{ height: "56px" }}>
                            <InputLabel
                                size="small"
                                id="demo-simple-select-label"
                            >
                                &gt;=/&lt;=
                            </InputLabel>
                            <Select
                                className="selectInput"
                                size="small"
                                id="demo-simple-select"
                                defaultValue="1"
                                label=">=/<="
                                onChange={(event) => {
                                    setValue(
                                        "weeklyMoreThan",
                                        event.target.value
                                    );
                                }}
                            >
                                <MenuItem value={1}>&gt;=</MenuItem>
                                <MenuItem value={2}>&lt;=</MenuItem>
                            </Select>
                        </FormControl>
                        <InputGeneric
                            className="inputs"
                            size="small"
                            inputProps={{ min: 1 }}
                            label="Qtd Apps"
                            type="number"
                            error={!!errors.weeklyAppAmount}
                            onChange={(event) => {
                                setValue("weeklyAppAmount", event.target.value);
                            }}
                        />
                        <Controller
                            control={control}
                            name="weeklyComissionValue"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeWeeklyComissionValueMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    inputProps={{ min: 1 }}
                                    className="inputs"
                                    size="small"
                                    label="Valor comissão"
                                    error={!!errors.weeklyComissionValue}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>

                    <h2>Global</h2>

                    <div className="inputsContainer">
                        <FormControl sx={{ height: "56px" }}>
                            <InputLabel
                                size="small"
                                id="demo-simple-select-label"
                            >
                                &gt;=/&lt;=
                            </InputLabel>
                            <Select
                                className="selectInput"
                                size="small"
                                id="demo-simple-select"
                                defaultValue="1"
                                label=">=/<="
                                onChange={(event) => {
                                    setValue(
                                        "globalFirstMoreThan",
                                        event.target.value
                                    );
                                }}
                            >
                                <MenuItem value={1}>&gt;=</MenuItem>
                                <MenuItem value={2}>&lt;=</MenuItem>
                            </Select>
                        </FormControl>
                        <InputGeneric
                            className="inputs300px"
                            size="small"
                            inputProps={{ min: 1 }}
                            label="Qtd Apps"
                            type="number"
                            error={!!errors.globalAppAmount}
                            onChange={(event) => {
                                setValue("globalAppAmount", event.target.value);
                            }}
                        />
                        <FormControl sx={{ height: "56px" }}>
                            <InputLabel
                                size="small"
                                id="demo-simple-select-label"
                            >
                                ou / e
                            </InputLabel>
                            <Select
                                className="selectInput"
                                size="small"
                                id="demo-simple-select"
                                defaultValue="1"
                                onChange={(event) => {
                                    setValue("globalAndOr", event.target.value);
                                }}
                                label="ou/e"
                            >
                                <MenuItem value={1}>ou</MenuItem>
                                <MenuItem value={2}>e</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ height: "56px" }}>
                            <InputLabel
                                size="small"
                                id="demo-simple-select-label"
                            >
                                &gt;=/&lt;=
                            </InputLabel>
                            <Select
                                className="selectInput"
                                size="small"
                                id="demo-simple-select"
                                defaultValue="1"
                                label=">=/<="
                                onChange={(event) => {
                                    setValue(
                                        "globalSecondMoreThan",
                                        event.target.value
                                    );
                                }}
                            >
                                <MenuItem value={1}>&gt;=</MenuItem>
                                <MenuItem value={2}>&lt;=</MenuItem>
                            </Select>
                        </FormControl>
                        <Controller
                            control={control}
                            name="globalValue"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeGlobalValueMask(event);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    inputProps={{ min: 1 }}
                                    className="inputsDynamics"
                                    size="small"
                                    label="Valor Vendido"
                                    error={!!errors.globalValue}
                                    ref={ref}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="globalComissionValue"
                            render={({
                                field: { onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleChangeGlobalComissionValueMask(
                                            event
                                        );
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    inputProps={{ min: 1 }}
                                    className="inputsDynamics"
                                    size="small"
                                    label="Valor comissão"
                                    error={!!errors.globalComissionValue}
                                    ref={ref}
                                />
                            )}
                        />
                    </div>

                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/products")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormComissionSetupPage>
        </PageWrapper>
    );
}
export default ComissionSetupPage;
