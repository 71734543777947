import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Modal, Box, Typography, CircularProgress } from "@mui/material";
import { useState, forwardRef, useImperativeHandle } from "react";

import { ValidateCustomerModalStyled } from "./styles";

interface Props {
    customerName?: string;
}

interface CheckResult {
    url: string;
    success: boolean;
    body?: string | null;
}

const StabilityCheckModal = forwardRef((props: Props, ref) => {
    const [open, setOpen] = useState(false);
    const [checking, setChecking] = useState(false);
    const [checkResults, setCheckResults] = useState<CheckResult[]>([]);

    const performStabilityChecks = async () => {
        setChecking(true);
        const nameTest = props?.customerName;
        const appLinks = {
            monitor: `https://monitor-${nameTest}.${
                window.env.NODE_ENV === "development" ? "dev" : "prod"
            }.704apps.com.br/`,
            apiNode: `https://mobilidade-api-node-${nameTest}.${
                window.env.NODE_ENV === "development" ? "dev" : "prod"
            }.704apps.com.br/`,
            apiPhp: `https://mobilidade-api-php-${nameTest}.${
                window.env.NODE_ENV === "development" ? "dev" : "prod"
            }.704apps.com.br/`,
        };

        const checks = [
            { url: appLinks.monitor, expectedStatus: 200 },
            {
                url: appLinks.apiNode,
                expectedStatus: 200,
                expectedBody: "Hello World!",
            },
            { url: appLinks.apiPhp, expectedStatus: 401 },
        ];

        const results = await Promise.all(
            checks.map(async (check) => {
                try {
                    const res = await fetch(check.url);
                    const statusCheck = res.status === check.expectedStatus;
                    let bodyCheck = true;

                    let body = null;

                    if (check.expectedBody) {
                        body = await res?.text();
                        bodyCheck = body?.includes(check.expectedBody);
                    }
                    return {
                        url: check.url,
                        success: statusCheck && bodyCheck,
                        body,
                    };
                } catch (error) {
                    return {
                        url: check.url,
                        success: false,
                        error,
                        body: JSON.stringify(error),
                    };
                }
            })
        );

        setCheckResults(results);
        setChecking(false);
    };

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setOpen(true);
            performStabilityChecks();
        },
    }));

    const handleClose = () => {
        setOpen(false);
        setCheckResults([]);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <ValidateCustomerModalStyled>
                <Typography variant="h6">
                    Verificando a estabilidade do sistema...
                </Typography>
                {checking ? (
                    <CircularProgress />
                ) : (
                    checkResults.map((result: CheckResult) => (
                        <Box
                            key={result.url}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            width="100%"
                            mb={1}
                        >
                            {result?.success ? (
                                <CheckCircleOutline color="success" />
                            ) : (
                                <ErrorOutline color="error" />
                            )}
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {result?.url}
                            </Typography>
                        </Box>
                    ))
                )}
            </ValidateCustomerModalStyled>
        </Modal>
    );
});

export default StabilityCheckModal;
