import { CloudUpload, Delete, CloudDownload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    FormControlLabel,
    Switch,
    TextField,
    IconButton,
    Divider,
} from "@mui/material";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ButtonFile from "../../../components/ButtonFile";
import { If } from "../../../components/If";
import ModalConfirm from "../../../components/ModalConfirm";
import { useAuth } from "../../../contexts/contexts";
import { defaultToast } from "../../../helpers/toast/defaultToast";
import { api } from "../../../services/api";
import {
    Container,
    ContentInputsUploads,
    ContentFieldJson,
    ContentButton,
    ContentInput,
    ContentSwitchOnOffCustomer,
    ContentInformationsFiles,
} from "./styles";
import { CustomerFirebaseConfigProps } from "./types";

const valueDefaultJson = `{
 "apiKey": "",
 "authDomain": "",
 "databaseURL": "",
 "projectId": "",
 "storageBucket": "",
 "menssagingSenderId": ""
}`;

interface ConfigGeneralCustomerProps {
    firebaseConfig: CustomerFirebaseConfigProps | undefined;
    statusCustomer: number | undefined;
    idCustomer: string | null;
}

const statusIsTurnOff = [4, 5, 6, 7, 8];

const isAppLegado = true;

function ConfigGeneralCustomer({
    firebaseConfig,
    statusCustomer,
    idCustomer,
}: ConfigGeneralCustomerProps) {
    const { hasPermissions, permissions } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            hasPermissions("configuracoes.gerais") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    // const [acceptCreateIndex, setAcceptCreateIndex] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButtonModal, setLoadingButtonModal] =
        useState<boolean>(false);
    const [fileAppDriver, setFileAppDriver] = useState<File | null>(null);
    const [fileAppCustomer, setFileAppCustomer] = useState<File | null>(null);
    const [fileAppBack, setFileAppBack] = useState<File | null>(null);
    const [fileAppIos, setFileAppIos] = useState<File | null>(null);
    const [configFirebase, setConfigFirebase] = useState<string | null>(
        valueDefaultJson
    );
    const [linkJsonDriver, setLinkJsonDriver] = useState<string | null>(null);
    const [linkJsonCustomer, setLinkJsonCustomer] = useState<string | null>(
        null
    );
    const [linkJsonBack, setLinkJsonBack] = useState<string | null>(null);
    const [linkJsonIos, setLinkJsonIos] = useState<string | null>(null);

    const [showVisibleModal, setShowVisibleModal] = useState<boolean>(false);
    const [switchTurnOnTurnOff, setSwitchTurnOnTurnOff] =
        useState<boolean>(false);

    const refInputFileAppDriver = useRef<HTMLInputElement | null>(null);
    const refInputFileAppCustomer = useRef<HTMLInputElement | null>(null);
    const refInputFileAppBack = useRef<HTMLInputElement | null>(null);
    const refInputFileAppIos = useRef<HTMLInputElement | null>(null);

    const isStatusTurnOff = statusIsTurnOff.includes(statusCustomer as number);

    // const handleChangeCheckedAcceptIndex = (
    //     event: ChangeEvent<HTMLInputElement>
    // ) => {
    //     setAcceptCreateIndex(event.target.checked);
    // };

    const handleDeleteFile = (type: "driver" | "customer" | "back" | "ios") => {
        if (type === "driver") {
            setFileAppDriver(null);
            setLinkJsonDriver(null);
            if (refInputFileAppDriver.current) {
                refInputFileAppDriver.current.value = "";
            }
        } else if (type === "customer") {
            setFileAppCustomer(null);
            setLinkJsonCustomer(null);
            if (refInputFileAppCustomer.current) {
                refInputFileAppCustomer.current.value = "";
            }
        } else if (type === "back") {
            setFileAppBack(null);
            setLinkJsonBack(null);
            if (refInputFileAppBack.current) {
                refInputFileAppBack.current.value = "";
            }
        } else {
            setFileAppIos(null);
            setLinkJsonIos(null);
            if (refInputFileAppIos.current) {
                refInputFileAppIos.current.value = "";
            }
        }
    };

    const handleTurnOffAndTurnOnCustomer = async () => {
        try {
            setLoadingButtonModal(true);
            const responseTurnOnAndTunOffCustomer = await api.post(
                `customers/servers/${idCustomer}/${isStatusTurnOff ? 1 : 0}`
            );
            if (responseTurnOnAndTunOffCustomer.status) {
                defaultToast("error", "Cliente atualizado com sucesso!");
                setSwitchTurnOnTurnOff(!switchTurnOnTurnOff);
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao atualizar status do cliente");
        } finally {
            setLoadingButtonModal(false);
        }
    };

    const handleDownloadFileAws = async () => {
        // try {
        //     const responseDownloadFile = await fetch(
        //         "https://704apps-admin-dev.s3.amazonaws.com/contracts/firebaseFiles/1626/back/2NZ7T0PuB36l3ZF3UgihzQ0G2TqETqiex4vIeJYF.json"
        //     );
        //     if (responseDownloadFile.status) {
        //         alert("DEU CERTO");
        //     }
        // } catch (error) {
        //     console.error(error);
        // }
    };

    const handleDownloadFileLocal = async (file: File) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChangeFiles = (
        event: ChangeEvent<HTMLInputElement>,
        type: "driver" | "customer" | "back" | "ios"
    ) => {
        if (event.target.files) {
            const file = event?.target?.files[0];

            if (type === "driver") {
                if (file.type === "application/json") {
                    setFileAppDriver(file);
                } else {
                    defaultToast(
                        "warning",
                        "Somente arquivos de formato json é permitido"
                    );
                }
            }

            if (type === "customer") {
                if (file.type === "application/json") {
                    setFileAppCustomer(file);
                } else {
                    defaultToast(
                        "warning",
                        "Somente arquivos de formato json é permitido"
                    );
                }
            }

            if (type === "back") {
                if (file.type === "application/json") {
                    setFileAppBack(file);
                } else {
                    defaultToast(
                        "warning",
                        "Somente arquivos de formato json é permitido"
                    );
                }
            }

            if (type === "ios") {
                const lowerCaseFileName = file.name.toLowerCase();
                if (
                    lowerCaseFileName.endsWith(".plist") ||
                    lowerCaseFileName.endsWith(".xml")
                ) {
                    setFileAppIos(file);
                } else {
                    defaultToast(
                        "warning",
                        "Somente arquivos de formato xml e plist são permitidos"
                    );
                }
            }
        }
    };

    // useEffect(() => {
    //     if (!myParam) {
    //         navigate("/comercial/customers");
    //     }
    // }, [myParam]);

    const handleSaveConfigGeneral = async () => {
        if (!fileAppDriver || !fileAppBack || !fileAppIos) {
            defaultToast(
                "warning",
                "Insira os arquivos para salvar a configuração"
            );

            return;
        }

        if (isAppLegado) {
            if (!fileAppCustomer) {
                defaultToast(
                    "warning",
                    "Insira os arquivos para salvar a configuração"
                );

                return;
            }
        }

        if (!configFirebase) {
            defaultToast("warning", "Configuração do firebase está vazio");
            return;
        }

        if (configFirebase) {
            const listValuesConfigFirebase: string[] = Object.values(
                JSON.parse(configFirebase as string)
            );

            // eslint-disable-next-line
            for (let x = 0; x < listValuesConfigFirebase.length; x++) {
                if (listValuesConfigFirebase[x].length <= 0) {
                    defaultToast(
                        "warning",
                        "Preencha os valores da chaves de configurações de firebase"
                    );
                    return;
                }
            }
        }

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("app_driver_json", fileAppDriver);
            formData.append(
                "app_client_json",
                isAppLegado ? (fileAppCustomer as File) : fileAppDriver
            );
            formData.append("back_json", fileAppBack);
            formData.append("google_service_ios", fileAppIos);
            formData.append(
                "front_json",
                JSON.stringify(JSON.parse(configFirebase))
            );

            const responseSaveConfigGeneral = await api.post(
                `customers/firebase/${idCustomer}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (responseSaveConfigGeneral.status) {
                defaultToast("success", "Configuração geral salva com sucesso");
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao salvar configuração geral");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (firebaseConfig) {
            // eslint-disable-next-line
            const configFirebaseObject = JSON.parse(firebaseConfig?.json_front);
            setConfigFirebase(JSON.stringify(configFirebaseObject, null, 2));
            setLinkJsonCustomer(firebaseConfig?.json_file_customer);
            setLinkJsonDriver(firebaseConfig?.json_file_driver);
            setLinkJsonBack(firebaseConfig?.json_file_back);
            setLinkJsonIos(firebaseConfig?.json_google_service_ios);
        }
    }, []);

    return (
        <Container>
            <Divider />
            <If condition={hasPermissions("cliente.config.firebase")}>
                <h4>Configurações do Firebase</h4>

                <ContentInputsUploads>
                    <ContentInput>
                        <ButtonFile
                            fullWidth
                            onChangeInput={(event) =>
                                handleChangeFiles(event, "driver")
                            }
                            icon={<CloudUpload />}
                            title={`${
                                isAppLegado ? "JSON Motorista" : "JSON APP"
                            }`}
                            variant="contained"
                            refInput={refInputFileAppDriver}
                            accept=".json"
                        />

                        {linkJsonDriver && !fileAppDriver ? (
                            <ContentInformationsFiles>
                                {`${linkJsonDriver
                                    ?.split("/")
                                    .pop()
                                    ?.slice(0, 20)}.${
                                    linkJsonDriver
                                        ?.split("/")
                                        .pop()
                                        ?.split(".")[1]
                                }`}
                                <IconButton title="Baixar json">
                                    <CloudDownload color="primary" />
                                </IconButton>
                                <IconButton
                                    title="Deletar json"
                                    onClick={() => handleDeleteFile("driver")}
                                >
                                    <Delete color="error" />
                                </IconButton>
                            </ContentInformationsFiles>
                        ) : (
                            fileAppDriver && (
                                <ContentInformationsFiles>
                                    {fileAppDriver && (
                                        <span>{fileAppDriver.name}</span>
                                    )}
                                    <IconButton
                                        title="Baixar json"
                                        onClick={() =>
                                            handleDownloadFileLocal(
                                                fileAppDriver
                                            )
                                        }
                                    >
                                        <CloudDownload color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            handleDeleteFile("driver")
                                        }
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                </ContentInformationsFiles>
                            )
                        )}
                    </ContentInput>

                    {isAppLegado && (
                        <ContentInput>
                            <ButtonFile
                                fullWidth
                                onChangeInput={(event) =>
                                    handleChangeFiles(event, "customer")
                                }
                                icon={<CloudUpload />}
                                title="JSON Passageiro"
                                variant="contained"
                                refInput={refInputFileAppCustomer}
                                accept=".json"
                            />

                            {linkJsonCustomer && !fileAppCustomer ? (
                                <ContentInformationsFiles>
                                    {`${linkJsonCustomer
                                        ?.split("/")
                                        .pop()
                                        ?.slice(0, 20)}.${
                                        linkJsonCustomer
                                            ?.split("/")
                                            .pop()
                                            ?.split(".")[1]
                                    }`}
                                    <IconButton title="Baixar json">
                                        <CloudDownload color="primary" />
                                    </IconButton>
                                    <IconButton
                                        title="Deletar json"
                                        onClick={() =>
                                            handleDeleteFile("customer")
                                        }
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                </ContentInformationsFiles>
                            ) : (
                                fileAppCustomer && (
                                    <ContentInformationsFiles>
                                        {fileAppCustomer && (
                                            <span>{fileAppCustomer.name}</span>
                                        )}
                                        <IconButton
                                            title="Baixar json"
                                            onClick={() =>
                                                handleDownloadFileLocal(
                                                    fileAppCustomer
                                                )
                                            }
                                        >
                                            <CloudDownload color="primary" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleDeleteFile("customer")
                                            }
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </ContentInformationsFiles>
                                )
                            )}
                        </ContentInput>
                    )}

                    <ContentInput>
                        <ButtonFile
                            fullWidth
                            onChangeInput={(event) =>
                                handleChangeFiles(event, "back")
                            }
                            icon={<CloudUpload />}
                            title="JSON Back"
                            variant="contained"
                            refInput={refInputFileAppBack}
                            accept=".json"
                        />

                        {linkJsonBack && !fileAppBack ? (
                            <ContentInformationsFiles>
                                {`${linkJsonBack
                                    ?.split("/")
                                    .pop()
                                    ?.slice(0, 20)}.${
                                    linkJsonBack
                                        ?.split("/")
                                        .pop()
                                        ?.split(".")[1]
                                }`}
                                <IconButton
                                    title="Baixar json"
                                    onClick={handleDownloadFileAws}
                                >
                                    <CloudDownload color="primary" />
                                </IconButton>
                                <IconButton
                                    title="Deletar json"
                                    onClick={() => handleDeleteFile("back")}
                                >
                                    <Delete color="error" />
                                </IconButton>
                            </ContentInformationsFiles>
                        ) : (
                            fileAppBack && (
                                <ContentInformationsFiles>
                                    {fileAppBack && (
                                        <span>{fileAppBack.name}</span>
                                    )}
                                    <IconButton
                                        title="Baixar json"
                                        onClick={() =>
                                            handleDownloadFileLocal(fileAppBack)
                                        }
                                    >
                                        <CloudDownload color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteFile("back")}
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                </ContentInformationsFiles>
                            )
                        )}
                    </ContentInput>

                    <ContentInput>
                        <ButtonFile
                            fullWidth
                            onChangeInput={(event) =>
                                handleChangeFiles(event, "ios")
                            }
                            icon={<CloudUpload />}
                            title="JSON Google Service Ios"
                            variant="contained"
                            refInput={refInputFileAppIos}
                            accept=".xml, .plist"
                        />

                        {linkJsonIos && !fileAppIos ? (
                            <ContentInformationsFiles>
                                {`${linkJsonIos
                                    ?.split("/")
                                    .pop()
                                    ?.slice(0, 20)}.${
                                    linkJsonIos?.split("/").pop()?.split(".")[1]
                                }`}
                                <IconButton
                                    title="Baixar json"
                                    onClick={handleDownloadFileAws}
                                >
                                    <CloudDownload color="primary" />
                                </IconButton>
                                <IconButton
                                    title="Deletar json"
                                    onClick={() => handleDeleteFile("ios")}
                                >
                                    <Delete color="error" />
                                </IconButton>
                            </ContentInformationsFiles>
                        ) : (
                            fileAppIos && (
                                <ContentInformationsFiles>
                                    {fileAppIos && (
                                        <span>{fileAppIos.name}</span>
                                    )}
                                    <IconButton
                                        title="Baixar json"
                                        onClick={() =>
                                            handleDownloadFileLocal(fileAppIos)
                                        }
                                    >
                                        <CloudDownload color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteFile("ios")}
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                </ContentInformationsFiles>
                            )
                        )}
                    </ContentInput>
                </ContentInputsUploads>
                <ContentFieldJson>
                    <TextField
                        fullWidth
                        id="json-firebase-painel"
                        label="Json firebase painel"
                        variant="outlined"
                        multiline
                        rows={8}
                        value={configFirebase}
                        onChange={(event) =>
                            setConfigFirebase(event.target.value)
                        }
                    />
                </ContentFieldJson>

                <ContentButton>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        onClick={handleSaveConfigGeneral}
                    >
                        Salvar
                    </LoadingButton>
                </ContentButton>
            </If>
            <Divider
                style={{
                    margin: "20px 0",
                }}
            />

            <If condition={hasPermissions("apps.servidor.ligar.desligar")}>
                <h4>Servidores</h4>
                <ContentSwitchOnOffCustomer>
                    <FormControlLabel
                        control={
                            <Switch
                                onClick={() => setShowVisibleModal(true)}
                                checked={!isStatusTurnOff}
                            />
                        }
                        label={
                            isStatusTurnOff
                                ? "Ativar servidores do cliente"
                                : "Desativar servidores do cliente"
                        }
                    />
                </ContentSwitchOnOffCustomer>

                <ModalConfirm
                    titleModal="Deseja realmente executar esta ação?"
                    isLoadingButton={loadingButtonModal}
                    titleButton="Confirmar"
                    visible={showVisibleModal}
                    setVisible={setShowVisibleModal}
                    onClick={handleTurnOffAndTurnOnCustomer}
                />
            </If>
        </Container>
    );
}

export default ConfigGeneralCustomer;
