import { yupResolver } from "@hookform/resolvers/yup";
import {
    Visibility,
    VisibilityOff,
    AccountCircle,
    ArrowForward,
} from "@mui/icons-material/";
import {
    AlertColor,
    CircularProgress,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { AuthContext } from "../../contexts/contexts";
import Toast from "../Toast";
import { ErrorMessage, FormLoginStyle } from "./styles";

type LoginFormTypeValues = {
    email: string;
    password: string;
};

const LoginFormSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    password: yup
        .string()
        .required("Senha é obrigatória")
        .min(6, "Senha mínima de 6 caracteres"),
});

function FormLogin() {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const navigate = useNavigate();

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormTypeValues>({
        resolver: yupResolver(LoginFormSchema),
    });

    const { signIn } = useContext(AuthContext);
    const handleValidateFormAndLogin: SubmitHandler<
        LoginFormTypeValues
    > = async (values) => {
        setIsLoadingButton(true);
        await signIn(values.email, values.password)
            .then(() => {
                setIsLoadingButton(false);
                navigate("/home");
            })
            .catch((error) => {
                console.log(error);
                setIsLoadingButton(false);
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.response);
            });
    };

    return (
        <FormLoginStyle>
            <form onSubmit={handleSubmit(handleValidateFormAndLogin)}>
                <div className="inputsContainer">
                    <FormControl
                        sx={{ mt: 1, width: "28ch" }}
                        variant="outlined"
                    >
                        <InputLabel>Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            endAdornment={
                                <InputAdornment position="start">
                                    <Icon
                                        sx={{
                                            position: "relative",
                                            left: "4px",
                                        }}
                                    >
                                        <AccountCircle />
                                    </Icon>
                                </InputAdornment>
                            }
                            label="Email"
                            autoFocus
                            error={!!errors.email}
                            {...register("email")}
                        />
                    </FormControl>
                    <FormControl
                        sx={{ mt: 1, mb: 2, width: "28ch" }}
                        variant="outlined"
                    >
                        <InputLabel>Senha</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Senha"
                            error={!!errors.password}
                            {...register("password")}
                        />
                    </FormControl>
                </div>
                <ErrorMessage>
                    {errors && (
                        <p className="ErrorMessage">
                            {errors.email?.message || errors.password?.message}
                        </p>
                    )}
                </ErrorMessage>
                <button
                    type="submit"
                    className="loginButton"
                    disabled={isLoadingButton}
                >
                    {isLoadingButton ? (
                        <CircularProgress
                            className="circularProgressPosition"
                            // style={{ position: "absolute" }}
                        />
                    ) : (
                        "CONFIRMAR"
                    )}
                    {isLoadingButton ? undefined : (
                        <ArrowForward className="iconConfirm" />
                    )}
                </button>
            </form>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </FormLoginStyle>
    );
}

export default FormLogin;
