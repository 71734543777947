import { Person, CheckCircle, RemoveCircle } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { If } from "../../components/If";
import ImageDownload from "../../components/ImageDownload";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import { useColorInverse } from "../../helpers/colorInverse";
import { SearchParamsUrl } from "../../helpers/searchParamsUrl";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ConfigGeneralCustomer from "../CustomerDetailsPage/ConfigGeneralCustomer";
import { statusCustomer } from "../CustomerDetailsPage/GeneralData/consts";
import StabilityCheckModal from "../CustomerDetailsPage/StabilityCheckModal";
import {
    Container,
    Section,
    ContentActions,
    ContentApps,
    ContentColorsApps,
    ContentHealthCustomer,
    ContainerServers,
} from "./styles";

export function AnaDetailsPage() {
    const [customer, setCustomer] = useState<CustomerDetailsProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [statusId, setStatusId] = useState<number>(0);

    const [customerHealth, setCustomerHealth] = useState<boolean>(false);
    const [healthServerLoading, setHealthServerLoading] =
        useState<boolean>(false);

    const [loadingServer, setLoadingServer] = useState<boolean>(false);
    const [loadingApp, setLoadingApp] = useState<boolean>(false);
    const [loadingInstallments, setLoadingInstallments] =
        useState<boolean>(false);

    const stabilityCheckRef = useRef<any>(null);

    const idCustomer = SearchParamsUrl("idCustomer");

    const getDetailsUser = async () => {
        try {
            setLoading(true);
            const responseDetailsUser = await api.get(
                `customers/${idCustomer}`
            );

            if (responseDetailsUser.status) {
                setCustomer(responseDetailsUser.data.response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const changeCustomerStatus = (status: number) => {
        const oldStatusId = statusId;
        setStatusId(status);
        api.get(`/customers/status/${customer?.id}/${status}`)
            .then((res) => {
                defaultToast("success", "Atualizado com successo!");
            })
            .catch((err) => {
                defaultToast("error", err.response.data.message);
                setStatusId(oldStatusId);
            });
    };

    const handleCreateApps = async () => {
        setLoadingApp(true);
        api.post(`/customers/generateApps/${customer?.id}`)
            .then((res) => {
                defaultToast("success", "Criado com sucesso!");
            })
            .catch((err) => {
                defaultToast("error", err.response.data.message);
            })
            .finally(() => {
                setLoadingApp(false);
            });
    };

    const generateServers = () => {
        setLoadingServer(true);
        api.post(`/customers/generated/${idCustomer}`, {})
            .then((response) => {
                defaultToast("success", response.data.message);
            })
            .catch((error) => {
                defaultToast("error", error.response.data.message);
            })
            .finally(() => {
                setLoadingServer(false);
            });
    };

    const handleInstallImplementation = (idInstallments: number) => {
        setLoadingInstallments(true);
        api.post(`customers/install/${idCustomer}/${idInstallments}`, {})
            .then((response) => {
                defaultToast("success", "Implementação instalada com sucesso!");
            })
            .catch((error) => {
                defaultToast("error", error.response.data.message);
            })
            .finally(() => {
                setLoadingInstallments(false);
            });
    };

    const getCustomerHealth = () => {
        setHealthServerLoading(true);
        api.get(`/customerInfra/isActive/${idCustomer}`)
            .then((res) => {
                console.log(res.data);
                setCustomerHealth(res.data.response);
            })
            .catch((err) => {
                defaultToast(
                    "error",
                    "ocorreu um erro ao verificar saúde do cliente"
                );
            })
            .finally(() => {
                setHealthServerLoading(false);
            });
    };

    useEffect(() => {
        getDetailsUser();
        getCustomerHealth();
    }, []);

    useEffect(() => {
        if (customer) {
            setStatusId(customer?.customer_status_id);
        }
    }, [customer]);
;
    return (
        <PageWrapper>
            {loading ? (
                <PreloadFb />
            ) : (
                <>
                    <PageDefault
                        headerPage
                        header={{
                            title: customer
                                ? customer?.name.toLocaleUpperCase()
                                : "",
                            subtitle: customer
                                ? customer?.name.toLocaleUpperCase()
                                : "",
                            icon: <Person sx={{ color: "#fff" }} />,
                        }}
                        heightAuto
                    >
                        <Container>
                            <Section>
                                <ContainerServers>
                                    <h3>Status dos servidores</h3>
                                    <div>
                                        {customerHealth ? (
                                            <span>
                                                <If
                                                    condition={
                                                        !healthServerLoading
                                                    }
                                                >
                                                    <ContentHealthCustomer>
                                                        <CheckCircle color="success" />
                                                        <span>
                                                            Cliente está{" "}
                                                            <strong>
                                                                ONLINE
                                                            </strong>{" "}
                                                            <br />
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "0.6rem",
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                atualizado a 5
                                                                minutos atrás
                                                            </span>
                                                        </span>
                                                    </ContentHealthCustomer>
                                                </If>
                                            </span>
                                        ) : (
                                            <span>
                                                <If
                                                    condition={
                                                        !healthServerLoading
                                                    }
                                                >
                                                    <ContentHealthCustomer>
                                                        <span className="icon">
                                                            <RemoveCircle color="error" />
                                                        </span>
                                                        <span>
                                                            Cliente está{" "}
                                                            <strong>
                                                                OFFLINE
                                                            </strong>{" "}
                                                            <br />
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "0.6rem",
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                atualizado a 5
                                                                minutos atrás
                                                            </span>
                                                        </span>
                                                    </ContentHealthCustomer>
                                                </If>
                                            </span>
                                        )}
                                    </div>
                                </ContainerServers>

                                <div>
                                    <span>
                                        <strong>
                                            Informações do cliente (Comercial):{" "}
                                        </strong>
                                    </span>

                                    <a
                                        href={`${window.location.origin}/comercial/customers/customerDetail?id=${idCustomer}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        clique aqui
                                    </a>
                                </div>

                                {healthServerLoading && (
                                    <>
                                        <LinearProgress color="secondary" />

                                        <span style={{ fontSize: "0.8rem" }}>
                                            Verificandos saúde do servidor...
                                        </span>
                                    </>
                                )}

                                <h4>Status: </h4>
                                {customer && (
                                    <>
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-label">
                                                Status atual
                                            </InputLabel>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={statusId}
                                                label="Status atual"
                                                disabled={Number(statusId) >= 4}
                                                onChange={(e) => {
                                                    changeCustomerStatus(
                                                        Number(e.target.value)
                                                    );
                                                }}
                                                size="small"
                                                autoWidth
                                                sx={{
                                                    width: "250px",
                                                }}
                                            >
                                                {statusCustomer.map((item) => (
                                                    <MenuItem
                                                        key={item.value}
                                                        value={item.value}
                                                        disabled={
                                                            Number(
                                                                item.value
                                                            ) >= 4
                                                        }
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <CircularProgress
                                            size="small"
                                            sx={{ marginLeft: "5px" }}
                                        />
                                        <span style={{ fontSize: "8pt" }}>
                                            <br />
                                            Cuidado! Alterar essa informação irá
                                            impactar nos servidores e
                                            aplicativos do cliente.
                                        </span>
                                    </>
                                )}

                                <Divider
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                            </Section>

                            <Section>
                                <ContentActions>
                                    <If
                                        condition={Boolean(
                                            !customer?.migrated_client
                                        )}
                                    >
                                        <ConfirmModal
                                            variant=""
                                            title="Gerar Servidores"
                                            disabledButton={
                                                !!(
                                                    customer &&
                                                    customer.cloud === "aws"
                                                )
                                            }
                                            size="large"
                                            color="primary"
                                            onClick={() => {
                                                generateServers();
                                            }}
                                            loading={loadingServer}
                                        />
                                    </If>
                                    <If
                                        condition={Boolean(
                                            !customer?.migrated_client
                                        )}
                                    >
                                        <ConfirmModal
                                            variant=""
                                            title="Gerar APP"
                                            size="large"
                                            disabledButton={
                                                !!(
                                                    customer &&
                                                    customer.cloud === "aws"
                                                )
                                            }
                                            color="secondary"
                                            onClick={() => {
                                                handleCreateApps();
                                            }}
                                            loading={loadingApp}
                                        />
                                    </If>

                                    <ConfirmModal
                                        variant=""
                                        title="Verificar estabilidade"
                                        size="medium"
                                        color="primary"
                                        onClick={() => {
                                            stabilityCheckRef.current?.openModal();
                                        }}
                                        loading={false}
                                    />
                                </ContentActions>
                            </Section>

                            <Divider
                                style={{
                                    margin: "20px 0",
                                }}
                            />

                            <Section>
                                <h3>Aplicativo</h3>

                                <ContentApps>
                                    {customer?.customer_apps.map(
                                        ({
                                            app_type,
                                            title,
                                            logo,
                                            main_color,
                                            secondary_color,
                                            text_color,
                                            store_url,
                                            apk_link,
                                        }) => (
                                            <div key={app_type}>
                                                <h3>{title}</h3>
                                                <Divider
                                                    sx={{
                                                        marginBottom: "10px",
                                                    }}
                                                />
                                                <div>
                                                    <ImageDownload
                                                        nameImg={`logo-app-${app_type}-${title}`}
                                                        width={100}
                                                        height={100}
                                                        sourceImg={logo}
                                                        alt={`logo-app-${app_type}-${title}`}
                                                    />
                                                </div>
                                                <div>
                                                    <ContentColorsApps>
                                                        <div>
                                                            <p>
                                                                Cor principal:
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p
                                                                style={{
                                                                    backgroundColor: `${main_color}`,
                                                                    color: useColorInverse(
                                                                        main_color
                                                                    ),
                                                                }}
                                                            >
                                                                {main_color}
                                                            </p>
                                                        </div>
                                                    </ContentColorsApps>

                                                    <ContentColorsApps>
                                                        <div>
                                                            <p>
                                                                Cor secundária:
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    backgroundColor: `${secondary_color}`,
                                                                    color: useColorInverse(
                                                                        secondary_color
                                                                    ),
                                                                }}
                                                            >
                                                                {
                                                                    secondary_color
                                                                }
                                                            </p>
                                                        </div>
                                                    </ContentColorsApps>

                                                    <ContentColorsApps>
                                                        <div>
                                                            <p>Cor do texto:</p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    backgroundColor: `${text_color}`,
                                                                    color: useColorInverse(
                                                                        text_color
                                                                    ),
                                                                }}
                                                            >
                                                                {text_color}
                                                            </p>
                                                        </div>
                                                    </ContentColorsApps>

                                                    <ContentColorsApps>
                                                        <div>
                                                            <p>Store url:</p>
                                                        </div>
                                                        <div>
                                                            <p>{store_url}</p>
                                                        </div>
                                                    </ContentColorsApps>

                                                    <ContentColorsApps>
                                                        <div>
                                                            <p>Apk link:</p>
                                                        </div>
                                                        <div>
                                                            <p>{apk_link}</p>
                                                        </div>
                                                    </ContentColorsApps>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </ContentApps>
                            </Section>

                            {customer?.customer_implementation?.map(
                                ({ id, implementation, status }: any) => (
                                    <div className="contentFields" key={id}>
                                        <div className="contentRows">
                                            <p className="boldText">
                                                {implementation.description}
                                            </p>
                                        </div>
                                        <div className="contentRows">
                                            {status === "installed" ? (
                                                <p className="boldText">
                                                    Implementação instalada
                                                </p>
                                            ) : (
                                                <ConfirmModal
                                                    variant=""
                                                    title="Instalar implementação"
                                                    size="medium"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleInstallImplementation(
                                                            id
                                                        );
                                                    }}
                                                    loading={
                                                        loadingInstallments
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            )}

                            <Section>
                                <h3>Cidades disponíveis</h3>

                                <Divider
                                    style={{
                                        margin: "20px 0",
                                    }}
                                />

                                <ul className="container-cities-customer">
                                    {customer?.customer_cities?.map((value) => {
                                        return (
                                            <li
                                                className="content-cities-customer"
                                                key={value?.id}
                                            >
                                                <span>
                                                    {value.city} - (
                                                    {value.estate})
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Section>

                            <Section>
                                <ConfigGeneralCustomer
                                    idCustomer={idCustomer}
                                    firebaseConfig={
                                        customer?.customer_firebase_config
                                    }
                                    statusCustomer={
                                        customer?.customer_status_id
                                    }
                                />
                            </Section>
                        </Container>
                    </PageDefault>

                    <StabilityCheckModal
                        customerName={customer?.project_name}
                        ref={stabilityCheckRef}
                    />
                </>
            )}
        </PageWrapper>
    );
}
