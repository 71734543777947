export function MaskPercentage(value: string) {
    let inputValue = value;

    inputValue = inputValue.replace(/[^\d.]/g, "");

    const commaIndex = inputValue.indexOf(",");
    if (commaIndex !== -1) {
        inputValue =
            inputValue.substring(0, commaIndex + 1) +
            inputValue.substring(commaIndex + 1).replace(/,/g, "");
    }

    return `${inputValue.length > 0 ? inputValue : "0"}%`;
}
