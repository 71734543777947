import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import {
    AlertColor,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
} from "@mui/material";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ModalAddNewVideo from "../../components/ModalAddNewVideo";
import PageWrapper from "../../components/PageWrapper";
import PreloadButton from "../../components/PreloadButton";
import SimulationModalPublicity from "../../components/SimulationModalPublicity";
import Toast from "../../components/Toast";
import { phoneMask } from "../../helpers/phone";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { useCheckUrlValid } from "../../helpers/useCheckUrlValid";
import { useRemoveMaskPhone } from "../../helpers/useRemoveMaskPhone";
import { useResizeLogo } from "../../helpers/useResizeImage";
import { useUrlToFile } from "../../helpers/useUrlToFIle";
import { api } from "../../services/api";
import {
    Container,
    ContentInput,
    TitlePage,
    ContentButtonSendVideo,
    LabelText,
    ContentBannerAndVideo,
    Content,
    ButtonLabelBanner,
    ButtonAddVideo,
    ContentRowInput,
    TitleClientsActives,
    TextTotalClients,
    ContentInformationsCustomers,
} from "./styles";
import { VideosProps, CustomersProps } from "./types";

const schemaForms = yup.object({
    title: yup.string().required("O campo título é obrigatório"),
    link: yup.string().required("O campo link é obrigatório"),
    phone: yup.string().required("O campo telefone é obrigatório"),
    description: yup.string().required("O campo descrição é obrigatório"),
});

type SchemaFormData = yup.InferType<typeof schemaForms>;

function NewPublicity() {
    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [customersFilter, setCustomersFilters] = useState<CustomersProps[]>(
        []
    );

    const [titlePublicity, setTitlePublicity] = useState<string>("");
    const [linkSitePublicity, setLinkSitePublicity] = useState<string>("");
    const [phonePublicity, setPhonePublicity] = useState<string>("");
    const [descriptionPublicity, setDescriptionPublicity] =
        useState<string>("");

    const [urlImgBanner, serUrlImgBanner] = useState<string | null>(null);
    const [showModalAddVideo, setShowModalAddVideo] = useState<boolean>(false);
    const [listVideo, setListVideo] = useState<VideosProps[]>([]);

    const [openToast, setOpenToast] = useState(false);
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const refImgBanner = useRef<HTMLInputElement | null>(null);

    type IFilterCustomer = {
        onlyActiveMonthlyBilling: boolean;
    };
    const [filterCustomer, setFilterCustomer] = useState<IFilterCustomer>({
        onlyActiveMonthlyBilling: false,
    });
    const handleGetCustomers = async () => {
        try {
            const responseCustomers = await api.get(
                `customers/filter/advertising?billing_active=${filterCustomer.onlyActiveMonthlyBilling}`
            );

            if (responseCustomers.status) {
                setCustomersFilters(responseCustomers.data.response);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao buscar clientes");
        }
    };

    const handleCreatePublicity = async (data: SchemaFormData) => {
        try {
            setIsLoading(true);

            const idsCustomers = customersFilter.map((value) => {
                return value.id;
            });

            if (!useCheckUrlValid(data.link)) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Link do site é inválido");
                // eslint-disable-next-line
                return;
            }

            const linkVideos = listVideo.map((value) => {
                return value.link;
            });

            const phoneFormated = useRemoveMaskPhone(phonePublicity);

            const formData = new FormData();

            formData.append("title", titlePublicity);
            formData.append("description", descriptionPublicity);
            formData.append("phone", phoneFormated);
            formData.append("link", linkSitePublicity);
            formData.append("videos", String(linkVideos));
            formData.append(
                "banner",
                useUrlToFile(
                    urlImgBanner,
                    `banner-publicity-${titlePublicity}.png`
                )
            );
            formData.append("customers", String(idsCustomers));

            const responseAdvertising = await api.post(
                "advertising",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (responseAdvertising.status) {
                defaultToast("success", "Publicidade adicionada com sucesso");

                setTimeout(() => {
                    navigate("/settings/publicity");
                }, 2000);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao adicionar publicidades");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeBanner = (data: ChangeEvent<HTMLInputElement>) => {
        if (data.target.files) {
            const files = data.target.files[0];
            useResizeLogo(files, 400, 200, "PNG", "base64")
                .then((response: any) => {
                    if (response) {
                        serUrlImgBanner(response);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleChangeInputs = debounce(
        (type: "title" | "link" | "phone" | "description", value: string) => {
            if (type === "title") {
                setTitlePublicity(value);
            } else if (type === "link") {
                setLinkSitePublicity(value);
            } else if (type === "phone") {
                setPhonePublicity(value);
            } else if (type === "description") {
                setDescriptionPublicity(value);
            }
        },
        500
    );

    useEffect(() => {
        if (urlImgBanner) {
            console.log(
                useUrlToFile(
                    urlImgBanner,
                    `banner-publicity-${titlePublicity}.png`
                )
            );
        }
    }, [urlImgBanner]);

    useEffect(() => {
        handleGetCustomers();
    }, [filterCustomer]);

    return (
        <PageWrapper>
            <Container onSubmit={handleSubmit(handleCreatePublicity)}>
                <TitlePage>Adicionar nova publicidade</TitlePage>
                <Content>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <ContentRowInput>
                            <ContentInput>
                                <Controller
                                    control={control}
                                    name="title"
                                    render={({
                                        field: { value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            fullWidth
                                            id="title"
                                            label="Título"
                                            variant="outlined"
                                            size="small"
                                            ref={ref}
                                            name={name}
                                            value={value}
                                            onChange={(event) => {
                                                handleChangeInputs(
                                                    "title",
                                                    event.target.value
                                                );
                                                setValue(
                                                    "title",
                                                    event.target.value
                                                );
                                            }}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Controller
                                    control={control}
                                    name="link"
                                    render={({
                                        field: { value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            fullWidth
                                            id="link"
                                            label="Link do site"
                                            variant="outlined"
                                            size="small"
                                            ref={ref}
                                            name={name}
                                            value={value}
                                            placeholder="https://url-site"
                                            onChange={(event) => {
                                                handleChangeInputs(
                                                    "link",
                                                    event.target.value
                                                );
                                                setValue(
                                                    "link",
                                                    event.target.value
                                                );
                                            }}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />
                            </ContentInput>

                            <ContentInput>
                                <Controller
                                    control={control}
                                    name="phone"
                                    render={({
                                        field: { value, name, ref },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            fullWidth
                                            id="phone"
                                            label="Telefone"
                                            variant="outlined"
                                            size="small"
                                            ref={ref}
                                            name={name}
                                            value={value}
                                            inputProps={{ maxLength: 14 }}
                                            onChange={(event) => {
                                                handleChangeInputs(
                                                    "phone",
                                                    phoneMask(
                                                        event.target.value
                                                    )
                                                );
                                                setValue(
                                                    "phone",
                                                    phoneMask(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                            error={Boolean(error)}
                                            helperText={error && error.message}
                                        />
                                    )}
                                />
                            </ContentInput>
                        </ContentRowInput>

                        <ContentInput>
                            <Controller
                                control={control}
                                name="description"
                                render={({
                                    field: { value, name, ref },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        id="description"
                                        label="Descrição"
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={5}
                                        ref={ref}
                                        name={name}
                                        value={value}
                                        onChange={(event) => {
                                            handleChangeInputs(
                                                "description",
                                                event.target.value
                                            );
                                            setValue(
                                                "description",
                                                event.target.value
                                            );
                                        }}
                                        error={Boolean(error)}
                                        helperText={error && error.message}
                                    />
                                )}
                            />
                        </ContentInput>

                        <ContentBannerAndVideo>
                            <ContentInput>
                                <LabelText>Vídeos relacionados: </LabelText>
                                <ButtonAddVideo
                                    type="button"
                                    onClick={() => setShowModalAddVideo(true)}
                                >
                                    <Add />
                                    Adicionar Vídeo
                                </ButtonAddVideo>
                            </ContentInput>
                            <ContentInput>
                                <LabelText>Escolha o banner: </LabelText>

                                <ButtonLabelBanner htmlFor="img-banner">
                                    <Add />
                                    Adicionar Banner
                                </ButtonLabelBanner>

                                <input
                                    type="file"
                                    id="img-banner"
                                    name="img-banner"
                                    ref={refImgBanner}
                                    onChange={handleChangeBanner}
                                />
                            </ContentInput>
                        </ContentBannerAndVideo>

                        <ContentInformationsCustomers>
                            <TitleClientsActives>
                                Clientes que devem receber a publicidade
                            </TitleClientsActives>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                onChange={() =>
                                                    setFilterCustomer({
                                                        ...filterCustomer,
                                                        onlyActiveMonthlyBilling:
                                                            !filterCustomer.onlyActiveMonthlyBilling,
                                                    })
                                                }
                                                checked={
                                                    !!filterCustomer.onlyActiveMonthlyBilling
                                                }
                                            />
                                        }
                                        label="Apenas clientes com mensalidade ativa"
                                    />
                                </FormGroup>
                            </div>

                            <TextTotalClients>
                                {`Total de clientes que receberão essa publicidade: ${customersFilter.length}`}
                            </TextTotalClients>
                        </ContentInformationsCustomers>

                        <ContentButtonSendVideo>
                            <PreloadButton
                                colorText="white"
                                background="var(--success)"
                                loading={isLoading}
                                text="Criar publicidade"
                                type="submit"
                                sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "13px !important",
                                }}
                            />
                        </ContentButtonSendVideo>
                    </div>

                    <div>
                        <SimulationModalPublicity
                            link={linkSitePublicity}
                            description={descriptionPublicity}
                            phone={phonePublicity}
                            title={titlePublicity}
                            urlImgBanner={urlImgBanner}
                            listVideo={listVideo}
                            setListVideo={setListVideo}
                        />
                    </div>
                </Content>

                <ModalAddNewVideo
                    listVideos={listVideo}
                    setOpenToast={setOpenToast}
                    setTextToast={setTextToast}
                    setToastRequisitionResult={setToastRequisitionResult}
                    setListVideos={setListVideo}
                    open={showModalAddVideo}
                    setOpen={setShowModalAddVideo}
                />
                <Toast
                    open={openToast}
                    onClose={() => setOpenToast(false)}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </Container>
        </PageWrapper>
    );
}

export default NewPublicity;
