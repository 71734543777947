import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";

import { defaultToast } from "../../../helpers/toast/defaultToast";
import { api } from "../../../services/api";

export function ModalSendMail({
    open,
    onClose,
    customer,
}: {
    open: boolean;
    onClose: () => void;
    customer: any;
}) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    function onSend() {
        api.post(`/customers/${customer.id}/send/mail`, {
            title,
            content,
        })
            .then(() => {
                defaultToast("success", "email enviado com sucesso!");
                setTitle("");
                setContent("");
                onClose();
            })
            .catch((err) => {
                defaultToast("error", err.response.data.message);
            });
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    width: "600px",
                    position: "absolute" as const,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    gap: "5px",
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Enviar email para cliente
                </Typography>
                <TextField
                    size="small"
                    placeholder="Título do Email"
                    variant="standard"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    size="small"
                    placeholder="Mensagem/Conteúdo do Email"
                    variant="filled"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    multiline
                    rows={4}
                />
                <p>
                    Variáveis disponíveis: <br />
                    <strong>
                        {"{customer_name}, " +
                            "{customer_email}, " +
                            "{customer_project_name}, " +
                            "{customer_phone}, " +
                            "{holder_name} e " +
                            "{holder_email} "}{" "}
                    </strong>
                </p>
                <div>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            onSend();
                        }}
                    >
                        Enviar
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}
