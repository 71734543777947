import styled from "styled-components";

interface Props {
    isDisabledCreateContractButton?: boolean;
}

export const PaymentCard = styled.div<Props>`
    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const PaymentCardBody = styled.div<Props>`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 25px;
    gap: 15px;
    align-items: top;
    /* margin-top: 30px; */
`;

export const FormProposalDetailsPage = styled.div<Props>`
    width: 100%;
    max-width: 1100px;
    padding: 10px 10%;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;
    position: relative;
    padding-bottom: 30px;

    .mainContainer {
        display: flex;
        flex-direction: column;
    }

    .printButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .iconPrint {
        cursor: pointer;
        font-size: 2rem;
    }

    .titlePageContainer {
        text-align: center;
    }

    .boldText {
        font-size: 0.9rem;

        font-weight: 600;
    }

    h2 {
        border-bottom: 1px solid #8888889e;
        margin-bottom: 20px;
    }

    h3 {
        border-bottom: 1px solid #8888889e;
        margin-bottom: 20px;
    }

    .contentFields {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    .contentFieldsToSignatories {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        flex-wrap: wrap;
        margin-bottom: 0px;
        border-bottom: 1px dotted #b3b3b38c;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    .contentRows {
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .buttonCreateLink {
        background-color: ${({ isDisabledCreateContractButton }) =>
            !isDisabledCreateContractButton ? "var(--primary)" : "gray"};
        color: white;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
        padding: 4px 8px;
        min-width: 150px;
        opacity: ${({ isDisabledCreateContractButton }) =>
            !isDisabledCreateContractButton ? 1 : 0.6};
    }

    .buttonCreateLink:hover {
        opacity: ${({ isDisabledCreateContractButton }) =>
            !isDisabledCreateContractButton ? 0.8 : 0.6};
        cursor: ${({ isDisabledCreateContractButton }) =>
            !isDisabledCreateContractButton ? "pointer" : "default"};
    }

    .informationsLinkContractContainer {
        margin-bottom: 10px;
        border: 1px solid #b3b3b38c;
        padding: 10px 5%;
        border-radius: 8px;
    }

    .buttonLinkContainer {
        width: 48%;
        display: flex;
        align-items: center;
    }

    .verySmallText {
        font-size: 0.7rem;
    }

    .smallText {
        font-size: 0.8rem;
    }

    .implementationsList {
        border-radius: 8px;
        padding: 0 4px;
        margin-bottom: 10px;
        background: #b3b3b33c;
    }

    @media (max-width: 800px) {
        .contentFields {
            flex-direction: column;
        }
        .contentRows {
            width: 100%;
        }
        @media (max-width: 400px) {
            .checkboxesList {
                max-height: 100%;
            }
            .inputs {
                width: 260px;
            }
        }
    }
`;
