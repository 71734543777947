import { useEffect } from "react";

import { useAuth } from "../contexts/contexts";
import { PrivateRoutes } from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

export default function RouterGlobal() {
    const { signed, token } = useAuth();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [token, signed]);
    return signed ? <PrivateRoutes /> : <PublicRoutes />;
}
