import styled from "styled-components";

export const Container = styled.form`
    background-color: #fff;
    padding: 32px 35px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 10, 0.05);

    @media (max-width: 768px) {
        padding: 16px 17.5px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin: 1rem 0;

    > div:nth-child(1) {
        max-width: 800px;
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        gap: 4rem;

        > div:nth-child(1) {
            width: 100%;
        }
    }
`;

export const TitlePage = styled.h1`
    font-size: 24px;
    text-align: center;
    margin-bottom: 3rem;
`;

export const ContentInput = styled.div`
    width: 100%;
    margin: 1rem 0;

    input[type="file"] {
        display: none;
    }
`;

export const ContentButtonSendVideo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
`;
