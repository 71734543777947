import {
    AdminPanelSettings,
    AppSettingsAlt,
    ConnectWithoutContact,
    Groups2,
    MoreTime,
    NoteAlt,
    PendingActions,
    Psychology,
    Badge,
    Settings,
    LocalActivity,
    Extension,
    Notifications,
    ViewCarousel,
    Help,
    FactCheck,
    SecurityUpdate,
} from "@mui/icons-material";

export const menuItems = [
    {
        tittle: "Usuários",
        text: "Listar, criar, editar ou excluir usuários",
        permission: "usuario.listar",
        url: "/users",
        icon: <Groups2 sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Produtos",
        text: "Listar, criar, editar ou excluir produtos/planos",
        permission: "produtos.listar",
        url: "/products",
        icon: <AppSettingsAlt sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Implementações",
        text: "Alterar preços ou desativar/ativar implementações",
        permission: "implementacoes.listar",
        url: "/implementations",
        icon: <Extension sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Cupons",
        text: "Listar, criar, editar ou excluir cupons",
        permission: "cupom.listar",
        url: "/cupons",
        icon: <LocalActivity sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Tipos de Titulares",
        text: "Listar, criar, editar ou excluir tipos de titulares",
        permission: "tipos.titulares.listar",
        url: "/holdersTypes",
        icon: <Psychology sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Categorias de Clientes",
        text: "Listar, criar, editar ou excluir categorias de clientes",
        permission: "categorias.clientes.listar",
        url: "/customersCategories",
        icon: <Badge sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Dias de Carência",
        text: "Listar, criar, editar ou excluir dias de carência",
        permission: "dias.carencia.listar",
        url: "/graceDays",
        icon: <MoreTime sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Origem Lead",
        text: "Listar, criar, editar ou excluir Leads",
        permission: "origem.lead.listar",
        url: "/leadsSource",
        icon: <ConnectWithoutContact sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Cargos",
        text: "Listar, criar, editar ou excluir perfis",
        permission: "perfil.listar",
        url: "/profiles",
        icon: <AdminPanelSettings sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Contratos",
        text: "Listar, criar, editar ou excluir contratos",
        permission: "contrato.listar",
        url: "/contracts",
        icon: <NoteAlt sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Configuração Lembrete",
        text: "Listar, criar, editar ou excluir configuração lembrete",
        permission: "configuracao.lembrete.cadastrar",
        url: "/reminderSetting",
        icon: <PendingActions sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Configuração de Notificações",
        text: "Listar, criar, ativar e desativar",
        permission: "notificacao.listar",
        url: "/notifications",
        icon: <Notifications sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Publicidades",
        text: "Listar, criar, ativar e desativar",
        permission: "configuracao.lembrete.cadastrar",
        url: "/publicity",
        icon: <ViewCarousel sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Configurações Gerais",
        text: "Configurações gerais",
        permission: "configuracoes.gerais",
        url: "/configs",
        icon: <Settings sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Helpers do monitor",
        text: "Configurações gerais de helpers do monitor",
        permission: "helpers.listar",
        url: "/helper-monitor",
        icon: <Help sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Itens de checklist",
        text: "Itens de checklist",
        permission: "validators-checklist.listar",
        url: "/checklists",
        icon: <FactCheck sx={{ color: "#172b4d" }} />,
    },
    {
        tittle: "Versões de aplicativos",
        text: "Versões de aplicativos",
        permission: "versions.listar",
        url: "/versoes-aplicativos",
        icon: <SecurityUpdate sx={{ color: "#172b4d" }} />,
    },
];
