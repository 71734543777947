import { FormControlLabel } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";

function Toggle({
    label,
    className,
    ...props
}: SwitchProps & { label: string }) {
    // const [contractIsActiveValue, setContractIsActive] = useState(0);
    // const [isActiveName, setIsActiveName] = useState("Ativo");

    return (
        <FormControlLabel
            className={className}
            control={<Switch {...props} />}
            label={label}
        />
    );
}

export default Toggle;
