import styled from "styled-components";

export const FormHolderRegisterPage = styled.div`
    width: 100%;
    max-width: 1280px;
    border-radius: 8px;
    padding: 10px 10px;
    box-shadow: 0px 0px 7px #8888889e;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h4 {
        text-align: center;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inputsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2%;
    }

    .inputs {
        min-width: 400px;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 450px) {
        .inputs {
            min-width: 230px;
        }
        h3 {
            text-align: center;
        }
        .variablesContent {
            margin-bottom: 10px;
        }
    }
`;
