import styled from "styled-components";

interface StatusCouponsProps {
    color: string;
}

export const StatusCoupons = styled.div<StatusCouponsProps>`
    border: 1px solid ${({ color }) => color};
    border-radius: 360px;
    padding: 0.3rem 0.8rem;
    color: ${({ color }) => color};
`;
