import { yupResolver } from "@hookform/resolvers/yup";
import {
    FormHelperText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useEffect, useState } from "react";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import { defaultToast } from "../../helpers/toast/defaultToast";
import PreloadFb from "../../components/Preload";
import { api } from "../../services/api";
import {
    ContentInput,
    DivInputs,
    DivShowPanel,
    Container,
    LabelInput,
    ContentBtnsSubmit,
} from "./styles";

import { MaskPercentage } from "../../helpers/maskPercentage";

import { ContractAdjustmentsProps, ModelsContractsProps } from "./types";

const schemaModalInputs = yup
    .object({
        modalTitle: yup
            .string()
            .required("Este campo é obrigatório")
            .default(""),
        enabledInPanel: yup.boolean().required("Este campo é obrigatório"),
        typeRenovation: yup.string().required("Este campo é obrigatório"),
        modalContent: yup.string().required("Este campo é obrigatório"),
        modalTitleSecondary: yup.string().required("Este campo é obrigatório"),
        noAcceptContent: yup.string().required("Este campo é obrigatório"),
        redirectLink: yup.string().required("Este campo é obrigatório"),
        acceptedButtonTitle: yup.string().required("Este campo é obrigatório"),
        canceledButtonTitle: yup.string().required("Este campo é obrigatório"),
        acceptedPanelSingIn: yup.boolean().required("Este campo é obrigatório"),
        baseTax: yup.string().required("Este campo é obrigatório"),
        baseTaxExcessDriver: yup.string().required("Este campo é obrigatório"),
        contract: yup.number().required("Este campo é obrigatório"),
    })
    .required();

type SchemaForm = yup.InferType<typeof schemaModalInputs>;

export function ContractAdjustment() {
    const { handleSubmit, control, setValue } = useForm<SchemaForm>({
        resolver: yupResolver(schemaModalInputs),
    });

    const [contractAdjustments, setContractAdjustments] =
        useState<ContractAdjustmentsProps | null>(null);
    const [modelsContracts, setModelsContracts] = useState<
        ModelsContractsProps[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [preloadLoading, setPreloadLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmitForm = async (event: SchemaForm) => {
        try {
            setLoading(true);
            const responseSubmit = await api.post("contractAdjustment", {
                base_adjustment_tax: Number(event.baseTax.replace("%", "")),
                excess_driver_adjustment_tax: Number(
                    event.baseTaxExcessDriver.replace("%", "")
                ),
                contract_id: event.contract,
                show_in_customer_panel: event.enabledInPanel,
                unlock_panel_access: event.acceptedPanelSingIn,
                custom_fields: {
                    title: event.modalTitle,
                    text_condition: event.modalContent,
                    title_modal: event.modalTitleSecondary,
                    text_not_accepted: event.noAcceptContent,
                    link_redirect_not_accepted: event.redirectLink,
                    title_button_confirm: event.acceptedButtonTitle,
                    title_button_cancel: event.canceledButtonTitle,
                },
                to_contract_time: event.typeRenovation,
            });
            if (responseSubmit.status) {
                defaultToast(
                    "success",
                    "Renovação de contrato ajustada com sucesso"
                );
                setTimeout(() => {
                    navigate("/settings/contracts");
                }, 2000);
            }
        } catch (error) {
            defaultToast(
                "error",
                "Erro ao enviar dados de ajuste de renovação"
            );
        } finally {
            setLoading(false);
        }
    };

    const getContracts = async () => {
        try {
            const responseContracts = await api.get("contracts/allContracts");

            if (responseContracts.status) {
                setModelsContracts(responseContracts.data.response);
            }
        } catch (error) {
            defaultToast("error", "Erro ao pegar contratos de renovação");
        }
    };

    const getDatasContract = async () => {
        try {
            setPreloadLoading(true);
            const responseContract = await api.get("contractAdjustment");

            if (responseContract.status) {
                setContractAdjustments(responseContract.data.response);
            }
        } catch (error) {
            defaultToast("error", "Erro ao pegar dados de contratos");
        } finally {
            setPreloadLoading(false);
        }
    };

    useEffect(() => {
        getContracts();
        getDatasContract();
    }, []);

    useEffect(() => {
        if (contractAdjustments) {
            setValue(
                "baseTax",
                MaskPercentage(String(contractAdjustments.base_adjustment_tax))
            );
            setValue(
                "baseTaxExcessDriver",
                MaskPercentage(
                    String(contractAdjustments.excess_driver_adjustment_tax)
                )
            );
            setValue("contract", contractAdjustments.contract_id);
            setValue(
                "acceptedPanelSingIn",
                // eslint-disable-next-line
                contractAdjustments.unlock_panel_access ? true : false
            );
            setValue("modalTitle", contractAdjustments.custom_fields.title);
            setValue(
                "modalTitleSecondary",
                contractAdjustments.custom_fields.title_modal
            );
            setValue(
                "modalContent",
                contractAdjustments.custom_fields.text_condition
            );
            setValue(
                "noAcceptContent",
                contractAdjustments.custom_fields.text_not_accepted
            );
            setValue(
                "acceptedButtonTitle",
                contractAdjustments.custom_fields.title_button_confirm
            );
            setValue(
                "canceledButtonTitle",
                contractAdjustments.custom_fields.title_button_cancel
            );
            setValue(
                "redirectLink",
                contractAdjustments.custom_fields.link_redirect_not_accepted
            );
            setValue("typeRenovation", contractAdjustments.to_contract_time);
        } else {
            setValue("acceptedPanelSingIn", false);
            setValue("enabledInPanel", false);
            setValue("typeRenovation", "anual");
        }
    }, [contractAdjustments]);

    console.log(modelsContracts);

    return (
        <PageWrapper>
            {preloadLoading ? (
                <PreloadFb />
            ) : (
                <PageDefault
                    style={{
                        height: "100%",
                    }}
                >
                    <Container onSubmit={handleSubmit(handleSubmitForm)}>
                        <h2>
                            Configurações de renovação de contrato de contratos
                        </h2>

                        <DivInputs>
                            <ContentInput
                                style={{
                                    width: "100%",
                                }}
                            >
                                <LabelInput>
                                    TEXTO EXÍBIVEL NO PAINEL DO GESTOR
                                </LabelInput>
                                <Controller
                                    control={control}
                                    name="modalTitle"
                                    render={({
                                        field: { onChange, value = "" },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{
                                                width: "100%",
                                            }}
                                            id="title-painel"
                                            className="item-input"
                                            variant="outlined"
                                            label="Título principal do modal"
                                            size="small"
                                            onChange={onChange}
                                            value={value}
                                            error={Boolean(error)}
                                            helperText={error && error?.message}
                                        />
                                    )}
                                />
                            </ContentInput>

                            <DivShowPanel>
                                <Controller
                                    control={control}
                                    name="enabledInPanel"
                                    render={({
                                        field: { value },
                                        fieldState: { error },
                                    }) => (
                                        <ContentInput>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(error)}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            value={value}
                                                            onChange={(event) =>
                                                                setValue(
                                                                    "enabledInPanel",
                                                                    event.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label="Exibir nos painéis"
                                                />
                                                {error && (
                                                    <FormHelperText>
                                                        {error.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </ContentInput>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="typeRenovation"
                                    render={({
                                        field: { onChange, value = "anual" },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl
                                            fullWidth
                                            error={Boolean(error)}
                                        >
                                            <FormLabel>Exibir</FormLabel>
                                            <RadioGroup
                                                aria-label="gender"
                                                name="gender1"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <FormControlLabel
                                                    value="anual"
                                                    control={<Radio />}
                                                    label="Renovação Anual"
                                                />
                                                <FormControlLabel
                                                    value="all"
                                                    control={<Radio />}
                                                    label="Todos"
                                                />
                                            </RadioGroup>
                                            {error && (
                                                <FormHelperText>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            </DivShowPanel>
                        </DivInputs>
                        <Controller
                            control={control}
                            name="modalContent"
                            render={({
                                field: { onChange, value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    variant="outlined"
                                    label="Texto de novas condições [texto ao gestor]"
                                    size="small"
                                    onChange={onChange}
                                    value={value}
                                    error={Boolean(error)}
                                    helperText={error && error?.message}
                                    style={{ width: "100%" }}
                                    multiline
                                    rows={6}
                                />
                            )}
                        />
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                margin: "1rem 0",
                            }}
                        >
                            <Controller
                                control={control}
                                name="modalTitleSecondary"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Título secundário do modal"
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            control={control}
                            name="noAcceptContent"
                            render={({
                                field: { onChange, value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    variant="outlined"
                                    label="Texto de não aceitação"
                                    size="small"
                                    onChange={onChange}
                                    value={value}
                                    error={Boolean(error)}
                                    helperText={error && error?.message}
                                    multiline
                                    rows={4}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                        <DivInputs>
                            <Controller
                                control={control}
                                name="redirectLink"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Link de direcionamento de não aceito"
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="acceptedButtonTitle"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        className="field-buttons"
                                        variant="outlined"
                                        label="Texto do botão de não aceito"
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="canceledButtonTitle"
                                render={({
                                    field: { onChange, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        className="field-buttons"
                                        variant="outlined"
                                        label="Texto de botão de cancelamento"
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                    />
                                )}
                            />
                        </DivInputs>
                        <Controller
                            control={control}
                            name="acceptedPanelSingIn"
                            render={({
                                field: { value = true },
                                fieldState: { error },
                            }) => (
                                <FormControl
                                    fullWidth
                                    className="item-input"
                                    error={Boolean(error)}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={(event) => {
                                                    setValue(
                                                        "acceptedPanelSingIn",
                                                        event.target.checked
                                                    );
                                                }}
                                                checked={value}
                                            />
                                        }
                                        label="Em caso de não aceitar, permitir entrada no painel"
                                    />
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <DivInputs>
                            <Controller
                                control={control}
                                name="baseTax"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Taxa básica de reajuste"
                                        className="item-input"
                                        size="small"
                                        onChange={(event) => {
                                            setValue(
                                                "baseTax",
                                                MaskPercentage(
                                                    event.target.value
                                                )
                                            );
                                        }}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="baseTaxExcessDriver"
                                render={({
                                    field: { value = "" },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Taxa de excedentes"
                                        className="item-input"
                                        size="small"
                                        onChange={(event) => {
                                            setValue(
                                                "baseTaxExcessDriver",
                                                MaskPercentage(
                                                    event.target.value
                                                )
                                            );
                                        }}
                                        value={value}
                                        error={Boolean(error)}
                                        helperText={error && error?.message}
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="contract"
                                render={({
                                    field: { name, onChange, ref, value = "" },
                                    fieldState: { error },
                                }) => (
                                    <FormControl
                                        error={Boolean(error)}
                                        fullWidth
                                    >
                                        <InputLabel
                                            size="small"
                                            id="contract-selected"
                                        >
                                            Modelo de contrato
                                        </InputLabel>
                                        <Select
                                            name={name}
                                            onChange={onChange}
                                            ref={ref}
                                            value={value}
                                            size="small"
                                            labelId="contract-selected"
                                            id="contract"
                                            label="Modelo de contato para renovação"
                                            fullWidth
                                        >
                                            {modelsContracts &&
                                                modelsContracts
                                                    .filter(
                                                        (data) =>
                                                            data.is_active === 1
                                                    )
                                                    .map((value) => {
                                                        return (
                                                            <MenuItem
                                                                key={value.id}
                                                                value={value.id}
                                                            >
                                                                {value.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                        </Select>
                                        {error && (
                                            <FormHelperText>
                                                {error.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </DivInputs>

                        <ContentBtnsSubmit>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={loading}
                            >
                                Editar Notificação
                            </LoadingButton>
                        </ContentBtnsSubmit>
                    </Container>
                </PageDefault>
            )}
        </PageWrapper>
    );
}
