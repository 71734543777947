import { AlertColor, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import ButtonGeneric from "../../components/ButtonGeneric";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { InstallImplementationModalStyled } from "./styles";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    myParam: string;
    handleCloseInstallImplementationModal: () => void;
    openInstallImplementationModal: boolean;
    currentImplementationId: number;
}

function InstallImplementationModal({
    handleCloseInstallImplementationModal,
    openInstallImplementationModal,
    currentImplementationId,
    myParam,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function installImplementation() {
        setIsLoadingButton(true);
        api.post(`customers/install/${myParam}/${currentImplementationId}`, {})
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openInstallImplementationModal}
            onClose={handleCloseInstallImplementationModal}
        >
            <InstallImplementationModalStyled>
                <Box>
                    <p className="topModalText">
                        Deseja instalar a implementação manualmente?
                    </p>
                    <form name="editFormModal">
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => {
                                    handleCloseInstallImplementationModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoadingButton}
                                text="Confirmar"
                                type="submit"
                                onClick={() => {
                                    installImplementation();
                                }}
                                sx={{ minWidth: "140px" }}
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </InstallImplementationModalStyled>
        </Modal>
    );
}
export default InstallImplementationModal;
