import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { useResizeLogo } from "../../helpers/useResizeImage";
import { Container, ContentBtnDownload, Image } from "./styles";

interface ImageDownloadProps {
    nameImg: string;
    sourceImg: string;
    width: number;
    height: number;
    alt: string;
}

export default function ImageDownload({
    nameImg,
    sourceImg,
    height,
    width,
    alt,
}: ImageDownloadProps) {
    const handleDownloadImg = async () => {
        try {
            const downloadLink = document.createElement("a");
            downloadLink.href = sourceImg;
            downloadLink.download = nameImg;

            document.body.appendChild(downloadLink);
            downloadLink.click();

            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container width={width} height={height}>
            <div>
                <ContentBtnDownload>
                    <IconButton
                        onClick={handleDownloadImg}
                        title="Download logo"
                    >
                        <Download
                            sx={{
                                color: "#ffffff",
                            }}
                        />
                    </IconButton>
                </ContentBtnDownload>
            </div>
            <Image src={sourceImg} alt={alt} />
        </Container>
    );
}
