import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 0px;
`;

export const ContentSquares = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0px;
`;

export const Title = styled.h3`
    margin: 7px 0px;
    font-size: 22px;
`;

type SquareColorProps = {
    color?: string;
};

export const SquareColor = styled.span<SquareColorProps>`
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color ?? "#5de937"};
    margin: 2px;
`;
export const InfoServers = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: wrap;
`;

type InfoProps = {
    bgColor: string;
    color: string;
};
export const Info = styled.span<InfoProps>`
    width: 100%;
    background-color: ${(props) => props.bgColor};
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex: 1;

    span {
        display: flex;
        align-items: center;
    }

    h2 {
        color: ${(props) => props.color};
        font-size: 14px;
    }

    h1 {
        color: ${(props) => props.color};
        margin: 0 4px;
        font-size: 14px;
    }
`;

export const DivDropDown = styled.div`
    padding: 10px;
    background-color: var(--primary);
    color: white;
`;

export const TableContent = styled.div`
    height: 550px;
`;

export const InfraCustomers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
    margin: 10px 0px;

    .filter {
        display: flex;
        flex-direction: column;
        width: 35%;
    }

    @media screen and (max-width: 800px) {
        .filter {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
`;
export const CustomerAdmin = styled.div`
    margin: 10px 0px;
`;
