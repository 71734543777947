/*eslint-disable*/
import { Delete, Visibility, Add, Extension } from "@mui/icons-material";
import { AlertColor, Chip } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ModalDeleteProposalImpelementation from "../../components/DeleteProposalImplementationModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { TransformDate } from "../../helpers/transformDate";
import ButtonGeneric from "../../components/ButtonGeneric";
import { PageDefault } from "../../components/PageDefault";
import { formatCurrencyToShow } from "../../helpers/currency";

export interface ProposalsProps {
    color: string;
    description: string;
    id: number;
    name: string;
}

let color: string;

function ImplementationProposalPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("propostas.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataProposalsList, setDataProposalsList] = useState<
        ProposalsProps[]
    >([]);

    const [openDeleteProposalModal, setOpenDeleteProposalModal] =
        useState(false);
    const handleCloseDeleteProposalModal = () => {
        setOpenDeleteProposalModal(false);
    };
    const [selectedRowProposal, setSelectedRowProposal] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [openProposalInvoiceModal, setOpenProposalInvoiceModal] =
        useState(false);
    const handleCloseProposalInvoiceModal = () => {
        setOpenProposalInvoiceModal(false);
    };

    const [openSendSavedProposalModal, setOpenSendSavedProposalModal] =
        useState(false);

    const handleCloseSendSavedProposalModal = () => {
        setOpenSendSavedProposalModal(false);
    };

    /* eslint-disable */
    const switchStatus = (id: number) => {
        switch (id) {
            case 1:
                color = "primary";
                return "salva";
            case 2:
                color = "warning";
                return "enviada";
            case 3:
                color = "success";
                return "paga";
            case 4:
                color = "error";
                return "expirada";
            default:
                return "";
        }
    };

    useEffect(() => {
        api.get("/implementationProposal")
            .then((response) => {
                setLoadingTable(false);
                setDataProposalsList(response.data.response);
                console.log(response.data.response);
                
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        {
            field: "customer",
            headerName: "Cliente",
            flex: 12,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.customer ? params.row.customer?.name : "-"}`,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    {`${params.row.customer ? params.row.customer?.name : "-"}`}
                </strong>
            )
        },
        {
            field: "amount",
            headerName: "Valor",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${Number(params.row.payment?.total_amount).toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                )}`,
            renderCell: (params: GridRenderCellParams) => (
                <p style={{ color: "green" }}>
                    {`R$ ${formatCurrencyToShow(
                        params.row?.payment?.total_amount
                    )}`}
                    </p>
            )
        },
        {
            field: "seller",
            headerName: "Vendedor",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user?.name}`,
            renderCell: (params: GridRenderCellParams) => (
                <strong>
                    {`${params.row.user ? params.row.user?.name : "-"}`}
                </strong>
            )
        },
        {
            field: "created_at",
            headerName: "Data de Vencimento",
            flex: 10,
            valueGetter: (params: GridValueGetterParams) => {
                return TransformDate(
                    params.row.payment?.form_payment?.due_date
                );
            },
        },
        {
            field: "due_date",
            flex: 10,
            headerName: "Data de Vencimento",
            valueGetter: (params: GridValueGetterParams) => {
                return TransformDate(
                    params.row.payment?.form_payment?.due_date
                );
            },
        },
        {
            field: "status_id",
            headerName: "Status",
            align: "center",
            flex: 10,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <span style={{
                        width: "65%",
                    }}>
                        <Chip
                            label={`${switchStatus(params.row.status_id)}`}
                            variant="outlined"
                            size="small"
                            color={color as any}
                            sx={{
                                width: "100%",
                            }}
                        />
                    </span>
                );
            },
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 10,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("propostas.detalhar")}>
                        <Visibility
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/comercial/implementationProposal/proposalImplementatinDetails?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    {/* <If condition={hasPermissions("propostas.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteProposalModal(true);
                            }}
                        />
                    </If> */}
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <PageDefault
                    headerPage
                    header={{
                        title: "Propostas de Implementação",
                        subtitle: "Lista de propostas de implementações",
                        icon: <Extension sx={{ color: "#fff" }} />,
                    }}
                >
                    <div className="containerListTablePagesGlobal">
                        <div
                            className="containerButtonNewRegisterListTablePagesGlobal"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <If
                                condition={hasPermissions(
                                    "propostas.cadastrar"
                                )}
                            >
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate(
                                            "/comercial/implementationProposal/newImplementationProposal"
                                        );
                                    }}
                                    buttonColor="var(--secondary)"
                                    text="Criar proposta de implementações"
                                    icon={<Add />}
                                />
                            </If>
                        </div>
                        <TableDataGrid
                            style={{ minWidth: "460px !important" }}
                            rows={dataProposalsList}
                            columns={columns}
                            onRowClick={(rowParams: GridRowParams) => {
                                setSelectedRowProposal(rowParams.row);
                            }}
                            loading={loadingTable}
                        />
                        {/* <ModalDeleteProposalImpelementation
                            openDeleteProposalModal={openDeleteProposalModal}
                            handleCloseDeleteProposalModal={
                                handleCloseDeleteProposalModal
                            }
                            selectedRowProposal={selectedRowProposal}
                            dataProposalsList={dataProposalsList}
                            setDataProposalsList={setDataProposalsList}
                        /> */}
                    </div>
                </PageDefault>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default ImplementationProposalPage;
