import { VerifiedUser } from "@material-ui/icons";
import {
    ArrowBack,
    Home,
    ManageAccounts,
    MonetizationOn,
    QueryStats,
    Business,
    ErrorOutline,
    RequestQuote,
    AccountCircle,
    Settings,
} from "@mui/icons-material/";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { emphasize, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = "#fff";
    return {
        boxShadow: theme.shadows[2],
        backgroundColor: emphasize(backgroundColor, 0.06),
        height: theme.spacing(4),
        color: theme.palette.text.primary,

        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default function BreadcrumbsHeader() {
    const [currentUrl, setCurrentUrl] = useState("");
    const [firstLabel, setFirstLabel] = useState("");
    const [secondLabel, setSecondLabel] = useState("");
    const [thirdLabel, setThirdLabel] = useState("");
    const [iconsLabel, setIconsLabel] = useState(<Home fontSize="small" />);
    const [firstLabelTranslated, setFirstLabelTranslated] = useState("");
    const [secondLabelTranslated, setSecondLabelTranslated] = useState("");
    const [thirdLabelTranslated, setThirdLabelTranslated] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const pathContent: string[] = currentUrl.split("/");
        setCurrentUrl(window.location.pathname);
        setFirstLabel(pathContent[1]);
        setSecondLabel(pathContent[2]);
        setThirdLabel(pathContent[3]);

        // Switchs destinados somente a tradução das breadcrumbs
        switch (firstLabel) {
            case "home":
                setFirstLabelTranslated("Início");
                setIconsLabel(<Home fontSize="small" />);
                break;
            case "comercial":
                setFirstLabelTranslated("Comercial");
                setIconsLabel(<MonetizationOn fontSize="small" />);
                break;
            case "financial":
                setFirstLabelTranslated("Financeiro");
                setIconsLabel(<MonetizationOn fontSize="small" />);
                break;

            case "settings":
                setFirstLabelTranslated("Configurações");
                setIconsLabel(<ManageAccounts fontSize="small" />);
                break;
            case "reports":
                setFirstLabelTranslated("Relatórios");
                setIconsLabel(<QueryStats fontSize="small" />);
                break;
            case "companies":
                setFirstLabelTranslated("Empresas");
                setIconsLabel(<Business fontSize="small" />);
                break;
            case "comissions":
                setFirstLabelTranslated("Comissões");
                setIconsLabel(<RequestQuote fontSize="small" />);
                break;
            case "perfil":
                setFirstLabelTranslated("perfil");
                setIconsLabel(<VerifiedUser fontSize="small" />);
                break;
            case "ana":
                setFirstLabelTranslated("Ana");
                setIconsLabel(<Settings fontSize="small" />);
                break;
            default:
                setFirstLabelTranslated("Página não existe");
                setIconsLabel(<ErrorOutline fontSize="small" />);
                break;
        }
        switch (secondLabel) {
            case "newClient":
                setSecondLabelTranslated("Novo Cliente");
                break;
            case "products":
                setSecondLabelTranslated("Produtos");
                break;
            case "proposal":
                setSecondLabelTranslated("Propostas");
                break;
            case "customers":
                setSecondLabelTranslated("Clientes");
                break;

            case "holders":
                setSecondLabelTranslated("Titulares");
                break;
            case "holdersTypes":
                setSecondLabelTranslated("Tipos de Titulares");
                break;
            case "followUp":
                setSecondLabelTranslated("Acompanhamento");
                break;
            case "implementations":
                setSecondLabelTranslated("Implementações");
                break;
            case "users":
                setSecondLabelTranslated("Usuários");
                break;
            case "profiles":
                setSecondLabelTranslated("Perfis");
                break;
            case "clientes":
                setSecondLabelTranslated("Clientes");
                setIconsLabel(<AccountCircle fontSize="small" />);
                break;
            case "contracts":
                setSecondLabelTranslated("Contratos");
                break;
            case "reminderSetting":
                setSecondLabelTranslated("Configuração Lembrete");
                break;
            case "invoices":
                setSecondLabelTranslated("Notas Fiscais");
                break;
            case "faturas-mensais":
                setSecondLabelTranslated("Relatórios de Faturas");
                break;
            case "listagem-relatorios":
                setSecondLabelTranslated("Listagem de Relatórios");
                break;
            case "production":
                setSecondLabelTranslated("Produção");
                break;
            case "customersCategories":
                setSecondLabelTranslated("Categoria de Clientes");
                break;
            case "graceDays":
                setSecondLabelTranslated("Dias de Carência");
                break;
            case "leadsSource":
                setSecondLabelTranslated("Lista dos Leads");
                break;
            case "companiesList":
                setSecondLabelTranslated("Lista de Empresas");
                break;
            case "comissionSetup":
                setSecondLabelTranslated("Configuração de Comissão");
                break;
            case "coupons":
                setSecondLabelTranslated("Cupons");
                break;
            case "implementationProposal":
                setSecondLabelTranslated("Propostas de Implementações");
                break;
            case "notifications":
                setSecondLabelTranslated("Configurações de notificações");
                break;
            case "publicity":
                setSecondLabelTranslated("Publicidade");
                break;
            case "helper-monitor":
                setSecondLabelTranslated("Helper do Monitor");
                break;
            case "checklists":
                setSecondLabelTranslated("Itens de checklist");
                break;
            case "validations":
                setSecondLabelTranslated("Validações");
                break;
            case "configs":
                setSecondLabelTranslated("Configurações Gerais");
                break;
            case "listagem-clientes":
                setSecondLabelTranslated("Clientes");
                break;
            case "versoes-aplicativos":
                setSecondLabelTranslated("Versões do Aplicativos");
                break;
            default:
                setSecondLabelTranslated("Rota não existente");
                break;
        }
        switch (thirdLabel) {
            case "newPlan":
                setThirdLabelTranslated("Novo Plano");
                break;
            case "editPlan":
                setThirdLabelTranslated("Editar Plano");
                break;
            case "newProposal":
                setThirdLabelTranslated("Nova Proposta");
                break;
            case "central":
                setThirdLabelTranslated("Central");
                break;
            case "proposalEdit":
                setThirdLabelTranslated("Editar Proposta");
                break;
            case "proposalDetails":
                setThirdLabelTranslated("Detalhes da Proposta");
                break;
            case "customerDetail":
                setThirdLabelTranslated("Detalhes do Cliente");
                break;
            case "holderRegister":
                setThirdLabelTranslated("Novo Titular");
                break;
            case "holderEdit":
                setThirdLabelTranslated("Editar Titular");
                break;
            case "holderTypeRegister":
                setThirdLabelTranslated("Novo Tipo de Titular");
                break;
            case "holderTypeEdit":
                setThirdLabelTranslated("Editar Tipo de Titular");
                break;
            case "graceDayRegister":
                setThirdLabelTranslated("Criar novo Período");
                break;
            case "graceDayEdit":
                setThirdLabelTranslated("Editar Período");
                break;
            case "leadSourceRegister":
                setThirdLabelTranslated("Novo Lead");
                break;
            case "leadSourceEdit":
                setThirdLabelTranslated("Editar Lead");
                break;
            case "profileRegister":
                setThirdLabelTranslated("Novo Perfil");
                break;
            case "profileEdit":
                setThirdLabelTranslated("Editar Perfil");
                break;
            case "contractRegister":
                setThirdLabelTranslated("Novo Contrato");
                break;
            case "contractEdit":
                setThirdLabelTranslated("Editar Contrato");
                break;
            case "customerCategoryRegister":
                setThirdLabelTranslated("Nova Categoria de Cliente");
                break;
            case "customerCategoryEdit":
                setThirdLabelTranslated("Editar Categoria de Cliente");
                break;
            case "newCoupons":
                setThirdLabelTranslated("Novo Cupom");
                break;
            case "couponsDetails":
                setThirdLabelTranslated("Detalhes de Cupom");
                break;
            case "newImplementationProposal":
                setThirdLabelTranslated("Nova Proposta de Implementação");
                break;
            case "proposalImplementatinDetails":
                setThirdLabelTranslated(
                    "Detalhes de Proposta de Implementação"
                );
                break;
            case "newNotifications":
                setThirdLabelTranslated("Nova configuração de notificação");
                break;
            case "newPublicity":
                setThirdLabelTranslated("Nova publicidade");
                break;
            case "editPublicity":
                setThirdLabelTranslated("Editar publicidade");
                break;
            case "geracao-relatorios":
                setThirdLabelTranslated("Geração de Relatórios");
                break;
            case "helpersAdd":
                setThirdLabelTranslated("Adicionar Helper");
                break;
            case "helperEdit":
                setThirdLabelTranslated("Editar Helper");
                break;
            case "customers-add":
                setThirdLabelTranslated("Adicionar cliente");
                break;
            case "detalhes-clientes":
                setThirdLabelTranslated("Detalhes de Clientes");
                break;
            case "renovacao":
                setThirdLabelTranslated("Renovação");
                break;
            default:
                setThirdLabelTranslated("Rota não existente");
                break;
        }
    }, [currentUrl, firstLabel, secondLabel, thirdLabel]);

    return (
        <div
            className="no-print"
            style={{
                width: "80%",
                maxWidth: "1280px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
            role="presentation"
        >
            <Breadcrumbs aria-label="breadcrumb">
                {thirdLabel && (
                    <div style={{ width: "100%" }}>
                        <ArrowBack
                            style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                                marginBottom: "-10px",
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                    </div>
                )}
                {firstLabel === "settings" && secondLabel && !thirdLabel ? (
                    <div style={{ width: "100%" }}>
                        <ArrowBack
                            style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                                marginBottom: "-10px",
                            }}
                            onClick={() => {
                                navigate(`/settings`);
                            }}
                        />
                    </div>
                ) : (
                    ""
                )}
                {firstLabel && (
                    <StyledBreadcrumb
                        sx={{ borderRadius: "8px" }}
                        component="p"
                        label={
                            firstLabelTranslated !== ""
                                ? firstLabelTranslated
                                : firstLabel
                        }
                        icon={iconsLabel}
                    />
                )}

                {secondLabel && (
                    <StyledBreadcrumb
                        sx={{ borderRadius: "8px" }}
                        component="button"
                        label={
                            secondLabelTranslated !== ""
                                ? secondLabelTranslated
                                : secondLabel
                        }
                        onClick={() => {
                            navigate(`/${firstLabel}/${secondLabel}`);
                        }}
                    />
                )}
                {thirdLabel && (
                    <StyledBreadcrumb
                        sx={{ borderRadius: "8px", cursor: "default" }}
                        component="p"
                        label={
                            thirdLabelTranslated !== ""
                                ? thirdLabelTranslated
                                : thirdLabel
                        }
                    />
                )}
            </Breadcrumbs>
        </div>
    );
}
