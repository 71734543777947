import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { useState } from "react";

import { defaultToast } from "../../../helpers/toast/defaultToast";
import { useDateInitialDefault } from "../../../helpers/useDateInitialDefault";
import { api } from "../../../services/api";
import { ContentButtonGeneratedInvoice, ContentInputs } from "../styles";

import { handleFormartDate } from "..";

export default function MonthlyDelayedReport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string | null>(`01-01-2022`);
    const [endDate, setEndDate] = useState<string | null>(
        `${useDateInitialDefault().day}-${useDateInitialDefault().month}-${
            useDateInitialDefault().year
        }`
    );

    const handleChangeDate = (value: any, type: "startDate" | "endDate") => {
        const date = new Date(value).toLocaleDateString().split("/");

        const dateFormated = `${date[2]}-${date[1]}-01`;

        if (dateFormated) {
            if (type === "endDate") {
                setEndDate(handleFormartDate(dateFormated) as string);
            } else {
                setStartDate(handleFormartDate(dateFormated) as string);
            }
        }
    };

    const handleGenerateInvoices = async () => {
        try {
            setLoading(true);
            const responseGenerateInvoices = await api.get(
                `/v2/report/monthly-billing-delayed?start_date=${startDate}&end_date${endDate}`
            );

            if (responseGenerateInvoices.status) {
                defaultToast("success", responseGenerateInvoices.data.response);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao gerar relatório");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        Clientes com mensalidades atrasadas; quantidade e
                        valores.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContentInputs>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Inicial"
                                format="MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                    },
                                }}
                                onChange={(value) =>
                                    handleChangeDate(value, "startDate")
                                }
                                defaultValue={dayjs(`2022-01-01`)}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={
                                ptBR.components.MuiLocalizationProvider
                                    .defaultProps.localeText
                            }
                            adapterLocale="pt-br"
                        >
                            <DatePicker
                                views={["month", "year"]}
                                label="Data Final"
                                format="MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                    },
                                }}
                                onChange={(value) =>
                                    handleChangeDate(value, "endDate")
                                }
                                defaultValue={dayjs(
                                    `${new Date().getFullYear()}-${
                                        new Date().getMonth() + 1
                                    }-01`
                                )}
                            />
                        </LocalizationProvider>

                        <ContentButtonGeneratedInvoice>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                onClick={handleGenerateInvoices}
                                fullWidth
                            >
                                Gerar
                            </LoadingButton>
                        </ContentButtonGeneratedInvoice>
                    </ContentInputs>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
