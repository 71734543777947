export interface Filters {
    filterType: string | null;
    filterValue: string | null;
    status: number | null;
    monthlybilling: boolean | null;
    startDate: string;
    endDate: string;
    period: number;
}

export const fildsItems = [
    { value: "name", label: "Cliente" },
    { value: "document", label: "CNPJ ou CPF" },
    { value: "email", label: "Email" },
    {
        value: "holder_name",
        label: "Nome do Titular",
    },
    {
        value: "project_name",
        label: "Nome do Aplicativo",
    },
];

export const periodsItems = [
    {
        value: 30,
        label: "1 Mês",
    },
    {
        value: 90,
        label: "3 Meses",
    },
    {
        value: 180,
        label: "6 Meses",
    },
    {
        value: 365,
        label: "1 Ano",
    },
    {
        value: 1095,
        label: "3 Anos",
    },
    {
        value: 1825,
        label: "5 Anos",
    },
    {
        value: 20000,
        label: "Todo o período",
    },
];

export const statusItems = [
    {
        value: 1,
        label: "Ok",
    },
    // {
    //     value: 7,
    //     label: "Validado",
    // },
    {
        value: 6,
        label: "Aguardando Instalação",
    },
    {
        value: 2,
        label: "Aviso",
    },
    {
        value: 3,
        label: "Bloqueado",
    },
    {
        value: 4,
        label: "Em Fila de Exclusão",
    },
    {
        value: 5,
        label: "Deletado",
    },
    {
        value: 8,
        label: "Desligado",
    },
];
