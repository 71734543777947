import { AlertColor, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormProfileRegisterPage } from "./styles";

interface PermissionsProps {
    description: string;
    id: number;
    name: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface dataProfilePermissionsListProps {
    description: string;
    id: number;
    name: string;
    permissions: PermissionsProps[];
}

// eslint-disable-next-line
let listPermissions: string[] = [];

function ProfileRegisterPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [dataProfilePermissionsList, setDataProfilePermissionsList] =
        useState<dataProfilePermissionsListProps[]>([]);
    const [nameInputValue, setNameInputValue] = useState("");
    const [selectedPermissionsList, setSelectedPermissionsList] = useState<
        string[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("perfil.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [alertInput, setAlertInput] = useState(false);
    const [errorMessageInput, setErrorMessageInput] = useState("");
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        api.get("/permissions/all")
            .then((response) => {
                setDataProfilePermissionsList(response.data.response);
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const handleCheckedAll = (checked: boolean) => {
        if (!checked) {
            setSelectedPermissionsList([]);
        } else {
            setSelectedPermissionsList(listPermissions);
        }
    };

    const handleChecked = (name: string, checked: boolean) => {
        if (checked) {
            setSelectedPermissionsList(
                selectedPermissionsList.filter((item: string) => item !== name)
            );
        } else {
            setSelectedPermissionsList([...selectedPermissionsList, name]);
        }
    };

    const createNewProfile = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // validação do formulário
        if (nameInputValue !== "" && selectedPermissionsList.length > 0) {
            setIsLoadingButton(true);
            api.post("/roles", {
                name: nameInputValue,
                permissions: selectedPermissionsList,
            })
                .then((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                })
                .then(() => {
                    setTimeout(() => {
                        navigate("/settings/profiles");
                        setOpenToast(false);
                        setIsLoadingButton(false);
                    }, 2000);
                })
                .catch((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(response.response.data.response.name);
                    setIsLoadingButton(false);
                });
        } else {
            setAlertInput(true);
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast(
                "O campo nome é obrigatório e ao menos uma permissão deve ser selecionada!"
            );
            setErrorMessageInput(
                "O campo nome é obrigatório e ao menos uma permissão deve ser selecionada!"
            );
            setIsLoadingButton(false);
        }
    };

    dataProfilePermissionsList.map((value: any) => {
        return value.permissions.map((response: any) => {
            return listPermissions.push(response.name);
        });
    });

    return (
        <PageWrapper>
            <FormProfileRegisterPage>
                <div className="topFormPage">
                    <h2>Cadastrar Novo Perfil</h2>
                </div>
                <form onSubmit={createNewProfile}>
                    <div className="inputContainer">
                        <InputGeneric
                            className="inputs"
                            size="small"
                            name="name"
                            label="Digite o nome"
                            error={alertInput}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setNameInputValue(event.target.value);
                            }}
                            type="text"
                            autoFocus
                        />
                        <p>{errorMessageInput}</p>
                    </div>
                    <div className="checkboxListContainer">
                        <h2>Lista de permissões</h2>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) =>
                                            handleCheckedAll(
                                                event.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Listar Todos"
                            />
                        </FormGroup>

                        {!isLoading ? (
                            dataProfilePermissionsList.map(
                                ({ id, name, permissions }) => (
                                    <div
                                        className="permissionsBlock"
                                        key={name}
                                    >
                                        <h3 key={id}>{name}</h3>
                                        <div className="checkboxesList">
                                            {permissions.map(
                                                ({ id, description, name }) => (
                                                    <FormControlLabel
                                                        key={id}
                                                        checked={selectedPermissionsList.includes(
                                                            name
                                                        )}
                                                        control={
                                                            <Checkbox
                                                                key={
                                                                    description
                                                                }
                                                                onClick={() => {
                                                                    handleChecked(
                                                                        name,
                                                                        selectedPermissionsList.includes(
                                                                            name
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={description}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <Loading />
                        )}
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/profiles")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormProfileRegisterPage>
        </PageWrapper>
    );
}
export default ProfileRegisterPage;
