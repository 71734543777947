import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import { LogContent, Logs, TypeColor } from "./styles";

export function LogCustomer() {
    const [logs, setLogs] = useState<[]>([]);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    function handleGetLogs() {
        api.get(`customers/${id}/logs`)
            .then((response) => {
                console.log(response.data.response);
                setLogs(response.data.response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const logTypes = [
        {
            id: 1,
            type: "INFO",
            color: "#fff",
        },
        {
            id: 2,
            type: "ERRO",
            color: "#f52d2d",
        },
        {
            id: 3,
            type: "WARNING",
            color: "#f5d029",
        },
        {
            id: 4,
            type: "SUCCESS",
            color: "#3cf139",
        },
    ];

    useEffect(() => {
        handleGetLogs();
    }, []);
    return (
        <LogContent>
            {logs?.length > 0 ? (
                <Logs>
                    {logs.map((log: any) => {
                        return (
                            <p key={log.id}>
                                <TypeColor
                                    textColor={logTypes[log.type_id - 1].color}
                                >
                                    [{log.type.name}]{" "}
                                </TypeColor>
                                <div style={{ flex: 3 }}>
                                    {new Date(log.created_at)
                                        .toLocaleString()
                                        .replace(",", " ")
                                        .slice(0, 17)}{" "}
                                </div>
                                <div style={{ flex: 19 }}>{log.message}</div>
                            </p>
                        );
                    })}
                </Logs>
            ) : (
                ""
            )}
        </LogContent>
    );
}
