import styled from "styled-components";

interface Props {
    isDisabledCreateContractButton?: boolean;
}

export const FormProposalImplementationsDetails = styled.div<Props>`
    width: 100%;
    max-width: 1100px;
    padding: 10px 10%;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;
    position: relative;
    padding-bottom: 30px;

    .title-content {
        font-size: 25px;
        font-weight: bold;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid #000000;
    }

    .content {
        width: 100%;
        padding: 0.5rem 0;
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;

        > span:nth-child(2) {
            text-align: right;
            width: 400px;
        }

        @media (max-width: 425px) {
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            > span:nth-child(2) {
                text-align: center;
                width: 100%;
            }
        }
    }

    .label-text {
        font-size: 14px;
        font-weight: bold;
    }

    .text {
        font-size: 14px;
        font-style: normal;
    }
`;
