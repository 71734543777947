import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";

export function ModalCustomerCentral({
    currentCustomer,
}: {
    currentCustomer: any;
}) {
    const [openModal, setOpenModel] = useState(false);
    const handleOpenModal = () => setOpenModel(true);
    const handleCloseModal = () => setOpenModel(false);

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute" as const,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h3 style={{ margin: "1px 0px" }}>
                    Incosistências {currentCustomer?.name}
                </h3>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {currentCustomer?.disagreements?.map((disagrement: any) => (
                        <div key={disagrement.id}>
                            <Typography variant="subtitle1" color="error">
                                {` - ${disagrement.description}`}
                            </Typography>
                        </div>
                    ))}
                </Typography>
                <Button color="primary" variant="contained">
                    Executar diagnostico completo cliente
                </Button>
            </Box>
        </Modal>
    );
}
