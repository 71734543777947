import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormProposalDetailsPage } from "./styles";
import { CouponsProps } from "./types";

function CouponsDetailsPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState<string>("");

    const [isDisabledCreateContractButton, setIsDisabledCreateContractButton] =
        useState<boolean>(false);

    const [detailsCoupons, setDetailsCoupons] = useState<CouponsProps>();

    const getDetailsCoupons = async () => {
        setIsLoading(true);
        try {
            const responseDetailsCoupons = await api.get(`coupons/${myParam}`);

            if (responseDetailsCoupons.status) {
                setDetailsCoupons(responseDetailsCoupons.data.response);
            }
        } catch (error) {
            console.error(error);
            setOpenToast(true);
            setTextToast("Erro ao pegar cupons");
            setToastRequisitionResult("error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDetailsCoupons();
    }, []);

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <FormProposalDetailsPage
                    isDisabledCreateContractButton={
                        isDisabledCreateContractButton
                    }
                    id="print"
                >
                    <div className="mainContainer">
                        <h2>Dados Gerais</h2>
                    </div>

                    <div className="container-coupons">
                        <div className="content">
                            <span className="label-text">Código: </span>
                            <span className="text">{detailsCoupons?.key}</span>
                        </div>

                        <div className="content">
                            <span className="label-text">Status: </span>
                            <span className="text">Ativo</span>
                        </div>

                        <div className="content">
                            <span className="label-text">Max. de Usos: </span>
                            <span className="text">
                                {detailsCoupons?.max_uses}
                            </span>
                        </div>

                        <div className="content">
                            <span className="label-text">Vezes usados: </span>
                            <span className="text">{detailsCoupons?.uses}</span>
                        </div>

                        <div className="content">
                            <span className="label-text">Valor: </span>
                            <span className="text">
                                {detailsCoupons?.value.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </span>
                        </div>

                        <div className="content">
                            <span className="label-text">
                                Data de Vencimento:{" "}
                            </span>
                            <span className="text">
                                {new Date(
                                    detailsCoupons?.expires_at
                                ).toLocaleDateString()}
                            </span>
                        </div>

                        <div className="content">
                            <span className="label-text">Tipo de cupom: </span>
                            <span className="text">
                                {detailsCoupons?.type_id === 1
                                    ? "PERCENTUAL"
                                    : "VALOR FIXO"}
                            </span>
                        </div>
                    </div>

                    <Toast
                        open={openToast}
                        onClose={() => setOpenToast(false)}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </FormProposalDetailsPage>
            )}
        </PageWrapper>
    );
}

export default CouponsDetailsPage;
