import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

interface Props {
    title?: string;
    onClick: (event: MouseEvent) => void;
    handleClose: () => void;
    loading: boolean;
    isOpen: boolean;
    buttonColor:
        | "success"
        | "inherit"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "warning";
}

export default function ConfirmModalDefault({
    title = "confirmar?",
    onClick,
    loading = false,
    isOpen = false,
    handleClose,
    buttonColor = "success",
}: Props) {
    const confirm = (e: MouseEvent | any) => {
        handleClose();
        onClick(e);
    };
    return (
        <div>
            <Modal
                keepMounted
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h4"
                        fontWeight="bold"
                        align="center"
                        marginBottom="30px"
                    >
                        {title}
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            color="inherit"
                            variant="contained"
                        >
                            cancelar
                        </Button>
                        <Button
                            onClick={confirm}
                            color={buttonColor}
                            variant="contained"
                        >
                            confirmar
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
