import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { DataPlanProps } from "../../pages/PlansPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeletePlanStyle } from "./styles";

interface Props {
    openDeletePlanModal: boolean;
    handleCloseDeletePlanModal: () => void;
    selectedRowPlan: GridRowParams["row"];
    setDataPlansList: (values: DataPlanProps[]) => void;
    dataPlansList: DataPlanProps[];
}

function ModalDeletePlan({
    openDeletePlanModal,
    handleCloseDeletePlanModal,
    selectedRowPlan,
    setDataPlansList,
    dataPlansList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deletePlan() {
        setIsLoadingButton(true);
        api.delete(`/plans/${selectedRowPlan.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeletePlanModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataPlansList(
                    dataPlansList.filter((item: DataPlanProps) => {
                        return item.id !== selectedRowPlan.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
                setTimeout(() => {
                    setOpenToast(false);
                }, 1000);
            });
    }

    return (
        <Modal open={openDeletePlanModal} onClose={handleCloseDeletePlanModal}>
            <ModalDeletePlanStyle>
                <Box className="modalContainer">
                    <p>{`Você realmente deseja excluir o plano "${selectedRowPlan.description}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeletePlanModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deletePlan()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeletePlanStyle>
        </Modal>
    );
}
export default ModalDeletePlan;
