import { Rule, Search, ContentCopy, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    FormControl,
    MenuItem,
    TextField,
    InputLabel,
    Select,
    Chip,
    Pagination,
    IconButton,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";
import TableDataGrid from "../../components/TableDataGrid";
import ModalValidations from "../../components/ModalValidationsInformations";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/contexts";
import {
    Container,
    ContentPaginate,
    ContentTable,
    HeaderSearch,
} from "./styles";
import { CustomerInformationsProps, ItemsInformationsProps } from "./types";

type ColorsChipsProps =
    | "success"
    | "error"
    | "default"
    | "primary"
    | "secondary"
    | "info"
    | "warning";

const switchStatusValidations = (isValid: number | null) => {
    switch (isValid) {
        case 1:
            return {
                color: "success",
                text: "VALIDADO",
            };
        case 0:
            return {
                color: "error",
                text: "INVALIDADO",
            };
        case null:
            return {
                color: "default",
                text: "PENDENTE",
            };
        default:
            return {
                color: "default",
                text: "-",
            };
    }
};

let last_page: number | null = null;

function ButtonCopyUrl({ idToken }: { idToken: number }) {
    const [loading, setLoading] = useState<boolean>(false);

    const handleGetToken = async () => {
        try {
            setLoading(true);
            const responseGetToken = await api.get(
                `customerValidationForm/getToken/${idToken}`
            );

            if (responseGetToken.status) {
                navigator.clipboard.writeText(
                    `${window.location.origin}/checklist-app?token=${responseGetToken.data.response.token}`
                );
                defaultToast("success", "Url copiada com sucesso!");
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoadingButton
            color="primary"
            onClick={handleGetToken}
            title="Copiar link do formulário"
            loading={loading}
        >
            <ContentCopy />
        </LoadingButton>
    );
}

export default function ValidationsAppPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [statusInformations, setStatusInformations] =
        useState<string>("null");
    const [visibleModalDetailsValidations, setVisibleModalDetailsValidations] =
        useState<boolean>(false);

    const [inputSearch, setInputSearch] = useState<string>("");

    const [customerInformations, setCustomerInformations] = useState<
        CustomerInformationsProps[]
    >([]);

    const [itemsInformations, setItemsInformations] = useState<
        ItemsInformationsProps[] | null
    >(null);

    const { hasPermissions, permissions } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (!hasPermissions("validators.listar") && permissions.length > 0) {
            navigate("/Error");
        }
    }, [permissions]);

    const getInformationsCustomers = async (page = 1) => {
        try {
            setLoading(true);
            const responseInformationsCustomers = await api.get(
                `customerValidationForm/forms?search=${inputSearch}&validated=${statusInformations}&page=${page}`
            );

            if (responseInformationsCustomers.status) {
                setCustomerInformations(
                    responseInformationsCustomers.data.response.data
                );

                last_page =
                    responseInformationsCustomers.data.response.last_page;
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (dataItems: ItemsInformationsProps | null) => {
        setItemsInformations(dataItems as any);
        setVisibleModalDetailsValidations(true);
    };

    const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
        getInformationsCustomers(page);
    };

    const columns: GridColDef[] = [
        {
            field: "customer",
            headerName: "Cliente",
            align: "left",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <span>{String(params.row?.customer?.name)}</span>
            ),
        },
        {
            field: "holder",
            headerName: "Titular",
            align: "left",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <span>{String(params.row?.customer?.holder?.name)}</span>
            ),
        },
        {
            field: "name_app",
            headerName: "Nome do App",
            align: "left",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <span>{String(params.row?.customer?.project_name)}</span>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            align: "left",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {String(params.row?.customer?.customer_status?.name)}
                </span>
            ),
        },
        {
            field: "validations",
            headerName: "Validação",
            align: "left",
            flex: 15,
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    <Chip
                        label={
                            switchStatusValidations(params.row?.validated).text
                        }
                        color={
                            switchStatusValidations(params.row?.validated)
                                .color as ColorsChipsProps
                        }
                    />
                </span>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            flex: 15,
            minWidth: 50,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div>
                        <IconButton
                            color="primary"
                            title="Visualizar"
                            onClick={() => handleOpenModal(params.row.items)}
                        >
                            <Visibility />
                        </IconButton>
                        <ButtonCopyUrl idToken={params.row.id} />
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getInformationsCustomers();
    }, []);

    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Validações",
                    subtitle:
                        "Relatórios sobre validações de clientes sobre os seus aplicativos",
                    icon: <Rule sx={{ color: "#fff" }} />,
                }}
            >
                <Container>
                    <HeaderSearch>
                        <TextField
                            label="Pesquisa - (nome, email, cpf)"
                            size="small"
                            fullWidth
                            value={inputSearch}
                            onChange={(event) =>
                                setInputSearch(event.target.value)
                            }
                        />
                        <FormControl fullWidth size="small">
                            <InputLabel id="status-select-label">
                                Status
                            </InputLabel>
                            <Select
                                labelId="status-select-label"
                                id="status-select"
                                label="Status"
                                onChange={(event) =>
                                    setStatusInformations(
                                        event.target.value as string
                                    )
                                }
                                value={statusInformations}
                            >
                                <MenuItem value="null">
                                    Escolha o status
                                </MenuItem>
                                <MenuItem value="waiting">PENDENTES</MenuItem>
                                <MenuItem value="1">VALIDADO</MenuItem>
                                <MenuItem value="0">INVALIDADO</MenuItem>
                            </Select>
                        </FormControl>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={() => getInformationsCustomers()}
                        >
                            <Search />
                        </LoadingButton>
                    </HeaderSearch>
                    <ContentTable>
                        <TableDataGrid
                            hideExports
                            columns={columns}
                            rows={customerInformations}
                            loading={loading}
                            hideFooter
                            hideFooterPagination
                        />
                    </ContentTable>
                    {last_page && (
                        <ContentPaginate>
                            <Pagination
                                count={last_page}
                                color="primary"
                                onChange={handleChangePage}
                                hidePrevButton
                                hideNextButton
                            />
                        </ContentPaginate>
                    )}

                    <ModalValidations
                        items={itemsInformations}
                        setItems={setItemsInformations}
                        visible={visibleModalDetailsValidations}
                        setVisible={setVisibleModalDetailsValidations}
                    />
                </Container>
            </PageDefault>
        </PageWrapper>
    );
}
