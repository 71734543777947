import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { DataUserProps } from "../../pages/UsersPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import InputGeneric from "../InputGeneric";
import MultiSelect from "../MultiSelect";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalNewUserStyle } from "./styles";

interface Props {
    openNewUserModal: boolean;
    handleCloseNewUserModal: () => void;
    setDataUsersList: (value: DataUserProps[]) => void;
}

type NewUserFormTypeValues = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    role_id: { id: number }[];
};

const NewUserFormSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    password: yup
        .string()
        .required("Senha é obrigatória")
        .min(6, "Senha mínima de 6 caracteres"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null, "Senhas diferentes"])
        .required(),
    role_id: yup
        .array()
        .of(
            yup.object().shape({
                id: yup.number(),
            })
        )
        .required()
        .min(1),
});

function ModalNewUser({
    openNewUserModal,
    handleCloseNewUserModal,
    setDataUsersList,
}: Props) {
    const [dataRolesList, setDataRolesList] = useState([]);
    const [isLoadingMultiSelect, setIsLoadingMultiSelect] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<NewUserFormTypeValues>({
        resolver: yupResolver(NewUserFormSchema),
    });
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        if (
            openNewUserModal === true &&
            JSON.stringify(dataRolesList) === "[]"
        ) {
            setIsLoadingMultiSelect(true);
            api.get("/roles/all")
                .then((response) => {
                    if (response.data.status) {
                        setDataRolesList(response.data.response);
                    }
                })
                .then(() => {
                    setIsLoadingMultiSelect(false);
                })
                .catch((error) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(error.response.data.message);
                });
        }
    }, [openNewUserModal, dataRolesList]);

    const handleValidateFormAndCreateNewUser: SubmitHandler<
        NewUserFormTypeValues
    > = async (values) => {
        setIsLoadingButton(true);
        api.post("/register/", {
            name: values.name,
            email: values.email,
            password: values.password,
            password_confirmation: values.password,
            nameToken: values.name,
            role_id: values.role_id?.map(({ id }) => id),
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseNewUserModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                api.get("/users")
                    .then((response) => {
                        setDataUsersList(response.data.response.data);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    };

    return (
        <Modal open={openNewUserModal} onClose={handleCloseNewUserModal}>
            <ModalNewUserStyle>
                <p>Cadastrar novo usuário</p>
                <form
                    onSubmit={handleSubmit(handleValidateFormAndCreateNewUser)}
                >
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="inputs"
                                    label="Nome do Usuário"
                                    error={!!errors.name}
                                    ref={ref}
                                    autoFocus
                                />
                            )}
                        />
                        <InputGeneric
                            className="inputs"
                            size="small"
                            label="Digite o email"
                            error={!!errors.email}
                            {...register("email")}
                        />
                    </div>
                    <div className="inputsContainer">
                        <InputGeneric
                            className="inputs"
                            size="small"
                            label="Digite a senha"
                            type="password"
                            error={!!errors.password}
                            {...register("password")}
                        />
                        <InputGeneric
                            className="inputs"
                            size="small"
                            label="Confirme a senha"
                            type="password"
                            error={!!errors.confirmPassword}
                            {...register("confirmPassword")}
                        />
                    </div>
                    <div className="inputsContainer">
                        <Controller
                            control={control}
                            name="role_id"
                            rules={{ required: "Campo requerido" }}
                            render={({ field: { onChange } }) => (
                                <MultiSelect
                                    loading={isLoadingMultiSelect}
                                    loadingText="Carregando cargos..."
                                    onChange={(event, item) => {
                                        onChange(item);
                                    }}
                                    multiple
                                    limitTags={1}
                                    filterSelectedOptions
                                    options={dataRolesList}
                                    getOptionLabel={(option) =>
                                        typeof option === "object"
                                            ? option.name
                                            : ""
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            className="multiSelectInput"
                                            {...params}
                                            variant="outlined"
                                            label="Selecione os cargos"
                                            size="small"
                                            error={!!errors.role_id}
                                        />
                                    )}
                                />
                            )}
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            className="button"
                            onclick={handleCloseNewUserModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalNewUserStyle>
        </Modal>
    );
}
export default ModalNewUser;
