import {
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";

import { formatCurrencyToShow } from "../../../helpers/currency";
import { integerNumberMask } from "../../../helpers/masks/number";

function firstUppercase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const columnsReportDataGrid: GridColDef[] = [
    {
        field: "date_end",
        headerName: "Mês",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${
                firstUppercase(
                    new Date(params.row.date_end).toLocaleString("pt-BR", {
                        month: "long",
                    })
                ) ?? "no"
            }`,
        renderCell: (params: GridRenderCellParams) => (
            <strong>
                {params.row.date_start
                    ? `${new Date(
                          params.row.date_end
                      ).getFullYear()} - ${firstUppercase(
                          new Date(params.row.date_end).toLocaleString(
                              "pt-BR",
                              {
                                  month: "long",
                              }
                          )
                      )}`
                    : "sem dado"}
            </strong>
        ),
    },
    {
        field: "quantity_drivers",
        headerName: "Total de Motoristas",
        flex: 1,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.quantity_drivers}`,
        renderCell: (params: GridRenderCellParams) => (
            <span>{integerNumberMask(params.row.quantity_drivers)}</span>
        ),
    },
    {
        field: "quantity_active_drivers",
        headerName: "Motoristas Ativos",
        flex: 1,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.quantity_active_drivers}`,
        renderCell: (params: GridRenderCellParams) => (
            <strong>
                {integerNumberMask(params.row.quantity_active_drivers)}
            </strong>
        ),
    },
    {
        field: "total_cities",
        headerName: "Cidades",
        flex: 0.8,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.total_cities ?? "-"}`,
    },
    {
        field: "total_finished_trips",
        headerName: "Corridas Finalizadas",
        flex: 1.3,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.total_finished_trips ?? "-"}`,
        renderCell: (params: GridRenderCellParams) => (
            <strong>
                {params.row.total_finished_trips
                    ? integerNumberMask(params.row.total_finished_trips)
                    : "-"}
            </strong>
        ),
    },
    {
        field: "active_passengers",
        headerName: "Passageiros Ativos",
        flex: 1.3,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.active_passengers ?? "-"}`,
        renderCell: (params: GridRenderCellParams) => (
            <span>
                {params.row.active_passengers
                    ? integerNumberMask(params.row.active_passengers)
                    : "-"}
            </span>
        ),
    },
    {
        field: "quantity_driver_excess",
        headerName: "Motoristas Excedentes",
        flex: 1.5,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.quantity_driver_excess}`,
        renderCell: (params: GridRenderCellParams) => (
            <strong>
                {integerNumberMask(params.row.quantity_driver_excess)}
            </strong>
        ),
    },
    {
        field: "value_excess",
        headerName: "Valor do Excedentes",
        flex: 1.5,
        align: "right",
        valueGetter: (params: GridValueGetterParams) =>
            `R$ ${formatCurrencyToShow(params.row.value_excess ?? "-")}`,
        renderCell: (params: GridRenderCellParams) => (
            <span
                style={{
                    color:
                        params.row.value_excess > 0
                            ? "var(--success)"
                            : "#000000",
                }}
            >
                {`R$ ${formatCurrencyToShow(params.row.value_excess ?? "-")}`}
            </span>
        ),
    },
    {
        field: "total_billing",
        headerName: "Ganho do App",
        align: "right",
        flex: 1.3,
        valueGetter: (params: GridValueGetterParams) =>
            `R$ ${formatCurrencyToShow(params.row.total_billing_app ?? "-")}`,
        renderCell: (params: GridRenderCellParams) => (
            <span
                style={{
                    color:
                        params.row.value_excess > 0
                            ? "var(--success)"
                            : "#000000",
                }}
            >
                {`R$ ${formatCurrencyToShow(
                    params.row.total_billing_app ?? "-"
                )}`}
            </span>
        ),
    },
];
