import { Autocomplete, AutocompleteProps } from "@mui/material";
import Stack from "@mui/material/Stack";

export type MultiSelectProps = AutocompleteProps<
    { name: string; id: number },
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>;

function MultiSelect(props: MultiSelectProps) {
    return (
        <Stack spacing={3}>
            <Autocomplete {...props} />
        </Stack>
    );
}

export default MultiSelect;
