import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5px;
    border-radius: 8px;
    padding: 1rem;
`;

export const TitleModal = styled.h2`
    text-align: center;
    margin-bottom: 2rem;
`;

export const ContentItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
`;

export const Items = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding-bottom: 1rem;

    > span:nth-child(1) {
        width: 400px;
    }
`;

export const ContentButtonClose = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0;
`;
