import { PdfViewerStyle } from "./styles";

function PdfViewer({ pdfLink }: { pdfLink: string | undefined }) {
    return (
        <PdfViewerStyle>
            <object
                className="pdfContainer"
                data={pdfLink}
                type="application/pdf"
            >
                <p>
                    Seu navegador não tem um plugin para PDF, tente abrir o pdf
                    do Contrato com o link a seguir:
                </p>
                <a
                    className="linkPdf"
                    href={pdfLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    Clique aqui para abrir o PDF
                </a>
            </object>
        </PdfViewerStyle>
    );
}

export default PdfViewer;
