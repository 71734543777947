import styled from "styled-components";

export const PaymentExpiredPageStyles = styled.div`
    width: 100%;
    height: 100vh;
    min-width: 280px;
    color: #49494c;
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;

    .paymentImageStatus {
        width: 50px;
    }

    .messageContainer {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 10%;
    }

    @media (max-width: 1100px) {
        section {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        .leftSideContainer {
            margin-right: 0px;
        }

        .rightSideContainer {
            width: 760px;
        }

        @media (max-width: 840px) {
            .leftSideContainer {
                margin-right: 0px;
                width: 90%;
            }

            .threeInputsContainer {
                flex-direction: column;
            }

            .threeInputsContainer .inputs {
                width: 100%;
            }

            .threeInputsContainer .averageInput {
                width: 100%;
            }

            .threeInputsContainer .bigInput {
                width: 100%;
            }

            .threeInputsContainer .smallInput {
                width: 100%;
            }

            .twoInputsContainer {
                flex-direction: column;
            }

            .twoInputsContainer .inputs {
                width: 100%;
            }

            .twoInputsContainer .input {
                width: 100%;
            }

            .expirationDateInput {
                width: 100%;
            }

            .flagInput {
                width: 100%;
            }

            .bigSelect {
                width: 100%;
            }

            .rightSideContainer {
                width: 82%;
            }

            .inputs {
                width: 260px;
            }

            .buttonsContainer {
                font-size: 0.7rem;
            }
        }
        @media (max-width: 600px) {
            .pixContainer {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        @media (max-width: 470px) {
            .backButton {
                width: 100px;
            }
        }
    }
`;
export const PaymentCompletedPageStyles = styled.div`
    width: 100%;
    height: 100vh;
    min-width: 280px;
    color: #49494c;
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;

    .paymentImageStatus {
        width: 50px;
    }

    .messageContainer {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 10%;
    }

    @media (max-width: 1100px) {
        section {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        .leftSideContainer {
            margin-right: 0px;
        }

        .rightSideContainer {
            width: 760px;
        }

        @media (max-width: 840px) {
            .leftSideContainer {
                margin-right: 0px;
                width: 90%;
            }

            .threeInputsContainer {
                flex-direction: column;
            }

            .threeInputsContainer .inputs {
                width: 100%;
            }

            .threeInputsContainer .averageInput {
                width: 100%;
            }

            .threeInputsContainer .bigInput {
                width: 100%;
            }

            .threeInputsContainer .smallInput {
                width: 100%;
            }

            .twoInputsContainer {
                flex-direction: column;
            }

            .twoInputsContainer .inputs {
                width: 100%;
            }

            .twoInputsContainer .input {
                width: 100%;
            }

            .expirationDateInput {
                width: 100%;
            }

            .flagInput {
                width: 100%;
            }

            .bigSelect {
                width: 100%;
            }

            .rightSideContainer {
                width: 82%;
            }

            .inputs {
                width: 260px;
            }

            .buttonsContainer {
                font-size: 0.7rem;
            }
        }
        @media (max-width: 600px) {
            .pixContainer {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        @media (max-width: 470px) {
            .backButton {
                width: 100px;
            }
        }
    }
`;
