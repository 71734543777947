import { Add, Delete, Edit } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteContract from "../../components/DeleteContractModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface ContractsProps {
    id: number;
    is_active: number;
    title: string;
}

function ContractsPage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataContractsList, setDataContractsList] = useState<
        ContractsProps[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("contrato.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [openDeleteContractModal, setOpenDeleteContractModal] =
        useState(false);
    const handleCloseDeleteContractModal = () => {
        setOpenDeleteContractModal(false);
    };
    const [selectedRowContract, setSelectedRowContract] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("/contracts")
            .then((response) => {
                setLoadingTable(false);
                setDataContractsList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "title", headerName: "Nome", flex: 40 },
        {
            field: "is_active",
            headerName: "Status",
            flex: 20,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.is_active}`,
            renderCell: (params: GridRenderCellParams) => (
                <p>{params.row.is_active === 1 ? "Ativo" : "Inativo"}</p>
            ),
        },
        {
            field: "ações",
            headerName: "Ações",
            flex: 35,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("contrato.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/contracts/contractEdit?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("contrato.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteContractModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If condition={hasPermissions("contrato.cadastrar")}>
                            <ButtonGeneric
                                onclick={() => {
                                    navigate("/settings/contracts/renovacao");
                                }}
                                buttonColor="var(--primary)"
                                text="Sobre Renovação"
                            />
                        </If>
                        <If condition={hasPermissions("contrato.cadastrar")}>
                            <ButtonGeneric
                                onclick={() => {
                                    navigate(
                                        "/settings/contracts/contractRegister"
                                    );
                                }}
                                buttonColor="var(--primary)"
                                text="Novo Contrato"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataContractsList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowContract(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteContract
                        openDeleteContractModal={openDeleteContractModal}
                        handleCloseDeleteContractModal={
                            handleCloseDeleteContractModal
                        }
                        selectedRowContract={selectedRowContract}
                        dataContractsList={dataContractsList}
                        setDataContractsList={setDataContractsList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default ContractsPage;
