import { Box, Modal } from "@mui/material";
import { useState } from "react";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import { ModalDeleteProposalStyle } from "./styles";

interface Props {
    visible: boolean;
    setVisible: (data: boolean) => void;
    title: string;
    setTitle: (data: string) => void;
    content: string;
    setContent: (data: string) => void;
    idsMonthlyBilling: number[];
    vias: string[];
    setAnchorElVias: (data: HTMLElement | null) => void;
}

function ModalResendInvoicesBulk({
    visible,
    setVisible,
    content,
    title,
    idsMonthlyBilling,
    vias,
    setContent,
    setTitle,
    setAnchorElVias,
}: Props) {
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const handleSendInvoice = async () => {
        try {
            setIsLoadingButton(true);

            const responseSendInvoice = await api.post("monthly-billing/send", {
                ids: idsMonthlyBilling,
                title,
                content,
                vias,
            });

            if (responseSendInvoice.status) {
                defaultToast("success", "Faturas enviada com sucesso!");
                setTitle("");
                setContent("");
                setVisible(false);
                setAnchorElVias(null);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao reenviar faturas!");
        } finally {
            setIsLoadingButton(false);
        }
    };

    return (
        <Modal open={visible} onClose={() => setVisible(false)}>
            <ModalDeleteProposalStyle>
                <Box className="modalContainer">
                    <p
                        style={{
                            fontSize: 20,
                        }}
                    >
                        Deseja realmente enviar reenviar todas as faturas
                        atrasadas?
                    </p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => setVisible(false)}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="Enviar"
                            onClick={handleSendInvoice}
                        />
                    </div>
                </Box>
            </ModalDeleteProposalStyle>
        </Modal>
    );
}
export default ModalResendInvoicesBulk;
