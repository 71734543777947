import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { ProposalsProps } from "../../pages/ProposalPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteProposalStyle } from "./styles";

interface Props {
    openDeleteProposalModal: boolean;
    handleCloseDeleteProposalModal: () => void;
    selectedRowProposal: GridRowParams["row"];
    setDataProposalsList: (values: ProposalsProps[]) => void;
    dataProposalsList: ProposalsProps[];
}

function ModalDeleteProposal({
    openDeleteProposalModal,
    handleCloseDeleteProposalModal,
    selectedRowProposal,
    setDataProposalsList,
    dataProposalsList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteProposal() {
        setIsLoadingButton(true);
        api.delete(`proposal/${selectedRowProposal.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteProposalModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataProposalsList(
                    dataProposalsList.filter((item: { id: number }) => {
                        return item.id !== selectedRowProposal.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteProposalModal}
            onClose={handleCloseDeleteProposalModal}
        >
            <ModalDeleteProposalStyle>
                <Box className="modalContainer">
                    <p>{`Deseja excluir a Proposta do Titular "${selectedRowProposal.holder?.name}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteProposalModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                            className="cancelButton"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteProposal()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteProposalStyle>
        </Modal>
    );
}
export default ModalDeleteProposal;
