import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { HoldersTypesProps } from "../../pages/HoldersTypesPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteHolderTypeStyle } from "./styles";

interface Props {
    openDeleteHolderTypeModal: boolean;
    handleCloseDeleteHolderTypeModal: () => void;
    selectedRowHolderType: GridRowParams["row"];
    setDataHoldersTypesList: (values: HoldersTypesProps[]) => void;
    dataHoldersTypesList: HoldersTypesProps[];
}

function ModalDeleteHolderType({
    openDeleteHolderTypeModal,
    handleCloseDeleteHolderTypeModal,
    selectedRowHolderType,
    setDataHoldersTypesList,
    dataHoldersTypesList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteHolderType() {
        setIsLoadingButton(true);
        api.delete(`/holderLevels/${selectedRowHolderType.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteHolderTypeModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataHoldersTypesList(
                    dataHoldersTypesList.filter((item: { id: number }) => {
                        return item.id !== selectedRowHolderType.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteHolderTypeModal}
            onClose={handleCloseDeleteHolderTypeModal}
        >
            <ModalDeleteHolderTypeStyle>
                <Box className="modalContainer">
                    <p>{`Deseja excluir o tipo de Titular "${selectedRowHolderType.name}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteHolderTypeModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteHolderType()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteHolderTypeStyle>
        </Modal>
    );
}
export default ModalDeleteHolderType;
