import { Add, Delete, Edit } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeleteGraceDay from "../../components/DeleteGraceDayModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface GraceDaysProps {
    color: string;
    description: string;
    id: number;
    days: string;
}

function GraceDaysPage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataGraceDaysList, setDataGraceDaysList] = useState<
        GraceDaysProps[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("dias.carencia.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [openDeleteGraceDayModal, setOpenDeleteGraceDayModal] =
        useState(false);
    const handleCloseDeleteGraceDayModal = () => {
        setOpenDeleteGraceDayModal(false);
    };
    const [selectedRowGraceDay, setSelectedRowGraceDay] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("graceDay")
            .then((response) => {
                setLoadingTable(false);
                setDataGraceDaysList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "days", headerName: "Dias de Carência", flex: 20 },
        {
            field: "ações",
            headerName: "Ações",
            flex: 25,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("dias.carencia.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/graceDays/graceDayEdit?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("dias.carencia.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeleteGraceDayModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If
                            condition={hasPermissions(
                                "dias.carencia.cadastrar"
                            )}
                        >
                            <ButtonGeneric
                                onclick={() => {
                                    navigate(
                                        "/settings/graceDays/graceDayRegister"
                                    );
                                }}
                                buttonColor="var(--primary)"
                                text="Novo Período de Carência"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataGraceDaysList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowGraceDay(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeleteGraceDay
                        openDeleteGraceDayModal={openDeleteGraceDayModal}
                        handleCloseDeleteGraceDayModal={
                            handleCloseDeleteGraceDayModal
                        }
                        selectedRowGraceDay={selectedRowGraceDay}
                        dataGraceDaysList={dataGraceDaysList}
                        setDataGraceDaysList={setDataGraceDaysList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default GraceDaysPage;
