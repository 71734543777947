import { PieChart } from "@mui/x-charts";
import styled from "styled-components";

export const HeaderFilter = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 60px;
    margin-top: 20px;
    gap: 10px;

    @media (max-width: 1200px) {
        justify-content: center;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: normal;
        padding: 0px 0px;
    }
`;

export const Info = styled.div`
    padding: 0px 60px;
    font-size: 10pt;

    @media (max-width: 1200px) {
        text-align: center;
    }

    @media (max-width: 768px) {
        padding: 0px 0px;
    }
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px;
    justify-content: center;

    .flexing {
        flex: 0 0 calc(50% - 20px);
        box-sizing: border-box;
        min-width: 500px;

        @media (max-width: 850px) {
            flex: 1;
            box-sizing: border-box;
        }
    }

    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const BillingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 4px solid var(--primary);
`;

export const PieContainer = styled.div`
    /* border-top: 4px solid var(--primary); */
    padding: 5px;

    h3 {
        margin-bottom: 10px;
    }
`;

export const ContentPie = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Pie = styled(PieChart)``;

export const FinancialContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100px;
    gap: 10px;

    h3 {
        margin-bottom: 10px;
    }
`;

export const FinancialCard = styled.div`
    height: 50px;
    padding: 0px 30px;
    background-color: #bbbbbb;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ContentSelectStatus = styled.div`
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        padding: 0 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 1rem 0;
    }
`;

export const ItemInput = styled.div`
    margin: 1rem;
`;

export const ContentResendInvoices = styled.form`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const BillingTable = styled.div`
    padding: 0px 60px;
    margin: 0px 0px;
    height: 650px;

    @media (max-width: 768px) {
        padding: 0 10px;
    }
`;
export const Page = styled.div`
    background-color: #fff;
    padding: 20px;
`;

export const ContentGraph = styled.div`
    width: 100%;
    height: 500px;
    margin-bottom: 60px;
    padding: 0px 20px;
`;
export const ContentButtonSearch = styled.div`
    @media (max-width: 768px) {
        button {
            width: 100%;
        }
    }
`;

export const ContentPagination = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 3rem;
`;

export const ExportDiv = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ContentStatus = styled.div``;
