import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
    margin: 1rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ContentTable = styled.div`
    height: 550px;
`;
