import {
    DataGrid,
    ptBR,
    DataGridProps,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";

import { If } from "../If";

const styleTableButtons = {
    padding: "5px 10px",
    fontWeight: "600",
    color: "white",
    background: "var(--primary)",
    "&:hover": {
        background: "var(--primary)",
    },
};

const styleExportTableButtons = {
    padding: "5px 10px",
    color: "white",
    fontWeight: "600",
    background: "#107c41",
    "&:hover": {
        background: "#096030",
    },
};

export interface TableDataGrid extends DataGridProps {
    hideExports?: boolean;
    toolBar?: boolean;
    hideColumnMenu?: boolean;
    hideFilter?: boolean;
}

function TableDataGrid({
    rows,
    columns,
    onRowClick,
    loading,
    getRowId,
    hideFooterPagination,
    hideExports,
    hideColumnMenu,
    hideFilter,
    toolBar = true,
}: TableDataGrid) {
    return (
        <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            sx={{
                padding: "10px",
                // boxShadow: "0px 0px 7px #8888889e",
                borderRadius: 0,
                background: "#fff",
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            onRowClick={onRowClick}
            hideFooterSelectedRowCount
            hideFooterPagination={hideFooterPagination}
            loading={loading}
            getRowId={getRowId}
            disableColumnMenu
            components={{
                // eslint-disable-next-line react/no-unstable-nested-components
                Toolbar: () => {
                    return toolBar ? (
                        <GridToolbarContainer
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                            className="toolbarContainer"
                        >
                            {!hideColumnMenu && (
                                <GridToolbarColumnsButton
                                    sx={styleTableButtons}
                                />
                            )}
                            {!hideFilter && (
                                <GridToolbarFilterButton
                                    sx={styleTableButtons}
                                />
                            )}
                            {!hideExports && (
                                <GridToolbarExport
                                    sx={styleExportTableButtons}
                                    ic
                                />
                            )}
                        </GridToolbarContainer>
                    ) : (
                        <span />
                    );
                },
            }}
        />
    );
}

export default TableDataGrid;
