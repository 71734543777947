/* eslint-disable */
import Resizer from "react-image-file-resizer";

export function useResizeLogo(
    file: File,
    maxWidthImage: number,
    maxHeightImage: number,
    extensionImage: "JPEG" | "PNG" | "JPG",
    typeImage: "blob" | "base64",
    minWidthImage?: number,
    minHeigthImage?: number
) {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidthImage,
            maxHeightImage,
            extensionImage,
            100,
            0,
            (uri: any) => {
                resolve(uri);
            },
            typeImage,
            minWidthImage ? minWidthImage : maxWidthImage,
            minHeigthImage ? minHeigthImage : maxHeightImage
        );
    });
}
