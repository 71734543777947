import styled from "styled-components";

export const Container = styled.div``;

export const HeaderTable = styled.div`
    margin: 1rem 0;
`;

export const ContentTable = styled.div`
    margin: 1rem 0;
    height: 500px;
`;

export const ContentPagination = styled.div`
    display: flex;
    justify-content: flex-end;
`;
