import styled from "styled-components";

export const FormProposalRegisterPage = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;

    .topFormPage {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h4 {
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inputsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 0.3rem 0;
    }

    .inputsContainerFourItens {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }

    .verySmallInput {
        max-width: 100px;
    }

    .smallInput {
        min-width: 224px;
    }

    .mediumInput {
        min-width: 345px;
    }

    .bigInput {
        min-width: 450px;
    }

    .hugeInput {
        min-width: 709px;
    }

    .fullWidthInput {
        min-width: 942px;
        max-width: 80%;
    }

    .addButton {
        min-width: 224px;
        max-height: 40px;
    }

    .signatoryListMainContainer {
        width: 100%;
    }

    .signatoryListContainer {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        width: 75%;
        align-items: center;
        margin: 10px auto;
    }

    .iconDelete {
        cursor: pointer;
        color: var(--delete);
    }

    .optionContainerTab {
        display: flex;
        align-items: center;
    }

    .iconTab {
        margin-right: -25px;
        margin-bottom: 2px;
    }

    .implementationsIncludesContainer {
        width: 78%;
    }

    .implementationsIncludesContainer ul {
        padding: 0 3%;
    }

    .implementationsIncludesContainer ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 95%;
    }

    .includesImplementationsMaxHeight {
        max-height: 150px;
        overflow: auto;
        border-radius: 4px;
        border: 1px solid var(--cancel);
    }

    .liContainer {
        display: flex;
        justify-content: space-between;
        gap: 3%;
        width: 85%;
        align-items: center;
    }

    .valueProposalDetails {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
    }

    .rowValueProposalDetail {
        display: flex;
        justify-content: space-between;
        padding-right: 14%;
        width: 60%;
    }

    .allButtonsContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .saveButtonContainer {
        width: 90%;
    }

    .buttonsContainer {
        display: flex;
        gap: 3%;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    @media (max-width: 1234px) {
        .fullWidthInput {
            min-width: 709px;
        }

        .inputsContainerFullWidth {
            gap: 10px;
        }

        @media (max-width: 890px) {
            .signatoryListContainer {
                font-size: 0.8rem;
                font-weight: 700;
                width: 78%;
            }

            .inputsContainer {
                gap: 0px;
                flex-direction: column;
            }

            .css-heg063-MuiTabs-flexContainer,
            .MuiTabs-flexContainer {
                display: flex !important;
                flex-direction: column !important;
                align-items: flex-start;
            }
            .verySmallInput {
                min-width: 500px;
            }

            .smallInput {
                min-width: 500px;
            }

            .mediumInput {
                min-width: 500px;
            }

            .bigInput {
                min-width: 500px;
            }

            .hugeInput {
                min-width: 500px;
            }

            .fullWidthInput {
                min-width: 500px;
            }

            @media (max-width: 630px) {
                .verySmallInput {
                    min-width: 350px;
                }

                .smallInput {
                    min-width: 350px;
                }

                .mediumInput {
                    min-width: 350px;
                }

                .bigInput {
                    min-width: 350px;
                }

                .hugeInput {
                    min-width: 350px;
                }

                .fullWidthInput {
                    min-width: 350px;
                }

                h3 {
                    text-align: center;
                }
                @media (max-width: 400px) {
                    .verySmallInput {
                        min-width: 250px;
                    }

                    .smallInput {
                        min-width: 250px;
                    }

                    .mediumInput {
                        min-width: 250px;
                    }

                    .bigInput {
                        min-width: 250px;
                    }

                    .hugeInput {
                        min-width: 250px;
                    }

                    .fullWidthInput {
                        min-width: 250px;
                    }

                    h3 {
                        text-align: center;
                    }
                }
            }
        }
    }
`;

export const ContentInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0.5rem 0;

    @media (max-width: 890px) {
        flex-direction: column;
    }
`;
