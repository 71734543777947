import { Person } from "@mui/icons-material";
import { CSSProperties } from "react";

import { HeaderPage } from "../Header/HeaderPage";
import { If } from "../If";
import { Content, PageStyle } from "./styles";

type HeaderProps = {
    title: string;
    subtitle: string;
    icon: JSX.Element;
};

type Props = {
    children: JSX.Element;
    headerPage?: boolean;
    header?: HeaderProps;
    style?: CSSProperties;
    heightAuto?: boolean;
};

export function PageDefault({
    headerPage,
    children,
    header,
    style,
    heightAuto,
}: Props) {
    return (
        <PageStyle heightAuto={heightAuto} style={style}>
            <If condition={headerPage}>
                <HeaderPage
                    title={header?.title ?? ""}
                    subtitle={header?.subtitle ?? ""}
                    icon={header?.icon ?? <span />}
                />
            </If>
            <Content>{children}</Content>
        </PageStyle>
    );
}
