import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import SimulationModalHelper from "../../components/SimulationModalHelper";
import { useAuth } from "../../contexts/contexts";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { HelpersMonitorsProps } from "../HelpersMonitorPage/types";
import {
    Container,
    ContentInput,
    TitlePage,
    ContentButtonSendVideo,
    Content,
} from "./styles";

const schemaForms = yup.object({
    title: yup.string().required("O campo título é obrigatório"),
    url_link: yup.string().required("O campo url do vídeo é obrigatório"),
    url_application: yup
        .string()
        .required("O campo url da página é obrigatório"),
    url_redirect: yup
        .string()
        .required("O campo url de redirecionamento é obrigatório"),
});

type SchemaFormData = yup.InferType<typeof schemaForms>;

function HelpersMonitorEditPage() {
    const { control, handleSubmit, setValue } = useForm<SchemaFormData>({
        resolver: yupResolver(schemaForms),
    });

    const [informationsHelperMonitor, setInformationsHelperMonitor] =
        useState<HelpersMonitorsProps>();

    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [titlePublicity, setTitlePublicity] = useState<string>("");
    const [urlVideo, setUrlVideo] = useState<string>("");
    const [urlApplication, setUrlApplication] = useState<string>("");
    const [urlRedirect, serUrlRedirect] = useState<string>("");

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const { permissions, hasPermissions } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (permissions.length > 0) {
            if (!hasPermissions("helpers.editar")) {
                navigate("/Error");
            }
        }
    }, [permissions]);

    const getInformationsHelper = async () => {
        try {
            setLoading(true);
            const responseInformations = await api.get(
                `helperMonitor/onlyHelper/${myParam}`
            );

            if (responseInformations.status) {
                setInformationsHelperMonitor(
                    responseInformations.data.response
                );
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.response[0]);
        } finally {
            setLoading(false);
        }
    };

    const handleEditHelper = async (data: SchemaFormData) => {
        try {
            setIsLoading(true);
            const responseEditHelper = await api.put(
                `helperMonitor/updateHelper/${myParam}`,
                {
                    title: data.title,
                    url_video: data.url_link,
                    url_application: data.url_application,
                    url_redirect: data.url_redirect,
                }
            );

            if (responseEditHelper.status) {
                defaultToast("success", "Helper editado com sucesso");

                setTimeout(() => {
                    navigate("/settings/helper-monitor");
                }, 2000);
            }
            // eslint-disable-next-line
        } catch (error: any) {
            defaultToast("error", error.response.data.response[0]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeInputs = debounce(
        (
            type: "title" | "url_link" | "url_application" | "url_redirect",
            value: string
        ) => {
            if (type === "title") {
                setTitlePublicity(value);
            } else if (type === "url_link") {
                setUrlVideo(value);
            } else if (type === "url_application") {
                setUrlApplication(value);
            } else if (type === "url_redirect") {
                serUrlRedirect(value);
            }
        },
        500
    );

    useEffect(() => {
        getInformationsHelper();
    }, []);

    useEffect(() => {
        if (informationsHelperMonitor) {
            setTitlePublicity(informationsHelperMonitor.title);
            setUrlVideo(informationsHelperMonitor.url_video);
            setUrlApplication(informationsHelperMonitor.url_application);
            serUrlRedirect(informationsHelperMonitor.url_redirect);
            setValue("title", informationsHelperMonitor.title);
            setValue("url_link", informationsHelperMonitor.url_video);
            setValue(
                "url_application",
                informationsHelperMonitor.url_application
            );
            setValue("url_redirect", informationsHelperMonitor.url_redirect);
        }
    }, [informationsHelperMonitor]);

    return (
        <div>
            {loading ? (
                <PreloadFb />
            ) : (
                <PageWrapper>
                    <Container onSubmit={handleSubmit(handleEditHelper)}>
                        <TitlePage>Editar Helper</TitlePage>

                        <Content>
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <ContentInput>
                                    <Controller
                                        control={control}
                                        name="title"
                                        render={({
                                            field: { value = "", name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="title"
                                                label="Título"
                                                variant="outlined"
                                                size="small"
                                                ref={ref}
                                                name={name}
                                                value={value}
                                                onChange={(event) => {
                                                    handleChangeInputs(
                                                        "title",
                                                        event.target.value
                                                    );
                                                    setValue(
                                                        "title",
                                                        event.target.value
                                                    );
                                                }}
                                                error={Boolean(error)}
                                                helperText={
                                                    error && error.message
                                                }
                                            />
                                        )}
                                    />
                                </ContentInput>

                                <ContentInput>
                                    <Controller
                                        control={control}
                                        name="url_link"
                                        render={({
                                            field: { value = "", name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="link"
                                                label="Url do vídeo"
                                                variant="outlined"
                                                size="small"
                                                ref={ref}
                                                name={name}
                                                value={value}
                                                placeholder="https://www.youtube.com/"
                                                onChange={(event) => {
                                                    handleChangeInputs(
                                                        "url_link",
                                                        event.target.value
                                                    );

                                                    setValue(
                                                        "url_link",
                                                        event.target.value
                                                    );
                                                }}
                                                error={Boolean(error)}
                                                helperText={
                                                    error && error.message
                                                }
                                            />
                                        )}
                                    />
                                </ContentInput>

                                <ContentInput>
                                    <Controller
                                        control={control}
                                        name="url_application"
                                        render={({
                                            field: { value = "", name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="link"
                                                label="Url da aplicação"
                                                variant="outlined"
                                                size="small"
                                                ref={ref}
                                                name={name}
                                                value={value}
                                                placeholder="https://monitor/monitoramento"
                                                onChange={(event) => {
                                                    handleChangeInputs(
                                                        "url_application",
                                                        event.target.value
                                                    );

                                                    setValue(
                                                        "url_application",
                                                        event.target.value
                                                    );
                                                }}
                                                error={Boolean(error)}
                                                helperText={
                                                    error && error.message
                                                }
                                            />
                                        )}
                                    />
                                </ContentInput>

                                <ContentInput>
                                    <Controller
                                        control={control}
                                        name="url_redirect"
                                        render={({
                                            field: { value, name, ref },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="link"
                                                label="Url de redirecionamento"
                                                variant="outlined"
                                                size="small"
                                                ref={ref}
                                                name={name}
                                                value={value}
                                                placeholder="Link de redirecionamento"
                                                onChange={(event) => {
                                                    handleChangeInputs(
                                                        "url_redirect",
                                                        event.target.value
                                                    );

                                                    setValue(
                                                        "url_redirect",
                                                        event.target.value
                                                    );
                                                }}
                                                error={Boolean(error)}
                                                helperText={
                                                    error && error.message
                                                }
                                            />
                                        )}
                                    />
                                </ContentInput>

                                <ContentButtonSendVideo>
                                    <PreloadButton
                                        colorText="white"
                                        background="var(--success)"
                                        loading={isLoading}
                                        text="Editar publicidade"
                                        type="submit"
                                        sx={{
                                            whiteSpace: "nowrap",
                                            fontSize: "13px !important",
                                        }}
                                    />
                                </ContentButtonSendVideo>
                            </div>

                            <div>
                                <SimulationModalHelper
                                    title={titlePublicity}
                                    url_video={urlVideo}
                                    url_application={urlApplication}
                                    url_redirect={urlRedirect}
                                />
                            </div>
                        </Content>
                    </Container>
                </PageWrapper>
            )}
        </div>
    );
}

export default HelpersMonitorEditPage;
