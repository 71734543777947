import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 1280px;
    padding: 20px 20px;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;
`;

export const ContentTitlePage = styled.div`
    > h1 {
        text-align: center;
        font-size: 24px;
    }

    margin-bottom: 2rem;
`;

export const Body = styled.form``;

export const ContentInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ContentButtonSave = styled.div`
    display: flex;
    justify-content: center;
`;
