/* eslint-disable */

import { AxiosError } from "axios";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

import pathLogo from "../../assets/images/logo704LoginPage.png";
import ModalConfirm from "../../components/ModalConfirm";
import PreloadFb from "../../components/Preload";
import { SearchParamsUrl } from "../../helpers/searchParamsUrl";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import {
    Container,
    Header,
    ItemsChecklists,
    ContentLogo,
    SubTitle,
    ContentTerms,
    ContentButtons,
    TableCheckLists,
    ContentTable,
    Content,
    TextObs,
} from "./styles";

import { useNavigate } from "react-router-dom";

import {
    CustomerValidationProps,
    ImplementationsValidedProps,
    ItemsProps,
} from "./types";

const defaultImplementationsValided = {
    implementations_valided: [],
    implementations_not_valided: [],
};

export default function CheckListAppPage() {
    const [informationsCheckLists, setInformationsCheckLists] =
        useState<CustomerValidationProps | null>(null);
    const [itemsCheckLists, setItemsCheckLists] = useState<ItemsProps[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScreens, setLoadingScreens] = useState<boolean>(false);

    const [visibleModalConfirm, setVisibleModalConfirm] =
        useState<boolean>(false);
    const [isAuthorizationApp, setIsAuthorizationApp] = useState<boolean>(true);

    const [comments, setComments] = useState<string>("");
    const [implementationsValided, setImplementationsValided] =
        useState<ImplementationsValidedProps>(defaultImplementationsValided);
    const [implementationsAccepted, setImplementationsAccepted] =
        useState<boolean>(false);
    const [accepted, setAccepted] = useState<number>(0);

    const navigate = useNavigate();

    const token = SearchParamsUrl("token");

    const verifyAllChecklistsFilled = (
        listImplementationsChecked: ImplementationsValidedProps | null = null
    ) => {
        if (listImplementationsChecked) {
            const lengthItemsValided =
                listImplementationsChecked.implementations_valided.length;
            const lengthItemsNotValided =
                listImplementationsChecked.implementations_not_valided.length;

            const AllItemsChecked = lengthItemsNotValided + lengthItemsValided;
            const lengthItems = itemsCheckLists.length;

            return AllItemsChecked === lengthItems;
        }
    };

    const getInformationsChecklists = async () => {
        try {
            setLoadingScreens(true);
            const responseInformationsChecklists = await api.get(
                `customerValidationForm/form`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (responseInformationsChecklists.status) {
                setInformationsCheckLists(
                    responseInformationsChecklists.data.response
                );
            }
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoadingScreens(false);
        }
    };

    const getItemsCheckLists = async () => {
        try {
            setLoadingScreens(true);
            const responseItemsCheckLists = await api.get(
                "customerValidationForm/items",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (responseItemsCheckLists.status) {
                setItemsCheckLists(responseItemsCheckLists.data.response);
            }
        } catch (error: any) {
            defaultToast("error", error.response.data.message);
        } finally {
            setLoadingScreens(false);
        }
    };

    const handleAuthorizationApp = async (validedApp: boolean) => {
        try {
            setLoading(true);
            const resonseAuthorizationApp = await api.post(
                "customerValidationForm/finished/1",
                {
                    comments: comments.length > 0 ? comments : null,
                    implementation_accepted: implementationsAccepted,
                    accepted,
                    validated: validedApp,
                    items_validated:
                        implementationsValided.implementations_valided,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (resonseAuthorizationApp.status) {
                defaultToast("success", "Formulário preenchido com sucesso!");

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModalConfirm = (isAuthorization: boolean) => {
        setVisibleModalConfirm(true);
        setIsAuthorizationApp(isAuthorization);
    };

    const handleValidsImplementation = (idItem: number, isValided: boolean) => {
        if (isValided) {
            if (
                !implementationsValided.implementations_valided.includes(
                    idItem
                ) &&
                !implementationsValided.implementations_not_valided.includes(
                    idItem
                )
            ) {
                setImplementationsValided((data) => {
                    const implement_valided_old =
                        implementationsValided.implementations_valided;

                    const implement_valided_new = [
                        ...implement_valided_old,
                        idItem,
                    ];
                    return {
                        ...data,
                        implementations_valided: implement_valided_new,
                    };
                });
            }

            if (
                implementationsValided.implementations_not_valided.includes(
                    idItem
                )
            ) {
                setImplementationsValided((data) => {
                    const old_implementations_not_valided =
                        data.implementations_not_valided.filter(
                            (id) => id !== idItem
                        );

                    return {
                        ...data,
                        implementations_not_valided:
                            old_implementations_not_valided,
                        implementations_valided: [
                            ...data.implementations_valided,
                            idItem,
                        ],
                    };
                });
            }
        } else {
            if (
                !implementationsValided.implementations_not_valided.includes(
                    idItem
                ) &&
                !implementationsValided.implementations_valided.includes(idItem)
            ) {
                setImplementationsValided((data) => {
                    const implement_not_valided_old =
                        implementationsValided.implementations_not_valided;

                    const implement_not_valided_new = [
                        ...implement_not_valided_old,
                        idItem,
                    ];
                    return {
                        ...data,
                        implementations_not_valided: implement_not_valided_new,
                    };
                });
            }

            if (
                implementationsValided.implementations_valided.includes(idItem)
            ) {
                setImplementationsValided((data) => {
                    const old_implementations_valided =
                        implementationsValided.implementations_valided.filter(
                            (id) => id !== idItem
                        );

                    return {
                        ...data,
                        implementations_valided: old_implementations_valided,
                        implementations_not_valided: [
                            ...data.implementations_not_valided,
                            idItem,
                        ],
                    };
                });
            }
        }
    };

    useEffect(() => {
        if (informationsCheckLists?.finished_at) {
            navigate("/formulario-checklist-preenchido");
        }
    }, [informationsCheckLists]);

    useEffect(() => {
        if (!token) {
            navigate("/Error");
        } else {
            getInformationsChecklists();
            getItemsCheckLists();
        }
    }, []);

    const canValidAppAndAuthorize =
        verifyAllChecklistsFilled(implementationsValided) &&
        itemsCheckLists.length ===
            implementationsValided.implementations_valided.length &&
        implementationsAccepted &&
        accepted === 1;

    const notValidApp =
        verifyAllChecklistsFilled(implementationsValided) &&
        itemsCheckLists.length !==
            implementationsValided.implementations_valided.length &&
        implementationsAccepted &&
        accepted === 1 &&
        comments.length > 0;

    return (
        <>
            {token &&
                (loadingScreens ? (
                    <PreloadFb />
                ) : (
                    <Container>
                        <Content>
                            <Header>
                                <ContentLogo>
                                    <img
                                        src={pathLogo}
                                        alt="logo-application"
                                    />
                                </ContentLogo>
                                <h2>Bem vindo ao checklist do seu App</h2>
                            </Header>

                            <ItemsChecklists>
                                <SubTitle>
                                    Precisamos que valide alguns detalhes do seu
                                    app
                                </SubTitle>

                                <ContentTable>
                                    <TableCheckLists>
                                        <thead>
                                            <th>Itens a validar</th>
                                            <th>Valido</th>
                                            <th>Não valido</th>
                                        </thead>
                                        <tbody>
                                            {itemsCheckLists?.map((value) => {
                                                return (
                                                    <tr key={value.id}>
                                                        <td>
                                                            {value.description}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={implementationsValided.implementations_valided.includes(
                                                                    value.id
                                                                )}
                                                                onChange={() =>
                                                                    handleValidsImplementation(
                                                                        value.id,
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={implementationsValided.implementations_not_valided.includes(
                                                                    value.id
                                                                )}
                                                                onChange={() =>
                                                                    handleValidsImplementation(
                                                                        value.id,
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </TableCheckLists>
                                </ContentTable>
                            </ItemsChecklists>

                            <TextField
                                fullWidth
                                label="Observações finais"
                                size="small"
                                multiline
                                rows={4}
                                onChange={(event) =>
                                    setComments(event.target.value)
                                }
                            />

                            <TextObs>
                                Em caso de aplicativo não validado é obrigatório
                                inserir as observações.
                            </TextObs>

                            <SubTitle>Sobre implementações:</SubTitle>

                            <ContentTerms>
                                <p>
                                    Lembre que caso você tenha alguma
                                    implementação, ela só poderá ser ativada
                                    após a validação do seu app básico.
                                </p>

                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Aceito"
                                    checked={implementationsAccepted}
                                    onChange={(event: any) =>
                                        setImplementationsAccepted(
                                            event.target.checked
                                        )
                                    }
                                />
                            </ContentTerms>

                            <ContentTerms>
                                <p>
                                    Afirmo que verifiquei os itens acima e
                                    concordo em publicar meu app na
                                    loja704.com.br e que a 704Apps
                                    disponibilizar acesso para que possa
                                    publicá-lo nas demais lojas de minha
                                    preferência
                                </p>

                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Aceito"
                                    checked={accepted === 1 ? true : false}
                                    onChange={(event: any) =>
                                        setAccepted(
                                            event.target.checked ? 1 : 0
                                        )
                                    }
                                />
                            </ContentTerms>

                            <ContentButtons>
                                <LoadingButton
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                        handleOpenModalConfirm(false)
                                    }
                                    disabled={!notValidApp}
                                    loading={loading}
                                >
                                    Não valido meu app
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleOpenModalConfirm(true)}
                                    disabled={!canValidAppAndAuthorize}
                                    loading={loading}
                                >
                                    Valido meu app e autorizo a publicação
                                </LoadingButton>
                            </ContentButtons>

                            <ModalConfirm
                                titleModal="Deseja realmente executar esta ação?"
                                isLoadingButton={loading}
                                visible={visibleModalConfirm}
                                setVisible={setVisibleModalConfirm}
                                titleButton="Confirmar"
                                onClick={() =>
                                    handleAuthorizationApp(isAuthorizationApp)
                                }
                            />
                        </Content>
                    </Container>
                ))}
        </>
    );
}
