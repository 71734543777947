/* eslint-disable @typescript-eslint/no-explicit-any */
import { Send, Close } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";

import ButtonGeneric from "../../components/ButtonGeneric";
import PreloadButton from "../../components/PreloadButton";
import TextEditor from "../../components/TextEditor";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { EditNoteModalStyled } from "./styles";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleCloseEditNoteModal: () => void;
    openEditNoteModal: boolean;
    noteIndividualId: number | undefined;
    myParam: string | any;
    setNotepadData: any;
    noteIndividualContent: any;
}

function EditNoteModal({
    handleCloseEditNoteModal,
    openEditNoteModal,
    noteIndividualId,
    myParam,
    setNotepadData,
    noteIndividualContent,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }

    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [textEditorValue, setTextEditorValue] = useState<string>("");
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");
    useEffect(() => {
        setTextEditorValue(noteIndividualContent);
    }, [noteIndividualId]);

    const sendMesage = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // validação do formulário
        if (textEditorValue !== "") {
            setIsLoadingButton(true);
            api.post(
                `/customers/notepad/${myParam}/${noteIndividualId}`,
                {
                    content: textEditorValue,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then((response) => {
                    setIsLoadingButton(false);
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                })
                .then(() => {
                    api.get(`/customers/notepad/${myParam}`).then(
                        (response) => {
                            setNotepadData(response.data.response.data);
                        }
                    );
                    setTimeout(() => handleCloseEditNoteModal(), 1000);
                })
                .catch((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(response.response.data.response.name);
                    setIsLoadingButton(false);
                });
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Título ou contrato está em branco");
        }
    };

    return (
        <Modal open={openEditNoteModal} onClose={handleCloseEditNoteModal}>
            <EditNoteModalStyled>
                <div className="topModalContainer">
                    <h3>Editar observação</h3>
                    {/* eslint-disable-next-line */}
                    <button onClick={handleCloseEditNoteModal} type="button">
                        <Close />
                    </button>
                </div>
                <form encType="multipart/form-data" onSubmit={sendMesage}>
                    <TextEditor
                        placeholder="Editar texto de observação"
                        value={textEditorValue}
                        onChange={(value: string) => setTextEditorValue(value)}
                    />
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseEditNoteModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            text="Enviar"
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            type="submit"
                            endIcon={isLoadingButton ? "" : <Send />}
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        />
                    </div>
                </form>

                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </EditNoteModalStyled>
        </Modal>
    );
}
export default EditNoteModal;
