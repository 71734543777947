import styled from "styled-components";

export const HomePageStyle = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        .variablesContainer {
            height: auto;
        }

        @media (max-width: 400px) {
            h3 {
                text-align: center;
            }

            .inputs {
                width: 260px;
            }
        }
    }
`;
