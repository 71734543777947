import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CancelledImage from "../../assets/images/cancelled-image.png";
import ConfirmedImage from "../../assets/images/confirmed-image.png";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { api } from "../../services/api";
import { PaymentCompletedPageStyles } from "./styles";

const SUCCESS_STATUS_ID = 1;
const PENDING_STATUS_ID = 2;
const CANCELED_STATUS_ID = 3;
const REVERSED_STATUS_ID = 4;
const REFUSED_STATUS_ID = 5;

function PaymentCompletedPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const [paymentStatusValue, setPaymentStatusValue] = useState<number>();
    const [paymentStatusMessage, setPaymentStatusMessage] = useState<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        string | any
    >();
    const [paymentStatusImage, setPaymentStatusImage] = useState<string>();

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        api.get(`/checkout/client/${myParam}`)
            .then((response) => {
                setPaymentStatusValue(
                    response.data.response.payment.payment_status?.id
                );

                // setIsLoading(false);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    useEffect(() => {
        switch (paymentStatusValue) {
            case SUCCESS_STATUS_ID:
                setPaymentStatusMessage("Pagamento efetuado com sucesso.");
                setPaymentStatusImage(ConfirmedImage);
                break;
            case PENDING_STATUS_ID:
                navigate(`/checkout?id=${myParam}`, {
                    replace: true,
                });
                break;
            case CANCELED_STATUS_ID:
                setPaymentStatusMessage(
                    "Pagamento cancelado, fale com o setor financeiro para mais informações. (85) 98201-1498"
                );
                setPaymentStatusImage(CancelledImage);
                break;
            case REVERSED_STATUS_ID:
                setPaymentStatusMessage(
                    "O valor do seu pagamento já foi estornado, entre em contato com o setor financeiro para mais informações. (85) 98201-1498"
                );
                setPaymentStatusImage(ConfirmedImage);

                break;
            case REFUSED_STATUS_ID:
                navigate(`/checkout?id=${myParam}`, {
                    replace: true,
                });
                break;
            default:
                setPaymentStatusMessage(<PreloadFb />);
                break;
        }
    }, [paymentStatusValue]);
    return (
        <>
            <PaymentCompletedPageStyles>
                <div className="messageContainer">
                    <h2>{paymentStatusMessage}</h2>
                    <img
                        className="paymentImageStatus"
                        src={paymentStatusImage}
                        alt=""
                    />
                </div>
            </PaymentCompletedPageStyles>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}

export default PaymentCompletedPage;
