import LockIcon from "@mui/icons-material/Lock";

import imageLogo from "../../assets/images/logo704LoginPage.png";
import FormLogin from "../../components/FormLogin";
import { LoginPageStyle } from "./styles";

function LoginPage() {
    return (
        <LoginPageStyle>
            <section>
                <div className="mainContainer">
                    <img src={imageLogo} alt="704AppLogo" />
                    <h1>Fazer Login</h1>
                    <FormLogin />
                    <p className="bottomMessage">
                        <LockIcon className="iconSecurity" /> AMBIENTE SEGURO
                    </p>
                </div>
            </section>
        </LoginPageStyle>
    );
}

export default LoginPage;
