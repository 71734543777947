/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unused-prop-types */
import { AlertColor, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadFb from "../../components/Preload";
import PreloadButton from "../../components/PreloadButton";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormProfileEditPage } from "./styles";

interface PermissionsProps {
    description: string;
    id: number;
    name: string;
}

interface dataProfilePermissionsListProps {
    description: string;
    id: number;
    name: string;
    permissions: PermissionsProps[];
}

// eslint-disable-next-line
let listPermissions: string[] = [];

function ProfileEditPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [dataProfilePermissionsList, setDataProfilePermissionsList] =
        useState([]);
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const [nameInputValue, setNameInputValue] = useState("");
    const [selectedPermissionsList, setSelectedPermissionsList] = useState<
        string[]
    >([]);
    const [alertInput, setAlertInput] = useState(false);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("perfil.editar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [errorMessageInput, setErrorMessageInput] = useState("");
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const getCheckedCheckboxes = async (permissions: PermissionsProps[]) => {
        setSelectedPermissionsList(permissions.map((checked) => checked.name));
    };

    useEffect(() => {
        setIsLoading(true);
        api.get("/permissions/all")
            .then((response) => {
                setDataProfilePermissionsList(response.data.response);
            })
            .then(() => {
                api.get(`/roles/${myParam}/permissions`)
                    .then((response) => {
                        getCheckedCheckboxes(
                            response.data.response.permissions
                        );
                        setNameInputValue(response.data.response.name);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoading(false);
            });
    }, [myParam]);

    const handleCheckedAll = (checked: boolean) => {
        if (!checked) {
            setSelectedPermissionsList([]);
        } else {
            setSelectedPermissionsList(listPermissions);
        }
    };

    const handleChecked = (name: string, checked: boolean) => {
        if (checked) {
            setSelectedPermissionsList(
                selectedPermissionsList.filter((item: string) => item !== name)
            );
        } else {
            setSelectedPermissionsList([...selectedPermissionsList, name]);
        }
    };

    const editProfile = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (nameInputValue !== "" && selectedPermissionsList.length > 0) {
            setIsLoadingButton(true);
            api.put(`/roles/${myParam}`, {
                name: nameInputValue,
                permissions: selectedPermissionsList,
            })
                .then((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                })
                .then(() => {
                    setTimeout(() => {
                        navigate("/settings/profiles");
                        setOpenToast(false);
                        setIsLoadingButton(false);
                    }, 2000);
                })
                .catch((error) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setTextToast(error.response.data.message);
                });
        } else {
            setAlertInput(true);
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast(
                "O campo nome é obrigatório e ao menos uma permissão deve ser selecionada!"
            );
            setErrorMessageInput(
                "O campo nome é obrigatório e ao menos uma permissão deve ser selecionada!"
            );
        }
    };

    dataProfilePermissionsList.map((value: any) => {
        return value.permissions.map((response: any) => {
            return listPermissions.push(response.name);
        });
    });

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <FormProfileEditPage>
                    <div className="topFormPage">
                        <h2>Editar Perfil</h2>
                    </div>
                    <form onSubmit={editProfile}>
                        <div className="inputContainer">
                            <InputGeneric
                                className="inputs"
                                size="small"
                                name="name"
                                label="Digite o nome"
                                value={nameInputValue}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setNameInputValue(event.target.value);
                                }}
                                error={alertInput}
                                autoFocus
                            />
                            <p>{errorMessageInput}</p>
                        </div>
                        <div className="checkboxListContainer">
                            <h2>Lista de permissões</h2>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) =>
                                                handleCheckedAll(
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Listar Todos"
                                />
                            </FormGroup>

                            {!isLoading ? (
                                dataProfilePermissionsList.map(
                                    ({
                                        id,
                                        name,
                                        permissions,
                                    }: dataProfilePermissionsListProps) => (
                                        <div
                                            className="permissionsBlock"
                                            key={name}
                                        >
                                            <h3 key={id}>{name}</h3>
                                            <div className="checkboxesList">
                                                {permissions.map(
                                                    ({
                                                        id,
                                                        description,
                                                        name,
                                                    }: PermissionsProps) => (
                                                        <FormControlLabel
                                                            checked={selectedPermissionsList.includes(
                                                                name
                                                            )}
                                                            key={id}
                                                            control={
                                                                <Checkbox
                                                                    onClick={() => {
                                                                        handleChecked(
                                                                            name,
                                                                            selectedPermissionsList.includes(
                                                                                name
                                                                            )
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                            label={description}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                )
                            ) : (
                                <Loading />
                            )}
                        </div>
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => navigate("/settings/profiles")}
                                buttonColor="var(--cancel)"
                                text="VOLTAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background="var(--confirm)"
                                loading={isLoadingButton}
                                text="CONFIRMAR"
                                type="submit"
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </FormProfileEditPage>
            )}
        </PageWrapper>
    );
}
export default ProfileEditPage;
