import styled from "styled-components";

export const FormProposalImplementationRegisterPage = styled.form`
    width: 100%;
    max-width: 1280px;
    padding: 30px;
    box-shadow: 0px 0px 10px #88888830;
    background-color: #fff;

    .topFormPage {
        /* width: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
    }

    .inputsContainer {
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: left;
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }

    .line1 {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .line2 {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: right;
        gap: 20px;
    }

    .content-inputs {
        display: "flex";
        flex-direction: column;
        width: 100%;

        /* @media (max-width: 768px) {
            flex-direction: column;
            gap: 0.5rem;
        } */
    }

    .content-implementations {
        width: 90%;
        max-height: 150px;
        overflow: auto;

        strong {
        }

        > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 1.3rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-size: 16px;
            }
        }

        .content-actions {
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }

    .footer {
    }

    .content-cupom {
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.1);

        > div {
            width: 100%;
            display: flex;
            justify-content: right;
            align-items: center;

            span {
                font-size: 16px;
            }
        }
    }

    .bottom {
        width: 100%;
        display: flex;
        justify-content: right;
        margin: 14px 0;
    }

    .content-button {
        width: 100%;
        display: flex;
        justify-content: right;
    }
`;
