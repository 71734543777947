import { Assessment } from "@mui/icons-material";
import { LineChart, PieChart } from "@mui/x-charts";

import { PageDefault } from "../../components/PageDefault";
import PageWrapper from "../../components/PageWrapper";

export function FinancialReport() {
    return (
        <PageWrapper>
            <PageDefault
                headerPage
                header={{
                    title: "Relatórios financeiro",
                    subtitle: "Controle de todas informações financeiras ANA",
                    icon: <Assessment sx={{ color: "#fff" }} />,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[
                            {
                                curve: "linear",
                                data: [0, 5, 2, 6, 3, 9.3],
                                color: "var(--primary)",
                            },
                            {
                                curve: "linear",
                                data: [6, 3, 7, 9.5, 4, 2],
                                color: "var(--success)",
                            },
                        ]}
                        width={700}
                        height={300}
                    />

                    <PieChart
                        series={[
                            {
                                data: [
                                    {
                                        id: 0,
                                        value: 10,
                                        label: "Propostas",
                                        color: "var(--primary)",
                                    },
                                    {
                                        id: 1,
                                        value: 15,
                                        label: "Mensalidades",
                                        color: "var(--secondary)",
                                    },
                                    {
                                        id: 2,
                                        value: 20,
                                        label: "Implementações",
                                    },
                                ],
                            },
                        ]}
                        width={400}
                        height={200}
                    />
                </div>
            </PageDefault>
        </PageWrapper>
    );
}
