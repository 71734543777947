import styled from "styled-components";

interface PageStyleProps {
    heightAuto?: boolean;
}

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PageStyle = styled.div<PageStyleProps>`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: ${({ heightAuto }) => (heightAuto ? "auto" : "800px")};
    padding: 32px 35px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 10, 0.05);
`;
