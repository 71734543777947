/* eslint-disable radix */
/* eslint-disable no-plusplus */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIfCNPJIsValid(value: string) {
    if (!value) return false;

    const isString = typeof value === "string";
    const validTypes =
        isString || Number.isInteger(value) || Array.isArray(value);

    if (!validTypes) return false;

    if (isString) {
        if (value.length > 18) return false;

        const digitsOnly = /^\d{14}$/.test(value);
        const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

        // eslint-disable-next-line no-unused-expressions
        if (digitsOnly || validFormat) true;
        else return false;
    }

    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) return false;

    const calc = (x: number) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
            const n = slice[x - i];
            sum += n * factor--;
            if (factor < 2) factor = 9;
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
}

const formatedCPF = (cpf: string) => {
    const cpfWithoutPoints = cpf.replaceAll(".", "");
    const cpfFormated = cpfWithoutPoints.replace("-", "");

    return cpfFormated;
};

export const validatorCpf = (cpf: number) => {
    let sum;
    let rest;
    sum = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueCpf = formatedCPF(cpf as any);

    if (valueCpf === "00000000000") return false;
    for (let i = 1; i <= 9; i++)
        sum += parseInt(valueCpf.substring(i - 1, i)) * (11 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(valueCpf.substring(9, 10))) return false;

    sum = 0;

    for (let i = 1; i <= 10; i++)
        sum += parseInt(valueCpf.substring(i - 1, i)) * (12 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(valueCpf.substring(10, 11))) return false;
    return true;
};

export function maskCpfCnpj(input: string) {
    // Remove all non-digit characters
    const cleanedInput = input.replace(/\D/g, "");

    // Check if it's a CPF (11 digits) or CNPJ (14 digits)
    if (cleanedInput.length === 11) {
        // Mask CPF as XXX.XXX.XXX-XX
        return cleanedInput.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
        );
    }
    if (cleanedInput.length === 14) {
        // Mask CNPJ as XX.XXX.XXX/XXXX-XX
        return cleanedInput.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
        );
    }
    // Invalid input length, return as is
    return input;
}
