/*eslint-disable*/
import { FormEvent, useEffect, useState } from "react";
import { Add, Delete, Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    IconButton,
    Input,
    Box,
    Divider,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";
import ButtonGeneric from "../../components/ButtonGeneric";

import PageWrapper from "../../components/PageWrapper/index";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import {
    ListImplementationsProps,
    ListCustomersProps,
    OptionsProps,
    ObjectErrorSelectsSearchProps,
} from "./types";
import { FormProposalImplementationRegisterPage } from "./styles";
import { api } from "../../services/api";
import SelectSearch from "../../components/SelectSearch";
import InputGeneric from "../../components/InputGeneric";
import { AlertColor } from "@mui/material";
import { TransformDateEUA } from "../../helpers/tranformDateEUA";
import { setMaskMoney } from "../../helpers/maskMoney";

function ImplementationProposalRegisterPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [textToast, setTextToast] = useState("");
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [dueDate, setDueDate] = useState<any>(null);

    const [listImplementations, setListImplementations] = useState<
        ListImplementationsProps[]
    >([]);

    const [listCustomers, setListCustomers] = useState<ListCustomersProps[]>(
        []
    );

    const [implementationsSelected, setImplementationsSelected] =
        useState<ListImplementationsProps | null>(null);

    const [customerSelected, setCustomersSelected] = useState<OptionsProps>();

    const [listImplementsSelected, setListImplementsSelected] = useState<
        ListImplementationsProps[]
    >([]);

    const [keyCoupon, setKeyCoupon] = useState<string>("");

    const [listCouponsSelected, setListCouponsSelected] = useState<string[]>(
        []
    );

    const [errorsInputs, setErrorsInputs] = useState<
        ObjectErrorSelectsSearchProps[]
    >([
        {
            name: "implementations",
            error: false,
            message: "",
        },
        {
            name: "coupons",
            error: false,
            message: "",
        },
        {
            name: "clients",
            error: false,
            message: "",
        },
        {
            name: "due_Date",
            error: false,
            message: "",
        },
    ]);

    const getImplementationsAndCustomers = () => {
        setLoading(true);
        const responseListCustomers = api.get("customers/");

        Promise.all([responseListCustomers])
            .then((values) => {
                if (values[0].status) {
                    setListCustomers(values[0].data.response);
                }
            })
            .catch((error) => {
                console.error(error);
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast("Erro ao pegar dados");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getImplementations = () => {
        setLoading(true);
        const responseListImplementations = api.get(`implementations/${customerSelected?.value}/customer/not`);

        Promise.all([responseListImplementations])
            .then((values) => {
                if (values[0].status) {
                    console.log(values[0].data.response)
                    setListImplementations(values[0].data.response);
                }
            })
            .catch((error) => {
                setListImplementations([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getImplementations();
    }, [customerSelected]);

    const handleSaveProposalImplementation = async (
        event: FormEvent<HTMLFormElement>
    ) => {
        try {
            setLoading(true);
            event.preventDefault();

            if (!implementationsSelected) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Escolha uma implementação!");

                return;
            }

            if (!customerSelected) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Escolha um cliente!");

                return;
            }

            if (!dueDate) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Escolha uma data de nascimento!");

                return;
            }

            const dueDateFormated = TransformDateEUA(
                new Date(dueDate.$d).toLocaleDateString(),
                false
            );

            const idsImplementations = listImplementsSelected.map((value) => {
                return value.id;
            });

            const objectSend = {
                customer_id: customerSelected?.value,
                implementations: idsImplementations,
                due_date: dueDateFormated,
                coupons: listCouponsSelected,
            };

            const responseCreateImplementationProposal = await api.post(
                "implementationProposal",
                objectSend
            );

            if (responseCreateImplementationProposal.status) {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast("Cadastrado com sucesso!");

                setTimeout(() => {
                    navigate("/comercial/implementationProposal");
                    setOpenToast(false);
                }, 1000);
            } else {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(
                    responseCreateImplementationProposal.data.response[0]
                );
            }
        } catch (error: any) {


            if (error.response.status >= 400 && error.response.status < 500) {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            } else {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(
                    "Erro ao cadastrar nova proposta de implementação!"
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const handleAddImplementation = () => {
        if (implementationsSelected) {
            if (listImplementsSelected.includes(implementationsSelected)) {
                setOpenToast(true);
                setToastRequisitionResult("warning");
                setTextToast("Implementação já adicionada");

                setImplementationsSelected(null);

                return;
            }

            setListImplementsSelected([
                ...listImplementsSelected,
                implementationsSelected,
            ]);
            setListImplementations(
                listImplementations.filter(
                    (data) => data.id !== implementationsSelected.id
                )
            );
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Escolha uma implementação para adicionar!");
        }
    };

    const handleRemoveImplementationSelected = (
        value: ListImplementationsProps
    ) => {
        setListImplementsSelected(
            listImplementsSelected.filter((data) => data.id !== value.id)
        );
        setListImplementations([...listImplementations, value]);
    };

    const handleAddCoupons = () => {
        if (keyCoupon.length > 0) {
            setListCouponsSelected([...listCouponsSelected, keyCoupon]);
            setKeyCoupon("");
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Digite o código!");
        }
    };

    const handleRemoveCouponsSelected = (value: string) => {
        setListCouponsSelected(
            listCouponsSelected.filter((data) => data !== value)
        );
    };

    useEffect(() => {
        if (
            hasPermissions("propostas.cadastrar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
        getImplementationsAndCustomers();
    }, [permissions]);

    const listCustomersFormated = listCustomers.map((value) => {
        return {
            label: `${value.name} - ${
                value.holder_name === null ? "" : value.holder_name
            }`,
            value: value.id,
        };
    });

    return (
        <>
            <PageWrapper>
                {loading ? (
                    <PreloadFb />
                ) : (
                    <FormProposalImplementationRegisterPage
                        onSubmit={handleSaveProposalImplementation}
                    >
                        <div className="topFormPage">
                            <h2>Nova Proposta de Implementação</h2>
                        </div>

                        <div className="inputsContainer">
                            <div className="line1">
                                <SelectSearch
                                    value={customerSelected}
                                    setValue={setCustomersSelected}
                                    options={listCustomersFormated}
                                    label="Cliente"
                                    placeholder="Pesquise..."
                                    error={errorsInputs[2].error}
                                    textError={
                                        errorsInputs[2].error &&
                                        errorsInputs[2].message
                                    }
                                    width="100%"
                                />

                                <div
                                    style={{
                                        minWidth: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "20px",
                                    }}
                                >
                                    <FormControl
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <InputLabel
                                            id="implementation-select-label"
                                            size="small"
                                        >
                                            Implementações
                                        </InputLabel>

                                        <Select
                                            labelId="implementation-select-label"
                                            id="implementation-select"
                                            label="Implementações"
                                            size="small"
                                            onChange={(event) =>
                                                setImplementationsSelected(
                                                    JSON.parse(
                                                        event.target
                                                            .value as any
                                                    )
                                                )
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                            error={errorsInputs[0].error}
                                        >
                                            {listImplementations
                                                .filter(
                                                    (data) =>
                                                        data.price !== 0
                                                )
                                                .map((value) => {
                                                    return (
                                                        <MenuItem
                                                            key={value?.id}
                                                            value={JSON.stringify(
                                                                value
                                                            )}
                                                        >
                                                            {`${
                                                                value?.description
                                                            } - ${
                                                                value.price > 0
                                                                    ? value?.price.toLocaleString(
                                                                          "pt-br",
                                                                          {
                                                                              style: "currency",
                                                                              currency:
                                                                                  "BRL",
                                                                          }
                                                                      )
                                                                    : ""
                                                            }`}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                        <FormHelperText
                                            error={errorsInputs[0].error}
                                        >
                                            {errorsInputs[0].error &&
                                                errorsInputs[0].message}
                                        </FormHelperText>
                                    </FormControl>

                                    <ButtonGeneric
                                        buttonColor="var(--primary)"
                                        text="Adicionar"
                                        icon={<Add />}
                                        onclick={handleAddImplementation}
                                    />

                                    <FormControl sx={{ width: "60%" }}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="pt-br"
                                        >
                                            <DatePicker
                                                label="Data de Vencimento"
                                                disablePast
                                                slotProps={{
                                                    textField: {
                                                        error: errorsInputs[3]
                                                            .error,
                                                        helperText:
                                                            errorsInputs[3]
                                                                .error &&
                                                            errorsInputs[3]
                                                                .message,
                                                        size: "small",
                                                    },
                                                }}
                                                defaultValue={new Date()
                                                    .toISOString()
                                                    .substr(0, 10)}
                                                value={dueDate}
                                                onChange={(value) =>
                                                    setDueDate(value as any)
                                                }
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="content-implementations">
                                {listImplementsSelected.map((value) => {
                                    return (
                                        <div key={value.id}>
                                            <span>{value.description}</span>
                                            <div className="content-actions">
                                                <span>
                                                    {value.price.toLocaleString(
                                                        "pt-br",
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                                </span>
                                                <Delete
                                                    onClick={() => {
                                                        handleRemoveImplementationSelected(
                                                            value
                                                        );
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "var(--delete)",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <Divider />
                            {listCouponsSelected.length <= 0 && (
                                <div className="line2">
                                    <Input
                                        fullWidth
                                        size="medium"
                                        value={keyCoupon}
                                        onChange={(e) => {
                                            setKeyCoupon(e.target.value);
                                        }}
                                        placeholder="CUPOM"
                                        sx={{
                                            width: "200px",
                                        }}
                                    />

                                    <ButtonGeneric
                                        buttonColor="var(--primary)"
                                        text="Adicionar"
                                        icon={<Add />}
                                        onclick={handleAddCoupons}
                                    />
                                </div>
                            )}
                        </div>

                        <Divider />

                        <div className="footer">
                            <div className="content-cupom">
                                {listCouponsSelected.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            <span>Código Cupom: {value}</span>
                                            <div className="content-actions">
                                                <IconButton>
                                                    <Delete
                                                        onClick={() => {
                                                            handleRemoveCouponsSelected(
                                                                value
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "var(--delete)",
                                                        }}
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="bottom">
                                Valor: R${" "}
                                {setMaskMoney(
                                    listImplementsSelected.reduce(
                                        (ac, value) => ac + value.price,
                                        0
                                    ) * 100
                                )}
                            </div>
                            
                            <div className="bottom">
                                Desconto: R${" "}
                                {setMaskMoney("0")}
                            </div>
                            
                            <div className="bottom">
                                Valor final: R${" "}
                                {setMaskMoney(
                                    listImplementsSelected.reduce(
                                        (ac, value) => ac + value.price,
                                        0 
                                    ) * 100
                                )}
                            </div>
                            <div className="content-button">
                                <ButtonGeneric
                                    typeButton="submit"
                                    buttonColor="var(--secondary)"
                                    text="Adicionar Proposta"
                                    icon={<Add />}
                                />
                            </div>
                        </div>
                        <Toast
                            open={openToast}
                            onClose={() => setOpenToast(false)}
                            severity={toastRequisitionResult}
                            text={textToast}
                        />
                    </FormProposalImplementationRegisterPage>
                )}
            </PageWrapper>
        </>
    );
}
export default ImplementationProposalRegisterPage;
