import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import TextEditor from "../../components/TextEditor";
import Toast from "../../components/Toast";
import Toggle from "../../components/Toggle";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import { FormContractEditPage } from "./styles";

function ContractEditPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const [isloading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("contrato.editar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [dataVariablesContractList, setDataVariablesContractList] = useState(
        []
    );
    const [titleValue, setTitleValue] = useState("");
    const [contractIsActiveValue, setContractIsActive] = useState(0);

    function handleChangeContractActiveStatus() {
        if (contractIsActiveValue === 1) {
            setContractIsActive(0);
        } else {
            setContractIsActive(1);
        }
    }

    const [textEditorValue, setTextEditorValue] = useState<string>("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        api.get(`/contracts/${myParam}`)
            .then((response) => {
                setTitleValue(response.data.response.title);
                setContractIsActive(response.data.response.is_active);
                setTextEditorValue(response.data.response.content);
            })
            .then(() => {
                api.get("/contracts/variables")
                    .then((response) => {
                        setDataVariablesContractList(response.data.response);
                    })
                    .then(() => {
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            });
    }, [myParam]);

    const createNewContract = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // validação do formulário
        if (titleValue !== "" && textEditorValue !== "") {
            setIsLoadingButton(true);
            api.put(`/contracts/${myParam}`, {
                title: titleValue,
                content: textEditorValue,
                is_active: contractIsActiveValue,
            })
                .then((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("success");
                    setTextToast(response.data.message);
                })
                .then(() => {
                    setTimeout(() => {
                        navigate("/settings/contracts");
                        setOpenToast(false);
                        setIsLoadingButton(false);
                    }, 2000);
                })
                .catch((response) => {
                    setOpenToast(true);
                    setToastRequisitionResult("error");
                    setIsLoadingButton(false);
                    setTextToast(response.response.data.message);
                });
        } else {
            setOpenToast(true);
            setToastRequisitionResult("warning");
            setTextToast("Título ou contrato está em branco");
        }
    };

    return (
        <PageWrapper>
            <FormContractEditPage>
                <div className="topFormPage">
                    <h2>Editar Contrato</h2>
                </div>
                <h3>PALAVRAS-CHAVE</h3>
                <div className="variablesContainer">
                    {!isloading ? (
                        dataVariablesContractList.map(
                            ({ id, value, description }) => (
                                <div className="variablesContent" key={id}>
                                    <p className="bolderText">
                                        {value}{" "}
                                        <span className="smallSizeText">
                                            {description}
                                        </span>
                                    </p>
                                </div>
                            )
                        )
                    ) : (
                        <Loading />
                    )}
                </div>
                <h4>TÍTULO</h4>
                <form onSubmit={createNewContract}>
                    <InputGeneric
                        className="inputs"
                        size="small"
                        label="Título do Contrato"
                        value={titleValue}
                        onChange={(event) => {
                            setTitleValue(event.target.value);
                        }}
                        autoFocus
                    />
                    <div className="contractActiveContainer">
                        <h4>Contrato ativo?</h4>
                        <Toggle
                            value={contractIsActiveValue}
                            label={
                                contractIsActiveValue === 1
                                    ? "Ativo"
                                    : "Inativo"
                            }
                            checked={contractIsActiveValue === 1}
                            onChange={() => {
                                handleChangeContractActiveStatus();
                            }}
                        />
                    </div>
                    <div className="contractContainer">
                        <h4>CONTRATO</h4>

                        <TextEditor
                            placeholder="Editar conteúdo do contrato."
                            value={textEditorValue}
                            onChange={(value: string) =>
                                setTextEditorValue(value)
                            }
                        />
                    </div>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={() => navigate("/settings/contracts")}
                            buttonColor="var(--cancel)"
                            text="VOLTAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--confirm)"
                            loading={isLoadingButton}
                            text="CONFIRMAR"
                            type="submit"
                        />
                    </div>
                </form>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </FormContractEditPage>
        </PageWrapper>
    );
}
export default ContractEditPage;
