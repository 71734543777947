import styled from "styled-components";

interface ContainerProps {
    width: number;
    height: number;
}

export const Container = styled.div<ContainerProps>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;

    > div {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #00000081;
        z-index: 100;
        display: flex;
        opacity: 0;
    }

    :hover {
        > div {
            opacity: 1;
            transition: all ease 0.3s;
        }
    }
`;

export const ContentBtnDownload = styled.div`
    width: 100%;
    align-self: center;
    text-align: center;
    color: #ffffff !important;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;
