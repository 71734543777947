import BreadcrumbsHeader from "../BreadcrumbsHeader";
import Navbar from "../Navbar/VerticalNavbar";
import { UnderNavbarHeader } from "./styles";

function VertialHeader() {
    return (
        <div>
            <Navbar />
        </div>
    );
}

export default VertialHeader;
