import styled from "styled-components";

export const Container = styled.form``;

export const TitlePage = styled.h1`
    font-size: 24px;
    text-align: center;
`;

export const ContentInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    gap: 1rem;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const Session = styled.div`
    margin: 1rem 0;
`;

export const LabelInput = styled.span`
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const InputColor = styled.input`
    width: 40px;
    height: 40px;
    border-radius: 360px;
    cursor: pointer;
    border: none;
    background: none;

    ::-webkit-color-swatch {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 0.5px solid gray;
    }

    @media (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
`;

export const ContentButtonSubmit = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
`;

export const InputLogo = styled.label`
    display: block;
    width: 150px;
    height: 150px;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(0.95);
    background-position: center;
    background-size: contain;

    :hover {
        transition: all ease 0.3s;
        transform: scale(1);
    }
`;

export const ImgLogo = styled.img`
    width: 250px;
    height: 250px;
    border: 1px solid #000000;
`;

export const SessionLogo = styled.div`
    margin: 1rem 0;

    @media (max-width: 680px) {
        display: none;
    }
`;

export const ContentLogoMobile = styled.div`
    display: none;

    @media (max-width: 680px) {
        display: block;
    }
`;

export const ContentLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 680px) {
        display: none;
    }
`;
