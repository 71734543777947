import { Select, MenuItem } from "@mui/material";
import { LineChart } from "@mui/x-charts";
/* eslint-disable */
/* prettier-ignore */
import { format, getMonth } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";

import SelectSearch from "../../../components/SelectSearch";
import { api } from "../../../services/api";
import { getLastDaysOfMonthsBeforeCurrentDate } from "./scripts";
import { GraphDiv, Grid, Header } from "./styles";
import TableDataGridPagination from "../../../components/TableDataGridPagination";
import { columnsReportDataGrid } from "./columnsReportDataGrid"
import TableDataGrid from "../../../components/TableDataGrid";
import { MobilyReport } from "./types";
import { findMaxValue, findMinValue } from "../../../helpers/array/arr";

interface Props {
    customer: number
}

export function AppDriverReport({ customer }: Props) {
    const [dateInterval, setDateInterval] = useState(6);
    const [dataChart, setDataChart] = useState([10]);
    const [maxDrivers, setMaxDrivers] = useState(10);
    const [minDrivers, setMinDrivers] = useState(0);

    const [timeXData, setTimeXData] = useState<any>([new Date()]);

    const [loadingTable, setLoadingTable] = useState(false);

    const [pages, setPages] = useState({
        currentPage: 1,
        totalPages: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [reports, setReports] = useState([]);
    function fetchReport() {
        setLoadingTable(true);
        api.get("v2/customer/suplus-drivers/" + customer)
        .then((response) => {
            setReports(response.data);
        })
        .finally(() => {
            setLoadingTable(false);
        })
    }

    function getDataToChart(dataX: Date[]) {
        return dataX.map((item: Date, index: number) => {
            
            const reportResult: any = reports.find((report: MobilyReport)  => {
                const reportDate = new Date(report.date_end);
                // @ts-ignore
                const result = reportDate.getFullYear() === item.getFullYear() && reportDate.getMonth() === item.getMonth();

                if(result) {
                    return report;
                }
            });
            return reportResult?.quantity_driver_excess || 0;
        });
    }

    useEffect(() => {
        const newTimeXData = getLastDaysOfMonthsBeforeCurrentDate(dateInterval);
        setTimeXData(getLastDaysOfMonthsBeforeCurrentDate(dateInterval));
        setDataChart(getDataToChart(newTimeXData));
    }, [dateInterval])

    useEffect(() => {
        fetchReport();
    }, []);

    useEffect(() => {
        const reportDataChart = reports.map((item: MobilyReport) => item.quantity_driver_excess);
        const max = findMaxValue(reportDataChart);
        
        setMaxDrivers(max || 100)
        setDataChart(getDataToChart(getLastDaysOfMonthsBeforeCurrentDate(dateInterval)))
    }, [reports]);

    return (
        <>
            <Header>
                <h2>Relatório geral de mobilidade</h2>
                <div>
                    <Select

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dateInterval}
                        label="Intervalo"
                        onChange={(e) => setDateInterval(Number(e.target.value))}
                        size="small"
                    >
                        <MenuItem value={3}>3 meses</MenuItem>
                        <MenuItem value={6}>6 meses</MenuItem>
                        <MenuItem value={12}>1 ano</MenuItem>
                        <MenuItem value={60}>5 anos</MenuItem>
                        <MenuItem value={100}>todo periodo</MenuItem>
                    </Select>
                </div>
            </Header>
            <GraphDiv>
                <LineChart
                    series={[
                        {
                            curve: "linear",
                            data: dataChart,
                            label: "Motoristas Excedentes",
                            area: true,
                            color: "#799926",
                        },
                    ]}
                    xAxis={[
                        {
                            id: "Data",
                            scaleType: "point",
                            data: getLastDaysOfMonthsBeforeCurrentDate(dataChart.length),
                            valueFormatter: (date) => (
                                dateInterval > 12 ?
                                dateInterval > 60 ? "-" : `${format(date, "yy")}` :
                                `${format(date, "MM/yy")} - ${format(
                                    date,
                                    "MMMM",
                                    { locale: ptBR }
                                )}`
                            ),
                            label: "Mês de relatório",
                        },
                    ]}
                    yAxis={[
                        {
                            label: "Excedentes",
                            max: maxDrivers,
                            min: minDrivers,
                        },
                    ]}
                />
            </GraphDiv>
            <Grid>
                <TableDataGrid
                    rows={reports}
                    columns={columnsReportDataGrid}
                    loading={loadingTable}
                />
            </Grid>
        </>
    );
}
