// import { Delete } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    // GridRenderCellParams,
    // GridRowId,
    // GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import ModalDeleteProfile from "../../components/DeleteProfileModal";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

function CompaniesListPage() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataCompaniesList, setDataCompaniesList] = useState([]);
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("empresas.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    // const [openCompaniesModal, setOpenCompaniesModal] = useState(false);
    // const handleCloseDeleteProfileModal = () => {
    //     setOpenCompaniesModal(false);
    // };
    // const [selectedRowProfileId, setSelectedRowProfileId] =
    //     useState<GridRowId>();
    // const [selectedRowProfileName, setSelectedRowProfileName] = useState("");
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("companies")
            .then((response) => {
                setLoadingTable(false);

                if (response.data.status) {
                    setDataCompaniesList(response.data.response);
                }
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "corporate_name", headerName: "Nome", flex: 65 },
        {
            field: "ações",
            headerName: "Ações",
            flex: 30,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            // renderCell: (params: GridRenderCellParams) => (
            //     <div className="containerActionButtonsListTablePagesGlobal">
            //         <Delete className="cursorPointerGlobal" />
            //     </div>
            // ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal" />
                    <TableDataGrid
                        rows={dataCompaniesList}
                        columns={columns}
                        // onRowClick={(row: GridRowParams) => {
                        // setSelectedRowProfileId(row.id);
                        // setSelectedRowProfileName(row.row.name);
                        // }}
                        loading={loadingTable}
                    />
                    {/* <ModalDeleteProfile
                        openCompaniesModal={openCompaniesModal}
                        handleCloseDeleteProfileModal={
                            handleCloseDeleteProfileModal
                        }
                        selectedRowProfileId={selectedRowProfileId}
                        dataCompaniesList={dataCompaniesList}
                        setDataCompaniesList={setDataCompaniesList}
                        selectedRowProfileName={selectedRowProfileName}
                    /> */}
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default CompaniesListPage;
