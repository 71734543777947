import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { DataUserProps } from "../../pages/UsersPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteUserStyle } from "./styles";

interface Props {
    openDeleteUserModal: boolean;
    handleCloseDeleteUserModal: () => void;
    selectedRowUser: GridRowParams["row"];
    setDataUsersList: (value: DataUserProps[]) => void;
    dataUsersList: DataUserProps[];
}

function ModalDeleteUser({
    openDeleteUserModal,
    handleCloseDeleteUserModal,
    selectedRowUser,
    setDataUsersList,
    dataUsersList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteUser() {
        setIsLoadingButton(true);
        api.delete(`/users/${selectedRowUser.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteUserModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataUsersList(
                    dataUsersList.filter((item: DataUserProps) => {
                        return item.id !== selectedRowUser.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
                setTimeout(() => {
                    setOpenToast(false);
                }, 1000);
            });
    }

    return (
        <Modal open={openDeleteUserModal} onClose={handleCloseDeleteUserModal}>
            <ModalDeleteUserStyle>
                <Box className="modalContainer">
                    <p>{`Você realmente deseja excluir o usuário "${selectedRowUser.name}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteUserModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteUser()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteUserStyle>
        </Modal>
    );
}
export default ModalDeleteUser;
