import {
    ExpandLess,
    ExpandMore,
    FollowTheSigns,
    Home,
    Logout,
    AccountCircle,
    ManageAccounts,
    MonetizationOn,
    OnDeviceTraining,
    QueryStats,
    SupervisorAccount,
    NextWeek,
    Receipt,
    FolderShared,
    Extension,
    AccountBalanceWallet,
    Calculate,
    InsertDriveFile,
    PostAdd,
    Addchart,
    SsidChart,
    RequestQuote,
    CurrencyExchange,
    Rule,
    Settings,
    Build,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
    Collapse,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import logoImage from "../../assets/images/logo704LoginPage.png";
import { useAuth } from "../../contexts/contexts";
import { If } from "../If";
import { DrawerStyle, NavbarStyle } from "./styles";

const drawerWidth = 260;

function Navbar() {
    const { signOut, hasPermissions } = useAuth();
    // MENU TELA SEM RESPONSIVIDADE

    const [anchorComercialMenu, setAnchorComercialMenu] =
        useState<null | HTMLElement>(null);
    const handleOpenComercialMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorComercialMenu(event.currentTarget);
    };
    const handleCloseComercialMenu = () => {
        setAnchorComercialMenu(null);
    };

    const [anchorReportsMenu, setAnchorReportsMenu] =
        useState<null | HTMLElement>(null);
    const handleOpenReportsMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorReportsMenu(event.currentTarget);
    };
    const handleCloseReportsMenu = () => {
        setAnchorReportsMenu(null);
    };

    const [anchorFinacialMenu, setAnchorFinacialMenu] =
        useState<null | HTMLElement>(null);
    const handleOpenFinancialMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorFinacialMenu(event.currentTarget);
    };
    const handleCloseFinancialMenu = () => {
        setAnchorFinacialMenu(null);
    };

    const [anchorCompaniesMenu, setAnchorCompaniesMenu] =
        useState<null | HTMLElement>(null);
    const handleOpenCompaniesMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorCompaniesMenu(event.currentTarget);
    };
    const handleCloseCompaniesMenu = () => {
        setAnchorCompaniesMenu(null);
    };

    const [anchorComissionsMenu, setAnchorComissionsMenu] =
        useState<null | HTMLElement>(null);
    const handleOpenComissionsMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorComissionsMenu(event.currentTarget);
    };
    const handleCloseComissionsMenu = () => {
        setAnchorComissionsMenu(null);
    };

    const [anchorAnaMenu, setAnchorAnaMenu] = useState<null | HTMLElement>(
        null
    );
    const handleOpenAnaMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorAnaMenu(event.currentTarget);
    };
    const handleCloseAnaMenu = () => {
        setAnchorAnaMenu(null);
    };

    const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(
        null
    );
    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorUserMenu(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorUserMenu(null);
    };

    //   CÓDIGO DO ASIDE MENU RESPONSIVO

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [openCollapseMenu, setOpenCollapseMenu] = useState("");

    const navigate = useNavigate();

    const menuNavItems = [
        {
            text: "Comercial",
            id: "comercial",
            permission: "comercial.visualizar",
            icon: <MonetizationOn />,
            onclick: handleOpenComercialMenu,
            anchor: anchorComercialMenu,
            onclose: handleCloseComercialMenu,
            subItems: [
                {
                    text: "Propostas",
                    permission: "propostas.listar",
                    url: "/proposal",
                    icon: <NextWeek />,
                },
                {
                    text: "Propostas de Implementações",
                    permission: "propostas.listar",
                    url: "/implementationProposal",
                    icon: <Extension />,
                },
                {
                    text: "Clientes",
                    permission: "titulares.listar",
                    url: "/customers",
                    icon: <FolderShared />,
                },

                {
                    text: "Titulares",
                    permission: "titulares.listar",
                    url: "/holders",
                    icon: <SupervisorAccount />,
                },
                {
                    text: "Acompanhamento",
                    permission: "projetos.visualizar",
                    url: "/acompanhamento",
                    icon: <FollowTheSigns />,
                },
            ],
        },
        {
            text: "Financeiro",
            id: "financial",
            permission: "financeiro",
            icon: <Calculate />,
            onclick: handleOpenFinancialMenu,
            anchor: anchorFinacialMenu,
            onclose: handleCloseFinancialMenu,
            subItems: [
                {
                    text: "Central de Clientes",
                    permission: "clientes.central",
                    url: "/clientes/central",
                    icon: <FolderShared />,
                },
                {
                    text: "Faturas Mensais",
                    permission: "nota.fiscal.listar",
                    url: "/faturas-mensais",
                    icon: <AccountBalanceWallet />,
                },
                {
                    text: "Notas Fiscais",
                    permission: "nota.fiscal.listar",
                    url: "/invoices",
                    icon: <Receipt />,
                },
                {
                    text: "Produção",
                    permission: "relatorios.producao.listar",
                    url: "/production",
                    icon: <OnDeviceTraining />,
                },
            ],
        },
        {
            text: "Relatórios",
            id: "reports",
            permission: "relatorios.visualizar",
            icon: <QueryStats />,
            onclick: handleOpenReportsMenu,
            anchor: anchorReportsMenu,
            onclose: handleCloseReportsMenu,
            subItems: [
                {
                    text: "Novo relatório",
                    permission: "relatorio.criar",
                    url: "/listagem-relatorios/geracao-relatorios",
                    icon: <Addchart />,
                },
                {
                    text: "Relatórios gerados",
                    permission: "relatorio.listar",
                    url: "/listagem-relatorios",
                    icon: <SsidChart />,
                },
                {
                    text: "Validações",
                    permission: "validators.listar",
                    url: "/validations",
                    icon: <Rule />,
                },
            ],
        },
        // {
        //     text: "Empresas",
        //     id: "companies",
        //     permission: "empresas.visualizar",
        //     icon: <Business />,
        //     onclick: handleOpenCompaniesMenu,
        //     anchor: anchorCompaniesMenu,
        //     onclose: handleCloseCompaniesMenu,
        //     subItems: [
        //         {
        //             text: "Lista de Empresas",
        //             permission: "empresas.listar",
        //             url: "/companiesList",
        //             icon: <FormatListBulleted />,
        //         },
        //     ],
        // },
        {
            text: "Comissões",
            id: "comissions",
            permission: "comissoes.visualizar",
            icon: <RequestQuote />,
            onclick: handleOpenComissionsMenu,
            anchor: anchorComissionsMenu,
            onclose: handleCloseComissionsMenu,
            subItems: [
                {
                    text: "Configuração de Comissão",
                    permission: "comissoes.visualizar",
                    url: "/comissionSetup",
                    icon: <CurrencyExchange />,
                },
            ],
        },
    ];

    const name = localStorage.getItem("@Auth:name")?.replace(/"/g, "");

    const drawer = (
        <DrawerStyle>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <div className="logoInDrawerMenu">
                    <img src={logoImage} alt="" />
                </div>
                <If condition={hasPermissions("ana.home")}>
                    <ListItemButton onClick={() => navigate("/home")}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Início" />
                    </ListItemButton>
                </If>
                {menuNavItems.map(
                    ({ id, icon, text, subItems, permission }) => (
                        <If key={id} condition={hasPermissions(permission)}>
                            <ListItemButton
                                onClick={() => {
                                    setOpenCollapseMenu((state) =>
                                        state === id ? "" : id
                                    );
                                }}
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText primary={text} />
                                {openCollapseMenu === id ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={openCollapseMenu === id}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {subItems.map(
                                        ({ text, icon, url, permission }) => (
                                            <If
                                                key={url}
                                                condition={hasPermissions(
                                                    permission
                                                )}
                                            >
                                                <ListItemButton
                                                    key={text}
                                                    onClick={() =>
                                                        navigate(`/${id}${url}`)
                                                    }
                                                    sx={{ pl: 4 }}
                                                >
                                                    <ListItemIcon>
                                                        {icon}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={text}
                                                    />
                                                </ListItemButton>
                                            </If>
                                        )
                                    )}
                                </List>
                            </Collapse>
                        </If>
                    )
                )}
                <If condition={hasPermissions("configuracoes.visualizar")}>
                    <ListItemButton onClick={() => navigate("/settings")}>
                        <ListItemIcon>
                            <ManageAccounts />
                        </ListItemIcon>
                        <ListItemText primary="Configurações" />
                    </ListItemButton>
                </If>
            </List>
        </DrawerStyle>
    );

    return (
        <NavbarStyle>
            <AppBar className="no-print navbarContent" position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* CONTAINER LOGO */}
                        <div className="content-logo">
                            {/* IMAGEM DA LOGO */}
                            <img
                                className="logoNavbar"
                                src={logoImage}
                                alt="Logo 704app"
                            />
                        </div>
                        {/* FIM DO CONTAINER */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", sm: "flex", md: "none" },
                            }}
                        >
                            {/* CONTAINER DO BOTÃO DE MENU DE RESPONSIVIDADE */}
                            <IconButton
                                size="large"
                                aria-controls="menu-appbar"
                                aria-haspopup="false"
                                onClick={handleDrawerToggle}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* FIM DO CONTAINER */}
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: "flex", sm: "none", md: "none" },
                                flexGrow: 1,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            <img
                                className="logoNavbar"
                                src={logoImage}
                                alt=""
                            />
                        </Typography>
                        {/* INÍCIO DO NAVBAR TELAS GRANDES */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", sm: "none", md: "flex" },
                                gap: "10px",
                            }}
                        >
                            <If condition={hasPermissions("ana.home")}>
                                <Button
                                    onClick={() => {
                                        navigate("/home");
                                    }}
                                    sx={{
                                        my: 2,
                                        display: "flex",
                                        color: "white",
                                        fontWeight: 600,
                                    }}
                                    startIcon={<Home />}
                                >
                                    Início
                                </Button>
                            </If>
                            {menuNavItems.map(
                                ({
                                    id,
                                    onclick,
                                    icon,
                                    text,
                                    anchor,
                                    onclose,
                                    subItems,
                                    permission,
                                }) => (
                                    <If
                                        key={id}
                                        condition={hasPermissions(permission)}
                                    >
                                        <div key={text}>
                                            <Button
                                                onClick={onclick}
                                                sx={{
                                                    my: 2,
                                                    display: "flex",
                                                    color: "white",
                                                    fontWeight: 600,
                                                }}
                                                startIcon={icon}
                                            >
                                                {text}
                                            </Button>
                                            <Menu
                                                sx={{ mt: "40px" }}
                                                anchorEl={anchor}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                open={Boolean(anchor)}
                                                onClose={onclose}
                                            >
                                                {subItems.map(
                                                    ({
                                                        text,
                                                        icon,
                                                        url,
                                                        permission,
                                                    }) => (
                                                        <If
                                                            key={url}
                                                            condition={hasPermissions(
                                                                permission
                                                            )}
                                                        >
                                                            <ListItemButton
                                                                key={text}
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/${id}${url}`
                                                                    )
                                                                }
                                                            >
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        {icon}
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            text
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </ListItemButton>
                                                        </If>
                                                    )
                                                )}
                                            </Menu>
                                        </div>
                                    </If>
                                )
                            )}
                            <If
                                condition={hasPermissions(
                                    "configuracoes.visualizar"
                                )}
                            >
                                <Button
                                    onClick={() => {
                                        navigate("/settings");
                                    }}
                                    sx={{
                                        my: 2,
                                        display: "flex",
                                        color: "white",
                                        fontWeight: 600,
                                    }}
                                    startIcon={<ManageAccounts />}
                                >
                                    Configurações
                                </Button>
                            </If>
                            <If
                                condition={hasPermissions(
                                    "configuracoes.visualizar"
                                )}
                            >
                                <Button
                                    onClick={() => {
                                        navigate("/ana/listagem-clientes");
                                    }}
                                    sx={{
                                        my: 2,
                                        display: "flex",
                                        color: "white",
                                        fontWeight: 600,
                                    }}
                                    startIcon={<Settings />}
                                >
                                    Ana
                                </Button>
                            </If>
                        </Box>
                        {/* FIM NAVBAR TELAS GRANDES */}
                        {/* INICIO DO ASIDE */}
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidth } }}
                            aria-label="mailbox folders"
                        >
                            <Drawer
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: "block", sm: "flex" },
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                    },
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                        {/* FIM DO ASIDE */}
                        {/* <Button
                            onClick={() => {
                                const event = new Event("changeNavBar");
                                const main =
                                    document.getElementById("main-divider");

                                main?.dispatchEvent(event);
                            }}
                        >
                            <SwapVerticalCircle
                                sx={{
                                    color: "white",
                                }}
                            />
                        </Button> */}
                        <Box />
                        <Box sx={{ flexGrow: 0 }}>
                            <Button
                                onClick={handleOpenUserMenu}
                                sx={{
                                    my: 2,
                                    display: "flex",
                                    color: "white",
                                    fontWeight: 600,
                                }}
                                endIcon={<AccountCircle />}
                            >
                                {name}
                            </Button>
                            <Menu
                                sx={{ mt: "40px" }}
                                anchorEl={anchorUserMenu}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorUserMenu)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <ListItemButton
                                        onClick={signOut}
                                        sx={{ pl: 1 }}
                                    >
                                        <ListItemIcon>
                                            <Logout />
                                        </ListItemIcon>
                                        <ListItemText primary="Sair da conta" />
                                    </ListItemButton>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </NavbarStyle>
    );
}
export default Navbar;
