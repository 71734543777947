import styled from "styled-components";

export const ContainerProfile = styled.div`
    display: flex;
    margin: 25px 0;
    gap: 10px;
    justify-items: left;

    .img {
        flex: 1;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5%;
        }
    }

    .data-inputs {
        flex: 3;

        * {
            max-width: 600px;
        }
    }
`;
