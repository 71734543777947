import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Modal, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import { Container, TitleModal, ContentInput, ContentButton } from "./styles";
import { SchemaFormProps } from "./types";
import { StepsFollowUpProjectsProps } from "../../pages/FollowUpProjects/types";

interface ModalAddStepProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
    getStepsFollowUp: () => void;
    lastNumberOrder: number;
    projectSelected: StepsFollowUpProjectsProps | undefined;
    setProjectSelected: (data: StepsFollowUpProjectsProps | undefined) => void;
}

const schema = yup
    .object({
        nameStep: yup.string().required("O campo nome é obrigatório"),
        orderStep: yup
            .number()
            .positive()
            .integer("Este campo precisa ser um número")
            .required("O campo ordem é obrigatório"),
    })
    .required();

export default function ModalAddOrEditStep({
    visible,
    setVisible,
    getStepsFollowUp,
    lastNumberOrder,
    projectSelected,
    setProjectSelected,
}: ModalAddStepProps) {
    const { handleSubmit, control, setValue } = useForm<SchemaFormProps>({
        resolver: yupResolver(schema),
    });

    const [loading, setLoading] = useState<boolean>(false);

    const handleCloseModal = () => {
        setProjectSelected(undefined);
        setVisible(false);
    };

    const handleAddStep = async (data: SchemaFormProps) => {
        const { nameStep, orderStep } = data;

        try {
            setLoading(true);
            const responseStep = await api.post("projectStepAccompaniment", {
                name: nameStep,
                order: orderStep,
            });

            if (responseStep.status) {
                defaultToast("success", "Step adicionado com sucesso!");
                handleCloseModal();
                setValue("nameStep", "");
                setValue("orderStep", 0);
                getStepsFollowUp();
            }
        } catch (error: any) {
            console.error(error.response.data.message);
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditStep = async (data: SchemaFormProps) => {
        const { nameStep, orderStep } = data;

        try {
            setLoading(true);

            const responseEditStep = await api.put(
                `projectStepAccompaniment/${projectSelected?.id}`,
                {
                    name: nameStep,
                    order: orderStep,
                }
            );

            if (responseEditStep.status) {
                defaultToast("success", "Step editado com sucesso!");
                getStepsFollowUp();
                handleCloseModal();
            }
        } catch (error: any) {
            console.error(error);
            defaultToast("error", error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (projectSelected) {
            setValue("nameStep", projectSelected?.name);
            setValue("orderStep", projectSelected?.order);
        } else {
            setValue("orderStep", lastNumberOrder + 1);
            setValue("nameStep", "");
        }
    }, [projectSelected]);

    useEffect(() => {
        setValue("orderStep", lastNumberOrder + 1);
    }, [lastNumberOrder]);

    const handleAddOrEditStep = projectSelected
        ? handleSubmit(handleEditStep)
        : handleSubmit(handleAddStep);

    return (
        <Modal
            open={visible}
            onClose={handleCloseModal}
            aria-labelledby="modal-add-step-title"
            aria-describedby="modal-add-step-description"
        >
            <Container onSubmit={handleAddOrEditStep}>
                <TitleModal>
                    {projectSelected ? "Editar step" : "Adicionar step"}
                </TitleModal>

                <ContentInput>
                    <Controller
                        control={control}
                        name="nameStep"
                        render={({
                            field: { onChange, value = "" },
                            fieldState: { error },
                        }) => (
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="name-step"
                                label="Nome"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={error && error.message}
                            />
                        )}
                    />
                </ContentInput>

                <ContentInput>
                    <Controller
                        control={control}
                        name="orderStep"
                        render={({
                            field: {
                                onChange,
                                value = projectSelected
                                    ? projectSelected.order
                                    : lastNumberOrder + 1,
                            },
                            fieldState: { error },
                        }) => (
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="name-step"
                                label="Ordem"
                                type="number"
                                onChange={onChange}
                                value={value}
                                error={Boolean(error)}
                                helperText={
                                    error
                                        ? error.message
                                        : "Este campo define a ordem do passo"
                                }
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                            />
                        )}
                    />
                </ContentInput>

                <ContentButton>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        loading={loading}
                    >
                        {projectSelected ? "Editar" : "Adicionar"}
                    </LoadingButton>
                </ContentButton>
            </Container>
        </Modal>
    );
}
