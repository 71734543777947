import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper";
import { useAuth } from "../../contexts/contexts";
import { menuItems } from "./menuItems";
import { SettingsPageStyle } from "./styles";

function SettingsPage() {
    const navigate = useNavigate();

    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("configuracoes.visualizar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);

    return (
        <PageWrapper>
            <SettingsPageStyle>
                {menuItems?.map(({ icon, text, tittle, url, permission }) => (
                    <If key={tittle} condition={hasPermissions(permission)}>
                        <div className="cardButtons">
                            <div className="topCardButton">
                                {icon}
                                <p style={{ color: "--primary" }}>{tittle}</p>
                            </div>
                            <p className="textButton">{text}</p>
                            <hr />
                            <div className="linkContainer">
                                <Button
                                    onClick={() => navigate(`/settings${url}`)}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                >
                                    Configurar
                                </Button>
                            </div>
                        </div>
                    </If>
                ))}
            </SettingsPageStyle>
        </PageWrapper>
    );
}

export default SettingsPage;
