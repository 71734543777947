import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import SaveIcon from "@mui/icons-material/Save";
import Chip, { ChipProps } from "@mui/material/Chip";

const PAGO = 1;
const PENDENTE = 2;
const CANCELADO = 3;

interface Props {
    id: number;
}

export function ProposalPaymentStatusChip({ id }: Props) {
    let label: string;
    let color: ChipProps["color"];
    let icon: any;

    switch (id) {
        case PAGO:
            label = "recebido";
            color = "success";
            icon = <AttachMoneyIcon fontSize="small" />;
            break;
        case PENDENTE:
            label = "aguardando";
            color = "warning";
            icon = <SaveIcon fontSize="small" />;
            break;
        case CANCELADO:
            label = "cancelado";
            color = "error";
            icon = <CancelIcon fontSize="small" />;
            break;
        default:
            label = "Desconhecido";
            color = "default";
            icon = <DoDisturbOnIcon fontSize="small" />;
    }

    return (
        <div
            style={{
                width: "100px",
            }}
        >
            <Chip
                label={label}
                color={color}
                variant="outlined"
                size="small"
                sx={{
                    width: "100%",
                }}
            />
        </div>
    );
}
