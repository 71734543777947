import { AlertColor, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalProposalInvoiceStyle } from "./styles";

interface Props {
    openProposalInvoiceModal: boolean;
    handleCloseProposalInvoiceModal: () => void;
    selectedRowProposal: GridRowParams["row"];
}

function ModalProposalInvoice({
    openProposalInvoiceModal,
    handleCloseProposalInvoiceModal,
    selectedRowProposal,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] = useState<
        AlertColor | undefined
    >();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function editImplementationBlockState() {
        setIsLoadingButton(true);
        api.post(`/serviceInvoice/proposal`, {
            proposalId: selectedRowProposal.id,
        })
            .then(() => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(
                    "Solicitação concluída, ela ficará disponível no menu de notas fiscais"
                );
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseProposalInvoiceModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openProposalInvoiceModal}
            onClose={handleCloseProposalInvoiceModal}
        >
            <ModalProposalInvoiceStyle>
                <Box>
                    <p className="topModalText">Emitir nota fiscal?</p>
                    <form name="editFormModal">
                        <div className="buttonsContainer">
                            <ButtonGeneric
                                onclick={() => {
                                    handleCloseProposalInvoiceModal();
                                }}
                                buttonColor="var(--cancel)"
                                text="CANCELAR"
                            />
                            <PreloadButton
                                colorText="white"
                                background={
                                    selectedRowProposal.implementation
                                        ?.is_active === 1
                                        ? `var(--delete)`
                                        : `var(--confirm)`
                                }
                                loading={isLoadingButton}
                                text="Confirmar"
                                type="submit"
                                onClick={() => {
                                    editImplementationBlockState();
                                }}
                                sx={{ minWidth: "140px" }}
                            />
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </Box>
            </ModalProposalInvoiceStyle>
        </Modal>
    );
}
export default ModalProposalInvoice;
