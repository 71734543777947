import { AxiosError } from "axios";
import { Person } from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HeaderPage } from "../../components/Header/HeaderPage";
import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";
import FinancialContent from "./Financial/FinancialContent";
import GeneralDataContent from "./GeneralData/GeneralDataContent";
import { LogCustomer } from "./Log/LogCustomer";
import NotesCustomer from "./NotesCustomer";
import PlanDetailsContent from "./Plan/PlanDetailsContent";
import { AppDriverReport } from "./ReportDrivers/AppDriverReport";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { TabsContent } from "./styles";
import { CustomersProps } from "./types";

function CustomerDetailsPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const [isLoading, setIsLoading] = useState(true);

    const [currentCustomer, setCurrentCustomer] = useState<CustomersProps>();

    // PRECISO SABER ESSE VALOR PARA FAZER A REQUISIÇÃO DOS DETALHES DA FATURA
    const [currentAccount, setCurrentAccount] = useState();

    const getCustomer = async () => {
        try {
            setIsLoading(true);
            const responseCustomer = await api.get(`customers/${myParam}`);

            if (responseCustomer.status) {
                setCurrentCustomer(responseCustomer.data.response);
                setCurrentAccount(responseCustomer?.data.response.account_id);
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (
            hasPermissions("clientes.detalhes.visualizar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error", { replace: true });
        }

        getCustomer();
    }, [permissions]);

    const [currentTab, setCurrentTab] = useState<string>("1");

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <TabContext value={currentTab}>
                    <Box
                        sx={{
                            width: "100%",
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TabsContent>
                            <div
                                style={{
                                    margin: "15px 0px",
                                }}
                            >
                                <HeaderPage
                                    title={currentCustomer?.name ?? ""}
                                    subtitle={
                                        currentCustomer?.holder?.full_name ??
                                        currentCustomer?.holder_name ??
                                        ""
                                    }
                                    icon={<Person sx={{ color: "#fff" }} />}
                                />
                            </div>
                            <TabList
                                allowScrollButtonsMobile
                                variant="scrollable"
                                orientation="horizontal"
                                aria-label="lab API tabs example"
                            >
                                <Tab
                                    onClick={() => setCurrentTab("1")}
                                    label="Dados Gerais"
                                    value="1"
                                />
                                <Tab
                                    onClick={() => setCurrentTab("2")}
                                    label="Plano"
                                    value="2"
                                />
                                <Tab
                                    onClick={() => setCurrentTab("3")}
                                    label="Financeiro"
                                    value="3"
                                />
                                <Tab
                                    onClick={() => setCurrentTab("5")}
                                    label="Relatórios"
                                    value="5"
                                />
                                <Tab
                                    onClick={() => setCurrentTab("4")}
                                    label="Observações"
                                    value="4"
                                />

                                {/* {hasPermissions(
                                        "cliente.config.firebase"
                                    ) ? (
                                        <Tab
                                            onClick={() => setCurrentTab("6")}
                                            label="Configurações gerais"
                                            value="6"
                                        />
                                    ) : (
                                        ""
                                    )} */}

                                <Tab
                                    onClick={() => setCurrentTab("7")}
                                    label="Logs"
                                    value="7"
                                />
                            </TabList>
                            <TabPanel value="1">
                                <GeneralDataContent
                                    currentCustomer={currentCustomer}
                                    setCurrentCustomer={setCurrentCustomer}
                                    myParam={myParam}
                                    getCustomer={getCustomer}
                                />
                            </TabPanel>
                            <TabPanel value="2">
                                <PlanDetailsContent
                                    currentCustomer={currentCustomer}
                                />
                            </TabPanel>
                            <TabPanel value="3">
                                <FinancialContent
                                    currentAccountId={currentAccount}
                                    currentCustomer={currentCustomer}
                                    myParam={myParam || ""}
                                    setCurrentCustomer={setCurrentCustomer}
                                />
                            </TabPanel>
                            <TabPanel value="4">
                                <NotesCustomer myParam={myParam || ""} />
                            </TabPanel>
                            <TabPanel value="5">
                                <AppDriverReport
                                    customer={Number(myParam) || 1}
                                />
                            </TabPanel>
                            {/* <TabPanel value="6">
                                    <ConfigGeneralCustomer
                                        statusCustomer={
                                            currentCustomer?.customer_status_id
                                        }
                                        firebaseConfig={
                                            currentCustomer?.customer_firebase_config
                                        }
                                    />
                                </TabPanel> */}
                            <TabPanel value="7">
                                <LogCustomer />
                            </TabPanel>
                        </TabsContent>
                    </Box>
                </TabContext>
            )}
        </PageWrapper>
    );
}

export default CustomerDetailsPage;
