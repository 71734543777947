import styled from "styled-components";

export const ModalSignatoryPartnersStyle = styled.div`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-items: center;
    text-align: center;

    .inputsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .verySmallInput {
        max-width: 100px;
    }

    .smallInput {
        min-width: 224px;
    }

    .mediumInput {
        min-width: 100%;
    }

    .bigInput {
        min-width: 450px;
    }

    .hugeInput {
        min-width: 709px;
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .buttonsContainer button {
        min-width: 180px;
    }

    @media (max-width: 500px) {
        .cancelButton {
            width: 80px;
        }
        .verySmallInput {
            min-width: 350px;
        }

        .smallInput {
            min-width: 350px;
        }

        .mediumInput {
            min-width: 350px;
        }

        .bigInput {
            min-width: 350px;
        }

        .hugeInput {
            min-width: 350px;
        }

        .fullWidthInput {
            min-width: 350px;
        }
        .buttonsContainer button {
            min-width: 100px;
        }
        @media (max-width: 400px) {
            .verySmallInput {
                min-width: 250px;
            }

            .smallInput {
                min-width: 250px;
            }

            .mediumInput {
                min-width: 250px;
            }

            .bigInput {
                min-width: 250px;
            }

            .hugeInput {
                min-width: 250px;
            }

            .fullWidthInput {
                min-width: 250px;
            }
        }
    }
`;
