/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Add,
    CalendarMonth,
    CheckCircle,
    Delete,
    LocationCity,
    RadioButtonUnchecked,
    RememberMe,
} from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    AlertColor,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ButtonGeneric from "../../components/ButtonGeneric";
import InputGeneric from "../../components/InputGeneric";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper/index";
import PreloadButton from "../../components/PreloadButton";
import SaveButton from "../../components/SaveButton";
import ModalSignatoryPartners from "../../components/SignatoryPartnersModal";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { documentMask } from "../../helpers/document";
import { phoneMask } from "../../helpers/phone";
import { api, urlViaCep } from "../../services/api";
import { ContractsProps } from "../ContractsPage";
import { CustomersCategoriesProps } from "../CustomersCategoriesPage";
import { GraceDaysProps } from "../GraceDaysPage";
import { LeadsSourceProps } from "../LeadsSourcePage";
import { FormProposalEditPage } from "./styles";

type holderFormTypeValues = {
    // TITULAR
    fullName?: string;
    full_name: string;
    name: string;
    email?: string;
    phone?: string;
    document: string;
    holderLevel: string;
    zipCode: string;
    street?: string;
    number?: string;
    city?: string;
    state?: string;
    neighborhood?: string;
    complement?: string;
    country?: string;
    // PLANOS
    plan: string;
    price: string;
    subscriptionValue: string;
    periodInMonths: number;
    driversQuantity: number;
    valuePerSurplusDriver: string;
    cities: number;
    surplusValueCeiling: string;
    priceImplementation: string;
    graceDay: string;
    installment: string;
    // CONTRATO
    implementations: string;
    contract: string;
    urlLink: string;
    dueDate: string;
    proposalInformations: string;
    customerCategory: string;
    lead: string;
    discountCoupon: string;
};

type holderDataTypes = {
    full_name?: string;
    name: string;
    email: string;
    phone: string;
    document: string;
    holder_level_id: string;
    address: {
        zip_code: string;
        street: string;
        street_number: string;
        city: string;
        state: string;
        neighborhood?: string;
        complement: string;
        country: string;
    };
};

type plansDataTypes = {
    id: number;
    standard_payment_price: number;
    subscription_standard_payment_price: number;
    recurring_payment_price: number;
    subscription_recurring_payment_price: number;
    period_in_months: number;
    mobility_plan: {
        drivers_quantity: number;
        value_per_surplus_driver: number;
        cities: number;
        surplus_value_ceiling: number;
    };
    installment_standard: number;
    installment_recurring: number;
    implementation_plans: ImplementationPlan[];
};

interface Implementation {
    description: string;
}

interface ImplementationPlan {
    implementation_id: number;
    implementations: Implementation[];
}

type extraImplementationsProps = {
    implementation_id: number;
    price: number;
    implementation: {
        description: string;
        is_active: number;
    };
};

const holderFormSchema = yup.object().shape({
    fullName: yup.string().required("Nome é obrigatório"),
    name: yup.string().required("Nome é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    phone: yup.string().min(14).required(),
    document: yup.string().min(14).max(18).required(),
    holderLevel: yup.string().required(),
    zipCode: yup.string().required(),
    street: yup.string().required(),
    number: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    neighborhood: yup.string().required(),
    complement: yup.string(),
    country: yup.string().required(),
    plan: yup.string().required(),
    price: yup.string(),
    subscriptionValue: yup.string(),
    periodInMonths: yup.number(),
    driversQuantity: yup.number(),
    valuePerSurplusDriver: yup.string(),
    surplusValueCeiling: yup.string(),
    cities: yup.number(),
    graceDay: yup.string().required(),
    implementations: yup.string(),
    priceImplementation: yup.string(),
    contract: yup.string().required(),
    urlLink: yup.string(),
    dueDate: yup.string().required(),
    installment: yup.string().required(),
    proposalInformations: yup.string().required(),
    customerCategory: yup.string().required(),
    lead: yup.string().required(),
    discountCoupon: yup.string(),
});

function ProposalEditPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    // BOTÃO DE SALVAR PROPOSTA
    const [success, setSuccess] = useState(false);
    const sxSavebutton = {
        success,
    };

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [dataHoldersList, setDataHoldersList] = useState<holderDataTypes[]>(
        []
    );

    const [selectPlanOptions, setSelectPlanOptions] = useState([]);

    const [currentTab, setCurrentTab] = useState("");

    useEffect(() => {
        if (
            hasPermissions("propostas.editar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
        api.get("/holders/address")
            .then((response) => {
                setDataHoldersList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, [permissions]);

    const [selectHolderTypeOptions, setSelectHolderTypeOptions] = useState([]);
    const {
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useForm<holderFormTypeValues>({
        resolver: yupResolver(holderFormSchema),
    });

    const handleChangeSelectedHolderTypeOption = (event: SelectChangeEvent) => {
        setValue("holderLevel", event.target.value as string);
    };

    const handleChangeSelectedGracePeriodOption = (
        event: SelectChangeEvent
    ) => {
        setValue("graceDay", event.target.value as string);
    };

    const [dataGraceDaysList, setDataGraceDaysList] = useState<
        GraceDaysProps[]
    >([]);

    useEffect(() => {
        api.get("graceDay")
            .then((response) => {
                setDataGraceDaysList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const [installmentsValue, setInstallmentsValue] = useState<number>(0);

    const handleChangeSelectedInstallmentOption = (
        event: SelectChangeEvent
    ) => {
        setValue("installment", event.target.value as string);
    };
    const [dataPlansList, setDataPlansList] = useState<plansDataTypes[]>([]);

    // ATUALIZA OS VALORES DOS PLANOS E MUDA OS VALORES DOS INPUTS DE ACORDO COM A FORMA DE PAGAMENTO SELECIONADA

    const [
        listImplementationsSelectedPlan,
        setListImplementationsSelectedPlan,
    ] = useState<ImplementationPlan[]>([]);

    const [activationPlanPrice, setActivationPlanPrice] = useState<number>(0);

    const [implementationsList, setImplementationsList] = useState<
        extraImplementationsProps[]
    >([]);

    const [filteredListImplementations, setFilteredListImplementations] =
        useState<extraImplementationsProps[]>([]);

    function filterListImplementations() {
        setFilteredListImplementations(
            implementationsList.filter((item) => {
                return !listImplementationsSelectedPlan.some((otherItem) => {
                    return (
                        item.implementation_id === otherItem.implementation_id
                    );
                });
            })
        );
    }

    useEffect(() => {
        filterListImplementations();
    }, [listImplementationsSelectedPlan]);

    const [selectedExtraImplementation, setSelectedExtraImplementation] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        useState<any>({});

    const [extraImplementationsSelected, setExtraImplementationsSelected] =
        useState<extraImplementationsProps[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [currentPlan, setCurrentPlan] = useState<any>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeSelectedPlanOption = (event: any) => {
        const valor = Number(event.target.value);
        setCurrentPlan(
            dataPlansList.find((item: plansDataTypes) => item.id === valor)
        );
        setValue("plan", event.target.value as string);
    };

    // FUNÇÃO QUE ATUALIZA OS VALORES DE ACORDO COM O PLANO SELECIONADO
    const handleCheckAndChangeValuesToCurrentPlanEffect = () => {
        const event = { target: { value: getValues("plan") } };

        setSelectedExtraImplementation({});
        setValue("implementations", "");
        setListImplementationsSelectedPlan(currentPlan?.implementation_plans);
        setExtraImplementationsSelected([]);
        setValue("priceImplementation", "");
        setValue("periodInMonths", currentPlan?.period_in_months);
        setValue(
            "driversQuantity",
            currentPlan?.mobility_plan.drivers_quantity
        );
        setValue(
            "valuePerSurplusDriver",
            formatCurrencyToShow(
                currentPlan?.mobility_plan.value_per_surplus_driver
            )
        );
        setValue(
            "surplusValueCeiling",
            formatCurrencyToShow(
                currentPlan?.mobility_plan.surplus_value_ceiling
            )
        );
        setValue("cities", currentPlan?.mobility_plan.cities);

        handleChangeSelectedPlanOption(event);
    };

    // FUNÇÃO QUE ATUALIZA OS VALORES DE ACORDO COM O MÉTODO DE PAGAMENTO SELECIONADO
    const handleCheckAndChangeValuesCurrentTabEffect = () => {
        if (currentPlan && currentTab === "3") {
            setActivationPlanPrice(currentPlan.standard_payment_price);
            setValue(
                "subscriptionValue",
                formatCurrencyToShow(
                    currentPlan.subscription_standard_payment_price
                )
            );
            setValue("installment", "");
            setValue(
                "price",
                formatCurrencyToShow(currentPlan.standard_payment_price)
            );
            setInstallmentsValue(currentPlan.installment_standard);
        } else if (currentPlan && (currentTab === "1" || currentTab === "2")) {
            setActivationPlanPrice(currentPlan.recurring_payment_price);
            setValue(
                "subscriptionValue",
                formatCurrencyToShow(
                    currentPlan.subscription_recurring_payment_price
                )
            );
            setValue("installment", "");
            setValue(
                "price",
                formatCurrencyToShow(currentPlan.recurring_payment_price)
            );
            setInstallmentsValue(currentPlan.installment_recurring);
        }
    };

    useEffect(() => {
        handleCheckAndChangeValuesCurrentTabEffect();
    }, [dataPlansList, currentTab, currentPlan]);

    const handleChangeSelectedImplementationOption = (
        event: SelectChangeEvent
    ) => {
        const valor = Number(event.target.value);

        const dataItem = implementationsList.find(
            (item: { implementation_id: number }) =>
                item.implementation_id === valor
        );
        if (dataItem) {
            setValue(
                "priceImplementation",
                formatCurrencyToShow(dataItem.price)
            );
            setSelectedExtraImplementation(dataItem);
            setValue("implementations", event.target.value as string);
        }
    };

    const handleChangeSelectedContractOption = (event: SelectChangeEvent) => {
        setValue("contract", event.target.value as string);
    };

    const handleChangeSelectedCustomerCategoryOption = (
        event: SelectChangeEvent
    ) => {
        setValue("customerCategory", event.target.value);
    };

    const handleChangeSelectedLeadOption = (event: SelectChangeEvent) => {
        setValue("lead", event.target.value as string);
    };

    const [fullNameLabelInput, setFullNameLabelInput] =
        useState("Nome Completo");
    const [nameLabelInput, setNameLabelInput] = useState("Apelido");

    useEffect(() => {
        api.get("holderLevels/all")
            .then((response) => {
                setSelectHolderTypeOptions(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const [dataLeadsSourceList, setDataLeadsSourceList] = useState<
        LeadsSourceProps[]
    >([]);

    useEffect(() => {
        api.get("leadSource")
            .then((response) => {
                setDataLeadsSourceList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const getAndSetAddress = async () => {
        urlViaCep.get(`/${getValues("zipCode")}/json/`).then((response) => {
            setValue("street", response.data.logradouro);
            setValue("city", response.data.localidade);
            setValue("state", response.data.uf);
            setValue("neighborhood", response.data.bairro);
            setValue("country", "Brasil");
        });
    };

    useEffect(() => {
        api.get("/implementations/prices/all")
            .then((response) => {
                setImplementationsList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const [dataContractsList, setDataContractsList] = useState<
        ContractsProps[]
    >([]);

    useEffect(() => {
        api.get("/contracts")
            .then((response) => {
                setDataContractsList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const [dataCustomersCategoriesList, setDataCustomersCategoriesList] =
        useState<CustomersCategoriesProps[]>([]);

    useEffect(() => {
        api.get("/customersCategory")
            .then((response) => {
                setDataCustomersCategoriesList(response.data.response);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    function handleChangePhoneMask(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setValue("phone", phoneMask(value));
    }

    function handleChangeDocumentMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue("document", documentMask(value));
    }

    const zipCodeMask = (value: string) => {
        let newValue = value.replace(/\D/g, "");
        newValue = newValue.replace(/(\d{5})(\d)/, "$1-$2");
        return newValue;
    };

    function handleChangeZipCodeMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        setValue("zipCode", zipCodeMask(value));
    }

    function handleChangePriceMask(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue("price", formatCurrencyToShow(valueNumberWithTwoDecimals));
    }

    function handleChangeSubscriptionValueMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "subscriptionValue",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeValuePerSurplusDriverMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "valuePerSurplusDriver",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    function handleChangeSurplusValueCeilingMask(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const { value } = event.target;
        const valueNumber = +value.replace(/\D/g, "");
        const valueNumberWithTwoDecimals = valueNumber / 100;
        setValue(
            "surplusValueCeiling",
            formatCurrencyToShow(valueNumberWithTwoDecimals)
        );
    }

    // FUNÇÕES PARA ABRIR E FECHAR O MODAL PARA ADICIONAR SIGNATÁRIOS
    const [openSignatoryPartnersModal, setOpenSignatoryPartnersModal] =
        useState(false);
    const handleCloseSignatoryPartnersModal = () => {
        setOpenSignatoryPartnersModal(false);
    };

    // LISTA DE SIGNATÁRIOS
    const [signatoryPartnersList, setSignatoryPartnersList] = useState<
        holderFormTypeValues[]
    >([]);

    // DELETA O SIGNATÁRIO SELECIONADO
    const handleRemoveSignatory = (index: number) => {
        setSignatoryPartnersList(
            signatoryPartnersList.filter((_: unknown, i: number) => i !== index)
        );
    };

    // REQUISIÇÃO PARA OS PLANOS E REQUISIÇÃO DE ATUALIZAR OS CAMPOS DO FORMULÁRIO PARA A PROPOSTA SELECIONADA PARA EDITAR
    const [currentProposal, setCurrentProposal] = useState();
    useEffect(() => {
        api.get("/plans/details")
            .then((response) => {
                setDataPlansList(response.data.response);
                setSelectPlanOptions(response.data.response);
            })
            .then(() => {
                api.get(`proposal/details/${myParam}`)
                    .then((response) => {
                        setIsLoading(false);
                        setCurrentProposal(response.data.response[0]);
                        setValue(
                            "document",
                            documentMask(
                                response.data.response[0].holder.document
                            )
                        );
                        setValue(
                            "fullName",
                            response.data.response[0].holder.full_name
                        );
                        setValue("name", response.data.response[0].holder.name);
                        setValue(
                            "email",
                            response.data.response[0].holder.email
                        );
                        setValue(
                            "phone",
                            phoneMask(
                                response.data.response[0].holder.phone.toString()
                            )
                        );
                        setValue(
                            "holderLevel",
                            response.data.response[0].holder.holder_level_id
                        );
                        setValue(
                            "number",
                            response.data.response[0].holder.address
                                .street_number
                        );
                        setValue(
                            "neighborhood",
                            response.data.response[0].holder.address
                                .neighborhood
                        );
                        setValue(
                            "city",
                            response.data.response[0].holder.address.city
                        );
                        setValue(
                            "state",
                            response.data.response[0].holder.address.state
                        );
                        setValue(
                            "country",
                            response.data.response[0].holder.address.country
                        );
                        setValue(
                            "zipCode",
                            zipCodeMask(
                                response.data.response[0].holder.address
                                    .zip_code
                            )
                        );
                        setValue(
                            "street",
                            response.data.response[0].holder.address.street
                        );
                        if (
                            response.data.response[0].holder.address
                                .complement !== null
                        ) {
                            setValue(
                                "complement",
                                response.data.response[0].holder.address
                                    .complement
                            );
                        }

                        setValue(
                            "contract",
                            response.data.response[0].contract_id
                        );

                        setValue(
                            "plan",
                            response.data.response[0].app_plan_id.toString()
                        );

                        setValue(
                            "graceDay",
                            response.data.response[0].grace_day_id
                        );

                        setValue(
                            "proposalInformations",
                            response.data.response[0].proposal_informations
                        );

                        setCurrentTab(
                            response.data.response[0].payment[0].form_payment.type_form_payment_type_id.toString()
                        );

                        setValue(
                            "dueDate",
                            response.data.response[0].payment[0].due_date.slice(
                                0,
                                10
                            )
                        );

                        setValue(
                            "lead",
                            response.data.response[0].lead_source_id.toString()
                        );

                        setValue(
                            "customerCategory",
                            response.data.response[0].customer_category_id.toString()
                        );

                        setValue(
                            "installment",
                            response.data.response[0].payment[0].form_payment.number_installments.toString()
                        );
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    useEffect(() => {
        handleCheckAndChangeValuesToCurrentPlanEffect();
    }, [dataPlansList, currentPlan, currentProposal]);

    const handleChangeInputsAndGetDocumentValueLabel = () => {
        if (getValues("document").length > 14) {
            setFullNameLabelInput("Razão Social");
            setNameLabelInput("Nome de Fantasia");
        } else {
            setFullNameLabelInput("Nome Completo");
            setNameLabelInput("Apelido");
        }
        const currentDocument = getValues("document")
            .replaceAll(".", "")
            .replaceAll("-", "")
            .replaceAll("/", "");
        const dataItem = dataHoldersList.find(
            (item: holderDataTypes) => item.document === currentDocument
        );
        if (dataItem) {
            setValue("fullName", dataItem.full_name);
            setValue("name", dataItem.name);
            setValue("email", dataItem.email);
            setValue("phone", phoneMask(dataItem.phone));
            setValue("holderLevel", dataItem.holder_level_id);
            setValue("zipCode", zipCodeMask(dataItem.address.zip_code));
            setValue("street", dataItem.address.street);
            setValue("number", dataItem.address.street_number);
            setValue("neighborhood", dataItem.address.neighborhood);
            setValue("city", dataItem.address.city);
            setValue("state", dataItem.address.state);
            setValue("country", dataItem.address.country);
            setValue("complement", dataItem.address?.complement);
        }
    };

    const handleRemove = (index: number) => {
        setExtraImplementationsSelected(
            extraImplementationsSelected.filter(
                (_: unknown, i: number) => i !== index
            )
        );
    };

    const ImplementationsExtrasSum = extraImplementationsSelected.reduce(
        (total, item) => total + item.price,
        0
    );

    const [saveAndSendContract, setSaveAndSendContract] = useState(false);

    const createOrSaveProposal: SubmitHandler<holderFormTypeValues> = async (
        formValues
    ) => {
        setIsLoadingButton(true);
        api.put(`/proposal/${myParam}`, {
            full_name: formValues.fullName,
            name: formValues.name,
            document: formValues.document?.replaceAll(/\D/g, ""),
            email: formValues.email,
            phone: Number(formValues.phone?.replaceAll(/\D/g, "")),
            holder_level_id: Number(formValues.holderLevel),
            street: formValues.street,
            street_number: formValues.number,
            complement: formValues.complement,
            neighborhood: formValues.neighborhood,
            city: formValues.city,
            state: formValues.state,
            country: formValues.country,
            zip_code: formValues.zipCode.replace("-", ""),

            plan_id: Number(formValues.plan),
            grace_day_id: Number(formValues.graceDay),
            contract_id: Number(formValues.contract),
            lead_source_id: Number(formValues.lead),
            proposal_informations: formValues.proposalInformations,
            customer_category_id: Number(formValues.customerCategory),

            submission_proposal: saveAndSendContract,
            form_payment: {
                due_date: formValues.dueDate,
                number_installments: Number(formValues.installment),
                type_form_payment_type_id: Number(currentTab),
            },
            implementations_extras: [
                extraImplementationsSelected?.map(
                    ({ implementation_id }: { implementation_id: number }) =>
                        Number(implementation_id)
                ),
            ],
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
                setSuccess(true);
            })
            .then(() => {
                setTimeout(() => {
                    navigate("/comercial/proposal");
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .catch((response) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(response.response.data.response.name);
                setIsLoadingButton(false);
            });
    };

    return (
        <PageWrapper>
            {isLoading ? (
                <Loading />
            ) : (
                <FormProposalEditPage>
                    <div className="topFormPage">
                        <h2>Nova Proposta Comercial</h2>
                    </div>
                    <form onSubmit={handleSubmit(createOrSaveProposal)}>
                        <h3>Dados do Titular</h3>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="document"
                                render={({ field: { value = "", ref } }) => (
                                    <InputGeneric
                                        onBlur={
                                            handleChangeInputsAndGetDocumentValueLabel
                                        }
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="CPF/CNPJ"
                                        error={!!errors.document}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangeDocumentMask(event)}
                                        ref={ref}
                                        inputProps={{ maxLength: 18 }}
                                        autoFocus
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="fullName"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="hugeInput"
                                        label={fullNameLabelInput}
                                        error={!!errors.fullName}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="name"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label={nameLabelInput}
                                        error={!!errors.name}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="email"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Digite o email"
                                        error={!!errors.email}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="phone"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangePhoneMask(event)}
                                        inputProps={{ maxLength: 14 }}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Telefone para Contato"
                                        error={!!errors.phone}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="holderLevel"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Tipo de Titular
                                        </InputLabel>
                                        <Select
                                            error={!!errors.holderLevel}
                                            className="mediumInput"
                                            size="small"
                                            placeholder="teste"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedHolderTypeOption
                                            }
                                            label="Tipo de Titular"
                                        >
                                            {selectHolderTypeOptions?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: string;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <h3>Endereço</h3>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="zipCode"
                                render={({ field: { value = "", ref } }) => (
                                    <InputGeneric
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangeZipCodeMask(event)}
                                        onBlur={async () => {
                                            if (
                                                getValues("zipCode").length > 7
                                            ) {
                                                getAndSetAddress();
                                            }
                                        }}
                                        inputProps={{ maxLength: 9 }}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Digite o Cep"
                                        error={!!errors.zipCode}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="street"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="hugeInput"
                                        label="Rua/Logradouro"
                                        error={!!errors.street}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="number"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="verySmallInput"
                                        label="Número"
                                        error={!!errors.number}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="neighborhood"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Bairro"
                                        error={!!errors.neighborhood}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="city"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Cidade"
                                        error={!!errors.city}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="state"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        variant="outlined"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Estado"
                                        error={!!errors.state}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="country"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        variant="outlined"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="País"
                                        error={!!errors.country}
                                        ref={ref}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="complement"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        variant="outlined"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="hugeInput"
                                        label="Complemento"
                                        error={!!errors.complement}
                                        ref={ref}
                                    />
                                )}
                            />
                        </div>
                        <h3>Seleção de Contrato</h3>
                        <Controller
                            control={control}
                            name="contract"
                            render={({ field: { value = "" } }) => (
                                <FormControl sx={{ height: "56px" }}>
                                    <InputLabel
                                        size="small"
                                        id="demo-simple-select-label"
                                    >
                                        Selecione o Contrato
                                    </InputLabel>
                                    <Select
                                        error={!!errors.holderLevel}
                                        className="fullWidthInput"
                                        size="small"
                                        placeholder="teste"
                                        id="demo-simple-select"
                                        value={value}
                                        onChange={
                                            handleChangeSelectedContractOption
                                        }
                                        label="Selecione o Contrato"
                                    >
                                        {dataContractsList.map(
                                            ({
                                                id,
                                                title,
                                            }: {
                                                id: number;
                                                title: string;
                                            }) => (
                                                <MenuItem key={id} value={id}>
                                                    {title}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <h3>Signatários</h3>
                        <div style={{ width: "100%" }}>
                            {signatoryPartnersList?.map(
                                (
                                    {
                                        full_name,
                                        document,
                                    }: holderFormTypeValues,
                                    index: number
                                ) => (
                                    <div
                                        className="signatoryListContainer"
                                        key={document}
                                    >
                                        <p>{full_name}</p>
                                        <p>{documentMask(document)}</p>
                                        <Delete
                                            onClick={() => {
                                                handleRemoveSignatory(index);
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                color: "var(--delete)",
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                        <ButtonGeneric
                            onclick={() => {
                                setOpenSignatoryPartnersModal(true);
                            }}
                            buttonColor="var(--primary)"
                            text="Adicionar"
                            icon={<Add />}
                            className="addButton"
                        />
                        <ModalSignatoryPartners
                            openSignatoryPartnersModal={
                                openSignatoryPartnersModal
                            }
                            handleCloseSignatoryPartnersModal={
                                handleCloseSignatoryPartnersModal
                            }
                            signatoryPartnersList={signatoryPartnersList}
                            setSignatoryPartnersList={setSignatoryPartnersList}
                        />
                        <h3>Plano Contratado</h3>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="plan"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Selecione o Plano
                                        </InputLabel>
                                        <Select
                                            error={!!errors.plan}
                                            className="bigInput"
                                            size="small"
                                            placeholder="teste"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedPlanOption
                                            }
                                            label="Selecione o Plano"
                                        >
                                            {selectPlanOptions?.map(
                                                ({
                                                    id,
                                                    description,
                                                }: {
                                                    id: string;
                                                    description: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {description}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="price"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(event) =>
                                            handleChangePriceMask(event)
                                        }
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Ativação"
                                        disabled
                                        error={!!errors.price}
                                        ref={ref}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="subscriptionValue"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeSubscriptionValueMask(
                                                event
                                            );
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Valor da Assinatura"
                                        disabled
                                        error={!!errors.subscriptionValue}
                                        ref={ref}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainerFourItens">
                            <Controller
                                control={control}
                                name="valuePerSurplusDriver"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeValuePerSurplusDriverMask(
                                                event
                                            );
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Valor por motorista excedente"
                                        disabled
                                        error={!!errors.valuePerSurplusDriver}
                                        ref={ref}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="driversQuantity"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Quantidade de motoristas"
                                        disabled
                                        error={!!errors.driversQuantity}
                                        ref={ref}
                                        inputProps={{
                                            min: 1,
                                        }}
                                        type="number"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <RememberMe />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="cities"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        type="number"
                                        label="Quantidade de Cidades"
                                        disabled
                                        error={!!errors.cities}
                                        ref={ref}
                                        inputProps={{
                                            min: 1,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCity />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="surplusValueCeiling"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleChangeSurplusValueCeilingMask(
                                                event
                                            );
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Valor máximo por excedentes"
                                        disabled
                                        error={!!errors.surplusValueCeiling}
                                        ref={ref}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="periodInMonths"
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value = "",
                                        ref,
                                    },
                                }) => (
                                    <InputGeneric
                                        onChange={onChange}
                                        inputProps={{ min: 1, max: 24 }}
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="bigInput"
                                        type="number"
                                        label="Validade do Plano em Meses"
                                        disabled
                                        placeholder="Valor máximo 24"
                                        error={!!errors.periodInMonths}
                                        ref={ref}
                                        // eslint-disable-next-line react/jsx-no-duplicate-props
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarMonth />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="graceDay"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Carência em Dias
                                        </InputLabel>
                                        <Select
                                            error={!!errors.graceDay}
                                            className="bigInput"
                                            size="small"
                                            placeholder="teste"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedGracePeriodOption
                                            }
                                            label="Carência em Dias"
                                        >
                                            {dataGraceDaysList?.map(
                                                ({
                                                    id,
                                                    days,
                                                }: {
                                                    id: number;
                                                    days: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {days}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <h3>Implementações Inclusas no Plano</h3>
                        <div className="implementationsIncludesContainer">
                            <ul className="includesImplementationsMaxHeight">
                                {listImplementationsSelectedPlan?.map(
                                    ({
                                        implementation_id,
                                        implementations,
                                    }: {
                                        implementation_id: number;
                                        implementations: {
                                            description: string;
                                        }[];
                                    }) => (
                                        <li
                                            key={implementation_id}
                                            value={
                                                implementations[0].description
                                            }
                                        >
                                            <div className="liContainer">
                                                <p>
                                                    {
                                                        implementations[0]
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <h3>Forma de Pagamento - Ativação</h3>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TabContext value={currentTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList className="tabsRadioButtonsContainer">
                                        <Tab
                                            onClick={() => setCurrentTab("1")}
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        className="iconTab"
                                                        style={{
                                                            marginRight:
                                                                "-25px",
                                                            marginBottom: "2px",
                                                        }}
                                                    >
                                                        {currentTab === "1" ? (
                                                            <CheckCircle
                                                                sx={{
                                                                    color: "var(--primary)",
                                                                }}
                                                            />
                                                        ) : (
                                                            <RadioButtonUnchecked />
                                                        )}
                                                    </ListItemIcon>
                                                    Link de Pagamento
                                                </div>
                                            }
                                            value="1"
                                        />
                                        <Tab
                                            onClick={() => {
                                                setCurrentTab("2");
                                            }}
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        className="iconTab"
                                                        style={{
                                                            marginRight:
                                                                "-25px",
                                                            marginBottom: "2px",
                                                        }}
                                                    >
                                                        {currentTab === "2" ? (
                                                            <CheckCircle
                                                                sx={{
                                                                    color: "var(--primary)",
                                                                }}
                                                            />
                                                        ) : (
                                                            <RadioButtonUnchecked />
                                                        )}
                                                    </ListItemIcon>
                                                    Recorrente
                                                </div>
                                            }
                                            value="2"
                                        />
                                        <Tab
                                            onClick={() => {
                                                setCurrentTab("3");
                                            }}
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        className="iconTab"
                                                        style={{
                                                            marginRight:
                                                                "-25px",
                                                            marginBottom: "2px",
                                                        }}
                                                    >
                                                        {currentTab === "3" ? (
                                                            <CheckCircle
                                                                sx={{
                                                                    color: "var(--primary)",
                                                                }}
                                                            />
                                                        ) : (
                                                            <RadioButtonUnchecked />
                                                        )}
                                                    </ListItemIcon>
                                                    Cartão de crédito - à vista
                                                    ou parcelado
                                                </div>
                                            }
                                            value="3"
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <div className="inputsContainer">
                                        <Controller
                                            control={control}
                                            name="urlLink"
                                            render={({
                                                field: {
                                                    onBlur,
                                                    onChange,
                                                    value = "",
                                                    ref,
                                                },
                                            }) => (
                                                <InputGeneric
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    size="small"
                                                    className="fullWidthInput"
                                                    label="Link de Pagamento"
                                                    error={!!errors.urlLink}
                                                    ref={ref}
                                                />
                                            )}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div className="inputsContainer">
                                        <Controller
                                            control={control}
                                            name="installment"
                                            render={({
                                                field: { value = "" },
                                            }) => (
                                                <FormControl
                                                    sx={{ height: "56px" }}
                                                >
                                                    <InputLabel
                                                        size="small"
                                                        id="demo-simple-select-label"
                                                    >
                                                        Quantidade de Parcelas
                                                    </InputLabel>
                                                    <Select
                                                        error={
                                                            !!errors.installment
                                                        }
                                                        className="bigInput"
                                                        size="small"
                                                        placeholder="teste"
                                                        id="demo-simple-select"
                                                        value={value}
                                                        onChange={
                                                            handleChangeSelectedInstallmentOption
                                                        }
                                                        label="Quantidade de Parcelas"
                                                    >
                                                        {Array(
                                                            installmentsValue +
                                                                1
                                                        )
                                                            .fill("")
                                                            .map(
                                                                (
                                                                    res,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                index
                                                                            }
                                                                            value={
                                                                                index >
                                                                                0
                                                                                    ? index
                                                                                    : undefined
                                                                            }
                                                                        >
                                                                            {index >
                                                                            0
                                                                                ? index
                                                                                : "Quantidade de Parcelas"}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name="dueDate"
                                            render={({
                                                field: {
                                                    onBlur,
                                                    onChange,
                                                    value = "",
                                                    ref,
                                                },
                                            }) => (
                                                <InputGeneric
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    size="small"
                                                    ref={ref}
                                                    value={value}
                                                    className="bigInput"
                                                    type="date"
                                                    label="Data de Vencimento"
                                                    error={!!errors.dueDate}
                                                    helperText={
                                                        !!errors.dueDate &&
                                                        "Selecione uma Data para confirmar o Vencimento"
                                                    }
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel value="3">
                                    <div className="inputsContainer">
                                        <Controller
                                            control={control}
                                            name="installment"
                                            render={({
                                                field: { value = "" },
                                            }) => (
                                                <FormControl
                                                    sx={{ height: "56px" }}
                                                >
                                                    <InputLabel
                                                        size="small"
                                                        id="demo-simple-select-label"
                                                    >
                                                        Quantidade de Parcelas
                                                    </InputLabel>
                                                    <Select
                                                        error={
                                                            !!errors.installment
                                                        }
                                                        className="bigInput"
                                                        size="small"
                                                        id="demo-simple-select"
                                                        value={value}
                                                        onChange={
                                                            handleChangeSelectedInstallmentOption
                                                        }
                                                        label="Quantidade de Parcelas"
                                                    >
                                                        {Array(
                                                            installmentsValue +
                                                                1
                                                        )
                                                            .fill("")
                                                            .map(
                                                                (
                                                                    res,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                index
                                                                            }
                                                                            value={
                                                                                index >
                                                                                0
                                                                                    ? index
                                                                                    : undefined
                                                                            }
                                                                        >
                                                                            {index >
                                                                            0
                                                                                ? index
                                                                                : "Quantidade de Parcelas"}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name="dueDate"
                                            render={({
                                                field: {
                                                    onBlur,
                                                    onChange,
                                                    value = "",
                                                    ref,
                                                },
                                            }) => (
                                                <InputGeneric
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    size="small"
                                                    ref={ref}
                                                    value={value}
                                                    className="bigInput"
                                                    type="date"
                                                    label="Data de Vencimento"
                                                    error={!!errors.dueDate}
                                                    helperText={
                                                        !!errors.dueDate &&
                                                        "Selecione uma Data para confirmar o Vencimento"
                                                    }
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                        <h3>Implementações Extras</h3>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="implementations"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Selecione a Implementação Extra
                                        </InputLabel>
                                        <Select
                                            error={!!errors.implementations}
                                            className="bigInput"
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedImplementationOption
                                            }
                                            label="Selecione a Implementação Extra"
                                        >
                                            {filteredListImplementations
                                                ?.filter(
                                                    (item) =>
                                                        item.price !== null
                                                )
                                                .filter(
                                                    (item) =>
                                                        item.implementation
                                                            .is_active === 1
                                                )
                                                ?.map(
                                                    ({
                                                        implementation_id,
                                                        implementation,
                                                    }: extraImplementationsProps) => (
                                                        <MenuItem
                                                            key={
                                                                implementation_id
                                                            }
                                                            value={
                                                                implementation_id
                                                            }
                                                        >
                                                            {
                                                                implementation.description
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="priceImplementation"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(event) =>
                                            handleChangePriceMask(event)
                                        }
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="smallInput"
                                        label="Ativação"
                                        disabled
                                        error={!!errors.price}
                                        ref={ref}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <ButtonGeneric
                                onclick={() => {
                                    if (
                                        extraImplementationsSelected.includes(
                                            selectedExtraImplementation
                                        )
                                    ) {
                                        setOpenToast(true);
                                        setToastRequisitionResult("error");
                                        setTextToast(
                                            "A implementação já foi selecionada!"
                                        );
                                        return;
                                    }
                                    setExtraImplementationsSelected((prev) => [
                                        ...prev,
                                        selectedExtraImplementation,
                                    ]);
                                }}
                                buttonColor="var(--primary)"
                                text="Adicionar"
                                disabled={
                                    Object.keys(selectedExtraImplementation)
                                        .length === 0
                                }
                                icon={<Add />}
                                className="addButton"
                            />
                            <div className="implementationsIncludesContainer">
                                <ul>
                                    {extraImplementationsSelected?.map(
                                        (
                                            {
                                                implementation_id,
                                                implementation,
                                                price,
                                            }: {
                                                implementation_id: number;
                                                price: number;
                                                implementation: {
                                                    description: string;
                                                };
                                            },
                                            index: number
                                        ) => (
                                            <li
                                                key={implementation_id}
                                                value={implementation_id}
                                            >
                                                <div className="liContainer">
                                                    <p>
                                                        {
                                                            implementation.description
                                                        }
                                                    </p>
                                                    <p>
                                                        R${" "}
                                                        {formatCurrencyToShow(
                                                            price
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "var(--delete)",
                                                        width: "8%",
                                                    }}
                                                >
                                                    <Delete
                                                        onClick={() => {
                                                            handleRemove(index);
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "var(--delete)",
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>

                        <h3>Observações da Proposta</h3>
                        <Controller
                            control={control}
                            name="proposalInformations"
                            render={({
                                field: { onBlur, onChange, value = "", ref },
                            }) => (
                                <InputGeneric
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    size="small"
                                    className="fullWidthInput"
                                    label="Informações Sobre a Proposta"
                                    error={!!errors.proposalInformations}
                                    ref={ref}
                                    multiline
                                    minRows="5"
                                    maxRows="6"
                                />
                            )}
                        />

                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="customerCategory"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Nível de Cliente
                                        </InputLabel>
                                        <Select
                                            error={!!errors.holderLevel}
                                            className="bigInput"
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedCustomerCategoryOption
                                            }
                                            label="Tipo de Titular"
                                        >
                                            {dataCustomersCategoriesList?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: number;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="lead"
                                render={({ field: { value = "" } }) => (
                                    <FormControl sx={{ height: "56px" }}>
                                        <InputLabel
                                            size="small"
                                            id="demo-simple-select-label"
                                        >
                                            Origem do Lead
                                        </InputLabel>
                                        <Select
                                            error={!!errors.lead}
                                            className="bigInput"
                                            size="small"
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={
                                                handleChangeSelectedLeadOption
                                            }
                                            label="Origem do Lead"
                                        >
                                            {dataLeadsSourceList?.map(
                                                ({
                                                    id,
                                                    name,
                                                }: {
                                                    id: number;
                                                    name: string;
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className="inputsContainer">
                            <Controller
                                control={control}
                                name="discountCoupon"
                                render={({
                                    field: { onBlur, value = "", ref },
                                }) => (
                                    <InputGeneric
                                        onChange={(event) =>
                                            handleChangePriceMask(event)
                                        }
                                        onBlur={onBlur}
                                        value={value}
                                        size="small"
                                        className="mediumInput"
                                        label="Cupom de Desconto"
                                        disabled
                                        error={!!errors.discountCoupon}
                                        ref={ref}
                                    />
                                )}
                            />
                            <ButtonGeneric
                                buttonColor="var(--primary)"
                                text="Adicionar"
                                icon={<Add />}
                                className="addButton"
                            />
                        </div>
                        <h3>Total da Proposta</h3>
                        <div className="valueProposalDetails">
                            <div className="rowValueProposalDetail">
                                <h4>Valor Ativação:</h4>
                                <h4>
                                    R${" "}
                                    {activationPlanPrice
                                        ? formatCurrencyToShow(
                                              activationPlanPrice
                                          )
                                        : "0,00"}
                                </h4>
                            </div>
                            <div className="rowValueProposalDetail">
                                <h4>Implementações extras:</h4>
                                <h4>
                                    R$
                                    {formatCurrencyToShow(
                                        ImplementationsExtrasSum
                                    ) || "0,00"}
                                </h4>
                            </div>
                            <div className="rowValueProposalDetail">
                                <h4>Descontos:</h4>
                                <h4>R$ 0,00</h4>
                            </div>
                            <div className="rowValueProposalDetail">
                                <h3>Valor Final:</h3>
                                <h3>
                                    R${" "}
                                    {activationPlanPrice
                                        ? formatCurrencyToShow(
                                              activationPlanPrice +
                                                  ImplementationsExtrasSum
                                          )
                                        : formatCurrencyToShow(
                                              ImplementationsExtrasSum || 0
                                          )}
                                </h3>
                            </div>
                        </div>

                        <div className="allButtonsContainer">
                            <div className="saveButtonContainer">
                                <SaveButton
                                    isLoadingButton={isLoadingButton}
                                    success={success}
                                    sx={sxSavebutton}
                                    onFocus={() => {
                                        setSaveAndSendContract(false);
                                    }}
                                />
                            </div>
                            <div className="buttonsContainer">
                                <ButtonGeneric
                                    onclick={() => {
                                        navigate("/comercial/proposal");
                                    }}
                                    buttonColor="var(--cancel)"
                                    text="VOLTAR"
                                />
                                <PreloadButton
                                    colorText="white"
                                    background="var(--confirm)"
                                    loading={isLoadingButton}
                                    text="ENVIAR PROPOSTA"
                                    type="submit"
                                    onFocus={() => {
                                        setSaveAndSendContract(true);
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </FormProposalEditPage>
            )}
        </PageWrapper>
    );
}
export default ProposalEditPage;
