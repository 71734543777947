import * as yup from "yup";

export const holderFormSchemaNational = yup.object().shape({
    fullName: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    phone: yup.string().required("Este campo é obrigatório"),
    document: yup.string().min(14).max(18).required("Este campo é obrigatório"),
    holderLevel: yup.string().required("Este campo é obrigatório"),
    zipCode: yup.string().required("Este campo é obrigatório"),
    street: yup.string().required("Este campo é obrigatório"),
    // ddd_phone: yup.string().required("Este campo obrigatório"),
    ddi: yup.string().required("Este campo obrigatório"),
    number: yup.string().required("Este campo é obrigatório"),
    city: yup.string().required("Este campo é obrigatório"),
    state: yup.string().required("Este campo é obrigatório"),
    neighborhood: yup.string().required("Este campo é obrigatório"),
    complement: yup.string(),
    country: yup.number().required("O campo país é obrigatório"),
    plan: yup.string().required("Este campo é obrigatório"),
    price: yup.string(),
    subscriptionValue: yup.string(),
    periodInMonths: yup.number(),
    driversQuantity: yup.number(),
    valuePerSurplusDriver: yup.string(),
    surplusValueCeiling: yup.string(),
    cities: yup.number(),
    graceDay: yup.string().required("Este campo é obrigatório"),
    implementations: yup.string(),
    priceImplementation: yup.string(),
    contract: yup.string().required("Este campo é obrigatório"),
    urlLink: yup.string(),
    entryAmount: yup.number().min(0),
    dueDate: yup
        .string()
        .required("Selecione uma Data para confirmar o Vencimento"),
    installment: yup.string(),
    proposalInformations: yup.string().required("Este campo é obrigatório"),
    customerCategory: yup.string().required("Este campo é obrigatório"),
    lead: yup.string().required("Este campo é obrigatório"),
    discountCoupon: yup.string().nullable(),
});

export const holderFormSchemaInternational = yup.object().shape({
    fullName: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    email: yup
        .string()
        .required("Email é obrigatório")
        .email("Insira um email válido"),
    phone: yup.string().required("Este campo é obrigatório"),
    document: yup.string().required("Este campo é obrigatório"),
    holderLevel: yup.string().required("Este campo é obrigatório"),
    zipCode: yup.string().required("Este campo é obrigatório"),
    street: yup.string().required("Este campo é obrigatório"),
    ddi: yup.string().required("Este campo obrigatório"),
    number: yup.string().required("Este campo é obrigatório"),
    city: yup.string().required("Este campo é obrigatório"),
    state: yup.string().required("Este campo é obrigatório"),
    neighborhood: yup.string().required("Este campo é obrigatório"),
    complement: yup.string(),
    entryAmount: yup.number().min(0),
    country: yup.string().required("O campo país é obrigatório"),
    plan: yup.string().required("Este campo é obrigatório"),
    price: yup.string(),
    subscriptionValue: yup.string(),
    periodInMonths: yup.number(),
    driversQuantity: yup.number(),
    valuePerSurplusDriver: yup.string(),
    surplusValueCeiling: yup.string(),
    cities: yup.number(),
    graceDay: yup.string().required("Este campo é obrigatório"),
    implementations: yup.string(),
    priceImplementation: yup.string(),
    contract: yup.string().required("Este campo é obrigatório"),
    urlLink: yup.string().required("Este campo é obrigatório"),
    dueDate: yup
        .string()
        .required("Selecione uma Data para confirmar o Vencimento"),
    installment: yup.string().required("Este campo é obrigatório"),
    proposalInformations: yup.string().required("Este campo é obrigatório"),
    customerCategory: yup.string().required("Este campo é obrigatório"),
    lead: yup.string().required("Este campo é obrigatório"),
    discountCoupon: yup.string().nullable(),
});
