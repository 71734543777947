import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Modal,
    TextField,
    Divider,
    FormControl,
    Select,
    FormHelperText,
    InputLabel,
    MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { maskCpfCnpj } from "../../helpers/cpfOrCnpjValidator";
import { phoneMask } from "../../helpers/phone";
import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import {
    Container,
    TitleModal,
    ContentButtonModal,
    ContentInput,
    Body,
    SubTitle,
} from "./styles";
import { ClusterListProps, SchemaForm, ModalEditCustomerProps } from "./types";
import { HolderProps } from "../../pages/ProposalRegisterPage/types";
import { FormatDefaultListProps } from "../../pages/CustomerCreatePage/types";
import SelectSearch from "../SelectSearch";
import { schemaModalInputs } from "./schema";

const mysql = 1;
const mongodb = 2;

function ModalEditCustomer({
    visible,
    setVisible,
    datasCustomer,
    getCustomer,
}: ModalEditCustomerProps) {
    const { handleSubmit, control, setValue } = useForm<SchemaForm>({
        resolver: yupResolver(schemaModalInputs),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [listClustersMongo, setListClustersMongo] = useState<
        ClusterListProps[]
    >([]);
    const [listClustersMysql, setListClustersMysql] = useState<
        ClusterListProps[]
    >([]);
    const [listHolder, setListHolder] = useState<FormatDefaultListProps[]>([]);
    const [loadingHolders, setLoadingHolders] = useState<boolean>(false);

    const getAllHolder = async (nameHolder: string) => {
        try {
            setLoadingHolders(true);
            const responseAllHolder = await api.get(
                `holders/all?full_name=${nameHolder}`
            );

            if (responseAllHolder.status) {
                const listHoldersFormated =
                    responseAllHolder.data.response.data.map(
                        (value: HolderProps) => {
                            return {
                                value: value.id,
                                label: value.full_name,
                            };
                        }
                    );

                setListHolder(listHoldersFormated);
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro pegar titulares");
        } finally {
            setLoadingHolders(false);
        }
    };

    const handleGetHolders = debounce((nameHolder: string) => {
        getAllHolder(nameHolder);
    }, 500);

    const getAllClusters = () => {
        const responseAllClustersMongo = api.get(
            `clustersDatabases/${mongodb}`
        );

        const responseAllClustersMysql = api.get(`clustersDatabases/${mysql}`);

        Promise.all([responseAllClustersMongo, responseAllClustersMysql]).then(
            (response) => {
                if (response[0].status) {
                    setListClustersMongo(response[0].data);
                }

                if (response[1].status) {
                    setListClustersMysql(response[1].data);
                }
            }
        );
    };

    const handleEditCustomer = async (data: SchemaForm) => {
        try {
            setLoading(true);
            const responseEditCustomer = await api.put(
                `customers/${datasCustomer?.id}`,
                {
                    name: data.nameCompany,
                    email: data.email,
                    phone: data.phone,
                    managed_database_mysql_id: data.id_cluster_mysql,
                    managed_database_mongo_id: data.id_cluster_mongo,
                    cloud: data.cloud,
                    holder_id: data.holder.value,
                    holder_name: data.holder.label,
                }
            );

            if (responseEditCustomer.status) {
                defaultToast("success", "Cliente atualizado com sucesso!");
                setVisible(false);
                getCustomer();
            }
        } catch (error) {
            console.error(error);
            defaultToast("error", "Erro ao editar cliente");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllClusters();
        setValue("cloud", datasCustomer?.cloud ?? "");
    }, []);

    useEffect(() => {
        if (datasCustomer) {
            setValue("nameCompany", datasCustomer.name);
            setValue("phone", datasCustomer.phone);
            setValue("email", datasCustomer.email);
            setValue("holder", {
                label: datasCustomer.holder.full_name,
                value: datasCustomer.holder.id,
            });

            if (datasCustomer.customer_database_mongo) {
                if (datasCustomer.customer_database_mongo.managed_database_id) {
                    console.log(
                        "MONGODB",
                        datasCustomer.customer_database_mongo
                            .managed_database_id
                    );

                    setValue(
                        "id_cluster_mongo",
                        datasCustomer.customer_database_mongo
                            .managed_database_id
                    );
                }
            }

            if (datasCustomer.customer_database_mysql) {
                if (datasCustomer.customer_database_mysql.managed_database_id) {
                    console.log(
                        "MYSQL",
                        datasCustomer.customer_database_mysql
                            .managed_database_id
                    );

                    setValue(
                        "id_cluster_mysql",
                        datasCustomer.customer_database_mysql
                            .managed_database_id
                    );
                }
            }
        }
    }, [datasCustomer]);

    const clouds = ["gcp", "aws", "digitalocean"];
    return (
        <Modal
            open={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="modal-customer-edit-title"
            aria-describedby="modal-customer-edit-description"
        >
            <Container onSubmit={handleSubmit(handleEditCustomer)}>
                <TitleModal>Editar Cliente</TitleModal>
                <Body>
                    <ContentInput>
                        <TextField
                            variant="outlined"
                            label="Nome do projeto"
                            size="small"
                            fullWidth
                            value={datasCustomer?.project_name}
                            disabled
                        />

                        <Controller
                            control={control}
                            name="nameCompany"
                            render={({
                                field: { onChange, value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    variant="outlined"
                                    label="Nome"
                                    size="small"
                                    onChange={onChange}
                                    value={value}
                                    fullWidth
                                    error={Boolean(error)}
                                    helperText={error && error?.message}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="email"
                            render={({
                                field: { onChange, value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    size="small"
                                    onChange={onChange}
                                    value={value}
                                    fullWidth
                                    error={Boolean(error)}
                                    helperText={error && error?.message}
                                />
                            )}
                        />
                    </ContentInput>

                    <ContentInput>
                        <Controller
                            control={control}
                            name="phone"
                            render={({
                                field: { value = "" },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    variant="outlined"
                                    label="Telefone"
                                    size="small"
                                    fullWidth
                                    onChange={(event) => {
                                        setValue(
                                            "phone",
                                            phoneMask(event.target.value)
                                        );
                                    }}
                                    value={value}
                                    error={Boolean(error)}
                                    helperText={error && error?.message}
                                    inputProps={{
                                        maxLength: 14,
                                    }}
                                />
                            )}
                        />

                        {datasCustomer?.document && (
                            <TextField
                                variant="outlined"
                                label="Documento"
                                size="small"
                                fullWidth
                                disabled
                                value={maskCpfCnpj(
                                    datasCustomer?.document as string
                                )}
                                inputProps={{
                                    maxLength: 18,
                                }}
                            />
                        )}

                        <Controller
                            control={control}
                            name="holder"
                            render={({
                                field: { value },
                                fieldState: { error },
                            }) => (
                                <SelectSearch
                                    fullWidth
                                    width="400"
                                    label="Titular"
                                    options={listHolder}
                                    placeholder="Tilular"
                                    setValue={(value) => {
                                        setValue("holder", value);
                                    }}
                                    loading={loadingHolders}
                                    onChangeTextField={handleGetHolders}
                                    value={value}
                                    error={Boolean(error)}
                                    textError={error?.message}
                                />
                            )}
                        />
                    </ContentInput>

                    <Divider />

                    <SubTitle>Cloud - Banco de dados</SubTitle>

                    <ContentInput>
                        <Controller
                            control={control}
                            name="id_cluster_mongo"
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <FormControl
                                    fullWidth
                                    size="small"
                                    error={Boolean(error)}
                                >
                                    <InputLabel id="select-mongo-cluster-label">
                                        MongoDB
                                    </InputLabel>
                                    <Select
                                        labelId="select-mongo-cluster-label"
                                        id="select-mongo-cluster"
                                        label="MongoDB"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {listClustersMongo.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.cluster_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <Controller
                            control={control}
                            name="id_cluster_mysql"
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <FormControl
                                    fullWidth
                                    size="small"
                                    error={Boolean(error)}
                                >
                                    <InputLabel id="select-mysql-cluster-label">
                                        Mysql
                                    </InputLabel>
                                    <Select
                                        labelId="select-mysql-cluster-label"
                                        id="select-mysql-cluster"
                                        label="Mysql"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {listClustersMysql.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.cluster_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </ContentInput>
                    <div>
                        <Controller
                            control={control}
                            name="cloud"
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <FormControl
                                    fullWidth
                                    size="small"
                                    error={Boolean(error)}
                                >
                                    <InputLabel id="select-cloud">
                                        Cloud Provider
                                    </InputLabel>
                                    <Select
                                        labelId="select-cloud"
                                        id="select-mysql-cluster"
                                        label="Cloud Provider"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {clouds.map((value) => {
                                            return (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </div>
                </Body>

                <ContentButtonModal>
                    <ButtonGeneric
                        onclick={() => setVisible(false)}
                        buttonColor="var(--cancel)"
                        text="CANCELAR"
                        className="cancelButton"
                    />
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={loading}
                    >
                        Confirmar
                    </LoadingButton>
                </ContentButtonModal>
            </Container>
        </Modal>
    );
}

export default ModalEditCustomer;
