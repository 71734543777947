import styled from "styled-components";

export const ContractSignedPageStyles = styled.div`
    width: 100%;
    min-width: 280px;
    height: 100vh;
    overflow: auto;
    color: #363638;
    margin: auto auto;
    background: #363638;
    display: flex;
    justify-content: center;
    align-items: center;

    .contractSignedImageStatus {
        width: 50px;
    }

    .messageContainer {
        text-align: center;
        display: flex;
        width: 80%;
        max-width: 900px;
        border-radius: 10px;
        padding: 50px 10px;
        background-color: #f6f6f6;
        flex-direction: column;
        align-items: center;
        /* margin-top: 10%; */
    }

    @media (max-width: 1100px) {
        @media (max-width: 600px) {
            .messageContainer {
                margin-top: 30%;
            }
        }
        @media (max-width: 470px) {
            .messageContainer {
                margin-top: 50%;
            }
        }
    }
`;
