import { Close, Check } from "@mui/icons-material";
import { Modal, Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import {
    Container,
    ContentItems,
    Items,
    TitleModal,
    ContentButtonClose,
} from "./styles";

import { ItemsInformationsProps } from "../../pages/ValidationsAppPages/types";

interface ModalValidationsProps {
    visible: boolean;
    setVisible: (data: boolean) => void;
    items: ItemsInformationsProps[] | null;
    setItems: Dispatch<SetStateAction<ItemsInformationsProps[] | null>>;
}

export default function ModalValidations({
    visible,
    setVisible,
    items,
    setItems,
}: ModalValidationsProps) {
    const handleCloseModal = () => {
        setVisible(false);
        setItems(null);
    };

    return (
        <Modal open={visible} onClose={handleCloseModal}>
            <Container>
                <TitleModal>Detalhes de validações</TitleModal>

                <div>
                    <ContentItems>
                        {items?.map((value) => {
                            return (
                                <Items>
                                    <span>{value.item.description}</span>
                                    <span>
                                        {value.validated === 1 ? (
                                            <Check color="success" />
                                        ) : (
                                            <Close color="error" />
                                        )}
                                    </span>
                                </Items>
                            );
                        })}
                    </ContentItems>
                </div>

                <ContentButtonClose>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "var(--cancel)",
                        }}
                        onClick={handleCloseModal}
                    >
                        Fechar
                    </Button>
                </ContentButtonClose>
            </Container>
        </Modal>
    );
}
