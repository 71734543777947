import { Container, Message, Animation } from "./styles";

import pathAnimationChecked from "../../assets/animations/checked-animation.json";

export default function CheckListFinalized() {
    return (
        <Container>
            <Animation animationData={pathAnimationChecked} loop />
            <Message>Formulário de validação de aplicativo preenchido</Message>
        </Container>
    );
}
