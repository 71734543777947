import styled from "styled-components";

export const LoginPageStyle = styled.section`
    @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
    @keyframes neon {
        from {
            filter: drop-shadow(0px 0px 0px var(--cor-neon));
        }
        to {
            filter: drop-shadow(0px 0px 10px var(--cor-neon));
        }
    }

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    width: 100%;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8898aa;
    background-color: #fff;

    .mainContainer {
        box-shadow: 0px 0px 25px 25px #eef2f5;
        padding: 48px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img {
        width: 100px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 8px #eef2f5;
    }

    h1 {
        font-weight: 300;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .inputsContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .inputFormStyle {
        width: 200px;
        border: 1px solid #cad1d7;
        outline: none;
        border-radius: 6px;
        padding: 15px 20px;
        font-weight: 600;
    }

    .inputFormStyle:focus {
        outline: none;
        animation: neon 2s alternate infinite ease-in-out;
        --cor-neon: #cad1d7;
    }

    .loginButton {
        text-decoration: none;
        outline: none;
        border: none;
        width: 100%;
        min-height: 64px;
        padding: 20px 30px;
        color: white;
        background-color: #172b4d;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        border-radius: 6px;
        font-weight: 700;
        font-size: 15px;
    }

    .loginButton:hover {
        background-color: #172b3e;
        animation: neon 2s alternate infinite ease-in-out;
        --cor-neon: #172b1d;
    }

    .iconConfirm {
        width: 20px;
    }

    .bottomMessage {
        font-size: 10px;
        font-weight: 400;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .iconSecurity {
        width: 15px;
    }

    @media (max-width: 500px) {
        .mainContainer {
            box-shadow: none;
            padding: 48px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 150px;
        }
    }
`;
