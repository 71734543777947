// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const expirationDateMask = (value: string) => {
    let newValue = value.replace(/\D/g, "");

    if (newValue.length > 4) {
        newValue = newValue.slice(0, 4);
    }

    newValue = newValue.replace(/(\d{2})(\d)/, "$1/$2");

    return newValue;
};
