import { Add, Delete, Edit } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonGeneric from "../../components/ButtonGeneric";
import ModalDeletePlan from "../../components/DeletePlanModal";
import { If } from "../../components/If";
import PageWrapper from "../../components/PageWrapper/index";
import TableDataGrid from "../../components/TableDataGrid";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { api } from "../../services/api";

export interface DataPlanProps {
    id?: number | string;
    description?: string;
}

function PlansPage() {
    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();
    useEffect(() => {
        if (
            hasPermissions("produtos.listar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error");
        }
    }, [permissions]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataPlansList, setDataPlansList] = useState<DataPlanProps[]>([]);
    const [openDeletePlanModal, setOpenDeletePlanModal] = useState(false);
    const handleCloseDeletePlanModal = () => {
        setOpenDeletePlanModal(false);
    };
    const [selectedRowPlan, setSelectedRowPlan] = useState("");

    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    useEffect(() => {
        api.get("/plans")
            .then((response) => {
                setLoadingTable(false);
                setDataPlansList(response.data.response.data);
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, []);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 5 },
        { field: "description", headerName: "Nome", flex: 45 },
        {
            field: "ações",
            headerName: "Ações",
            flex: 30,
            sortable: false,
            align: "right",
            headerAlign: "right",
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <div className="containerActionButtonsListTablePagesGlobal">
                    <If condition={hasPermissions("produtos.editar")}>
                        <Edit
                            className="cursorPointerGlobal"
                            onClick={() => {
                                navigate(
                                    `/settings/products/editPlan?id=${params.id}`
                                );
                            }}
                        />
                    </If>
                    <If condition={hasPermissions("produtos.excluir")}>
                        <Delete
                            className="cursorPointerGlobal"
                            onClick={() => {
                                setOpenDeletePlanModal(true);
                            }}
                        />
                    </If>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageWrapper>
                <div className="containerListTablePagesGlobal">
                    <div className="containerButtonNewRegisterListTablePagesGlobal">
                        <If condition={hasPermissions("produtos.cadastrar")}>
                            <ButtonGeneric
                                onclick={() => {
                                    navigate("/settings/products/newPlan");
                                }}
                                buttonColor="var(--primary)"
                                text="Novo Plano"
                                icon={<Add />}
                            />
                        </If>
                    </div>
                    <TableDataGrid
                        rows={dataPlansList}
                        columns={columns}
                        onRowClick={(rowParams: GridRowParams) => {
                            setSelectedRowPlan(rowParams.row);
                        }}
                        loading={loadingTable}
                    />
                    <ModalDeletePlan
                        openDeletePlanModal={openDeletePlanModal}
                        handleCloseDeletePlanModal={handleCloseDeletePlanModal}
                        selectedRowPlan={selectedRowPlan}
                        dataPlansList={dataPlansList}
                        setDataPlansList={setDataPlansList}
                    />
                </div>
            </PageWrapper>
            <Toast
                open={openToast}
                onClose={() => handleCloseToast()}
                severity={toastRequisitionResult}
                text={textToast}
            />
        </>
    );
}
export default PlansPage;
