import { HeaderStyled } from "../../pages/CustomersPage/styles";

interface Props {
    title: string;
    subtitle: string;
    icon: JSX.Element;
}

export function HeaderPage({ title, subtitle, icon }: Props) {
    return (
        <HeaderStyled>
            <span
                className="tableIcon"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "var(--secondary)",
                    padding: "10px",
                    height: "30px",
                    width: "30px",
                }}
            >
                {icon}
            </span>
            <div
                className="description"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                }}
            >
                <h2 style={{ margin: 0 }}>{title}</h2>
                <p style={{ margin: 0 }}>{subtitle}</p>
            </div>
        </HeaderStyled>
    );
}
