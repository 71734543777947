import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GridRowParams } from "@mui/x-data-grid";
import { useState } from "react";

import { ContractsProps } from "../../pages/ContractsPage";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import PreloadButton from "../PreloadButton";
import Toast from "../Toast";
import { ModalDeleteContractStyle } from "./styles";

interface Props {
    openDeleteContractModal: boolean;
    handleCloseDeleteContractModal: () => void;
    selectedRowContract: GridRowParams["row"];
    setDataContractsList: (values: ContractsProps[]) => void;
    dataContractsList: ContractsProps[];
}

function ModalDeleteContract({
    openDeleteContractModal,
    handleCloseDeleteContractModal,
    selectedRowContract,
    setDataContractsList,
    dataContractsList,
}: Props) {
    // toast configs
    const [openToast, setOpenToast] = useState(false);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    function deleteContract() {
        setIsLoadingButton(true);
        api.delete(`/contracts/${selectedRowContract.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(response.data.message);
            })
            .then(() => {
                setTimeout(() => {
                    handleCloseDeleteContractModal();
                    setOpenToast(false);
                    setIsLoadingButton(false);
                }, 2000);
            })
            .then(() => {
                setDataContractsList(
                    dataContractsList.filter((item: { id: number }) => {
                        return item.id !== selectedRowContract.id;
                    })
                );
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
                setIsLoadingButton(false);
            });
    }

    return (
        <Modal
            open={openDeleteContractModal}
            onClose={handleCloseDeleteContractModal}
        >
            <ModalDeleteContractStyle>
                <Box className="modalContainer">
                    <p>{`Você realmente deseja excluir o contrato "${selectedRowContract.title}"?`}</p>
                    <div className="buttonsContainer">
                        <ButtonGeneric
                            onclick={handleCloseDeleteContractModal}
                            buttonColor="var(--cancel)"
                            text="CANCELAR"
                        />
                        <PreloadButton
                            colorText="white"
                            background="var(--delete)"
                            loading={isLoadingButton}
                            text="DELETAR"
                            type="submit"
                            onClick={() => deleteContract()}
                        />
                    </div>
                </Box>
                <Toast
                    open={openToast}
                    onClose={() => handleCloseToast()}
                    severity={toastRequisitionResult}
                    text={textToast}
                />
            </ModalDeleteContractStyle>
        </Modal>
    );
}
export default ModalDeleteContract;
